import { StatusComponent } from '../all-screenings/component/StatusComponent';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import corporateReportDispatcher from './action';
import GroupToolbar from './component/GroupToolbar';
import { CORPORATE_REPORT_STATUS, renderCRCol } from './constants';
import {
  ArrowDownCircle,
  LinkIconBold,
  MailIcon,
  NoDataScreenings,
} from '@/assets/svg';
import { handleSelectAll } from '@/helpers';
import CRReportViewerDownload from '@/module/coporate-report-viewer-download';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';
import { LoadingOutlined } from '@ant-design/icons';
import { IconButton, TableCell, TableHead, TableRow } from '@material-ui/core';
import { debounce } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const CorportateReport = () => {
  const { list: data, paging } = useSelector((state) => state.corporateReport);

  const [sortOption, setSortOption] = useState({ by: 'Date', dir: 'desc' });
  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState({
    appointmentTypes: ['TeleCounsel'],
  });

  const [counselDate, setCounselDate] = useState([]);
  const [statuses, setAppointmentStatuses] = useState([]);
  const [counselIds, setCounselIds] = useState([]);

  const [downloading, setDownloading] = useState({});
  const [activeRow, setActiveRow] = useState(null);
  const handleDownload = async (row) => {
    if (downloading[row.id]) return; // Prevent multiple downloads
    setDownloading((prev) => ({ ...prev, [row.id]: true }));
    setActiveRow(row); // Render CRReportPDF for this row
  };

  const onFilterOptionClear = (key, defaultValue, setState) => () => {
    setFilterOption({
      ...filterOption,
      [key]: defaultValue,
    });

    setState && setState(defaultValue);
  };

  const onFilterOptionChange = (key, setState) => (e, newValue) => {
    let value = e.target.value;

    switch (key) {
      case 'statuses':
        let newValue = handleSelectAll(
          value,
          CORPORATE_REPORT_STATUS.map((item) => item.key),
          statuses
        );

        if (newValue.includes('Upcoming')) {
          newValue = newValue.concat('Assigned');
        } else {
          newValue = newValue;
        }

        newValue = newValue.filter(
          (item, pos, self) => self.indexOf(item) === pos
        );
        value = newValue;

        break;
      case 'counselIds':
        value = newValue.map((item) => item.id);
        setCounselIds(value);
        break;
      default:
        break;
    }

    setFilterOption({
      ...filterOption,
      [key]: value,
    });

    setState && setState(value);
  };

  const onResetFilterOps = () => {
    setCounselDate([]);
    setAppointmentStatuses([]);
    setCounselIds([]);
    setFilterOption({ appointmentTypes: ['TeleCounsel'] });
  };

  const onSendMail = (id) => {
    corporateReportDispatcher.sendMail(id, () => {
      renderToast('Sent mail success', true);
    });
  };

  const onGenerateLink = (row) => {
    corporateReportDispatcher.getAccessLink(row.id, (result) => {
      navigator.clipboard.writeText(result);
      renderToast(
        <>
          <b>Generate Link</b>
          <div>
            Link Corporate report of <b>{row.minmedId}</b> has been successfully
            copied.
          </div>
        </>,
        true
      );
    });
  };

  const renderToast = (data) => {
    return customToast(
      'success',
      <span style={{ color: 'rgba(0, 0, 0, 0.65)' }}>{data}</span>
    );
  };

  const renderDate = (date) => {
    let dateFormat;
    if (!date) return null;
    dateFormat = moment(date).format('DD/MM/YYYY');
    return `${dateFormat}`;
  };

  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    sortOptionData = sortOption,
    filterOptionData = filterOption
  ) => {
    corporateReportDispatcher.getData(
      search,
      pagingData,
      ...[sortOptionData, filterOptionData].filter((i) => i)
    );
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging, sortOption, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize,
        },
        sortOption,
        filterOption
      );
    }, 500),
    []
  );
  useEffect(() => {
    debounceLoadData(searchKey, paging, sortOption, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, sortOption, filterOption]);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {renderCRCol().map((item) =>
          item.sortBy ? (
            <TableCellFilted
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              sortBy="Date"
              onSortChange={() => {
                let newSortOption = {
                  sortBy: 'StartDate',
                  orderBy: sortOption.orderBy !== 'Asc' ? 'Asc' : 'Desc',
                };
                setSortOption(newSortOption);
              }}
              style={{
                minWidth: item.minWidth || 'unset',
                width: item.width || 'unset',
              }}
              isHidden={false}
            />
          ) : (
            <TableCellFilted
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              style={{
                minWidth: item.minWidth || 'unset',
                width: item.width || 'unset',
              }}
              isHidden={false}
            />
          )
        )}
      </TableRow>
    </TableHead>
  );
  const renderTableBody = (row) => {
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.corporateName} />
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.minmedId} />
        </TableCell>
        <TableCell>
          {renderDate(row?.startDate)} - {renderDate(row?.endDate)}
        </TableCell>

        <TableCell>
          <StatusComponent status={row.status} />
        </TableCell>
        <TableCell>
          {activeRow?.id === row.id && (
            <CRReportViewerDownload
              downloading={downloading}
              resetData={() => {
                setDownloading({});
                setActiveRow(null);
              }}
              projectId={row.id}
            />
          )}{' '}
          <>
            <IconButton
              disabled={downloading[row.id] || row.status !== 'Done'}
              onClick={() => handleDownload(row)}
            >
              {downloading[row.id] ? (
                <LoadingOutlined
                  style={{ fontSize: 16, color: '#6c6c6c', marginRight: 7.5 }}
                />
              ) : (
                <ArrowDownCircle />
              )}
            </IconButton>
            <IconButton
              disabled={!row.hrEmail || row.status !== 'Done'}
              onClick={() => {
                onSendMail(row.id);
              }}
            >
              <MailIcon />
            </IconButton>
            <IconButton
              disabled={!row.hrEmail || row.status !== 'Done'}
              onClick={() => {
                onGenerateLink(row);
              }}
            >
              <LinkIconBold
                color={
                  !row.hrEmail || row.status !== 'Done' ? '#ccc' : '#6c6c6c'
                }
              />
            </IconButton>
          </>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <CustomPaperContainer
        header={
          <CustomHeader
            searchPlaceholder="Search by project name or code..."
            title="Corporate Reports"
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            sortOption={sortOption}
            fetchData={fetchData}
            renderToolbar={() => (
              <GroupToolbar
                filterOption={filterOption}
                setFilterOption={setFilterOption}
                onFilterOptionClear={onFilterOptionClear}
                onFilterOptionChange={onFilterOptionChange}
                onResetFilterOps={onResetFilterOps}
                counselDate={counselDate}
                setCounselDate={setCounselDate}
                statuses={statuses}
                setAppointmentStatuses={setAppointmentStatuses}
                counselIds={counselIds}
                setCounselIds={setCounselIds}
              />
            )}
          />
        }
      >
        <CustomTable
          data={data}
          paging={paging}
          header={TableHeader}
          renderRow={(row, i) => renderTableBody(row)}
          noDataHelperText="No coporate reports"
          noDataHelperSubText="Please come back at another time."
          Icon={NoDataScreenings}
          totalCols={renderCRCol().length}
          fetchData={fetchData}
        />
      </CustomPaperContainer>
    </>
  );
};

export default CorportateReport;
