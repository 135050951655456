import { formatTotalPrice } from '../checkin-patient-information/util';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import upcomingHSDispatcher from './action';
import GroupToolbar from './component/GroupToolbar';
import { StatusComponent } from './component/StatusComponent';
import {
  HS_COLUMN_UPCOMING,
  HS_PAXSCREENING_STATUS,
  HS_SERVICE_TYPES,
  HS_UPCOMING_PAXSCREENING_STATUS,
} from './constants';
import {
  CloseCircle,
  EditIcon,
  ExportIcon,
  NewLocation,
  NoDataScreenings,
  ReverseIcon,
} from '@/assets/svg';
import { urlLabel } from '@/enum/PermissionEnum';
import { getCostRange } from '@/helpers';
import CustomMenu from '@/new-components/CustomMenu';
import CustomMenuActions from '@/new-components/CustomMenuActions';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';
import globalDispatcher from '@/redux/global/actions';
import { TableCell } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { MoreVertRounded } from '@material-ui/icons';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const CustomButton = withStyles({
  root: {
    height: 40,
    minWidth: 105,
    borderRadius: 8,

    fontSize: 16,
    textTransform: 'unset',
  },
})(Button);

const UpcomingHealthScreening = () => {
  const { list: data, paging } = useSelector((state) => state.upcomingHS);
  const { locations: listLocations } = useSelector(
    (state) => state.globalState
  );

  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortOption, setSortOption] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [paxScreenStatuses, setPaxScreenStatuses] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [genders, setGenders] = useState([]);
  const [locationIds, setLocationIds] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState({});
  const [quickRange, setQuickRange] = useState(null);
  const [maxPriceDefault, setMaxPriceDefault] = useState(0);

  const [filterOption, setFilterOption] = useState({
    paxScreenStatuses: HS_UPCOMING_PAXSCREENING_STATUS.map((item) => item.key),
    startDate: moment().startOf('date'),
    endDate: moment().endOf('date'),
  });

  const renderDateTime = (row) => {
    let startDateFormat, startTimeFormat;
    const { startDate } = row;

    if (!startDate) return null;

    startDateFormat = moment(startDate).format('DD/MM/YYYY');
    startTimeFormat = moment(startDate).format('LT');
    return `${startDateFormat} ${startTimeFormat}`;
  };

  const handlePatientCheckIn = () => {
    const successDescription = `Patient ${selectedItem.fullName} has successfully checked in the location`;
    upcomingHSDispatcher.patientCheckInLocation(selectedItem.id, () => {
      fetchData();
      customToast('success', successDescription, 'Checked in');
    });
  };

  const renderListActions = (status) => {
    if (!status) return [];
    let listActions = [];
    switch (status) {
      case HS_PAXSCREENING_STATUS.Upcoming:
        listActions = listActions.concat(
          {
            key: 'check-in-location',
            icon: NewLocation,
            label: 'Check in',
            onClick: () => {
              setAnchorEl(null);
              handlePatientCheckIn();
            },
          },
          {
            key: 'edit',
            icon: EditIcon,
            label: 'Edit appointment',
            styles: { width: 20, height: 20 },
            onClick: onEditAppointment,
          },
          {
            key: 'cancel',
            icon: CloseCircle,
            label: 'Cancel appointment',
            color: '#DB3D49',
            styles: { width: 20, height: 20 },
            onClick: () => {
              setAnchorEl(null);
              setShowCancelModal(true);
            },
          }
        );
        break;
      case HS_PAXSCREENING_STATUS.CheckInLocation:
        break;
      case HS_PAXSCREENING_STATUS.Completed:
        break;
      case HS_PAXSCREENING_STATUS.InProgress:
        break;
      case HS_PAXSCREENING_STATUS.Missed:
        listActions = listActions.concat({
          key: 'reschedule',
          icon: ReverseIcon,
          label: 'Reschedule appointment',
          onClick: onRescheduleAppointment,
        });
        break;
      case HS_PAXSCREENING_STATUS.Waiting:
        break;

      default:
        break;
    }

    return listActions;
  };

  const onExportExcel = () => {
    if (data.length === 0) {
      return customToast(
        'error',
        'There is no data available to export the excel file'
      );
    }
    let params = {
      ...sortOption,
      ...filterOption,
      search: searchKey,
    };

    upcomingHSDispatcher.getExportExcel(params);
  };

  const onCancelAppointment = () => {
    if (isEmpty(selectedItem)) return;
    upcomingHSDispatcher.cancelTeleAppointment(selectedItem.id, () => {
      customToast('success', 'Cancel Appointment Success');
      upcomingHSDispatcher.getData(searchKey, paging, filterOption);
      setShowCancelModal(false);
    });
  };
  const onEditAppointment = () => {
    history.push({
      pathname: `/${urlLabel.upcomingEditAppointment}`,
      state: selectedItem,
      label: 'Edit appointment',
    });
  };
  const onRescheduleAppointment = () => {
    history.push({
      pathname: `/${urlLabel.upcomingEditAppointment}`,
      state: { ...selectedItem, isReschedule: true },
      label: 'Reschedule appointment',
    });
  };

  const onResetFilterOps = () => {
    setLocationIds([]);
    setPaxScreenStatuses([]);
    setGenders([]);
    setServiceTypes([]);
    setFilterOption({
      paxScreenStatuses: HS_UPCOMING_PAXSCREENING_STATUS.map(
        (item) => item.key
      ),
      startDate: moment().startOf('date'),
      endDate: moment().endOf('date'),
    });
  };

  const onFilterOptionClear = (key, defaultValue, setState) => () => {
    let clearValue = defaultValue;
    switch (key) {
      case 'genders':
        clearValue = '';
        break;
      case 'paxScreenStatuses':
        clearValue = [];
        break;

      default:
        break;
    }

    setFilterOption({
      ...filterOption,
      [key]: defaultValue,
    });

    setState && setState(clearValue);
  };

  const onFilterOptionChange = (key, setState) => (e, newValue) => {
    let value = e?.target?.value;

    switch (key) {
      case 'genders':
        value = [value];
        break;
      case 'minCost':
      case 'maxCost':
        value = e.value;
        break;
      case 'paxScreenStatuses':
        value = handleSelectAll(
          value,
          HS_UPCOMING_PAXSCREENING_STATUS.map((item) => item.key),
          paxScreenStatuses
        );
        break;
      case 'serviceTypes':
        value = handleSelectAll(
          value,
          HS_SERVICE_TYPES.map((item) => item.value),
          serviceTypes
        );
        break;
      case 'locationIds':
        value = newValue.map((item) => item.id);
        setLocationIds(value);
        break;
      default:
        break;
    }

    if (key === 'paxScreenStatuses' && isEmpty(value)) {
      setFilterOption({
        ...filterOption,
        paxScreenStatuses: HS_UPCOMING_PAXSCREENING_STATUS.map(
          (item) => item.key
        ),
      });
    } else if (key === 'serviceTypes') {
      // Check if Others is selected
      if (value.includes('Others')) {
        setFilterOption({
          ...filterOption,
          [key]: value.filter((it) => it !== 'Others'),
          filterOtherService: true,
        });
      } else {
        delete filterOption.filterOtherService;
        setFilterOption({
          ...filterOption,
          [key]: value.filter((it) => it !== 'Others'),
        });
      }
    } else
      setFilterOption({
        ...filterOption,
        [key]: value,
      });
    setState && setState(value);
  };

  const handleSelectAll = (inputValue, originArr, newArr) => {
    let newValue = [...inputValue];
    const isEqualLength = originArr.length === newArr.length;

    if (inputValue.includes('selectAll')) {
      if (isEqualLength) newValue = [];
      else newValue = [...originArr];
    }
    return newValue;
  };

  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    sortOptionData = sortOption,
    filterOptionData = filterOption
  ) => {
    upcomingHSDispatcher.getData(
      search,
      pagingData,
      ...[sortOptionData, filterOptionData].filter((i) => i)
    );
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging, sortOption, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize,
        },
        sortOption,
        filterOption
      );
    }, 500),
    []
  );

  useEffect(() => {
    debounceLoadData(searchKey, paging, sortOption, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, sortOption, filterOption]);

  useEffect(() => {
    globalDispatcher.getListLocations();
    globalDispatcher.getMaxPriceDefault('HSUpcoming', (data) => {
      setMaxPriceDefault(data);
      setFilterOption({
        ...filterOption,
        maxCost: data,
        minCost: 0,
      });
    });

    return () => {
      upcomingHSDispatcher.resetData();
    };
  }, []);

  useEffect(() => {
    const [minCost, maxCost] = getCostRange(quickRange, maxPriceDefault);
    if (
      Number(filterOption.minCost) !== Number(minCost) ||
      Number(filterOption.maxCost) !== Number(maxCost)
    )
      setQuickRange(null);
  }, [filterOption, maxPriceDefault, quickRange]);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {HS_COLUMN_UPCOMING.map((item) =>
          item.sortBy ? (
            <TableCellFilted
              isHidden={false}
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              sortBy="Date"
              sortOption={sortOption}
              onSortChange={() => {
                let newSortOption = {
                  sortBy: 'BookedApptDate',
                  orderBy: sortOption.orderBy !== 'Asc' ? 'Asc' : 'Desc',
                };
                setSortOption(newSortOption);
              }}
              style={{ minWidth: item.minWidth || 'unset' }}
            />
          ) : (
            <TableCell
              key={item.stateValue}
              style={{
                minWidth: item.minWidth,
                maxWidth: item.maxWidth,
                width: item.width,
              }}
            >
              {item.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = (row) => {
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.fullName} />
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.nric} />
        </TableCell>
        <TableCell>{HS_SERVICE_TYPES[row.packageType]}</TableCell>
        <TableCell>{renderDateTime(row)}</TableCell>
        <TableCell>
          <CustomTooltip content={row.packageName} />
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.location} />
        </TableCell>
        <TableCell>{formatTotalPrice(row.totalCost, '$')}</TableCell>
        <TableCell>
          <StatusComponent status={row.paxScreenStatus} />
        </TableCell>
        <TableCell>
          <IconButton
            disabled={row.paxScreenStatus === HS_PAXSCREENING_STATUS.Cancelled}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setSelectedItem(row);
            }}
          >
            <MoreVertRounded />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <CustomMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <CustomMenuActions
          listActions={renderListActions(selectedItem.paxScreenStatus)}
        />
      </CustomMenu>
      <CustomPaperContainer
        header={
          <CustomHeader
            searchPlaceholder="Search by name or ID number..."
            title="Appointments Today"
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            sortOption={sortOption}
            fetchData={fetchData}
            renderToolbar={() => (
              <GroupToolbar
                genders={genders}
                setGenders={setGenders}
                serviceTypes={serviceTypes}
                setServiceTypes={setServiceTypes}
                paxScreenStatuses={paxScreenStatuses}
                setPaxScreenStatuses={setPaxScreenStatuses}
                locationIds={locationIds}
                setLocationIds={setLocationIds}
                filterOption={filterOption}
                setFilterOption={setFilterOption}
                quickRange={quickRange}
                setQuickRange={setQuickRange}
                onFilterOptionClear={onFilterOptionClear}
                onFilterOptionChange={onFilterOptionChange}
                onResetFilterOps={onResetFilterOps}
                maxPriceDefault={maxPriceDefault}
              />
            )}
            renderButton={() => (
              <CustomButton
                color="primary"
                variant="contained"
                startIcon={<ExportIcon width={20} height={20} />}
                onClick={onExportExcel}
              >
                Export
              </CustomButton>
            )}
          />
        }
        children={
          <CustomTable
            data={data}
            Icon={NoDataScreenings}
            paging={paging}
            header={TableHeader}
            renderRow={(row, i) => renderTableBody(row)}
            noDataHelperText="No screenings"
            noDataHelperSubText="Please come back at another time."
            totalCols={HS_COLUMN_UPCOMING.length}
            fetchData={fetchData}
          />
        }
      />

      {showCancelModal && (
        <ModalConfirmation
          open={showCancelModal}
          onClick={onCancelAppointment}
          onClose={() => setShowCancelModal(false)}
          mainContent={'Cancel appointment'}
          subContent={'Are you sure you want to cancel this appointment?'}
          cancelLabel="No, keep it"
          confirmLabel="Confirm"
          confirmColor="red"
        />
      )}
    </>
  );
};

export default UpcomingHealthScreening;
