import MenuAction from '../../../components/MenuAction';
import TableContainer from '../../../components/Table/TableContainer';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import allUsersDispatcher from '../action';
import { urlLabel } from '@/enum/PermissionEnum';
import { getNationalNumber } from '@/helpers';
import customToast from '@/new-components/CustomNotification';
import {
  TableHead,
  TableRow,
  TableCell,
  Menu,
  IconButton,
} from '@material-ui/core';
import { CreateRounded, GetApp, MoreVertRounded } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const AllUsers = () => {
  const { list, paging } = useSelector((state) => state.allUsers);
  const history = useHistory();

  const [itemSelected, setItemSelected] = useState(null);
  const [hiddenRows, setHiddenRows] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const onShowAllUsers = () =>
    history.push({
      pathname: `/${urlLabel.editUserDetail}`,
      state: itemSelected,
    });

  const exportExcel = () => {
    if (list.length === 0) {
      return customToast(
        'info',
        'There is no data available to export the excel file'
      );
    }
    allUsersDispatcher.getExportExcel(searchKey);
  };

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows,
      }}
      {...props}
    />
  );
  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper label="Name" stateValue="name" />
        <TableCellFiltedWrapper label="Date of Birth" stateValue="birthday" />
        <TableCellFiltedWrapper label="NRIC" stateValue="nric" />
        <TableCellFiltedWrapper label="Notification Email" stateValue="email" />
        <TableCellFiltedWrapper
          label="Mobile Number"
          stateValue="mobile"
          style={{ width: 120 }}
        />
        <TableCell align="right">
          <IconButton
            style={{ marginBottom: -12 }}
            aria-label="export"
            onClick={exportExcel}
          >
            <GetApp />
          </IconButton>
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const listActions = [
    {
      key: 'detail',
      icon: CreateRounded,
      label: 'Detail',
      onClick: onShowAllUsers,
    },
  ];

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {anchorEl && <MenuAction listActions={listActions} />}
      </Menu>
      <TableContainer
        title="All Users"
        paging={paging}
        data={list}
        fullWidthSearch
        searchPlaceholder="User Name, User ID, NRIC, Email, Mobile Number..."
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        query={allUsersDispatcher.getAllUsers}
        renderRow={(row, i) => (
          <>
            <TableCell>{!hiddenRows.name && row.fullName}</TableCell>
            <TableCell>
              {!hiddenRows.birthday
                ? isEmpty(row.dateOfBirth)
                  ? ''
                  : moment(row.dateOfBirth).format('DD/MM/yyyy')
                : ''}
            </TableCell>
            <TableCell>{!hiddenRows.nric && row.nric}</TableCell>
            <TableCell>{!hiddenRows.email && row.email}</TableCell>
            <TableCell>
              {!hiddenRows.mobile
                ? isEmpty(row.mobile)
                  ? ''
                  : getNationalNumber(row.mobile, row.countryCode, true)
                : ''}
            </TableCell>
            <TableCell align="right">
              <IconButton
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setItemSelected(row);
                }}
              >
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </>
        )}
      />
    </>
  );
};

export default AllUsers;
