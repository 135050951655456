import { COLORS } from '../../constants';
import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';

export const DEFAULT = [
  {
    answer: 'Less than 2 servings of fruits and 2 servings of vegetables  ',
    total: 2,
    percent: 28.57,
  },
  {
    answer: '2 servings of fruits and 2 servings of vegetables',
    total: 2,
    percent: 28.57,
  },
  {
    answer: 'More than 2 servings of fruits and 2 servings of vegetables',
    total: 3,
    percent: 42.86,
  },
];

const ListOfAnswersCom = ({ data = DEFAULT, colors = COLORS }) => {
  const listAnswers = useMemo(() => {
    return data.map((item, index) => ({
      color: item.color,
      title: item?.answer,
      value: item?.total,
    }));
  }, [data]);

  return (
    <Box display="flex" gridGap="0.67vw" flexDirection="column">
      {listAnswers.map((it, inx) => (
        <Box key={`${it.title}-${inx}`} display="flex" gridGap="0.67vw">
          <Box
            fontFamily="OpenSans"
            fontSize="1.3vw"
            lineHeight="1.8vw"
            borderRadius="0.67vw"
            width={'25.1vw'}
            bgcolor={'#E0E0E0'}
            px={'1.34vw'}
            py={'0.67vw'}
          >
            {it.title}
          </Box>
          <Box
            fontFamily="OpenSans"
            fontSize="1.3vw"
            lineHeight="1.8vw"
            borderRadius="0.67vw"
            width={'4.72vw'}
            bgcolor={'#F5F5F5'}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {it.value}
          </Box>
          <Box borderRadius={'0.67vw'} width={'4.72vw'} bgcolor={it.color} />
        </Box>
      ))}
    </Box>
  );
};

export default ListOfAnswersCom;
