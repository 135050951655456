import bgFrontCover from '@/assets/image/pdfexport/bg-front-cover.png';
import checkmarkShieldImg from '@/assets/image/pdfexport/checkmark-shield.png';
import { Box, Link, withStyles } from '@material-ui/core';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

const CustomContainer = withStyles({
  root: {
    padding: '0 6.72vw',
    height: 'calc(100vw*1.4)',
    width: '100vw',
    backgroundImage: `url(${bgFrontCover})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
  },
})(Box);
const CustomLink = withStyles({
  root: {
    color: '#333',
  },
})(Link);

const FontCover = ({ corporateData }) => {
  const companyName = get(corporateData, 'projectInfo.companyName') || '';

  const projectName = get(corporateData, 'projectInfo.projectName') || '';
  const projectCode = get(corporateData, 'projectInfo.projectCode') || '';
  const reportDateToShow = moment().format('DD/MM/YYYY');
  const startDate = get(corporateData, 'projectInfo.startDate') || '';
  const endDate = get(corporateData, 'projectInfo.endDate') || '';
  const reportYearToShow = moment(startDate).format('YYYY');

  return (
    <CustomContainer>
      <Box pt="79.66vw">
        <Title>Corporate Report {reportYearToShow}</Title>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="28.57vw"
          height="5.71vw"
          bgcolor="#3776AB"
          borderRadius="2.7vw"
          color="#FFFFFF"
          fontFamily="OpenSans"
          fontSize="2.01vw"
          fontWeight={600}
          gridGap={8}
        >
          <img
            alt="#"
            src={checkmarkShieldImg}
            style={{ width: '2.7vw', height: '2.7vw' }}
          />
          Private & Confidential
        </Box>

        <InfoContainer>
          <InfoTitle>{companyName}</InfoTitle>
          {/* <InfoRow>
            <div>
              <InfoName>Project Code:</InfoName> {projectCode}
            </div>
            <div>
              <InfoName>Screening date: </InfoName>
              {`${moment(startDate).format('DD/MM/YYYY')} - ${moment(
                endDate
              ).format('DD/MM/YYYY')}`}
            </div>
          </InfoRow>
          <InfoRow>
            <div></div>
            <div>
              <InfoName>Report date:</InfoName> {reportDateToShow}
            </div>
          </InfoRow> */}
        </InfoContainer>
      </Box>

      <SocialContainer>
        <Box fontSize="1.5vw">@minmedgroup</Box>
        <Box fontSize="1.5vw">
          <CustomLink href="www.minmed.sg" target="_blank">
            <span className="label">www.minmed.sg</span>
          </CustomLink>
        </Box>
        <Box fontSize="1.5vw">
          <CustomLink href="https://wa.me/6592300123" target="_blank">
            <span className="label">https://wa.me/6592300123</span>
          </CustomLink>
        </Box>
      </SocialContainer>
    </CustomContainer>
  );
};

const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-top: 9.5vw;
  // margin-left: 8.9vw;

  position: absolute;
  width: 78vw;
  bottom: 4.03vw;
  left: 15.8vw;
`;
const InfoName = styled.span`
  font-weight: 500;
`;
const InfoRow = styled.div`
  display: flex;
  align-items: center;
  font-family: 'OpenSans';
  font-size: 2.01vw;
  line-height: 3.02vw;
  letter-spacing: 0%;
  & > div {
    flex: 1 1 0px;
    text-align: left;
  }
  margin: 10px 0;
`;
const InfoTitle = styled.div`
  font-family: 'OpenSans';
  font-weight: 700;
  font-size: 2.69vw;
  line-height: 4.03vw;
  letter-spacing: 0px;
  margin-bottom: 16px;
  color: #333333;
`;
const InfoContainer = styled.div`
  background-color: white;
  border-radius: 2.01vw;
  padding: 4.03vw;
  margin-top: 4.03vw;
  border: 1px solid #fafafa;
`;

const Title = styled.div`
  font-family: 'Lato';
  font-weight: 700;
  font-size: 8.06vw;
  line-height: 10.75vw;
  letter-spacing: 0px;
  margin-bottom: 32px;
  color: #333333;
`;
export default FontCover;
