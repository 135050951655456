import { mergeArrayByKey } from '../../../helpers';
import {
  ROLE_TYPE,
  ROLE_TYPE_CA,
  ROLE_TYPE_CLINIC_ADMIN,
  ROLE_TYPE_MMS_PROJECT_MANAGER,
} from '@/module/role-management/constants';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: (data) => ({ data }),
  getData:
    (search, paging, filterOption = {}) =>
    async ({ Api, getState }) => {
      let pagingData = paging || getState().admin.paging;
      let { result, status } = await Api.post(`SystemAdmins/filter`, {
        pageIndex: pagingData.pageIndex,
        pageSize: pagingData.pageSize,
        search,
        ...filterOption,
      });
      status === 200 && adminDispatcher.getDataSuccess(result);
    },
  setState: (state, value) => ({ state, value }),
  createUser:
    (data, callback) =>
    async ({ Api, customToast }) => {
      const { status } = await Api.post('SystemAdmins', data);
      adminDispatcher.setState('loading', false);

      if (status === 200) {
        customToast('success', 'Success');
        callback();
      }
    },
  updateUser:
    (payload, callback) =>
    async ({ Api, customToast }) => {
      const { id, data } = payload;
      const { status } = await Api.put(`SystemAdmins/${id}`, data);
      adminDispatcher.setState('loading', false);

      if (status === 200) {
        customToast('success', 'Success');
        callback();
      }
    },
  deleteData:
    (id, searchKey, filterOption = {}) =>
    async ({ Api, customToast, getState }) => {
      const { status } = await Api.delete(`SystemAdmins/${id}`);
      if (status === 200) {
        let { paging } = getState().admin;
        adminDispatcher.getData(searchKey, paging, filterOption);
        customToast('success', 'Deleted');
      }
    },
  getAdminRole:
    (callbackSuccess) =>
    async ({ Api, getState }) => {
      const { status, result } = await Api.get('Roles/can-create');

      if (status === 200) {
        let roleType = getState().auth.roleType;

        let listRoleType = ROLE_TYPE;
        if (roleType === 'ClinicAdmin') {
          listRoleType = ROLE_TYPE_CLINIC_ADMIN;
        }
        if (roleType === 'ClinicManager') {
          listRoleType = ROLE_TYPE_CA;
        } else if (
          ['MMSProjectManager', 'MSCManager', 'MSCCSA'].includes(roleType)
        ) {
          listRoleType = ROLE_TYPE_MMS_PROJECT_MANAGER;
        }

        //format data to obj with label & roleType
        const formattedData = mergeArrayByKey(listRoleType, result, 'roleType');
        adminDispatcher.getAdminRoleSuccess(formattedData);
        callbackSuccess && callbackSuccess(formattedData);
      }
    },
  getAdminRoleSuccess: (data) => ({ data }),
};

const adminDispatcher = synthesize('admin', mapDispatchToAC);
export default adminDispatcher;
