import FilterConsultAverageChart from './component/FilterConsultAverageChart';
import FilterConsultCountChart from './component/FilterConsultCountChart';
import ChartWrapper from '@/new-components/CustomChart';
import {
  DEFAULT_DATE_FILTER_TAB,
  chartPlugins,
  renderBarChartData,
  renderLineChartData,
  renderOptionBarChart,
  renderOptionLineChart,
} from '@/new-components/CustomChart/ultils';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import globalDispatcher from '@/redux/global/actions';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
} from 'chart.js';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler
);

const CustomCard = withStyles({
  root: {
    boxShadow: 'none',
    borderRadius: 8,
    borderColor: '#e1e1e1',

    overflow: 'auto',
    width: (props) => (props.width ? props.width : 'unset'),
    '& .MuiCardContent-root': {
      paddingBottom: 16,
    },

    '& .title': {
      fontWeight: 600,
      color: '#666666',
    },
    '& .main-text': {
      fontSize: 24,
      fontWeight: 700,
      fontFamily: 'Lato',
      color: '#333333',
    },
    '& .sub-text': {
      fontSize: 12,
      color: '#333333',
    },
  },
})(Card);

const CustomChip = withStyles({
  root: {
    height: 20,
    fontWeight: 500,
    fontSize: 12,
    borderRadius: 4,

    '&.up ': {
      color: '#0B8E5E',
      backgroundColor: '#B6DDCF66',

      '& .MuiChip-icon': {
        fontSize: 12,
        color: '#0B8E5E',
        backgroundColor: 'transparent',
      },
    },
    '&.down': {
      color: '#DB3D49',
      backgroundColor: '#db3d4929',

      '& .MuiChip-icon': {
        fontSize: 12,
        color: '#DB3D49',
        backgroundColor: 'transparent',
      },
    },
  },
})(Chip);

const TitleText = withStyles({
  root: {
    marginBottom: 20,
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 700,
    color: '#333',
  },
})(Typography);

const TeleconsultAnalytics = () => {
  const {
    teleConsultSummary,
    teleConsultPeakDayOfWeekLoading,
    teleConsultPeakHourOfDayLoading,
    teleConsultWaitingTimeLoading,
    teleConsultDropsLoading,
    teleConsultPickupsLoading,
    teleConsultCallsLoading,

    chartTeleConsultPeakDayOfWeek,
    chartTeleConsultPeakHourOfDay,
    chartTeleConsultWaitingTime,
    chartTeleConsultDrops,
    chartTeleConsultPickups,
    chartTeleConsultCalls,
  } = useSelector((state) => state.globalState);

  const LIST_TELECONSULT_CHART = [
    // {
    //   key: 'revenue',
    //   title: 'Revenue',
    //   options: renderOptionLineChart('Revenue', 'number'),
    //   chartData: chartRevenue,
    //   plugins: chartPlugins,
    //   ChartComponent: Line,
    //   query: globalDispatcher.getGlobalRevenueChart,
    //   loading: revenueLoading,
    //   renderChartData: renderLineChartData
    // },
    // {
    //   key: 'episodes',
    //   title: 'Episodes',
    //   options: renderOptionLineChart('Episodes', 'number'),
    //   chartData: chartEpisodes,
    //   plugins: chartPlugins,
    //   ChartComponent: Line,
    //   query: globalDispatcher.getGlobalEpisodesChart,
    //   loading: episodesLoading,
    //   renderChartData: renderLineChartData
    // },
    {
      key: 'highlight',
      title: 'Peak day of the week',
      options: renderOptionBarChart('Peak day of the week', 'number', false),
      chartData: chartTeleConsultPeakDayOfWeek,
      ChartComponent: Bar,
      query: globalDispatcher.getTeleConsultPeakDayOfWeek,
      loading: teleConsultPeakDayOfWeekLoading,
      renderChartData: renderBarChartData,
      showHeaderAction: false,
      showFilter: false,
      labelType: 'Week',
    },
    {
      key: 'highlight',
      title: 'Peak hour of the day',
      options: renderOptionBarChart('Peak hour of the day', '', true, true),
      chartData: chartTeleConsultPeakHourOfDay,
      barThickness: 8,
      ChartComponent: Bar,
      query: globalDispatcher.getTeleConsultPeakHourOfDay,
      loading: teleConsultPeakHourOfDayLoading,
      renderChartData: renderBarChartData,
      showHeaderAction: false,
      showFilter: false,
      labelType: 'Time',
    },
    {
      key: 'value',
      title: 'Waiting time',
      options: renderOptionLineChart('Waiting time', 'time'),
      chartData: chartTeleConsultWaitingTime,
      plugins: chartPlugins,
      ChartComponent: Line,
      query: globalDispatcher.getTeleConsultWaitingTimeChart,
      loading: teleConsultWaitingTimeLoading,
      renderChartData: renderLineChartData,
    },
    {
      key: 'value',
      title: 'Calls',
      options: renderOptionLineChart('Calls', 'number'),
      chartData: chartTeleConsultCalls,
      plugins: chartPlugins,
      ChartComponent: Line,
      query: globalDispatcher.getTeleConsultCallsChart,
      loading: teleConsultCallsLoading,
      renderChartData: renderLineChartData,
      Filters: FilterConsultAverageChart,
      buttonGroups: DEFAULT_DATE_FILTER_TAB.concat([
        {
          key: 'All time',
          labelChart: [],
          filterOptions: {
            filterBy: 'AllTime',
            fromDate: undefined,
            toDate: undefined,
          },
        },
      ]),
    },
    {
      key: 'value',
      title: 'Drops',
      options: renderOptionLineChart('Drops', 'number'),
      chartData: chartTeleConsultDrops,
      plugins: chartPlugins,
      ChartComponent: Line,
      query: globalDispatcher.getTeleConsultDropsChart,
      loading: teleConsultDropsLoading,
      renderChartData: renderLineChartData,
      Filters: FilterConsultAverageChart,
      buttonGroups: DEFAULT_DATE_FILTER_TAB.concat([
        {
          key: 'All time',
          labelChart: [],
          filterOptions: {
            filterBy: 'AllTime',
            fromDate: undefined,
            toDate: undefined,
          },
        },
      ]),
    },
    {
      key: 'value',
      title: 'Consult count',
      options: renderOptionLineChart('Consult count', 'number'),
      chartData: chartTeleConsultPickups,
      plugins: chartPlugins,
      ChartComponent: Line,
      query: globalDispatcher.getTeleConsultPickupsChart,
      loading: teleConsultPickupsLoading,
      renderChartData: renderLineChartData,
      Filters: FilterConsultCountChart,
      buttonGroups: DEFAULT_DATE_FILTER_TAB.concat([
        {
          key: 'All time',
          labelChart: [],
          filterOptions: {
            filterBy: 'AllTime',
            fromDate: undefined,
            toDate: undefined,
          },
        },
      ]),
    },
  ];

  useEffect(() => {
    globalDispatcher.getTeleConsultSummary();
  }, []);

  const summaryListCards = useMemo(() => {
    if (!teleConsultSummary) return [];
    const {
      averageCalls,
      percentCalls,
      averageDrops,
      percentDrops,
      averagePickups,
      percentPickups,
    } = teleConsultSummary;
    return [
      {
        title: 'Average calls per hour',
        value: averageCalls,
        percent: percentCalls,
      },
      {
        title: 'Average drops per hour',
        value: averageDrops,
        percent: -percentDrops,
      },
      {
        title: 'Average pickups per hour',
        value: averagePickups,
        percent: percentPickups,
      },
    ];
  }, [teleConsultSummary]);

  return (
    <CustomPaperContainer title="Analytics">
      <Box p={2.5} pb={6} borderTop="1px solid #f1f1f1">
        <TitleText>Today, {moment().format('DD MMMM YYYY')}</TitleText>
        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          {!isEmpty(summaryListCards) &&
            summaryListCards.map((item) => (
              <Grid item lg={3} md={6} xs={12} key={item.title}>
                <CustomCard width="100%" variant="outlined">
                  <CardContent>
                    <Typography className="title">{item.title}</Typography>
                    <Box display="flex" alignItems="center" gridGap={8}>
                      <Typography className="main-text">
                        {parseFloat(item.value).toFixed(1)}
                      </Typography>
                      {item.percent !== 0 ? (
                        <CustomChip
                          icon={
                            item.percent > 0 ? (
                              <ArrowUpward className="up" />
                            ) : (
                              <ArrowDownward className="down" />
                            )
                          }
                          label={`${Math.abs(item.percent)}%`}
                          className={item.percent > 0 ? 'up' : 'down'}
                        />
                      ) : null}
                    </Box>

                    <Typography className="sub-text">
                      Compared to last 3 months
                    </Typography>
                  </CardContent>
                </CustomCard>
              </Grid>
            ))}
        </Grid>
        <Grid container spacing={2}>
          {LIST_TELECONSULT_CHART.map((chart, index) => {
            const { ChartComponent } = chart;

            return (
              <Grid id={`${chart.key}-${index}`} item xs={12} md={6} xl={4}>
                <ChartWrapper
                  keyChart={chart.key}
                  title={chart.title}
                  ChartComponent={ChartComponent}
                  chartOptions={chart.options}
                  chartData={chart.chartData}
                  chartPlugins={chart?.plugins}
                  query={chart.query}
                  loading={chart.loading}
                  renderChartData={chart.renderChartData}
                  barThickness={chart?.barThickness}
                  showHeaderAction={chart.showHeaderAction}
                  showFilter={chart.showFilter}
                  labelType={chart.labelType}
                  Filters={chart?.Filters}
                  buttonGroups={chart?.buttonGroups}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </CustomPaperContainer>
  );
};

export default TeleconsultAnalytics;
