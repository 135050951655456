import bgTableContent from '@/assets/image/pdfexport/bg-table-contents.png';
import { Box, withStyles } from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';

const CustomContainer = withStyles({
  root: {
    padding: '16.81vw 15.13vw 0',
    maxWidth: '100vw',
    height: 'calc(100vw*1.4)',
    width: 'auto',
    backgroundImage: `url(${bgTableContent})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
})(Box);

const TableContent = ({ corporateData, totalLifeStylePage }) => {
  const finalCount = 13 + totalLifeStylePage;

  return (
    <CustomContainer>
      <Title>Table of contents</Title>
      <Item>
        Hi! Here's a quick look … <Count>01</Count>
      </Item>
      <Item>
        Attendance <Count>02</Count>
      </Item>
      <Item>
        Health Screening Findings <Count>03</Count>
      </Item>
      <Item>
        Lifestyle Questionnaire Findings <Count>13</Count>
      </Item>
      <Item>
        It's not goodbye. It's see you later … <Count>{finalCount}</Count>
      </Item>
    </CustomContainer>
  );
};

const Count = styled.div`
  border-left: 1px solid #e0e0e0;
  padding-left: 4.71vw;
  font-family: 'Lato';
  font-weight: 700;
  font-size: 4.03vw;
  line-height: 5.38vw;
  color: #333;
`;

const Item = styled.div`
  background-color: white;
  border-radius: 1.68vw;
  padding: 4.03vw;
  margin: 4.03vw 0;
  border: 0.17vw solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 2.6vw;
`;

const Title = styled.div`
  font-family: 'Lato';
  font-weight: 700;
  font-size: 6.05vw;
  line-height: 12.1vw;
  margin-bottom: 4.03vw;
`;

export default TableContent;
