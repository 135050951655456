import RestrictClinicServicesForm from './RestrictClinicServicesForm';
import RestrictServicesForm from './RestrictServicesForm';
import clinicServiceDispatcher from '@/module/clinic-services/action';
import { CLINIC_SERVICE_TYPE } from '@/module/clinic-services/constants';
import CustomTabs from '@/new-components/CustomTabs';
import { Box, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  leftContainer: {
    height: 'calc(100vh - 282px)',
  },
  rightContainer: {
    width: '100%',
    height: 'calc(100vh - 282px)',
    overflow: 'auto',
  },
});
const RestrictServices = ({
  listProcedures,
  listRadiologies,
  listPackages,
  restrictedPackageIds,
  setRestrictedPackageIds,
  restrictedTestIds,
  setRestrictedTestIds,
  restrictedSubPurposeIds,
  setRestrictedSubPurposeIds,
  restrictedPurposeIds,
  setRestrictedPurposeIds,
}) => {
  const { list: clinicServiceList } = useSelector(
    (state) => state.clinicService
  );

  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const [status, setStatus] = useState('');
  const [name, setName] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [openClinicServices, setOpenClinicServices] = useState(false);
  const [clinicServicesSelected, setClinicServicesSelected] = useState(null);

  const handleOpenClinicServices = () => {
    setSelectedOption(null);
    setOpenClinicServices(!openClinicServices);
  };
  useEffect(() => {
    setStatus('');
    setName('');
  }, [currentTab]);

  useEffect(() => {
    if (!isEmpty(clinicServiceList) && selectedOption) {
      clinicServiceDispatcher.getDetailPurpose(selectedOption, (result) => {
        setClinicServicesSelected(result);
      });
    }
  }, [selectedOption, clinicServiceList]);

  const tabs = [
    {
      label: 'Package',
      body: (
        <RestrictServicesForm
          isPackage
          setName={setName}
          name={name}
          setStatus={setStatus}
          status={status}
          prefix="package"
          prefixPlural="packages"
          placeholder="Search by package name..."
          listData={listPackages}
          selectedList={restrictedPackageIds}
          setSelectedList={setRestrictedPackageIds}
          restrictedTestIds={restrictedTestIds}
        />
      ),
    },
    {
      label: 'Procedure',
      body: (
        <RestrictServicesForm
          setName={setName}
          name={name}
          setStatus={setStatus}
          status={status}
          prefix="procedure"
          prefixPlural="procedures"
          placeholder="Search by procedure name..."
          listData={listProcedures}
          selectedList={restrictedTestIds}
          setSelectedList={setRestrictedTestIds}
        />
      ),
    },
    {
      label: 'Radiology',
      body: (
        <RestrictServicesForm
          setName={setName}
          name={name}
          setStatus={setStatus}
          status={status}
          prefix="radiology"
          prefixPlural="radiologies"
          placeholder="Search by radiology name..."
          listData={listRadiologies}
          selectedList={restrictedTestIds}
          setSelectedList={setRestrictedTestIds}
        />
      ),
    },
    // {
    //   label: 'Clinic services',
    //   body: (
    //     <RestrictClinicServicesForm
    //       data={clinicServiceList}
    //       setName={setName}
    //       name={name}
    //       setStatus={setStatus}
    //       status={status}
    //       prefix="clinic services"
    //       prefixPlural="clinic services"
    //       placeholder="Search by sub service name..."
    //       listData={listRadiologies}
    //       selectedList={restrictedTestIds}
    //       setSelectedList={setRestrictedTestIds}
    //     />
    //   ),
    // },
    {
      label: 'Clinic services',
      // selectedOption: selectedOption,
      // open: openClinicServices,
      // onOpen: handleOpenClinicServices,
      // onDropdownClick: (id) => setSelectedOption(id),
      // dropdownOptions: clinicServiceList.filter((it) =>
      //   [
      //     CLINIC_SERVICE_TYPE.MULTIPLE_CHOICE,
      //     CLINIC_SERVICE_TYPE.SINGLE_CHOICE,
      //   ].includes(it.purposeType)
      // ),
      body: (
        <RestrictClinicServicesForm
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          clinicServices={clinicServiceList}
          clinicServicesSelected={clinicServicesSelected}
          setName={setName}
          name={name}
          setStatus={setStatus}
          status={status}
          prefix="clinic services"
          prefixPlural="clinic services"
          restrictedSubPurposeIds={restrictedSubPurposeIds}
          setRestrictedSubPurposeIds={setRestrictedSubPurposeIds}
          restrictedPurposeIds={restrictedPurposeIds}
          setRestrictedPurposeIds={setRestrictedPurposeIds}
        />
      ),
    },
  ];

  return (
    <>
      <Box display="flex">
        <CustomTabs
          listTab={tabs}
          value={currentTab}
          onChange={(e, val) => {
            // if (!setSelectedOption) {
            //   setSelectedOption(null);
            //   setClinicServicesSelected(null);
            // }
            setStatus('');
            setName('');
            setSelectedOption(null);
            setClinicServicesSelected(null);
            setCurrentTab(val);
          }}
          renderLabel="label"
          renderKey="label"
          orientation="vertical"
          height="calc(100vh - 282px)"
        />

        <main className={classes.rightContainer}>{tabs[currentTab].body}</main>
      </Box>
    </>
  );
};

export default RestrictServices;
