// Extend Number.prototype
Number.prototype.formatPercentValue = function () {
  const num = Number(parseFloat(this).toFixed(1));

  if (num % 1 === 0) {
    return num.toString();
  }

  if (num.toString().endsWith('0')) {
    return parseFloat(num.toString()).toString();
  }

  return num.toString();
};

// Extend String.prototype
String.prototype.formatPercentValue = function () {
  const number = Number(this);
  if (isNaN(number)) return '0';

  const num = Number(parseFloat(number).toFixed(1));

  if (num % 1 === 0) {
    return num.toString();
  }

  if (num.toString().endsWith('0')) {
    return parseFloat(num.toString()).toString();
  }

  return num.toString();
};

export {};
