import podiumImg from '@/assets/image/pdfexport/podium.png';
import React from 'react';
import styled from 'styled-components';

const CRPreviewSection = ({ title, img = podiumImg }) => {
  return (
    <Container>
      <Avatar>
        <img src={img} alt="avatar" />
      </Avatar>
      <Title>{title}</Title>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.69vw;
`;

const Avatar = styled.div`
  background: #e2f2ff;
  width: 5.71vw;
  height: 5.71vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 3.36vw;
    height: 3.36vw;
  }
`;

const Title = styled.div`
  background: #e2f2ff;
  height: 5.71vw;
  border-radius: 100px;
  font-family: 'OpenSans';
  font-weight: 600;
  font-size: 2.35vw;
  color: #333333;
  line-height: 20px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 2.01vw;
  width: 92%;
`;
export default CRPreviewSection;
