import {
  BLOOD_PRESSURE_TABLE,
  LIST_TABLE_CONTENTS_LABEL_DISPLAY,
} from '../../constants';
import LegendsOfChartCom from '../common/LegendsOfChartCom';
import PageFooter from '../common/PageFooter';
import CRPreviewSection from '../common/Section';
import TableCom from '../common/TableCom';
import bgCover from '@/assets/image/pdfexport/bg-cover.png';
import DoughnutChart from '@/new-components/CRReportPDF/components/DoughnutChart';
import ChartDataLabels from '@energiency/chartjs-plugin-piechart-outlabels';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';

const CustomContainer = withStyles({
  root: {
    padding: '0 13.44vw',
    height: 'calc(100vw*1.4)',
    width: '100vw',
    backgroundImage: `url(${bgCover})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingTop: '10.75vw',
  },
})(Box);
const Title = withStyles({
  root: {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '6.05vw',
    lineHeight: '4.7vw',
  },
})(Typography);

const SubTitle = withStyles({
  root: {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '3.36vw',
    lineHeight: '4.7vw',
  },
})(Typography);

const Item = withStyles({
  root: {
    paddingLeft: '2.69vw',
    borderRight: '1px solid #CCCCCC',
    fontFamily: 'OpenSans',

    '& .title': {
      color: '#666',
      fontWeight: 600,
      fontSize: '1.68vw',
    },

    '& .value': {
      fontFamily: 'Lato',
      marginTop: '0.67vw',
      color: '#333',
      fontWeight: 600,
      fontSize: '2.69vw',
      lineHeight: '4.03vw',
    },
    '& .subValue': {
      fontFamily: 'OpenSans',
      marginLeft: '1.34vw',
      color: '#333',
      fontWeight: 600,
      fontSize: '2.01vw',
      lineHeight: '3.02vw',
    },

    '&:last-child': {
      borderRight: 'none',
    },
  },
})(Grid);

const HSBloodPressure = ({ corporateData, addPaging, totalLifeStylePage }) => {
  const bloodPressure = get(
    corporateData,
    'healthScreenings.bloodPressure.categories'
  );

  const grade1HypertensionPercent =
    bloodPressure.find(
      (it) => it.title.replace('\n', ' ') === 'Grade 1 Hypertension'
    )?.percent || 0;
  const grade2HypertensionPercent =
    bloodPressure.find(
      (it) => it.title.replace('\n', ' ') === 'Grade 2 Hypertension'
    )?.percent || 0;

  // const isolatedSystolicHypertensionPercent =
  //   bloodPressure.find(
  //     (it) => it.title.replace('\n', ' ') === 'Isolated Systolic Hypertension'
  //   )?.percent || 0;

  const highNormalPercent =
    bloodPressure.find((it) => it.title === 'High Normal')?.percent || 0;

  const nphSPercentAbnormal = get(
    corporateData,
    'healthScreenings.bloodPressure.nphS_PercentAbnormal'
  );

  const nphSPercentNormal = get(
    corporateData,
    'healthScreenings.bloodPressure.nphS_PercentNormal'
  );
  const nphsYear = get(corporateData, 'healthScreenings.nphsYear');

  const trendings = corporateData?.healthScreenings?.bloodPressure?.trendings;

  const textTrending = () => {
    if (trendings?.length >= 2) {
      let latestYears = trendings?.sort((a, b) => b.year - a.year).slice(0, 2);
      const percentLastYear = latestYears[1].percent;
      const percentThisYear = latestYears[0].percent;
      const lastYear = latestYears[1].year;
      if (percentLastYear === percentThisYear) {
        return 'There are increased cases by 0%.';
      } else if (percentThisYear > percentLastYear) {
        return `There are increased cases by ${Math.abs(
          percentThisYear?.formatPercentValue() -
            percentLastYear?.formatPercentValue()
        )?.formatPercentValue()}%.`;
      } else {
        return `GREAT, we have improved by ${Math.abs(
          percentThisYear?.formatPercentValue() -
            percentLastYear?.formatPercentValue()
        )?.formatPercentValue()}% since ${lastYear}!`;
      }
    } else {
      return 'There are increased cases by 0%.';
    }
  };

  const chartData = useMemo(() => {
    return bloodPressure
      ?.filter((it) => !it.title.includes('Isolated Systolic'))
      ?.map((it) => it.percent.formatPercentValue());
  }, [bloodPressure]);

  const backgroundColor = [
    'rgba(190, 238, 199, 1)',
    'rgba(249, 225, 161, 1)',
    'rgba(255, 188, 183, 1)',
    'rgba(255, 105, 94, 1)',
    'rgba(232, 222, 255, 1)',
  ];

  const legends = [
    {
      color: '#BEEEC7',
      title: 'Normal',
    },
    {
      color: '#F9E1A1',
      title: 'High Normal',
    },
    {
      color: '#FFBCB7',
      title: 'Grade 1 Hypertension',
    },
    {
      color: '#FF695E',
      title: 'Grade 2 Hypertension',
    },
    // {
    //   color: '#E8DEFF',
    //   title: 'Isolated Systolic Hypertension',
    // },
  ];

  const chartPlugins = [ChartDataLabels];

  const dataTable = useMemo(() => {
    if (isEmpty(bloodPressure)) return [];
    const table = [...BLOOD_PRESSURE_TABLE];

    const dataCutOffs = bloodPressure
      ?.filter((it) => !it.title.includes('Isolated Systolic'))
      ?.map((it, inx) => {
        return {
          color: '#F5F5F5',
          value: `${it.cutOffs}`,
          width: table[0][inx + 1].width,
          height: 24,
        };
      });

    const dataTotal = bloodPressure
      ?.filter((it) => !it.title.includes('Isolated Systolic'))
      ?.map((it, inx) => ({
        color: '#F5F5F5',
        value: `${it.total}`,
        width: table[0][inx + 1]?.width,
        height: 24,
      }));
    const dataPercent = bloodPressure
      ?.filter((it) => !it.title.includes('Isolated Systolic'))
      ?.map((it, inx) => ({
        color: '#F5F5F5',
        value: `${it.percent.formatPercentValue()}%`,
        width: table[0][inx + 1].width,
        height: 24,
      }));

    let header = [];
    header.push(table[0][0]);
    header = header.concat(
      bloodPressure
        ?.filter((it) => !it.title.includes('Isolated Systolic'))
        ?.map((it, inx) => ({
          color: '#E0E0E0',
          value: it.title,
          width: table[0][inx + 1]?.width,
          height: table[0][inx + 1]?.height,
        }))
    );

    table[0] = table[0].map((it, inx) => ({
      ...header[inx],
    }));

    table[1] = table[1].concat(dataCutOffs);
    table[2] = table[2].concat(dataTotal);
    table[3] = table[3].concat(dataPercent);

    return table;
  }, [bloodPressure]);
  // This year is main project data, last year is mapped project data

  const renderDatas = useMemo(() => {
    let datas = trendings
      ?.sort((a, b) => a.year - b.year)
      .map((it) => ({
        value: `${it.percent?.formatPercentValue()}%`,
        subValue: ` (${it.total} pax)`,
        title: `${it.year}`,
      }));
    return datas;
  }, [corporateData]);

  let CONTENTS = [
    {
      boldText: '1. Hypertension workshops',
      text: `help us understand the condition better and how to help prevent the onset through healthy eating and lifestyle habits. It also helps our staff make sense of all the confusing hypertension news that bombards them every day.`,
    },
    {
      boldText: '2. Hypertension Targeted Intervention Programme',
      text: `will allow our staff to learn practical strategies to better manage their blood pressure and understand the causes and health consequences of high blood pressure. Our staff will been couraged to self-monitor their blood pressure to better understand their health status.`,
    },
    {
      boldText: '3. Physical Activities',
      text: `(e.g. power walking, Pilates) are an effective solution to maintain and achieve healthy blood pressure levels. An introduction to the physical activities at the workplace might be the starter to embarking onto aregular exercise regime.`,
    },
  ];

  // Grade 1 Hypertension +	Grade 2 Hypertension	+ Isolated Systolic Hypertension
  const totalGroupValue =
    Number(grade1HypertensionPercent) + Number(grade2HypertensionPercent);
  // +
  // Number(isolatedSystolicHypertensionPercent);

  useEffect(() => {
    addPaging(LIST_TABLE_CONTENTS_LABEL_DISPLAY.HealthScreeningFindings);
  }, []);

  return (
    <>
      <CustomContainer>
        <Box display="flex" flexDirection="column" minHeight="100%">
          <Title>Health Screening Findings</Title>
          <Box mt="6.72vw">
            <CRPreviewSection title="Blood Pressure" />
          </Box>

          <Box
            fontFamily="OpenSans"
            mt="4.03vw"
            fontSize="2.01vw"
            lineHeight="3.02vw"
          >
            High blood pressure is related to risks of cardiovascular diseases,
            strokes, kidney diseases and retinal damage.
          </Box>

          <Box display="flex" justifyContent="space-between" mt="6.72vw">
            <Box width="30vw" height="25vw">
              <DoughnutChart
                chartData={chartData}
                chartPlugins={chartPlugins}
                backgroundColor={backgroundColor}
                animationDuration={400}
                isLabelOutside
              />
            </Box>
            <Box>
              <LegendsOfChartCom data={legends} />
            </Box>
          </Box>

          <Box
            fontFamily="OpenSans"
            mt="6.72vw"
            fontSize="2.01vw"
            lineHeight="3.02vw"
          >
            <p>
              {totalGroupValue?.formatPercentValue()}% has high blood pressure
              readings (i.e. Grade 1, 2). This is{' '}
              {totalGroupValue?.formatPercentValue() >
              nphSPercentAbnormal?.formatPercentValue()
                ? 'higher'
                : 'lower'}{' '}
              than the national prevalence of{' '}
              {nphSPercentAbnormal.formatPercentValue()}%.
            </p>
            <p>
              {highNormalPercent?.formatPercentValue()}% has high normal
              readings. 4S – (high Sodium diet, high Stress level, Smoking,
              Sedentary lifestyle) may increase such staff's risk of developing
              high blood pressure.
            </p>
          </Box>

          <Box mt="6.72vw">
            <TableCom
              data={dataTable}
              nphsYear={nphsYear}
              lastRowData={[
                {
                  colSpan: 2,
                  value: nphSPercentNormal.formatPercentValue(),
                  color: '#F5F5F5',
                },
                {
                  colSpan: 2,
                  value: nphSPercentAbnormal.formatPercentValue(),
                  color: '#F5F5F5',
                },
              ]}
            />
          </Box>
          <PageFooter
            page={'03'}
            totalPages={totalLifeStylePage + 13}
            title={LIST_TABLE_CONTENTS_LABEL_DISPLAY.HealthScreeningFindings}
          />
        </Box>
      </CustomContainer>
      {/*Trending*/}

      {corporateData?.healthScreenings?.bloodPressure?.trendings && (
        <CustomContainer>
          <Box display="flex" flexDirection="column" minHeight="100%">
            <SubTitle>Trending: High Blood Pressure Readings</SubTitle>
            <Box
              fontFamily="OpenSans"
              mt="2.68vw"
              fontSize="2.01vw"
              lineHeight="3.02vw"
            >
              {textTrending()}
            </Box>

            <Box
              mt="2.68vw"
              display="flex"
              bgcolor="#E2F2FF"
              width="73.1vw"
              height="11.42vw"
              borderRadius="1.68vw"
              py="2.01vw"
            >
              <Grid container>
                {renderDatas?.map((it, inx) => (
                  <Item
                    item
                    xs={renderDatas?.length >= 3 ? 4 : 6}
                    key={`${it.title}-${inx}`}
                  >
                    <div className="title">{it.title}</div>
                    <div className="value">
                      {it.value}

                      <span className="subValue">{it.subValue}</span>
                    </div>
                  </Item>
                ))}
              </Grid>
            </Box>

            <Box
              fontFamily="OpenSans"
              mt="4.03vw"
              fontSize="2.67vw"
              lineHeight="4.03vw"
              fontWeight={600}
            >
              How do we maintain a healthy trend?
            </Box>

            {CONTENTS.map((it, inx) => (
              <Box
                fontFamily="OpenSans"
                mt="4.03vw"
                fontSize="2.01vw"
                lineHeight="3.02vw"
                key={`content-${inx}`}
              >
                <Box component="span" fontWeight={600}>
                  {it.boldText}
                </Box>{' '}
                {it.text}
              </Box>
            ))}

            <Box
              fontFamily="OpenSans"
              mt="2.67vw"
              fontSize="1.68vw"
              lineHeight="2.35vw"
              fontWeight={600}
              color="#666666"
            >
              *A point to note is that a single reading of blood pressure does
              not necessarily constitute diagnosis of hypertension. Proper
              hypertension diagnosis is defined by elevated readings taken on
              separate occasions at least one week apart.
            </Box>
            <PageFooter
              page={'04'}
              totalPages={totalLifeStylePage + 13}
              title={LIST_TABLE_CONTENTS_LABEL_DISPLAY.HealthScreeningFindings}
            />
          </Box>
        </CustomContainer>
      )}
    </>
  );
};

export default HSBloodPressure;
