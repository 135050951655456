import './fonts/Lato-Bold.js';
import './fonts/Lato-Regular.js';
import './fonts/OpenSans-Bold.js';
import './fonts/OpenSans-Medium-normal.js';
import './fonts/OpenSans-Medium.js';
import './fonts/OpenSans-Regular.js';
import './fonts/OpenSans-SemiBold.js';
import bgCover from '@/assets/image/pdfexport/bg-cover.png';
import bgFrontCover from '@/assets/image/pdfexport/bg-front-cover.png';
import bgTableContents from '@/assets/image/pdfexport/bg-table-contents.png';
import checkmarkShieldImg from '@/assets/image/pdfexport/checkmark-shield.png';
import headImg from '@/assets/image/pdfexport/head-fill.png';
import manImg from '@/assets/image/pdfexport/man.png';
import podiumImg from '@/assets/image/pdfexport/podium.png';
import womanImg from '@/assets/image/pdfexport/woman.png';
import '@/utils/formatExtensions';
import jsPDF from 'jspdf';
import { capitalize, get, groupBy, isEmpty, lowerCase, sum } from 'lodash';
import moment from 'moment';

// Import it once to apply globally

export const FONTS = {
  Lato: {
    bold: 'Lato-Bold',
    regular: 'Lato-Regular',
  },
  OpenSans: {
    bold: 'OpenSans-Bold',
    medium: 'OpenSans-Medium',
    regular: 'OpenSans-Regular',
    semiBold: 'OpenSans-SemiBold',
  },
};

export const LIST_TABLE_CONTENTS_LABEL = {
  QuickLook: 'QuickLook',
  Attendance: 'Attendance',
  HealthScreeningFindings: 'Health Screening Findings',
  LifestyleQuestionnaireFindings: 'Lifestyle Questionnaire Findings',
  ThankYou: 'ThankYou',
};

export const LIST_TABLE_CONTENTS_LABEL_DISPLAY = {
  QuickLook: 'Hi, here is a quick look ...',
  Attendance: 'Attendance',
  HealthScreeningFindings: 'Health Screening Findings',
  LifestyleQuestionnaireFindings: 'Lifestyle Questionnaire Findings',
  ThankYou: `It's not goodbye. It's see you later …`,
};

class JsPdfExtended {
  constructor(margins = {}, papersize = 'a4') {
    Object.assign(this, new jsPDF('p', 'pt', papersize, true));
    this.margins = {
      top: 20,
      right: 25,
      bottom: 15,
      left: 25,
      ...margins,
    };
    this.bounds = {
      width: this.internal.pageSize.width,
      height: this.internal.pageSize.height,
    };
    this.x = this.margins.left;
    this.y = this.margins.top;
    this.page = 0;
    this.totalPage = [];
    this.tableContents = [];
  }

  setFontStyle({
    font = FONTS.OpenSans.regular,
    fontStyle = 'normal',
    fontWeight,
    fontSize = 15,
    color = '#515663',
    lineHeight,
  } = {}) {
    return this.setFont(font, fontStyle, fontWeight)
      .setFontSize(fontSize)
      .setLineHeightFactor(lineHeight ? lineHeight / fontSize : 1.15)
      .setTextColor(color);
  }
  addBg(img) {
    return this.addImage(img, 'PNG', 0, 0, 595, 842);
  }

  addTitle(text, x = 80, y = 80, style) {
    return this.setFontStyle({
      font: FONTS.Lato.bold,
      fontSize: 32,
      color: '#333333',
      ...style,
    }).text(text, x, y);
  }

  addSubTitle(text, x = 80, y = 80, style) {
    return this.setFontStyle({
      font: FONTS.Lato.bold,
      fontSize: 20,
      color: '#333333',
      ...style,
    }).text(text, x, y);
  }

  addHeaderSection(x, y, text, img) {
    return this.setFillColor('#E2F2FF')
      .roundedRect(x, y, 34, 34, 16, 16, 'F')
      .roundedRect(x + 38, y, 397, 34, 16, 16, 'F')
      .addImage(img, 'PNG', x + 7, y + 6, 20, 20)
      .setFontStyle({
        font: FONTS.OpenSans.semiBold,
        fontSize: 14,
        lineHeight: 20,
        color: '#333333',
      })
      .text(text, x + 54, y + 22);
  }

  /* Start of Paging */
  // Related functions: addPaging, addPagingAll, transformTableContent, addAnnexes
  addPaging(label = '') {
    this.page += 1;
    const key = label.replace(/\s+/g, '');

    this.setLineWidth(1).setDrawColor('#e1e1e1').line(24, 789, 565, 789);
    this.totalPage.push({
      key,
      label: LIST_TABLE_CONTENTS_LABEL_DISPLAY[key],
      page: this.page,
    });

    return this;
  }

  // Related functions: addPaging, addPagingAll, transformTableContent, addAnnexes
  addPagingAll() {
    this.transformTableContent(this.totalPage);

    this.totalPage
      .filter((item) => !item.ignorePaging)
      .forEach((item) => {
        const content = `Page ${item.page} of ${this.totalPage.length}`;
        this.setPage(item.page + 1);

        this.setFontStyle({
          font: FONTS.OpenSans.semiBold,
          fontSize: 10,
          lineHeight: 11,
          color: '#666666',
        }).text(item.label, 24, 820);

        this.setFontStyle({
          font: FONTS.OpenSans.regular,
          fontSize: 10,
          lineHeight: 11,
          color: '#666666',
        }).text(content, 565, 820, { align: 'right' });
      });

    return this;
  }

  // Related functions: addPaging, addPagingAll, transformTableContent, addAnnexes
  transformTableContent(data) {
    if (!isEmpty(data)) {
      const object = groupBy(data, (it) => it.key);

      data.forEach((it) => {
        this.tableContents.push(object[it.key][0]);
      });

      this.tableContents = [
        ...new Map(
          this.tableContents.map((item) => {
            return [
              item['key'],
              {
                label: LIST_TABLE_CONTENTS_LABEL_DISPLAY[item.key],
                startPage: item.page,
              },
            ];
          })
        ).values(),
      ];
    }

    return this;
  }

  addFontCover(corporateData) {
    const companyName = get(corporateData, 'projectInfo.companyName') || '';

    const projectName = get(corporateData, 'projectInfo.projectName') || '';
    const projectCode = get(corporateData, 'projectInfo.projectCode') || '';
    const reportDateToShow = moment().format('DD/MM/YYYY');
    const startDate = get(corporateData, 'projectInfo.startDate') || '';
    const endDate = get(corporateData, 'projectInfo.endDate') || '';
    const reportYearToShow = moment(startDate).format('YYYY');

    const screeningDate = `${moment(startDate).format('DD/MM/YYYY')} - ${moment(
      endDate
    ).format('DD/MM/YYYY')}`;

    this.addBg(bgFrontCover, 'PNG', 0, 0, 595, 842)
      .setFontStyle({
        font: FONTS.Lato.bold,
        fontSize: 48,
        color: '#333333',
      })
      .text(`Corporate Report ${reportYearToShow}`, 24, 540, {
        lineHeightFactor: 0.85,
      });
    this.setFillColor('#3776AB').roundedRect(24, 571, 170, 34, 16, 16, 'F');
    this.addImage(checkmarkShieldImg, 32, 580, 16, 16);

    this.setFontStyle({
      font: FONTS.OpenSans.bold,
      fontSize: 12,
      color: '#FFFFFF',
    }).text('Private & Confidential', 32 + 24, 593);
    this.setFillColor('#FFFFFF').roundedRect(24, 631, 544, 70, 20, 20, 'F');
    this.setFontStyle({
      font: FONTS.OpenSans.bold,
      fontSize: 18,
      color: '#333333',
    })
      .text(`${companyName}`, 44, 671)
      // .setFontStyle({
      //   font: FONTS.OpenSans.semiBold,
      //   fontSize: 14,
      //   color: '#333333',
      // })
      // .text('Project code:', 44, 700)
      // .text('Screening date:', 290, 700)
      // .text('Report date:', 290, 726)
      // .setFontStyle({
      //   font: FONTS.OpenSans.regular,
      //   color: '#333333',
      // })
      // .text(projectCode, 48 + this.getTextWidth('Project code:'), 700)
      // .text(`${screeningDate}`, 294 + this.getTextWidth('Screening date:'), 700)
      // .text(reportDateToShow, 294 + this.getTextWidth('Report date:'), 726)
      .setFontStyle({ fontSize: 10, color: '#313436' })
      .text('@minmedgroup', 96, 805)
      .text('www.minmed.sg', 270, 805)
      .text('https://wa.me/6592300123', 445, 805);

    return this;
  }

  addTableContents() {
    this.insertPage(2).addBg(bgTableContents);

    this.addTitle('Table of contents');

    const formatNumber = (num) => ('0' + num).slice(-2);

    let y = 132;

    this.tableContents.forEach((value) => {
      this.setFillColor('#fff')
        .setDrawColor('#e0e0e0')
        .roundedRect(80, y, 435, 59, 16, 16, 'FD');

      this.setFontStyle({
        color: '#333',
        font: FONTS.OpenSans.medium,
        fontSize: 14,
        lineHeight: 16,
      }).text(`${value.label}`, 96, y + 21 + this.getFontSize());

      this.setLineWidth(1)
        .setDrawColor('#e0e0e0')
        .line(447, y + 13, 447, y + 13 + 34);

      this.setFontStyle({
        color: '#333',
        font: FONTS.Lato.bold,
        fontSize: 28,
        lineHeight: 24,
      }).text(formatNumber(value.startPage), 467, y + 13 + this.getFontSize());

      y += 59 + 20;
    });

    return this;
  }

  addQuickLook(corporateData, chart) {
    if (!corporateData?.overView || !corporateData?.healthScreenings)
      return this;

    const totalFemalePercent = get(
      corporateData,
      'overView.totalFemalePercent'
    );

    const totalMalePercent = get(corporateData, 'overView.totalMalePercent');
    const hypertensive = get(corporateData, 'healthScreenings.hypertensive');
    const obese = get(corporateData, 'healthScreenings.obese');
    const highCholesterol = get(
      corporateData,
      'healthScreenings.highCholesterol'
    );
    const diabetic = get(corporateData, 'healthScreenings.diabetic');

    this.addPage()
      .addBg(bgCover)
      .addPaging(LIST_TABLE_CONTENTS_LABEL.QuickLook);

    this.addTitle('Hi, here is a quick look ...');

    if (chart) {
      const chartImg = chart.ctx.canvas.toDataURL();
      this.addImage(chartImg, 'PNG', 223.5, 156, 148, 148);
      this.addImage(womanImg, 'PNG', 80, 152, 75, 100);
      this.addImage(manImg, 'PNG', 431, 152, 75, 100);

      this.setFontStyle({
        font: FONTS.Lato.bold,
        fontSize: 20,
        lineHeight: 42,
        color: '#AD5E99',
      })
        .text(`${totalFemalePercent.formatPercentValue()}%`, 86, 286)
        .text(`${totalMalePercent.formatPercentValue()}%`, 437, 286);
    }

    // AGE GROUP
    this.addHeaderSection(80, 344, 'AGE GROUP', headImg);

    const LABELS = [
      {
        color: '#FFE9FB',
        title: '<20',
      },
      {
        color: '#FFBCB7',
        title: '20 - 29',
      },
      {
        color: '#BEEEC7',
        title: '30 - 39',
      },
      {
        color: '#F5C5D5',
        title: '40 - 49',
      },
      {
        color: '#F9E1A1',
        title: '50 - 59',
      },
      {
        color: '#E8DEFF',
        title: '>59',
      },
    ];

    this.addLegendLabelChart(LABELS, 130, 394);

    const DATAS = [
      {
        value: `${hypertensive.formatPercentValue()}%`,
        title: 'Hypertensive',
      },
      {
        value: `${obese.formatPercentValue()}%`,
        title: 'Obese',
      },
      {
        value: `${highCholesterol.formatPercentValue()}%`,
        title: 'High Cholesterol',
      },
      {
        value: `${diabetic.formatPercentValue()}%`,
        title: 'Diabetic',
      },
    ];
    let xDatas = 96;

    this.setFillColor('#E2F2FF').roundedRect(80, 604, 435, 68, 10, 10, 'F');

    DATAS.forEach((value, inx) => {
      this.setFontStyle({
        font: FONTS.OpenSans.semiBold,
        fontSize: 10,
        color: '#666',
        lineHeight: 16,
      }).text(value.title, xDatas, 620 + this.getFontSize());
      this.setFillColor(
        inx === DATAS.length - 1 ? '#E2F2FF' : '#ccc'
      ).roundedRect(xDatas + 92, 616, 1, 44, 1, 1, 'F');
      this.setFontStyle({
        font: FONTS.OpenSans.semiBold,
        fontSize: 16,
        color: '#333',
        lineHeight: 24,
      }).text(value.value, xDatas, 640 + this.getFontSize());

      xDatas += 106;
    });

    return this;
  }

  addAttendance(corporateData, chart) {
    if (!corporateData?.overView) return this;

    const totalAttended = get(corporateData, 'overView.totalAttended');

    const totalFemalePercent = get(
      corporateData,
      'overView.totalFemalePercent'
    );

    const totalMalePercent = get(corporateData, 'overView.totalMalePercent');
    const ageGroups = get(corporateData, 'overView.ageGroups');

    this.addPage()
      .addBg(bgCover)
      .addPaging(LIST_TABLE_CONTENTS_LABEL.Attendance);

    this.addTitle('Attendance')
      .setFillColor('#F5F5F5')
      .roundedRect(80, 132, 435, 62, 10, 10, 'F')
      .setFontStyle({
        font: FONTS.OpenSans.semiBold,
        fontSize: 10,
        color: '#666',
        lineHeight: 13.62,
      })
      .text('Total Attendance', 96, 155)
      .text(
        `${totalFemalePercent.formatPercentValue()}% attended are female; ${totalMalePercent.formatPercentValue()}% attended are male.`,
        96,
        178
      )
      .setFontStyle({
        font: FONTS.OpenSans.semiBold,
        fontSize: 10,
        color: '#333',
        lineHeight: 13.62,
      })
      .text(`${totalAttended}`, 208, 155);

    if (chart) {
      const chartImg = chart.ctx.canvas.toDataURL();

      this.addImage(chartImg, 'PNG', 80, 234, 435, 199);
      const LABELS = [
        {
          color: '#F5C5D5',
          title: 'Female',
        },
        {
          color: '#E2F2FF',
          title: 'Male',
        },
      ];

      this.addLegendLabelChart(LABELS, 140, 465);
    }

    // Table

    const table = [
      [
        {
          color: '#E0E0E0',
          value: 'GENDER/\nAGE GROUP',
          width: 73,
          height: 40,
        },
        { color: '#E0E0E0', value: '<20', width: 50, height: 40 },
        { color: '#E0E0E0', value: '20 - 29', width: 50, height: 40 },
        { color: '#E0E0E0', value: '30 - 39', width: 50, height: 40 },

        { color: '#E0E0E0', value: '40 - 49', width: 50, height: 40 },
        { color: '#E0E0E0', value: '50 - 59', width: 50, height: 40 },
        { color: '#E0E0E0', value: '>59', width: 50, height: 40 },
        { color: '#E0E0E0', value: 'TOTAL', width: 50, height: 40 },
      ],
      [{ color: '#E0E0E0', value: 'Female', width: 73, height: 24 }],
      [{ color: '#E0E0E0', value: 'Male', width: 73, height: 24 }],
      [{ color: '#E0E0E0', value: 'TOTAL', width: 73, height: 24 }],
    ];
    this.setFontStyle({
      font: FONTS.OpenSans.regular,
      fontSize: 10,
      color: '#333',
      lineHeight: 13.62,
    });

    const dataFemaleTotal = ageGroups?.map((it) => ({
      color: '#F5F5F5',
      value: `${it.femaleTotal}`,
      width: 50,
      height: 24,
    }));

    const dataMaleTotal = ageGroups?.map((it) => ({
      color: '#F5F5F5',
      value: `${it.maleTotal}`,
      width: 50,
      height: 24,
    }));

    const dataTotal = ageGroups?.map((it) => ({
      color: '#F5F5F5',
      value: `${it.total}`,
      width: 50,
      height: 24,
    }));

    let header = [];
    header.push(table[0][0]);
    header = header.concat(
      ageGroups?.map((it, inx) => ({
        color: '#E0E0E0',
        value: it.title,
        width: table[0][inx + 1]?.width,
        height: table[0][inx + 1]?.height,
      }))
    );

    table[0] = table[0].map((it, inx) => ({
      ...header[inx],
    }));

    table[1] = table[1].concat(dataFemaleTotal);
    table[2] = table[2].concat(dataMaleTotal);
    table[3] = table[3].concat(dataTotal);
    this.addTable(table);
    return this;
  }

  addBloodPressure(corporateData, chart) {
    if (!corporateData?.healthScreenings?.bloodPressure) return this;
    const bloodPressure = get(
      corporateData,
      'healthScreenings.bloodPressure.categories'
    );

    const grade1HypertensionPercent =
      bloodPressure.find(
        (it) => it.title.replace('\n', ' ') === 'Grade 1 Hypertension'
      )?.percent || 0;
    const grade2HypertensionPercent =
      bloodPressure.find(
        (it) => it.title.replace('\n', ' ') === 'Grade 2 Hypertension'
      )?.percent || 0;

    // const isolatedSystolicHypertensionPercent =
    //   bloodPressure.find(
    //     (it) => it.title.replace('\n', ' ') === 'Isolated Systolic Hypertension'
    //   )?.percent || 0;

    const highNormalPercent =
      bloodPressure.find((it) => it.title === 'High Normal')?.percent || 0;

    const nphSPercentAbnormal = get(
      corporateData,
      'healthScreenings.bloodPressure.nphS_PercentAbnormal'
    );

    const nphSPercentNormal = get(
      corporateData,
      'healthScreenings.bloodPressure.nphS_PercentNormal'
    );
    const nphsYear = get(corporateData, 'healthScreenings.nphsYear');

    // Grade 1 Hypertension +	Grade 2 Hypertension	+ Isolated Systolic Hypertension
    const totalGroupValue =
      Number(grade1HypertensionPercent) + Number(grade2HypertensionPercent);
    // +
    // Number(isolatedSystolicHypertensionPercent);

    this.addPage()
      .addBg(bgCover)
      .addPaging(LIST_TABLE_CONTENTS_LABEL.HealthScreeningFindings);
    this.addTitle('Health Screening Findings');

    // Blood Pressure
    this.addHeaderSection(80, 124, 'Blood Pressure', podiumImg);
    this.setFontStyle({
      font: FONTS.OpenSans.regular,
      fontSize: 12,
      color: '#333333',
      lineHeight: 18,
    }).text(
      'High blood pressure is related to risks of cardiovascular diseases, strokes, kidney diseases and retinal damage.',
      80,
      192,
      { maxWidth: 435 }
    );

    if (chart) {
      const chartImg = chart.ctx.canvas.toDataURL();

      this.addImage(chartImg, 'PNG', 0, 258, 300, 148).addLabelOutsideChart(
        chart,
        80,
        258
      );

      const LABELS = [
        {
          color: '#BEEEC7',
          title: 'Normal',
        },
        {
          color: '#F9E1A1',
          title: 'High Normal',
        },
        {
          color: '#FFBCB7',
          title: 'Grade 1 Hypertension',
        },
        {
          color: '#FF695E',
          title: 'Grade 2 Hypertension',
        },
        // {
        //   color: '#E8DEFF',
        //   title: 'Isolated Systolic Hypertension',
        // },
      ];
      this.addLegendLabelChart(LABELS, 303, 258);
    }

    this.setFontStyle({
      font: FONTS.OpenSans.regular,
      fontSize: 12,
      color: '#333333',
      lineHeight: 18,
    }).text(
      `${totalGroupValue.formatPercentValue()}% has high blood pressure readings (i.e. Grade 1, 2). This is ${
        totalGroupValue.formatPercentValue() >
        nphSPercentAbnormal.formatPercentValue()
          ? 'higher'
          : 'lower'
      } than the national prevalence of ${nphSPercentAbnormal.formatPercentValue()}%.

${highNormalPercent.formatPercentValue()}% has high normal readings. 4S – (high Sodium diet, high Stress level, Smoking, Sedentary lifestyle) may increase such staff’s risk of developing high blood pressure.`,
      80,
      446,
      { maxWidth: 435 }
    );

    // Table
    const table = [
      [
        {
          color: '#E0E0E0',
          value: 'CATEGORY',
          width: 78,
          height: 40,
          lineHeight: 8,
        },
        {
          color: '#E0E0E0',
          value: 'Normal',
          width: 78,
          height: 40,
          lineHeight: 8,
        },
        {
          color: '#E0E0E0',
          value: 'High Normal',
          width: 92,
          height: 40,
          lineHeight: 8,
        },
        {
          color: '#E0E0E0',
          value: 'Grade 1\nHypertension',
          width: 92,
          height: 40,
          lineHeight: 16,
        },

        {
          color: '#E0E0E0',
          value: 'Grade 2\nHypertension',
          width: 92,
          height: 40,
          lineHeight: 16,
        },
        // {
        //   color: '#E0E0E0',
        //   value: 'Isolated Systolic\nHypertension',
        //   width: 88,
        //   height: 40,
        //   lineHeight: 16,
        // },
      ],
      [{ color: '#E0E0E0', value: 'Cutoffs', width: 78, height: 24 }],
      [{ color: '#E0E0E0', value: 'Pax', width: 78, height: 24 }],
      [{ color: '#E0E0E0', value: '%', width: 78, height: 24 }],
      [
        {
          color: '#E0E0E0',
          value: `NPHS ${nphsYear}`,
          width: 78,
          height: 24,
        },
      ],
    ];
    const dataCutOffs = bloodPressure
      ?.filter((it) => !it.title.includes('Isolated Systolic'))
      ?.map((it, inx) => {
        return {
          color: '#F5F5F5',
          value: `${it.cutOffs}`,
          width: table[0][inx + 1].width,
          height: 24,
        };
      });

    const dataTotal = bloodPressure
      ?.filter((it) => !it.title.includes('Isolated Systolic'))
      ?.map((it, inx) => ({
        color: '#F5F5F5',
        value: `${it.total}`,
        width: table[0][inx + 1]?.width,
        height: 24,
      }));
    const dataPercent = bloodPressure
      ?.filter((it) => !it.title.includes('Isolated Systolic'))
      ?.map((it, inx) => ({
        color: '#F5F5F5',
        value: `${it.percent.formatPercentValue()}%`,
        width: table[0][inx + 1].width,
        height: 24,
      }));

    let header = [];
    header.push(table[0][0]);
    header = header.concat(
      bloodPressure
        ?.filter((it) => !it.title.includes('Isolated Systolic'))
        ?.map((it, inx) => ({
          color: '#E0E0E0',
          value: it.title,
          width: table[0][inx + 1]?.width,
          height: table[0][inx + 1]?.height,
          lineHeight: table[0][inx + 1]?.lineHeight,
        }))
    );

    table[0] = table[0].map((it, inx) => ({
      ...header[inx],
    }));

    table[1] = table[1].concat(dataCutOffs);
    table[2] = table[2].concat(dataTotal);
    table[3] = table[3].concat(dataPercent);

    let x = 80,
      y = 594,
      yLast = 714;
    let yRow = y + 16;
    let xRowHead = table[0][0].width + x + 2;
    let xRowMid = xRowHead;
    let fontSize = 8;
    this.setFontStyle({
      font: FONTS.OpenSans.regular,
      fontSize,
      color: '#333',
      lineHeight: 10.89,
    });
    table.forEach((rows, rinx) => {
      rows.forEach((cols, cinx) => {
        // Row 1
        if (rinx === 0 && cinx === 0) {
          this.setFillColor(cols.color)
            .rect(x + cols.width / 2, y, cols.width / 2, cols.height, 'F')
            .setFillColor(cols.color)
            .rect(x, y + cols.height / 2, cols.width, cols.height / 2, 'F')
            .setFillColor(cols.color)
            .roundedRect(x, y, cols.width, cols.height, 10, 10, 'F')
            .text(
              cols.value,
              x + cols.width / 2 - this.getTextWidth(cols.value) / 2,
              y + cols.height / 2 + cols.lineHeight / 2
            );
        } else if (rinx === 0 && cinx === rows.length - 1) {
          const width = rows.map((it) => it.width);
          width.pop();
          const xLast = x + sum(width) + rows.length * 1.7;

          this.setFillColor(cols.color)
            .rect(xLast, y, cols.width / 2, cols.height, 'F')
            .setFillColor(cols.color)
            .rect(xLast, y + 20, cols.width, cols.height / 2, 'F')
            .setFillColor(cols.color)
            .roundedRect(xLast, y, cols.width, cols.height, 10, 10, 'F')
            .text(cols.value, xLast + 42, y + 18, { align: 'center' });
        } else if (rinx === table.length - 1 && cinx === 0) {
          this.setFillColor(cols.color)
            .rect(x + cols.width / 2, yLast, cols.width / 2, cols.height, 'F')
            .setFillColor(cols.color)
            .rect(x, yLast, cols.width, cols.height / 2, 'F')
            .setFillColor(cols.color)
            .roundedRect(x, yLast, cols.width, cols.height, 10, 10, 'F')
            .text(cols.value, x + cols.width / 2 - 20, yLast + 15);
        } else if (rinx === table.length - 1 && cinx === rows.length - 1) {
        } else {
          if (rinx === 0) {
            this.setFillColor(cols.color)
              .rect(xRowHead, y, cols?.width, cols?.height, 'F')
              .text(
                cols.value,
                xRowHead + cols.width / 2 + 2,
                y + cols.height / 2 - cols.lineHeight / 2 + 7,
                { align: 'center' }
              );
          } else {
            // Middle rows
            this.setFillColor(cols.color)
              .rect(xRowMid - 18, yRow, cols.width, cols.height, 'F')
              .text(
                cols.value,
                xRowMid +
                  cols.width / 2 -
                  this.getTextWidth(cols.value) / 2 -
                  18,
                yRow + cols.height / 2 + 4
              );
            xRowMid += cols.width + 2;
          }

          xRowHead += cols.width + 2;
        }
      });
      yRow += 26;
      xRowMid = table[0][0].width + 20;
    });
    // Last Row
    this.setFillColor('#F5F5F5')
      .roundedRect(142 + 18, yLast, 172, 24, 0, 0, 'F')
      .text(
        `${nphSPercentNormal.formatPercentValue()}%`,
        142 + 136 / 2 - this.getTextWidth('0%') / 2 + 36,
        yLast + 15,
        {
          align: 'center',
        }
      );

    this.setFillColor('#F5F5F5')
      .rect(280 + 54, yLast, 240 / 2 - 54, 24, 'F')
      .setFillColor('#F5F5F5')
      .rect(280 + 54, yLast, 240 - 54, 24 / 2, 'F')
      .setFillColor('#F5F5F5')
      .roundedRect(280 + 54, yLast, 240 - 54, 24, 10, 10, 'F')
      .text(
        `${nphSPercentAbnormal.formatPercentValue()}%`,
        280 + 36 + 240 / 2 - this.getTextWidth('0%') / 2,
        yLast + 15,
        {
          align: 'center',
        }
      );

    return this;
  }

  addBloodPressureTrending(corporateData) {
    if (!corporateData?.healthScreenings?.bloodPressure?.trendings) return this;
    const trendings = corporateData?.healthScreenings?.bloodPressure?.trendings;

    const textTrending = () => {
      if (trendings?.length >= 2) {
        let latestYears = trendings
          ?.sort((a, b) => b.year - a.year)
          .slice(0, 2);
        const percentLastYear = latestYears[1].percent;
        const percentThisYear = latestYears[0].percent;
        const lastYear = latestYears[1].year;
        if (percentLastYear === percentThisYear) {
          return 'There are increased cases by 0%.';
        } else if (percentThisYear > percentLastYear) {
          return `There are increased cases by ${Math.abs(
            percentThisYear?.formatPercentValue() -
              percentLastYear?.formatPercentValue()
          )?.formatPercentValue()}%.`;
        } else {
          return `GREAT, we have improved by ${Math.abs(
            percentThisYear?.formatPercentValue() -
              percentLastYear?.formatPercentValue()
          )?.formatPercentValue()}% since ${lastYear}!`;
        }
      } else {
        return 'There are increased cases by 0%.';
      }
    };

    this.addPage()
      .addBg(bgCover)
      .addPaging(LIST_TABLE_CONTENTS_LABEL.HealthScreeningFindings);
    this.addSubTitle(`Trending: High Blood Pressure Readings`);
    this.setFontStyle({
      font: FONTS.OpenSans.regular,
      fontSize: 12,
      color: '#333333',
      lineHeight: 18,
    }).text(textTrending(), 80, 114, { maxWidth: 435 });

    let datas = trendings
      ?.sort((a, b) => a.year - b.year)
      .map((it) => ({
        value: `${it.percent?.formatPercentValue()}%`,
        subValue: ` (${it.total} pax)`,
        title: `${it.year}`,
      }));

    let xDatas = 96;
    this.setFillColor('#E2F2FF').roundedRect(80, 138, 435, 68, 10, 10, 'F');
    datas.forEach((value, inx) => {
      this.setFontStyle({
        font: FONTS.OpenSans.semiBold,
        fontSize: 12,
        color: '#666',
        lineHeight: 16,
      }).text(value.title, xDatas, 138 + 16 + this.getFontSize());
      if (datas.length > 1 && inx !== datas.length - 1)
        this.setFillColor('#ccc').roundedRect(
          datas.length >= 3 ? xDatas + 96 + 30 : xDatas * (datas?.length + 1),
          138 + 12,
          1,
          44,
          1,
          1,
          'F'
        );
      this.setFontStyle({
        font: FONTS.OpenSans.semiBold,
        fontSize: 16,
        color: '#333',
        lineHeight: 24,
      }).text(value.value, xDatas, 138 + 36 + this.getFontSize());
      this.setFontStyle({
        font: FONTS.OpenSans.semiBold,
        fontSize: 12,
        color: '#333',
        lineHeight: 18,
      }).text(
        value.subValue,
        xDatas + this.getTextWidth(value.value) + 15,
        189
      );

      xDatas += 435 / datas?.length;
    });

    this.addSubTitle('How do we maintain a healthy trend?', 80, 250);

    let xContents = 80;
    let lineSpacing = 16;
    let yContents = 277 - 16;

    let CONTENTS = [
      {
        boldText: '1. Hypertension workshops',
        text: `1. Hypertension workshops help us understand the condition better and how to help prevent the onset through healthy eating and lifestyle habits. It also helps our staff make sense of all the confusing hypertension news that bombards them every day.`,
      },
      {
        boldText: '2. Hypertension Targeted Intervention Programme',
        text: `2. Hypertension Targeted Intervention Programme will allow our staff to learn practical strategies to better manage their blood pressure and understand the causes and health consequences of high blood pressure. Our staff will been couraged to self-monitor their blood pressure to better understand their health status.`,
      },
      {
        boldText: '3. Physical Activities',
        text: `3. Physical Activities (e.g. power walking, Pilates) are an effective solution to maintain and achieve healthy blood pressure levels. An introduction to the physical activities at the workplace might be the starter to embarking onto aregular exercise regime.`,
      },
    ];

    CONTENTS.forEach((value) => {
      const lines = this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 12,
        color: '#333',
        lineHeight: 18,
      }).splitTextToSize(value.text, 430);
      const newLines = lines.filter((line) => !isEmpty(line));

      yContents += 16;

      newLines.forEach((lineText, inx) => {
        this.setFontStyle({
          font: FONTS.OpenSans.regular,
          fontSize: 12,
          lineHeight: 18,
          color: '#333',
        }).text(lineText, xContents, yContents + this.getLineHeight(), {
          maxWidth: 430,
          lineHeightFactor: 1.57,
          charSpace: 0.225,
        });

        if (inx === 0) {
          this.setFillColor('#fff').roundedRect(
            xContents,
            yContents + 2,
            this.getTextWidth(value.boldText),
            20,
            0,
            0,
            'F'
          );

          this.setFontStyle({
            font: FONTS.OpenSans.semiBold,
            fontSize: 12,
            color: '#333',
            lineHeight: 18,
          }).text(value.boldText, xContents, yContents + this.getLineHeight());
        }

        yContents += lineSpacing;
      });
    });

    this.setFontStyle({
      font: FONTS.OpenSans.semiBold,
      fontSize: 10,
      color: '#666',
      lineHeight: 14,
    }).text(
      '*A point to note is that a single reading of blood pressure does not necessarily constitute diagnosis of hypertension. Proper hypertension diagnosis is defined by elevated readings taken on separate occasions at least one week apart.',
      80,
      553,
      { maxWidth: 435 }
    );

    return this;
  }

  addBMI(corporateData, chart) {
    if (!corporateData?.healthScreenings?.bmi) return this;
    const bmi = get(corporateData, 'healthScreenings.bmi.categories');
    const highRiskPercent = bmi.find((it) => it.title === 'High Risk').percent;

    const nphSPercentHighRisk = get(
      corporateData,
      'healthScreenings.bmi.nphS_PercentHighRisk'
    );

    const nphSPercentLowRisk = get(
      corporateData,
      'healthScreenings.bmi.nphS_PercentLowRisk'
    );

    const nphSPercentRisk = get(
      corporateData,
      'healthScreenings.bmi.nphS_PercentRisk'
    );

    const nphSPercentUnderweight = get(
      corporateData,
      'healthScreenings.bmi.nphS_PercentUnderweight'
    );
    const nphsYear = get(corporateData, 'healthScreenings.nphsYear');

    // BMI

    this.addPage()
      .addBg(bgCover)
      .addPaging(LIST_TABLE_CONTENTS_LABEL.HealthScreeningFindings);

    this.addHeaderSection(80, 64, 'Body Mass Index (BMI)', podiumImg);
    this.setFontStyle({
      font: FONTS.OpenSans.regular,
      fontSize: 12,
      color: '#333333',
      lineHeight: 18,
    })
      .text(
        'Overweight and obesity indicates major health risks and increases the prevalence of premature death, diabetes mellitus, hypertension, hyperlipidaemia, atherosclerosis, coronary heart disease, gout, respiratory disease, arthritis and certain types of cancer. It also affect one’s psychological and social well-being.',
        80,
        132,
        { maxWidth: 435 }
      )
      .text('In Singapore, this is a rising health problem.', 80, 240, {
        maxWidth: 435,
      });

    if (chart) {
      const chartImg = chart.ctx.canvas.toDataURL();

      this.addImage(chartImg, 'PNG', 0, 288, 300, 148).addLabelOutsideChart(
        chart,
        80,
        288
      );

      const LABELS = [
        {
          color: '#F9E1A1',
          title: 'Underweight',
        },
        {
          color: '#BEEEC7',
          title: 'Low Risk',
        },
        {
          color: '#FFD0B7',
          title: 'Moderate Risk',
        },
        {
          color: '#FFBCB7',
          title: 'High Risk',
        },
      ];
      this.addLegendLabelChart(LABELS, 303, 307);
    }
    this.setFontStyle({
      font: FONTS.OpenSans.regular,
      fontSize: 12,
      color: '#333333',
      lineHeight: 18,
    }).text(
      `${highRiskPercent.formatPercentValue()}% has BMI in High Risk category. This is ${
        highRiskPercent.formatPercentValue() >
        nphSPercentHighRisk.formatPercentValue()
          ? 'higher'
          : 'lower'
      } than the national prevalence of ${nphSPercentHighRisk.formatPercentValue()}%.

To combat the increasing prevalence of obesity, we should educate and motivate our staff to adopt a healthy lifestyle and eating habits.`,
      80,
      486,
      { maxWidth: 435 }
    );

    // Table
    const table = [
      [
        {
          color: '#E0E0E0',
          value: 'CATEGORY',
          width: 82,
          height: 24,
          lineHeight: 8,
        },
        {
          color: '#E0E0E0',
          value: 'Underweight',
          width: 83,
          height: 24,
          lineHeight: 8,
        },
        {
          color: '#E0E0E0',
          value: 'Low Risk',
          width: 87,
          height: 24,
          lineHeight: 8,
        },
        {
          color: '#E0E0E0',
          value: 'Moderate Risk',
          width: 87,
          height: 24,
          lineHeight: 8,
        },

        {
          color: '#E0E0E0',
          value: 'High Risk',
          width: 88,
          height: 24,
          lineHeight: 8,
        },
      ],
      [{ color: '#E0E0E0', value: 'Cutoffs', width: 82, height: 24 }],
      [{ color: '#E0E0E0', value: 'Pax', width: 82, height: 24 }],
      [{ color: '#E0E0E0', value: '%', width: 82, height: 24 }],
      [
        {
          color: '#E0E0E0',
          value: `NPHS ${nphsYear}`,
          width: 82,
          height: 24,
        },
      ],
    ];

    const dataCutOffs = bmi?.map((it, inx) => {
      return {
        color: '#F5F5F5',
        value: `${it.cutOffs}`,
        width: table[0][inx + 1]?.width,
        height: 24,
      };
    });

    const dataTotal = bmi?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.total}`,
      width: table[0][inx + 1]?.width,
      height: 24,
    }));
    const dataPercent = bmi?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.percent.formatPercentValue()}%`,
      width: table[0][inx + 1]?.width,
      height: 24,
    }));

    const dataNPH = [
      nphSPercentUnderweight,
      nphSPercentLowRisk,
      nphSPercentRisk,
      nphSPercentHighRisk,
    ]?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.formatPercentValue()}%`,
      width: table[0][inx + 1]?.width,
      height: 24,
    }));

    let header = [];
    header.push(table[0][0]);
    header = header.concat(
      bmi?.map((it, inx) => ({
        color: '#E0E0E0',
        value: it.title,
        width: table[0][inx + 1]?.width,
        height: table[0][inx + 1]?.height,
        lineHeight: table[0][inx + 1]?.lineHeight,
      }))
    );

    table[0] = table[0].map((it, inx) => ({
      ...header[inx],
    }));

    table[1] = table[1].concat(dataCutOffs);
    table[2] = table[2].concat(dataTotal);
    table[3] = table[3].concat(dataPercent);
    table[4] = table[4].concat(dataNPH);

    let x = 80,
      y = 594,
      yLast = 694;
    let yRow = y;
    let xRowHead = table[0][0].width + x + 2;
    let xRowMid = xRowHead;
    let xRowLast = xRowHead;
    let fontSize = 8;

    this.setFontStyle({
      font: FONTS.OpenSans.regular,
      fontSize,
      color: '#333',
      lineHeight: 10.89,
    });
    table.forEach((rows, rinx) => {
      rows.forEach((cols, cinx) => {
        // Row 1
        if (rinx === 0 && cinx === 0) {
          this.setFillColor(cols.color)
            .rect(x + cols.width / 2, y, cols.width / 2, cols.height, 'F')
            .setFillColor(cols.color)
            .rect(x, y + cols.height / 2, cols.width, cols.height / 2, 'F')
            .setFillColor(cols.color)
            .roundedRect(x, y, cols.width, cols.height, 10, 10, 'F')
            .text(
              cols.value,
              x + cols.width / 2 - this.getTextWidth(cols.value) / 2,
              y + cols.height / 2 + cols.lineHeight / 2
            );
        } else if (rinx === 0 && cinx === rows.length - 1) {
          const width = rows.map((it) => it.width);
          width.pop();
          const xLast = x + sum(width) + rows.length * 1.6;

          this.setFillColor(cols.color)
            .rect(xLast, y, cols.width / 2, cols.height, 'F')
            .setFillColor(cols.color)
            .rect(xLast, y + cols.height / 2, cols.width, cols.height / 2, 'F')
            .setFillColor(cols.color)
            .roundedRect(xLast, y, cols.width, cols.height, 10, 10, 'F')
            .text(cols.value, xLast + 42, y + cols.height / 2 + 3, {
              align: 'center',
            });
        } else if (rinx === table.length - 1 && cinx === 0) {
          this.setFillColor(cols.color)
            .rect(x + cols.width / 2, yLast, cols.width / 2, cols.height, 'F')
            .setFillColor(cols.color)
            .rect(x, yLast, cols.width, cols.height / 2, 'F')
            .setFillColor(cols.color)
            .roundedRect(x, yLast, cols.width, cols.height, 10, 10, 'F')
            .text(cols.value, x + cols.width / 2 - 20, yLast + 15);
        } else if (rinx === table.length - 1 && cinx === rows.length - 1) {
          const width = rows.map((it) => it.width);
          width.pop();
          const xLast = x + sum(width) + rows.length * 1.6;
          this.setFillColor(cols.color)
            .rect(xLast, yLast, cols.width / 2, cols.height, 'F')
            .setFillColor(cols.color)
            .rect(xLast, yLast, cols.width, cols.height / 2, 'F')
            .setFillColor(cols.color)
            .roundedRect(xLast, yLast, cols.width, cols.height, 10, 10, 'F')
            .text(
              cols.value,
              xLast + cols.width / 2 - this.getTextWidth(cols.value) / 2,
              yLast + cols.height / 2 + 4
            );
        } else {
          if (rinx === 0) {
            this.setFillColor(cols.color)
              .rect(xRowHead, y, cols?.width, cols?.height, 'F')
              .text(
                cols.value,
                xRowHead + cols.width / 2 + 2,
                y + cols.height / 2 - cols.lineHeight / 2 + 7,
                { align: 'center' }
              );
          } else if (rinx === table.length - 1) {
            // Last rows
            this.setFillColor(cols.color)
              .rect(xRowLast, yLast, cols.width, cols.height, 'F')
              .text(
                cols.value,
                xRowLast + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                yLast + 15
              );
            xRowLast += cols.width + 2;
          } else {
            // Middle rows
            this.setFillColor(cols.color)
              .rect(xRowMid, yRow, cols.width, cols.height, 'F')
              .text(
                cols.value,
                xRowMid + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                yRow + cols.height / 2 + 4
              );
            xRowMid += cols.width + 2;
          }

          xRowHead += cols.width + 2;
        }
      });
      yRow += 25;
      xRowMid = table[0][0].width - 2;
    });

    return this;
  }
  addBMITrending(corporateData) {
    if (!corporateData?.healthScreenings?.bmi?.trendings) return this;
    const trendings = corporateData?.healthScreenings?.bmi?.trendings;

    const textTrending = () => {
      if (trendings?.length >= 2) {
        let latestYears = trendings
          ?.sort((a, b) => b.year - a.year)
          .slice(0, 2);
        const percentLastYear = latestYears[1].percent;
        const percentThisYear = latestYears[0].percent;
        const lastYear = latestYears[1].year;

        if (percentLastYear === percentThisYear) {
          return 'There are increased cases by 0%.';
        } else if (percentThisYear > percentLastYear) {
          return `There are increased cases by ${Math.abs(
            percentThisYear?.formatPercentValue() -
              percentLastYear?.formatPercentValue()
          )?.formatPercentValue()}%.`;
        } else {
          return `GREAT, we have improved by ${Math.abs(
            percentThisYear?.formatPercentValue() -
              percentLastYear?.formatPercentValue()
          )?.formatPercentValue()}% since ${lastYear}!`;
        }
      } else {
        return 'There are increased cases by 0%.';
      }
    };

    this.addPage()
      .addBg(bgCover)
      .addPaging(LIST_TABLE_CONTENTS_LABEL.HealthScreeningFindings);
    this.addSubTitle(`Trending: High BMI Readings`);
    this.setFontStyle({
      font: FONTS.OpenSans.regular,
      fontSize: 12,
      color: '#333333',
      lineHeight: 18,
    }).text(textTrending(), 80, 114, { maxWidth: 435 });

    let datas = trendings
      ?.sort((a, b) => a.year - b.year)
      .map((it) => ({
        value: `${it.percent?.formatPercentValue()}%`,
        subValue: ` (${it.total} pax)`,
        title: `${it.year}`,
      }));

    let xDatas = 96;
    this.setFillColor('#E2F2FF').roundedRect(80, 138, 435, 68, 10, 10, 'F');
    if (!isEmpty(datas))
      datas.forEach((value, inx) => {
        this.setFontStyle({
          font: FONTS.OpenSans.semiBold,
          fontSize: 12,
          color: '#666',
          lineHeight: 16,
        }).text(value.title, xDatas, 138 + 16 + this.getFontSize());
        if (datas.length > 1 && inx !== datas.length - 1)
          this.setFillColor('#ccc').roundedRect(
            datas.length >= 3 ? xDatas + 96 + 30 : xDatas * (datas?.length + 1),
            138 + 12,
            1,
            44,
            1,
            1,
            'F'
          );
        this.setFontStyle({
          font: FONTS.OpenSans.semiBold,
          fontSize: 16,
          color: '#333',
          lineHeight: 24,
        }).text(value.value, xDatas, 138 + 36 + this.getFontSize());
        this.setFontStyle({
          font: FONTS.OpenSans.semiBold,
          fontSize: 12,
          color: '#333',
          lineHeight: 18,
        }).text(
          value.subValue,
          xDatas + this.getTextWidth(value.value) + 15,
          189
        );

        xDatas += 435 / datas?.length;
      });

    this.addSubTitle('How do we maintain a healthy trend?', 80, 250);

    let xContents = 80;
    let lineSpacing = 16;
    let yContents = 277 - 16;

    let CONTENTS = [
      {
        boldText: '1. Weight Management workshops',
        text: `1. Weight Management workshops touches on the differences between being overweight and obese, highlighting the causes and serious impacts to health, as well as the solutions to better manage our weight. This talk is beneficial for those currently overweight or simply interested to learn more about weight management.`,
      },
      {
        boldText: '2. Healthy food',
        text: `2. Healthy food making demonstration place emphasis on the importance of eating healthily and include valuable tips on modifying diets without compromising the enjoyment of food.`,
      },
      {
        boldText: '3. Weight Targeted Intervention Programme',
        text: `3. Weight Targeted Intervention Programme is most suitable for those wanting to get back in shape but need a little nudge and motivation. In this programme, they can take a big step forward by learning how to set and achieve weight loss goals and equip themselves the knowledge and skills to make healthier food choices and include adequate physical activity.`,
      },
    ];

    CONTENTS.forEach((value) => {
      const lines = this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 12,
        color: '#333',
        lineHeight: 18,
      }).splitTextToSize(value.text, 430);
      const newLines = lines.filter((line) => !isEmpty(line));

      yContents += 16;

      newLines.forEach((lineText, inx) => {
        this.setFontStyle({
          font: FONTS.OpenSans.regular,
          fontSize: 12,
          lineHeight: 18,
          color: '#333',
        }).text(lineText, xContents, yContents + this.getLineHeight(), {
          maxWidth: 430,
          lineHeightFactor: 1.57,
          charSpace: 0.225,
        });

        if (inx === 0) {
          this.setFillColor('#fff').roundedRect(
            xContents,
            yContents + 2,
            this.getTextWidth(value.boldText),
            20,
            0,
            0,
            'F'
          );

          this.setFontStyle({
            font: FONTS.OpenSans.semiBold,
            fontSize: 12,
            color: '#333',
            lineHeight: 18,
          }).text(value.boldText, xContents, yContents + this.getLineHeight());
        }

        yContents += lineSpacing;
      });
    });

    return this;
  }

  addTotalCholesterol(corporateData, chart) {
    if (!corporateData?.healthScreenings?.tc) return this;
    const tc = get(corporateData, 'healthScreenings.tc.categories');

    const highPercent = tc.find((it) => it.title === 'High').percent;

    this.addPage()
      .addBg(bgCover)
      .addPaging(LIST_TABLE_CONTENTS_LABEL.HealthScreeningFindings);

    this.addHeaderSection(80, 64, 'Total Cholesterol (TC)', podiumImg);

    if (chart) {
      const chartImg = chart.ctx.canvas.toDataURL();

      this.addImage(chartImg, 'PNG', 0, 132, 300, 148).addLabelOutsideChart(
        chart,
        80,
        132
      );

      const LABELS = [
        {
          color: '#BEEEC7',
          title: 'Normal',
        },
        {
          color: '#F9E1A1',
          title: 'Borderline High',
        },
        {
          color: '#FFBCB7',
          title: 'High',
        },
      ];
      this.addLegendLabelChart(LABELS, 303, 166);
    }
    this.setFontStyle({
      font: FONTS.OpenSans.regular,
      fontSize: 12,
      color: '#333333',
      lineHeight: 18,
    }).text(`${highPercent.formatPercentValue()}% has High TC.`, 80, 320, {
      maxWidth: 435,
    });

    // Table
    const table = [
      [
        {
          color: '#E0E0E0',
          value: 'CATEGORY',
          width: 82,
          height: 24,
          lineHeight: 8,
        },
        {
          color: '#E0E0E0',
          value: 'Normal',
          width: 116,
          height: 24,
          lineHeight: 8,
        },
        {
          color: '#E0E0E0',
          value: 'Borderline High',
          width: 116,
          height: 24,
          lineHeight: 8,
        },
        {
          color: '#E0E0E0',
          value: 'High',
          width: 118,
          height: 24,
          lineHeight: 8,
        },
      ],
      [{ color: '#E0E0E0', value: 'Cutoffs', width: 82, height: 24 }],
      [{ color: '#E0E0E0', value: 'Pax', width: 82, height: 24 }],
      [{ color: '#E0E0E0', value: '%', width: 82, height: 24 }],
    ];

    const dataCutOffs = tc?.map((it, inx) => {
      return {
        color: '#F5F5F5',
        value: `${it.cutOffs}`,
        width: table[0][inx + 1]?.width,
        height: 24,
      };
    });

    const dataTotal = tc?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.total}`,
      width: table[0][inx + 1]?.width,
      height: 24,
    }));
    const dataPercent = tc?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.percent.formatPercentValue()}%`,
      width: table[0][inx + 1]?.width,
      height: 24,
    }));

    let header = [];
    header.push(table[0][0]);
    header = header.concat(
      tc?.map((it, inx) => ({
        color: '#E0E0E0',
        value: it.title,
        width: table[0][inx + 1]?.width,
        height: table[0][inx + 1]?.height,
        lineHeight: table[0][inx + 1]?.lineHeight,
      }))
    );

    table[0] = table[0].map((it, inx) => ({
      ...header[inx],
    }));

    table[1] = table[1].concat(dataCutOffs);
    table[2] = table[2].concat(dataTotal);
    table[3] = table[3].concat(dataPercent);

    let x = 80,
      y = 353,
      yLast = 428;
    let yRow = y;
    let xRowHead = table[0][0].width + x + 2;
    let xRowMid = xRowHead;
    let xRowLast = xRowHead;
    let fontSize = 8;

    this.setFontStyle({
      font: FONTS.OpenSans.regular,
      fontSize,
      color: '#333',
      lineHeight: 10.89,
    });
    table.forEach((rows, rinx) => {
      rows.forEach((cols, cinx) => {
        // Row 1
        if (rinx === 0 && cinx === 0) {
          this.setFillColor(cols.color)
            .rect(x + cols.width / 2, y, cols.width / 2, cols.height, 'F')
            .setFillColor(cols.color)
            .rect(x, y + cols.height / 2, cols.width, cols.height / 2, 'F')
            .setFillColor(cols.color)
            .roundedRect(x, y, cols.width, cols.height, 10, 10, 'F')
            .text(
              cols.value,
              x + cols.width / 2 - this.getTextWidth(cols.value) / 2,
              y + cols.height / 2 + cols.lineHeight / 2
            );
        } else if (rinx === 0 && cinx === rows.length - 1) {
          const width = rows.map((it) => it.width);
          width.pop();
          const xLast = x + sum(width) + rows.length * 1.6;

          this.setFillColor(cols.color)
            .rect(xLast, y, cols.width / 2, cols.height, 'F')
            .setFillColor(cols.color)
            .rect(xLast, y + cols.height / 2, cols.width, cols.height / 2, 'F')
            .setFillColor(cols.color)
            .roundedRect(xLast, y, cols.width, cols.height, 10, 10, 'F')
            .text(
              cols.value,
              xLast + cols.width / 2 - this.getTextWidth(cols.value) / 2,
              y + cols.height / 2 + 3
            );
        } else if (rinx === table.length - 1 && cinx === 0) {
          this.setFillColor(cols.color)
            .rect(x + cols.width / 2, yLast, cols.width / 2, cols.height, 'F')
            .setFillColor(cols.color)
            .rect(x, yLast, cols.width, cols.height / 2, 'F')
            .setFillColor(cols.color)
            .roundedRect(x, yLast, cols.width, cols.height, 10, 10, 'F')
            .text(
              cols.value,
              x + cols.width / 2 - this.getTextWidth(cols.value) / 2,
              yLast + 15
            );
        } else if (rinx === table.length - 1 && cinx === rows.length - 1) {
          const width = rows.map((it) => it.width);
          width.pop();
          const xLast = x + sum(width) + rows.length * 1.6;
          this.setFillColor(cols.color)
            .rect(xLast, yLast, cols.width / 2, cols.height, 'F')
            .setFillColor(cols.color)
            .rect(xLast, yLast, cols.width, cols.height / 2, 'F')
            .setFillColor(cols.color)
            .roundedRect(xLast, yLast, cols.width, cols.height, 10, 10, 'F')
            .text(
              cols.value,
              xLast + cols.width / 2 - this.getTextWidth(cols.value) / 2,
              yLast + cols.height / 2 + 4
            );
        } else {
          if (rinx === 0) {
            this.setFillColor(cols.color)
              .rect(xRowHead, y, cols?.width, cols?.height, 'F')
              .text(
                cols.value,
                xRowHead + cols.width / 2 + 2,
                y + cols.height / 2 - cols.lineHeight / 2 + 7,
                { align: 'center' }
              );
          } else if (rinx === table.length - 1) {
            // Last rows
            this.setFillColor(cols.color)
              .rect(xRowLast, yLast, cols.width, cols.height, 'F')
              .text(
                cols.value,
                xRowLast + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                yLast + 15
              );
            xRowLast += cols.width + 2;
          } else {
            // Middle rows
            this.setFillColor(cols.color)
              .rect(xRowMid, yRow, cols.width, cols.height, 'F')
              .text(
                cols.value,
                xRowMid + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                yRow + cols.height / 2 + 4
              );
            xRowMid += cols.width + 2;
          }

          xRowHead += cols.width + 2;
        }
      });
      yRow += 25;
      xRowMid = table[0][0].width - 2;
    });

    // Trending TC

    if (corporateData?.healthScreenings?.tc?.trendings) {
      const trendings = corporateData?.healthScreenings?.tc?.trendings;

      const textTrending = () => {
        if (trendings?.length >= 2) {
          let latestYears = trendings
            ?.sort((a, b) => b.year - a.year)
            .slice(0, 2);
          const percentLastYear = latestYears[1].percent;
          const percentThisYear = latestYears[0].percent;
          const lastYear = latestYears[1].year;

          if (percentLastYear === percentThisYear) {
            return 'There are increased cases by 0%.';
          } else if (percentThisYear > percentLastYear) {
            return `There are increased cases by ${Math.abs(
              percentThisYear?.formatPercentValue() -
                percentLastYear?.formatPercentValue()
            )?.formatPercentValue()}%.`;
          } else {
            return `GREAT, we have improved by ${Math.abs(
              percentThisYear?.formatPercentValue() -
                percentLastYear?.formatPercentValue()
            )?.formatPercentValue()}% since ${lastYear}!`;
          }
        } else {
          return 'There are increased cases by 0%.';
        }
      };

      this.addSubTitle(`Trending: High TC Readings`, 80, 508);

      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 12,
        color: '#333333',
        lineHeight: 18,
      }).text(textTrending(), 80, 549, { maxWidth: 435 });

      let datas = trendings
        ?.sort((a, b) => a.year - b.year)
        .map((it) => ({
          value: `${it.percent?.formatPercentValue()}%`,
          subValue: ` (${it.total} pax)`,
          title: `${it.year}`,
        }));

      let xDatas = 96;
      this.setFillColor('#E2F2FF').roundedRect(80, 572, 435, 68, 10, 10, 'F');
      datas.forEach((value, inx) => {
        this.setFontStyle({
          font: FONTS.OpenSans.semiBold,
          fontSize: 12,
          color: '#666',
          lineHeight: 16,
        }).text(value.title, xDatas, 572 + 16 + this.getFontSize());
        if (datas.length > 1 && inx !== datas.length - 1)
          this.setFillColor('#ccc').roundedRect(
            datas.length >= 3 ? xDatas + 96 + 30 : xDatas * (datas?.length + 1),
            572 + 12,
            1,
            44,
            1,
            1,
            'F'
          );
        this.setFontStyle({
          font: FONTS.OpenSans.semiBold,
          fontSize: 16,
          color: '#333',
          lineHeight: 24,
        }).text(value.value, xDatas, 572 + 36 + this.getFontSize());
        this.setFontStyle({
          font: FONTS.OpenSans.semiBold,
          fontSize: 12,
          color: '#333',
          lineHeight: 18,
        }).text(
          value.subValue,
          xDatas + this.getTextWidth(value.value) + 15,
          572 + 51
        );

        xDatas += 435 / datas?.length;
      });
    }

    return this;
  }

  addDirectLDL(corporateData, chart) {
    if (!corporateData?.healthScreenings?.ldl) return this;

    this.addPage()
      .addBg(bgCover)
      .addPaging(LIST_TABLE_CONTENTS_LABEL.HealthScreeningFindings);

    // if (corporateData?.healthScreenings?.tc) {
    //   // Trending TC
    //   const percentLastYear = get(
    //     corporateData,
    //     'healthScreenings.tc.trending.percentLastYear'
    //   );
    //   const percentThisYear = get(
    //     corporateData,
    //     'healthScreenings.tc.trending.percentThisYear'
    //   );
    //   const totalLastYear = get(
    //     corporateData,
    //     'healthScreenings.tc.trending.totalLastYear'
    //   );
    //   const totalThisYear = get(
    //     corporateData,
    //     'healthScreenings.tc.trending.totalThisYear'
    //   );
    //   const title = get(corporateData, 'healthScreenings.tc.trending.title');

    //   const text =
    //     percentThisYear?.formatPercentValue() -

    //   this.addTitle(`Trending: ${title}`);
    //   this.setFontStyle({
    //     font: FONTS.OpenSans.regular,
    //     fontSize: 12,
    //     color: '#333333',
    //     lineHeight: 18,
    //   }).text(
    //     `There are ${
    //       percentThisYear === percentLastYear ? 'the same' : text
    //     } cases by ${Math.abs(percentThisYear?.formatPercentValue() -
    //     80,
    //     114,
    //     { maxWidth: 435 }
    //   );

    //   const DATAS = [
    //     {
    //       value: `${percentLastYear?.formatPercentValue()}%`,
    //       subValue: ` (${totalLastYear} pax)`,
    //       title: moment().subtract(1, 'year').format('YYYY'),
    //     },
    //     {
    //       value: `${percentThisYear?.formatPercentValue()}%`,
    //       subValue: ` (${totalThisYear} pax)`,
    //       title: moment().format('YYYY'),
    //     },
    //   ];
    //   let xDatas = 96;
    //   this.setFillColor('#E2F2FF').roundedRect(80, 138, 435, 68, 10, 10, 'F');
    //   DATAS.forEach((value, inx) => {
    //     this.setFontStyle({
    //       font: FONTS.OpenSans.semiBold,
    //       fontSize: 10,
    //       color: '#666',
    //       lineHeight: 16,
    //     }).text(value.title, xDatas, 138 + 16 + this.getFontSize());
    //     this.setFillColor('#ccc').roundedRect(297, 138 + 12, 1, 44, 1, 1, 'F');
    //     this.setFontStyle({
    //       font: FONTS.OpenSans.semiBold,
    //       fontSize: 16,
    //       color: '#333',
    //       lineHeight: 24,
    //     }).text(value.value, xDatas, 138 + 36 + this.getFontSize());
    //     this.setFontStyle({
    //       font: FONTS.OpenSans.semiBold,
    //       fontSize: 12,
    //       color: '#333',
    //       lineHeight: 18,
    //     }).text(
    //       value.subValue,
    //       xDatas + this.getTextWidth(value.value) + 10,
    //       189
    //     );

    //     xDatas += 435 / 2;
    //   });
    // }

    if (corporateData?.healthScreenings?.ldl) {
      const ldl = get(corporateData, 'healthScreenings.ldl.categories');
      const veryHighPercent = ldl.find(
        (it) => it.title.replace('\n', ' ') === 'Very High'
      ).percent;

      const highPercent = ldl.find(
        (it) => it.title.replace('\n', ' ') === 'High'
      ).percent;

      const nphSPercentHigh = get(
        corporateData,
        'healthScreenings.ldl.nphS_PercentHigh'
      );

      const nphSPercentNormal = get(
        corporateData,
        'healthScreenings.ldl.nphS_PercentNormal'
      );
      // Direct LDL
      this.addHeaderSection(
        80,
        64,
        'Direct Low Density Lipoprotein (Direct LDL)',
        podiumImg
      );

      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 12,
        color: '#333333',
        lineHeight: 18,
      }).text(
        `Lipoproteins are carriers of cholesterol in the blood. LDL will build up cholesterol in the arteries when in excess and is a major cause of atherosclerosis.`,
        80,
        132,
        { maxWidth: 435 }
      );

      if (chart) {
        const chartImg = chart.ctx.canvas.toDataURL();

        this.addImage(chartImg, 'PNG', 0, 212, 300, 148).addLabelOutsideChart(
          chart,
          80,
          212
        );

        const LABELS = [
          {
            color: '#16a440',
            title: 'Optimal',
          },
          {
            color: '#BEEEC7',
            title: 'Desirable',
          },
          {
            color: '#FFE0B0',
            title: 'Borderline High',
          },
          {
            color: '#FFBCB7',
            title: 'High',
          },
          {
            color: '#DB3D49',
            title: 'Very High',
          },
        ];
        this.addLegendLabelChart(LABELS, 339, 216);
      }

      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 12,
        color: '#333333',
        lineHeight: 18,
      }).text(
        `${(
          veryHighPercent + highPercent
        ).formatPercentValue()}% has unhealthy level of Direct LDL (i.e. High and Very High). This is ${
          (veryHighPercent + highPercent).formatPercentValue() >
          nphSPercentNormal.formatPercentValue()
            ? 'higher'
            : 'lower'
        } than national prevalence of ${nphSPercentNormal.formatPercentValue()}%.`,
        80,
        400,
        {
          maxWidth: 435,
        }
      );

      // Table
      const table = [
        [
          {
            color: '#E0E0E0',
            value: 'CATEGORY',
            width: 60,
            height: 40,
            lineHeight: 8,
          },
          {
            color: '#E0E0E0',
            value: 'Normal',
            width: 60,
            height: 40,
            lineHeight: 8,
          },
          {
            color: '#E0E0E0',
            value: 'Desirable',
            width: 74,
            height: 40,
            lineHeight: 8,
          },
          {
            color: '#E0E0E0',
            value: 'Borderline\nHigh',
            width: 74,
            height: 40,
            lineHeight: 16,
          },

          {
            color: '#E0E0E0',
            value: 'High',
            width: 74,
            height: 40,
            lineHeight: 8,
          },
          {
            color: '#E0E0E0',
            value: 'Very High',
            width: 88,
            height: 40,
            lineHeight: 8,
          },
        ],
        [{ color: '#E0E0E0', value: 'Cutoffs', width: 60, height: 24 }],
        [{ color: '#E0E0E0', value: 'Pax', width: 60, height: 24 }],
        [{ color: '#E0E0E0', value: '%', width: 60, height: 24 }],
        [
          {
            color: '#E0E0E0',
            value: 'NPHS 2022',
            width: 60,
            height: 24,
          },
        ],
      ];
      const dataCutOffs = ldl?.map((it, inx) => {
        return {
          color: '#F5F5F5',
          value: `${it.cutOffs}`,
          width: table[0][inx + 1].width,
          height: 24,
        };
      });

      const dataTotal = ldl?.map((it, inx) => ({
        color: '#F5F5F5',
        value: `${it.total}`,
        width: table[0][inx + 1].width,
        height: 24,
      }));
      const dataPercent = ldl?.map((it, inx) => ({
        color: '#F5F5F5',
        value: `${it.percent.formatPercentValue()}%`,
        width: table[0][inx + 1].width,
        height: 24,
      }));

      let header = [];
      header.push(table[0][0]);
      header = header.concat(
        ldl?.map((it, inx) => ({
          color: '#E0E0E0',
          value: it.title,
          width: table[0][inx + 1]?.width,
          height: table[0][inx + 1]?.height,
          lineHeight: table[0][inx + 1]?.lineHeight,
        }))
      );

      table[0] = table[0].map((it, inx) => ({
        ...header[inx],
      }));

      table[1] = table[1].concat(dataCutOffs);
      table[2] = table[2].concat(dataTotal);
      table[3] = table[3].concat(dataPercent);
      let x = 80,
        y = 440,
        yLast = 560;
      let yRow = y + 16;
      let xRowHead = table[0][0].width + x + 2;
      let xRowMid = xRowHead;

      let fontSize = 8;
      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize,
        color: '#333',
        lineHeight: 10.89,
      });
      table.forEach((rows, rinx) => {
        rows.forEach((cols, cinx) => {
          // Row 1
          if (rinx === 0 && cinx === 0) {
            this.setFillColor(cols.color)
              .rect(x + cols.width / 2, y, cols.width / 2, cols.height, 'F')
              .setFillColor(cols.color)
              .rect(x, y + cols.height / 2, cols.width, cols.height / 2, 'F')
              .setFillColor(cols.color)
              .roundedRect(x, y, cols.width, cols.height, 10, 10, 'F')
              .text(
                cols.value,
                x + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                y + cols.height / 2 + cols.lineHeight / 2
              );
          } else if (rinx === 0 && cinx === rows.length - 1) {
            const width = rows.map((it) => it.width);
            width.pop();
            const xLast = x + sum(width) + rows.length * 1.7;

            this.setFillColor(cols.color)
              .rect(xLast, y, cols.width / 2, cols.height, 'F')
              .setFillColor(cols.color)
              .rect(xLast, y + 20, cols.width, cols.height / 2, 'F')
              .setFillColor(cols.color)
              .roundedRect(xLast, y, cols.width, cols.height, 10, 10, 'F')
              .text(
                cols.value,
                xLast + 42,
                y + cols.height / 2 - cols.lineHeight / 2 + 7,
                { align: 'center' }
              );
          } else if (rinx === table.length - 1 && cinx === 0) {
            this.setFillColor(cols.color)
              .rect(x + cols.width / 2, yLast, cols.width / 2, cols.height, 'F')
              .setFillColor(cols.color)
              .rect(x, yLast, cols.width, cols.height / 2, 'F')
              .setFillColor(cols.color)
              .roundedRect(x, yLast, cols.width, cols.height, 10, 10, 'F')
              .text(cols.value, x + cols.width / 2 - 20, yLast + 15);
          } else if (rinx === table.length - 1 && cinx === rows.length - 1) {
          } else {
            if (rinx === 0) {
              this.setFillColor(cols.color)
                .rect(xRowHead, y, cols?.width, cols?.height, 'F')
                .text(
                  cols.value,
                  xRowHead + cols.width / 2 + 2,
                  y + cols.height / 2 - cols.lineHeight / 2 + 7,
                  { align: 'center' }
                );
            } else {
              // Middle rows
              this.setFillColor(cols.color)
                .rect(xRowMid, yRow, cols.width, cols.height, 'F')
                .text(
                  cols.value,
                  xRowMid + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                  yRow + cols.height / 2 + 4
                );
              xRowMid += cols.width + 2;
            }

            xRowHead += cols.width + 2;
          }
        });
        yRow += 26;
        xRowMid = table[0][0].width + 20;
      });
      // Last Row

      this.setFillColor('#F5F5F5')
        .roundedRect(142, yLast, 212, 24, 0, 0, 'F')
        .text(
          `${nphSPercentHigh.formatPercentValue()}%`,
          142 + 212 / 2 - this.getTextWidth('0%') / 2,
          yLast + 15,
          {
            align: 'center',
          }
        );
      this.setFillColor('#F5F5F5')
        .rect(356, yLast, 164 / 2, 24, 'F')
        .setFillColor('#F5F5F5')
        .rect(356, yLast, 164, 24 / 2, 'F')
        .setFillColor('#F5F5F5')
        .roundedRect(356, yLast, 164, 24, 10, 10, 'F')
        .text(
          `${nphSPercentNormal.formatPercentValue()}%`,
          356 + 164 / 2 - this.getTextWidth('0%') / 2,
          yLast + 15,
          {
            align: 'center',
          }
        );
      if (corporateData?.healthScreenings?.ldl?.trendings) {
        // Trending LDL
        const trendings = corporateData?.healthScreenings?.ldl?.trendings;

        const textTrending = () => {
          if (trendings?.length >= 2) {
            let latestYears = trendings
              ?.sort((a, b) => b.year - a.year)
              .slice(0, 2);
            const percentLastYear = latestYears[1].percent;
            const percentThisYear = latestYears[0].percent;
            const lastYear = latestYears[1].year;

            if (percentLastYear === percentThisYear) {
              return 'There are increased cases by 0%.';
            } else if (percentThisYear > percentLastYear) {
              return `There are increased cases by ${Math.abs(
                percentThisYear?.formatPercentValue() -
                  percentLastYear?.formatPercentValue()
              )?.formatPercentValue()}%.`;
            } else {
              return `GREAT, we have improved by ${Math.abs(
                percentThisYear?.formatPercentValue() -
                  percentLastYear?.formatPercentValue()
              )?.formatPercentValue()}% since ${lastYear}!`;
            }
          } else {
            return 'There are increased cases by 0%.';
          }
        };

        this.addSubTitle(`Trending: High Direct LDL Readings`, 80, 643);
        this.setFontStyle({
          font: FONTS.OpenSans.regular,
          fontSize: 12,
          color: '#333333',
          lineHeight: 18,
        }).text(textTrending(), 80, 684, { maxWidth: 435 });

        let datas = trendings
          ?.sort((a, b) => a.year - b.year)
          .map((it) => ({
            value: `${it.percent?.formatPercentValue()}%`,
            subValue: ` (${it.total} pax)`,
            title: `${it.year}`,
          }));

        let xDatas = 96;
        this.setFillColor('#E2F2FF').roundedRect(80, 707, 435, 68, 10, 10, 'F');
        datas.forEach((value, inx) => {
          this.setFontStyle({
            font: FONTS.OpenSans.semiBold,
            fontSize: 12,
            color: '#666',
            lineHeight: 16,
          }).text(value.title, xDatas, 707 + 16 + this.getFontSize());
          if (datas.length > 1 && inx !== datas.length - 1)
            this.setFillColor('#ccc').roundedRect(
              datas.length >= 3
                ? xDatas + 96 + 30
                : xDatas * (datas?.length + 1),
              707 + 12,
              1,
              44,
              1,
              1,
              'F'
            );
          this.setFontStyle({
            font: FONTS.OpenSans.semiBold,
            fontSize: 16,
            color: '#333',
            lineHeight: 24,
          }).text(value.value, xDatas, 707 + 36 + this.getFontSize());
          this.setFontStyle({
            font: FONTS.OpenSans.semiBold,
            fontSize: 12,
            color: '#333',
            lineHeight: 18,
          }).text(
            value.subValue,
            xDatas + this.getTextWidth(value.value) + 15,
            707 + 51
          );

          xDatas += 435 / datas?.length;
        });
      }
    }

    return this;
  }

  addHDL(corporateData, chart) {
    if (!corporateData?.healthScreenings?.hdl) return this;

    this.addPage()
      .addBg(bgCover)
      .addPaging(LIST_TABLE_CONTENTS_LABEL.HealthScreeningFindings);

    // if (corporateData?.healthScreenings?.ldl) {
    //   // Trending LDL
    //   const percentLastYear = get(
    //     corporateData,
    //     'healthScreenings.ldl.trending.percentLastYear'
    //   );
    //   const percentThisYear = get(
    //     corporateData,
    //     'healthScreenings.ldl.trending.percentThisYear'
    //   );
    //   const totalLastYear = get(
    //     corporateData,
    //     'healthScreenings.ldl.trending.totalLastYear'
    //   );
    //   const totalThisYear = get(
    //     corporateData,
    //     'healthScreenings.ldl.trending.totalThisYear'
    //   );
    //   const title = get(corporateData, 'healthScreenings.ldl.trending.title');

    //   const text =
    //     percentThisYear?.formatPercentValue() -

    //   this.addTitle(`Trending: ${title}`);
    //   this.setFontStyle({
    //     font: FONTS.OpenSans.regular,
    //     fontSize: 12,
    //     color: '#333333',
    //     lineHeight: 18,
    //   }).text(
    //     `There are ${
    //       percentThisYear === percentLastYear ? 'the same' : text
    //     } cases by ${Math.abs(percentThisYear?.formatPercentValue() -
    //     80,
    //     114,
    //     { maxWidth: 435 }
    //   );

    //   const DATAS = [
    //     {
    //       value: `${percentLastYear?.formatPercentValue()}%`,
    //       subValue: ` (${totalLastYear} pax)`,
    //       title: moment().subtract(1, 'year').format('YYYY'),
    //     },
    //     {
    //       value: `${percentThisYear?.formatPercentValue()}%`,
    //       subValue: ` (${totalThisYear} pax)`,
    //       title: moment().format('YYYY'),
    //     },
    //   ];
    //   let xDatas = 96;
    //   this.setFillColor('#E2F2FF').roundedRect(80, 138, 435, 68, 10, 10, 'F');
    //   DATAS.forEach((value, inx) => {
    //     this.setFontStyle({
    //       font: FONTS.OpenSans.semiBold,
    //       fontSize: 10,
    //       color: '#666',
    //       lineHeight: 16,
    //     }).text(value.title, xDatas, 138 + 16 + this.getFontSize());
    //     this.setFillColor('#ccc').roundedRect(297, 138 + 12, 1, 44, 1, 1, 'F');
    //     this.setFontStyle({
    //       font: FONTS.OpenSans.semiBold,
    //       fontSize: 16,
    //       color: '#333',
    //       lineHeight: 24,
    //     }).text(value.value, xDatas, 138 + 36 + this.getFontSize());
    //     this.setFontStyle({
    //       font: FONTS.OpenSans.semiBold,
    //       fontSize: 12,
    //       color: '#333',
    //       lineHeight: 18,
    //     }).text(
    //       value.subValue,
    //       xDatas + this.getTextWidth(value.value) + 10,
    //       189
    //     );

    //     xDatas += 435 / 2;
    //   });
    // }

    if (corporateData?.healthScreenings?.hdl) {
      const hdl = get(corporateData, 'healthScreenings.hdl.categories');
      const lowPercent = hdl.find((it) => it.title === 'Low').percent;

      //  HDL
      this.addHeaderSection(
        80,
        64,
        'High Density Lipoprotein (HDL)',
        podiumImg
      );

      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 12,
        color: '#333333',
        lineHeight: 18,
      }).text(
        `Lipoproteins are carriers of cholesterol in the blood. HDL is protective against cardiovascular diseases as they can remove excess cholesterol from the body and reduce the risk of build-up of cholesterol in the arteries.`,
        80,
        132,
        { maxWidth: 435 }
      );

      if (chart) {
        const chartImg = chart.ctx.canvas.toDataURL();

        this.addImage(chartImg, 'PNG', 0, 212, 300, 148).addLabelOutsideChart(
          chart,
          80,
          212
        );

        const LABELS = [
          {
            color: '#BEEEC7',
            title: 'Optimal',
          },
          {
            color: '#F9E1A1',
            title: 'Desirable',
          },
          {
            color: '#FFBCB7',
            title: 'Low',
          },
        ];
        this.addLegendLabelChart(LABELS, 339, 246);
      }

      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 12,
        color: '#333333',
        lineHeight: 18,
      }).text(
        `${lowPercent.formatPercentValue()}% has low level of HDL.`,
        80,
        400,
        {
          maxWidth: 435,
        }
      );

      // Table
      const table = [
        [
          {
            color: '#E0E0E0',
            value: 'CATEGORY',
            width: 82,
            height: 24,
            lineHeight: 8,
          },
          {
            color: '#E0E0E0',
            value: 'Low',
            width: 116,
            height: 24,
            lineHeight: 8,
          },
          {
            color: '#E0E0E0',
            value: 'Desirable',
            width: 116,
            height: 24,
            lineHeight: 8,
          },
          {
            color: '#E0E0E0',
            value: 'Optimal',
            width: 118,
            height: 24,
            lineHeight: 8,
          },
        ],
        [{ color: '#E0E0E0', value: 'Cutoffs', width: 82, height: 24 }],
        [{ color: '#E0E0E0', value: 'Pax', width: 82, height: 24 }],
        [{ color: '#E0E0E0', value: '%', width: 82, height: 24 }],
      ];

      const dataCutOffs = hdl?.map((it, inx) => {
        return {
          color: '#F5F5F5',
          value: `${it.cutOffs}`,
          width: table[0][inx + 1]?.width,
          height: 24,
        };
      });

      const dataTotal = hdl?.map((it, inx) => ({
        color: '#F5F5F5',
        value: `${it.total}`,
        width: table[0][inx + 1]?.width,
        height: 24,
      }));
      const dataPercent = hdl?.map((it, inx) => ({
        color: '#F5F5F5',
        value: `${it.percent.formatPercentValue()}%`,
        width: table[0][inx + 1]?.width,
        height: 24,
      }));

      let header = [];
      header.push(table[0][0]);
      header = header.concat(
        hdl?.map((it, inx) => ({
          color: '#E0E0E0',
          value: it.title,
          width: table[0][inx + 1]?.width,
          height: table[0][inx + 1]?.height,
          lineHeight: table[0][inx + 1]?.lineHeight,
        }))
      );

      table[0] = table[0].map((it, inx) => ({
        ...header[inx],
      }));
      table[1] = table[1].concat(dataCutOffs);
      table[2] = table[2].concat(dataTotal);
      table[3] = table[3].concat(dataPercent);

      let x = 80,
        y = 440,
        yLast = 515;
      let yRow = y;
      let xRowHead = table[0][0].width + x + 2;
      let xRowMid = xRowHead;
      let xRowLast = xRowHead;
      let fontSize = 8;

      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize,
        color: '#333',
        lineHeight: 10.89,
      });
      table.forEach((rows, rinx) => {
        rows.forEach((cols, cinx) => {
          // Row 1
          if (rinx === 0 && cinx === 0) {
            this.setFillColor(cols.color)
              .rect(x + cols.width / 2, y, cols.width / 2, cols.height, 'F')
              .setFillColor(cols.color)
              .rect(x, y + cols.height / 2, cols.width, cols.height / 2, 'F')
              .setFillColor(cols.color)
              .roundedRect(x, y, cols.width, cols.height, 10, 10, 'F')
              .text(
                cols.value,
                x + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                y + cols.height / 2 + cols.lineHeight / 2
              );
          } else if (rinx === 0 && cinx === rows.length - 1) {
            const width = rows.map((it) => it.width);
            width.pop();
            const xLast = x + sum(width) + rows.length * 1.6;

            this.setFillColor(cols.color)
              .rect(xLast, y, cols.width / 2, cols.height, 'F')
              .setFillColor(cols.color)
              .rect(
                xLast,
                y + cols.height / 2,
                cols.width,
                cols.height / 2,
                'F'
              )
              .setFillColor(cols.color)
              .roundedRect(xLast, y, cols.width, cols.height, 10, 10, 'F')
              .text(
                cols.value,
                xLast + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                y + cols.height / 2 + 3
              );
          } else if (rinx === table.length - 1 && cinx === 0) {
            this.setFillColor(cols.color)
              .rect(x + cols.width / 2, yLast, cols.width / 2, cols.height, 'F')
              .setFillColor(cols.color)
              .rect(x, yLast, cols.width, cols.height / 2, 'F')
              .setFillColor(cols.color)
              .roundedRect(x, yLast, cols.width, cols.height, 10, 10, 'F')
              .text(
                cols.value,
                x + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                yLast + 15
              );
          } else if (rinx === table.length - 1 && cinx === rows.length - 1) {
            const width = rows.map((it) => it.width);
            width.pop();
            const xLast = x + sum(width) + rows.length * 1.6;
            this.setFillColor(cols.color)
              .rect(xLast, yLast, cols.width / 2, cols.height, 'F')
              .setFillColor(cols.color)
              .rect(xLast, yLast, cols.width, cols.height / 2, 'F')
              .setFillColor(cols.color)
              .roundedRect(xLast, yLast, cols.width, cols.height, 10, 10, 'F')
              .text(
                cols.value,
                xLast + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                yLast + cols.height / 2 + 4
              );
          } else {
            if (rinx === 0) {
              this.setFillColor(cols.color)
                .rect(xRowHead, y, cols?.width, cols?.height, 'F')
                .text(
                  cols.value,
                  xRowHead + cols.width / 2 + 2,
                  y + cols.height / 2 - cols.lineHeight / 2 + 7,
                  { align: 'center' }
                );
            } else if (rinx === table.length - 1) {
              // Last rows
              this.setFillColor(cols.color)
                .rect(xRowLast, yLast, cols.width, cols.height, 'F')
                .text(
                  cols.value,
                  xRowLast + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                  yLast + 15
                );
              xRowLast += cols.width + 2;
            } else {
              // Middle rows
              this.setFillColor(cols.color)
                .rect(xRowMid, yRow, cols.width, cols.height, 'F')
                .text(
                  cols.value,
                  xRowMid + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                  yRow + cols.height / 2 + 4
                );
              xRowMid += cols.width + 2;
            }

            xRowHead += cols.width + 2;
          }
        });
        yRow += 25;
        xRowMid = table[0][0].width - 2;
      });
      if (corporateData?.healthScreenings?.hdl?.trendings) {
        const trendings = corporateData?.healthScreenings?.hdl?.trendings;

        const textTrending = () => {
          if (trendings?.length >= 2) {
            let latestYears = trendings
              ?.sort((a, b) => b.year - a.year)
              .slice(0, 2);
            const percentLastYear = latestYears[1].percent;
            const percentThisYear = latestYears[0].percent;
            const lastYear = latestYears[1].year;

            if (percentLastYear === percentThisYear) {
              return 'There are increased cases by 0%.';
            } else if (percentThisYear > percentLastYear) {
              return `There are increased cases by ${Math.abs(
                percentThisYear?.formatPercentValue() -
                  percentLastYear?.formatPercentValue()
              )?.formatPercentValue()}%.`;
            } else {
              return `GREAT, we have improved by ${Math.abs(
                percentThisYear?.formatPercentValue() -
                  percentLastYear?.formatPercentValue()
              )?.formatPercentValue()}% since ${lastYear}!`;
            }
          } else {
            return 'There are increased cases by 0%.';
          }
        };

        this.addSubTitle(`Trending: Low HDL Readings`, 80, 598);
        this.setFontStyle({
          font: FONTS.OpenSans.regular,
          fontSize: 12,
          color: '#333333',
          lineHeight: 18,
        }).text(textTrending(), 80, 639, { maxWidth: 435 });

        let datas = trendings
          ?.sort((a, b) => a.year - b.year)
          .map((it) => ({
            value: `${it.percent?.formatPercentValue()}%`,
            subValue: ` (${it.total} pax)`,
            title: `${it.year}`,
          }));

        let xDatas = 96;
        this.setFillColor('#E2F2FF').roundedRect(80, 662, 435, 68, 10, 10, 'F');
        datas.forEach((value, inx) => {
          this.setFontStyle({
            font: FONTS.OpenSans.semiBold,
            fontSize: 12,
            color: '#666',
            lineHeight: 16,
          }).text(value.title, xDatas, 662 + 16 + this.getFontSize());
          if (datas.length > 1 && inx !== datas.length - 1)
            this.setFillColor('#ccc').roundedRect(
              datas.length >= 3
                ? xDatas + 96 + 30
                : xDatas * (datas?.length + 1),
              662 + 12,
              1,
              44,
              1,
              1,
              'F'
            );
          this.setFontStyle({
            font: FONTS.OpenSans.semiBold,
            fontSize: 16,
            color: '#333',
            lineHeight: 24,
          }).text(value.value, xDatas, 662 + 36 + this.getFontSize());
          this.setFontStyle({
            font: FONTS.OpenSans.semiBold,
            fontSize: 12,
            color: '#333',
            lineHeight: 18,
          }).text(
            value.subValue,
            xDatas + this.getTextWidth(value.value) + 15,
            662 + 51
          );

          xDatas += 435 / datas?.length;
        });
      }
    }

    return this;
  }

  addTriglyceride(corporateData, chart) {
    if (!corporateData?.healthScreenings?.triglyceride) return this;

    this.addPage()
      .addBg(bgCover)
      .addPaging(LIST_TABLE_CONTENTS_LABEL.HealthScreeningFindings);

    // if (corporateData?.healthScreenings?.hdl) {
    //   // Trending HDL
    //   const percentLastYear = get(
    //     corporateData,
    //     'healthScreenings.hdl.trending.percentLastYear'
    //   );
    //   const percentThisYear = get(
    //     corporateData,
    //     'healthScreenings.hdl.trending.percentThisYear'
    //   );
    //   const totalLastYear = get(
    //     corporateData,
    //     'healthScreenings.hdl.trending.totalLastYear'
    //   );
    //   const totalThisYear = get(
    //     corporateData,
    //     'healthScreenings.hdl.trending.totalThisYear'
    //   );
    //   const title = get(corporateData, 'healthScreenings.hdl.trending.title');

    //   const text =
    //     percentThisYear?.formatPercentValue() -

    //   this.addTitle(`Trending: ${title}`);
    //   this.setFontStyle({
    //     font: FONTS.OpenSans.regular,
    //     fontSize: 12,
    //     color: '#333333',
    //     lineHeight: 18,
    //   }).text(
    //     `There are ${
    //       percentThisYear === percentLastYear ? 'the same' : text
    //     } cases by ${Math.abs(percentThisYear?.formatPercentValue() -
    //     80,
    //     114,
    //     { maxWidth: 435 }
    //   );

    //   const DATAS = [
    //     {
    //       value: `${percentLastYear?.formatPercentValue()}%`,
    //       subValue: ` (${totalLastYear} pax)`,
    //       title: moment().subtract(1, 'year').format('YYYY'),
    //     },
    //     {
    //       value: `${percentThisYear?.formatPercentValue()}%`,
    //       subValue: ` (${totalThisYear} pax)`,
    //       title: moment().format('YYYY'),
    //     },
    //   ];
    //   let xDatas = 96;
    //   this.setFillColor('#E2F2FF').roundedRect(80, 138, 435, 68, 10, 10, 'F');
    //   DATAS.forEach((value, inx) => {
    //     this.setFontStyle({
    //       font: FONTS.OpenSans.semiBold,
    //       fontSize: 10,
    //       color: '#666',
    //       lineHeight: 16,
    //     }).text(value.title, xDatas, 138 + 16 + this.getFontSize());
    //     this.setFillColor('#ccc').roundedRect(297, 138 + 12, 1, 44, 1, 1, 'F');
    //     this.setFontStyle({
    //       font: FONTS.OpenSans.semiBold,
    //       fontSize: 16,
    //       color: '#333',
    //       lineHeight: 24,
    //     }).text(value.value, xDatas, 138 + 36 + this.getFontSize());
    //     this.setFontStyle({
    //       font: FONTS.OpenSans.semiBold,
    //       fontSize: 12,
    //       color: '#333',
    //       lineHeight: 18,
    //     }).text(
    //       value.subValue,
    //       xDatas + this.getTextWidth(value.value) + 10,
    //       189
    //     );

    //     xDatas += 435 / 2;
    //   });
    // }

    if (corporateData?.healthScreenings?.triglyceride) {
      const triglyceride = get(
        corporateData,
        'healthScreenings.triglyceride.categories'
      );
      const highPercent = triglyceride.find(
        (it) => it.title === 'High'
      ).percent;
      const veryHighPercent = triglyceride.find(
        (it) => it.title === 'Very High'
      ).percent;

      //  Triglyceride
      this.addHeaderSection(80, 64, 'Triglyceride', podiumImg);

      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 12,
        color: '#333333',
        lineHeight: 18,
      }).text(
        `Triglycerides are fats in the blood and a high level of it can pose a higher risk of developing heart diseases.`,
        80,
        132,
        { maxWidth: 435 }
      );

      if (chart) {
        const chartImg = chart.ctx.canvas.toDataURL();

        this.addImage(chartImg, 'PNG', 0, 200, 300, 148).addLabelOutsideChart(
          chart,
          80,
          200
        );

        const LABELS = [
          {
            color: '#BEEEC7',
            title: 'Optimal',
          },
          {
            color: '#F9E1A1',
            title: 'Desirable',
          },
          {
            color: '#FFBCB7',
            title: 'High',
          },
          {
            color: '#DB3D49',
            title: 'Very High',
          },
        ];
        this.addLegendLabelChart(LABELS, 340, 219);
      }

      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 12,
        color: '#333333',
        lineHeight: 18,
      }).text(
        `${(
          highPercent + veryHighPercent
        ).formatPercentValue()}% have unhealthy level of Triglyceride (i.e. High and Very High).`,
        80,
        398,
        {
          maxWidth: 435,
        }
      );

      // Table
      const table = [
        [
          {
            color: '#E0E0E0',
            value: 'CATEGORY',
            width: 82,
            height: 24,
            lineHeight: 8,
          },
          {
            color: '#E0E0E0',
            value: 'Optimal',
            width: 87,
            height: 24,
            lineHeight: 8,
          },
          {
            color: '#E0E0E0',
            value: 'Desirable',
            width: 87,
            height: 24,
            lineHeight: 8,
          },
          {
            color: '#E0E0E0',
            value: 'High',
            width: 87,
            height: 24,
            lineHeight: 8,
          },
          {
            color: '#E0E0E0',
            value: 'Very High',
            width: 88,
            height: 24,
            lineHeight: 8,
          },
        ],
        [{ color: '#E0E0E0', value: 'Cutoffs', width: 82, height: 24 }],
        [{ color: '#E0E0E0', value: 'Pax', width: 82, height: 24 }],
        [{ color: '#E0E0E0', value: '%', width: 82, height: 24 }],
      ];

      const dataCutOffs = triglyceride?.map((it, inx) => {
        return {
          color: '#F5F5F5',
          value: `${it.cutOffs}`,
          width: table[0][inx + 1]?.width,
          height: 24,
        };
      });

      const dataTotal = triglyceride?.map((it, inx) => ({
        color: '#F5F5F5',
        value: `${it.total}`,
        width: table[0][inx + 1]?.width,
        height: 24,
      }));
      const dataPercent = triglyceride?.map((it, inx) => ({
        color: '#F5F5F5',
        value: `${it.percent.formatPercentValue()}%`,
        width: table[0][inx + 1]?.width,
        height: 24,
      }));

      let header = [];
      header.push(table[0][0]);
      header = header.concat(
        triglyceride?.map((it, inx) => ({
          color: '#E0E0E0',
          value: it.title,
          width: table[0][inx + 1]?.width,
          height: table[0][inx + 1]?.height,
          lineHeight: table[0][inx + 1]?.lineHeight,
        }))
      );

      table[0] = table[0].map((it, inx) => ({
        ...header[inx],
      }));

      table[1] = table[1].concat(dataCutOffs);
      table[2] = table[2].concat(dataTotal);
      table[3] = table[3].concat(dataPercent);

      let x = 80,
        y = 440,
        yLast = 515;
      let yRow = y;
      let xRowHead = table[0][0].width + x + 2;
      let xRowMid = xRowHead;
      let xRowLast = xRowHead;
      let fontSize = 8;

      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize,
        color: '#333',
        lineHeight: 10.89,
      });
      table.forEach((rows, rinx) => {
        rows.forEach((cols, cinx) => {
          // Row 1
          if (rinx === 0 && cinx === 0) {
            this.setFillColor(cols.color)
              .rect(x + cols.width / 2, y, cols.width / 2, cols.height, 'F')
              .setFillColor(cols.color)
              .rect(x, y + cols.height / 2, cols.width, cols.height / 2, 'F')
              .setFillColor(cols.color)
              .roundedRect(x, y, cols.width, cols.height, 10, 10, 'F')
              .text(
                cols.value,
                x + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                y + cols.height / 2 + cols.lineHeight / 2
              );
          } else if (rinx === 0 && cinx === rows.length - 1) {
            const width = rows.map((it) => it.width);
            width.pop();
            const xLast = x + sum(width) + rows.length * 1.6;

            this.setFillColor(cols.color)
              .rect(xLast, y, cols.width / 2, cols.height, 'F')
              .setFillColor(cols.color)
              .rect(
                xLast,
                y + cols.height / 2,
                cols.width,
                cols.height / 2,
                'F'
              )
              .setFillColor(cols.color)
              .roundedRect(xLast, y, cols.width, cols.height, 10, 10, 'F')
              .text(
                cols.value,
                xLast + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                y + cols.height / 2 + 3
              );
          } else if (rinx === table.length - 1 && cinx === 0) {
            this.setFillColor(cols.color)
              .rect(x + cols.width / 2, yLast, cols.width / 2, cols.height, 'F')
              .setFillColor(cols.color)
              .rect(x, yLast, cols.width, cols.height / 2, 'F')
              .setFillColor(cols.color)
              .roundedRect(x, yLast, cols.width, cols.height, 10, 10, 'F')
              .text(
                cols.value,
                x + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                yLast + 15
              );
          } else if (rinx === table.length - 1 && cinx === rows.length - 1) {
            const width = rows.map((it) => it.width);
            width.pop();
            const xLast = x + sum(width) + rows.length * 1.6;
            this.setFillColor(cols.color)
              .rect(xLast, yLast, cols.width / 2, cols.height, 'F')
              .setFillColor(cols.color)
              .rect(xLast, yLast, cols.width, cols.height / 2, 'F')
              .setFillColor(cols.color)
              .roundedRect(xLast, yLast, cols.width, cols.height, 10, 10, 'F')
              .text(
                cols.value,
                xLast + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                yLast + cols.height / 2 + 4
              );
          } else {
            if (rinx === 0) {
              this.setFillColor(cols.color)
                .rect(xRowHead, y, cols?.width, cols?.height, 'F')
                .text(
                  cols.value,
                  xRowHead + cols.width / 2 + 2,
                  y + cols.height / 2 - cols.lineHeight / 2 + 7,
                  { align: 'center' }
                );
            } else if (rinx === table.length - 1) {
              // Last rows
              this.setFillColor(cols.color)
                .rect(xRowLast, yLast, cols.width, cols.height, 'F')
                .text(
                  cols.value,
                  xRowLast + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                  yLast + 15
                );
              xRowLast += cols.width + 2;
            } else {
              // Middle rows
              this.setFillColor(cols.color)
                .rect(xRowMid, yRow, cols.width, cols.height, 'F')
                .text(
                  cols.value,
                  xRowMid + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                  yRow + cols.height / 2 + 4
                );
              xRowMid += cols.width + 2;
            }

            xRowHead += cols.width + 2;
          }
        });
        yRow += 25;
        xRowMid = table[0][0].width - 2;
      });
    }

    return this;
  }
  addTriglycerideTrending(corporateData) {
    if (!corporateData?.healthScreenings?.triglyceride?.trendings) return this;
    const trendings = corporateData?.healthScreenings?.triglyceride?.trendings;

    const textTrending = () => {
      if (trendings?.length >= 2) {
        let latestYears = trendings
          ?.sort((a, b) => b.year - a.year)
          .slice(0, 2);
        const percentLastYear = latestYears[1].percent;
        const percentThisYear = latestYears[0].percent;
        const lastYear = latestYears[1].year;

        if (percentLastYear === percentThisYear) {
          return 'There are increased cases by 0%.';
        } else if (percentThisYear > percentLastYear) {
          return `There are increased cases by ${Math.abs(
            percentThisYear?.formatPercentValue() -
              percentLastYear?.formatPercentValue()
          )?.formatPercentValue()}%.`;
        } else {
          return `GREAT, we have improved by ${Math.abs(
            percentThisYear?.formatPercentValue() -
              percentLastYear?.formatPercentValue()
          )?.formatPercentValue()}% since ${lastYear}!`;
        }
      } else {
        return 'There are increased cases by 0%.';
      }
    };

    this.addPage()
      .addBg(bgCover)
      .addPaging(LIST_TABLE_CONTENTS_LABEL.HealthScreeningFindings);
    this.addSubTitle(`Trending: High Triglyceride Readings`);
    this.setFontStyle({
      font: FONTS.OpenSans.regular,
      fontSize: 12,
      color: '#333333',
      lineHeight: 18,
    }).text(textTrending(), 80, 114, { maxWidth: 435 });

    let datas = trendings
      ?.sort((a, b) => a.year - b.year)
      .map((it) => ({
        value: `${it.percent?.formatPercentValue()}%`,
        subValue: ` (${it.total} pax)`,
        title: `${it.year}`,
      }));

    let xDatas = 96;
    this.setFillColor('#E2F2FF').roundedRect(80, 138, 435, 68, 10, 10, 'F');
    datas.forEach((value, inx) => {
      this.setFontStyle({
        font: FONTS.OpenSans.semiBold,
        fontSize: 12,
        color: '#666',
        lineHeight: 16,
      }).text(value.title, xDatas, 138 + 16 + this.getFontSize());
      if (datas.length > 1 && inx !== datas.length - 1)
        this.setFillColor('#ccc').roundedRect(
          datas.length >= 3 ? xDatas + 96 + 30 : xDatas * (datas?.length + 1),
          138 + 12,
          1,
          44,
          1,
          1,
          'F'
        );
      this.setFontStyle({
        font: FONTS.OpenSans.semiBold,
        fontSize: 16,
        color: '#333',
        lineHeight: 24,
      }).text(value.value, xDatas, 138 + 36 + this.getFontSize());
      this.setFontStyle({
        font: FONTS.OpenSans.semiBold,
        fontSize: 12,
        color: '#333',
        lineHeight: 18,
      }).text(
        value.subValue,
        xDatas + this.getTextWidth(value.value) + 15,
        189
      );

      xDatas += 435 / datas?.length;
    });

    this.addSubTitle('How do we maintain a healthy trend?', 80, 250);

    let xContents = 80;
    let lineSpacing = 16;
    let yContents = 277 - 16;

    let CONTENTS = [
      {
        boldText: '1. Cholesterol Management workshops',
        text: `1. Cholesterol Management workshops will allow staff to learn that the key to achieving a healthy lipid profile is to choose healthy fats and exercise their way to a healthier heart.`,
      },
      {
        boldText: '2. Cholesterol Targeted Intervention Programme',
        text: `2. Cholesterol Targeted Intervention Programme is most suitable for those wanting to achieve a healthy lipid profile but need the right knowledge and skills. In this programme, they can look forward to learning practical tips to apply in their daily food choices and the benefits of regular physical activity.`,
      },
      {
        boldText: '3. Physical Activities',
        text: `3. Physical Activities (e.g. Kickboxing, Aerobics) boosts health and in particular to HDLs which have protective effects to heart health. Workplace exercise sessions can support our staff in achieving adequate physical activity and move their way to a healthier heart.`,
      },
    ];

    CONTENTS.forEach((value) => {
      const lines = this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 12,
        color: '#333',
        lineHeight: 18,
      }).splitTextToSize(value.text, 430);
      const newLines = lines.filter((line) => !isEmpty(line));

      yContents += 16;

      newLines.forEach((lineText, inx) => {
        this.setFontStyle({
          font: FONTS.OpenSans.regular,
          fontSize: 12,
          lineHeight: 18,
          color: '#333',
        }).text(lineText, xContents, yContents + this.getLineHeight(), {
          maxWidth: 430,
          lineHeightFactor: 1.57,
          charSpace: 0.225,
        });

        if (inx === 0) {
          this.setFillColor('#fff').roundedRect(
            xContents,
            yContents + 2,
            this.getTextWidth(value.boldText),
            20,
            0,
            0,
            'F'
          );

          this.setFontStyle({
            font: FONTS.OpenSans.semiBold,
            fontSize: 12,
            color: '#333',
            lineHeight: 18,
          }).text(value.boldText, xContents, yContents + this.getLineHeight());
        }

        yContents += lineSpacing;
      });
    });

    return this;
  }

  addRandomGlucose(corporateData, chart) {
    if (!corporateData?.healthScreenings?.randomGlucose) return this;
    const randomGlucose = get(
      corporateData,
      'healthScreenings.randomGlucose.categories'
    );
    this.addPage()
      .addBg(bgCover)
      .addPaging(LIST_TABLE_CONTENTS_LABEL.HealthScreeningFindings);

    this.addHeaderSection(80, 64, 'Random Glucose', podiumImg);
    this.setFontStyle({
      font: FONTS.OpenSans.regular,
      fontSize: 12,
      color: '#333333',
      lineHeight: 18,
    }).text(
      `A high sugar reading could be an indication that the body does not make or utilize insulin well resulting in excess sugar in the blood. This condition is termed as Diabetes Mellitus where over time could give rise to problems associated with infections, blindness, cardiovascular diseases and disorders of the kidney, leg and foot.

To date, Type 1 Diabetes Mellitus is unpreventable. Type 2 Diabetes, associated with being overweight, can be prevented.`,
      80,
      132,
      { maxWidth: 435 }
    );
    if (chart) {
      const chartImg = chart.ctx.canvas.toDataURL();

      this.addImage(chartImg, 'PNG', 0, 306, 300, 148).addLabelOutsideChart(
        chart,
        80,
        306
      );

      const LABELS = [
        {
          color: '#f9e1a1',
          title: 'Low',
        },
        {
          color: '#beeec7',
          title: 'Normal',
        },
        {
          color: '#ffbcb7',
          title: 'High',
        },
      ];
      this.addLegendLabelChart(LABELS, 303, 355);
    }

    // Table
    const table = [
      [
        {
          color: '#E0E0E0',
          value: 'CATEGORY',
          width: 82,
          height: 24,
          lineHeight: 8,
        },
        {
          color: '#E0E0E0',
          value: 'Low',
          width: 116,
          height: 24,
          lineHeight: 8,
        },
        {
          color: '#E0E0E0',
          value: 'Normal',
          width: 116,
          height: 24,
          lineHeight: 8,
        },
        {
          color: '#E0E0E0',
          value: 'High',
          width: 118,
          height: 24,
          lineHeight: 8,
        },
      ],
      [{ color: '#E0E0E0', value: 'Cutoffs', width: 82, height: 24 }],
      [{ color: '#E0E0E0', value: 'Pax', width: 82, height: 24 }],
      [{ color: '#E0E0E0', value: '%', width: 82, height: 24 }],
    ];

    const dataCutOffs = randomGlucose?.map((it, inx) => {
      return {
        color: '#F5F5F5',
        value: `${it.cutOffs}`,
        width: table[0][inx + 1]?.width,
        height: 24,
      };
    });

    const dataTotal = randomGlucose?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.total}`,
      width: table[0][inx + 1]?.width,
      height: 24,
    }));
    const dataPercent = randomGlucose?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.percent.formatPercentValue()}%`,
      width: table[0][inx + 1]?.width,
      height: 24,
    }));
    let header = [];
    header.push(table[0][0]);
    header = header.concat(
      randomGlucose?.map((it, inx) => ({
        color: '#E0E0E0',
        value: it.title,
        width: table[0][inx + 1]?.width,
        height: table[0][inx + 1]?.height,
        lineHeight: table[0][inx + 1]?.lineHeight,
      }))
    );

    table[0] = table[0].map((it, inx) => ({
      ...header[inx],
    }));

    table[1] = table[1].concat(dataCutOffs);
    table[2] = table[2].concat(dataTotal);
    table[3] = table[3].concat(dataPercent);

    let x = 80,
      y = 494,
      yLast = 569;
    let yRow = y;
    let xRowHead = table[0][0].width + x + 2;
    let xRowMid = xRowHead;
    let xRowLast = xRowHead;
    let fontSize = 8;

    this.setFontStyle({
      font: FONTS.OpenSans.regular,
      fontSize,
      color: '#333',
      lineHeight: 10.89,
    });
    table.forEach((rows, rinx) => {
      rows.forEach((cols, cinx) => {
        // Row 1
        try {
          if (rinx === 0 && cinx === 0) {
            this.setFillColor(cols.color)
              .rect(x + cols.width / 2, y, cols.width / 2, cols.height, 'F')
              .setFillColor(cols.color)
              .rect(x, y + cols.height / 2, cols.width, cols.height / 2, 'F')
              .setFillColor(cols.color)
              .roundedRect(x, y, cols.width, cols.height, 10, 10, 'F')
              .text(
                cols.value,
                x + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                y + cols.height / 2 + cols.lineHeight / 2
              );
          } else if (rinx === 0 && cinx === rows.length - 1) {
            const width = rows.map((it) => it.width);
            width.pop();
            const xLast = x + sum(width) + rows.length * 1.6;

            this.setFillColor(cols.color)
              .rect(xLast, y, cols.width / 2, cols.height, 'F')
              .setFillColor(cols.color)
              .rect(
                xLast,
                y + cols.height / 2,
                cols.width,
                cols.height / 2,
                'F'
              )
              .setFillColor(cols.color)
              .roundedRect(xLast, y, cols.width, cols.height, 10, 10, 'F')
              .text(
                cols.value,
                xLast + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                y + cols.height / 2 + 3
              );
          } else if (rinx === table.length - 1 && cinx === 0) {
            this.setFillColor(cols.color)
              .rect(x + cols.width / 2, yLast, cols.width / 2, cols.height, 'F')
              .setFillColor(cols.color)
              .rect(x, yLast, cols.width, cols.height / 2, 'F')
              .setFillColor(cols.color)
              .roundedRect(x, yLast, cols.width, cols.height, 10, 10, 'F')
              .text(
                cols.value,
                x + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                yLast + 15
              );
          } else if (rinx === table.length - 1 && cinx === rows.length - 1) {
            const width = rows.map((it) => it.width);
            width.pop();
            const xLast = x + sum(width) + rows.length * 1.6;
            this.setFillColor(cols.color)
              .rect(xLast, yLast, cols.width / 2, cols.height, 'F')
              .setFillColor(cols.color)
              .rect(xLast, yLast, cols.width, cols.height / 2, 'F')
              .setFillColor(cols.color)
              .roundedRect(xLast, yLast, cols.width, cols.height, 10, 10, 'F')
              .text(
                cols.value,
                xLast + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                yLast + cols.height / 2 + 4
              );
          } else {
            if (rinx === 0) {
              this.setFillColor(cols.color)
                .rect(xRowHead, y, cols?.width, cols?.height, 'F')
                .text(
                  cols.value,
                  xRowHead + cols.width / 2 + 2,
                  y + cols.height / 2 - cols.lineHeight / 2 + 7,
                  { align: 'center' }
                );
            } else if (rinx === table.length - 1) {
              // Last rows
              this.setFillColor(cols.color)
                .rect(xRowLast, yLast, cols.width, cols.height, 'F')
                .text(
                  cols.value,
                  xRowLast + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                  yLast + 15
                );
              xRowLast += cols.width + 2;
            } else {
              // Middle rows
              this.setFillColor(cols.color)
                .rect(xRowMid, yRow, cols.width, cols.height, 'F')
                .text(
                  cols.value,
                  xRowMid + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                  yRow + cols.height / 2 + 4
                );
              xRowMid += cols.width + 2;
            }

            xRowHead += cols.width + 2;
          }
        } catch (err) {}
      });
      yRow += 25;
      xRowMid = table[0][0].width - 2;
    });
    if (corporateData?.healthScreenings?.randomGlucose?.trendings) {
      const trendings =
        corporateData?.healthScreenings?.randomGlucose?.trendings;

      const textTrending = () => {
        if (trendings?.length >= 2) {
          let latestYears = trendings
            ?.sort((a, b) => b.year - a.year)
            .slice(0, 2);
          const percentLastYear = latestYears[1].percent;
          const percentThisYear = latestYears[0].percent;
          const lastYear = latestYears[1].year;

          if (percentLastYear === percentThisYear) {
            return 'There are increased cases by 0%.';
          } else if (percentThisYear > percentLastYear) {
            return `There are increased cases by ${Math.abs(
              percentThisYear?.formatPercentValue() -
                percentLastYear?.formatPercentValue()
            )?.formatPercentValue()}%.`;
          } else {
            return `GREAT, we have improved by ${Math.abs(
              percentThisYear?.formatPercentValue() -
                percentLastYear?.formatPercentValue()
            )?.formatPercentValue()}% since ${lastYear}!`;
          }
        } else {
          return 'There are increased cases by 0%.';
        }
      };

      this.addSubTitle(`Trending: High Random Blood Glucose Readings`, 80, 643);
      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 12,
        color: '#333333',
        lineHeight: 18,
      }).text(textTrending(), 80, 684, { maxWidth: 435 });

      let datas = trendings
        ?.sort((a, b) => a.year - b.year)
        .map((it) => ({
          value: `${it.percent?.formatPercentValue()}%`,
          subValue: ` (${it.total} pax)`,
          title: `${it.year}`,
        }));

      let xDatas = 96;
      this.setFillColor('#E2F2FF').roundedRect(80, 707, 435, 68, 10, 10, 'F');
      datas.forEach((value, inx) => {
        this.setFontStyle({
          font: FONTS.OpenSans.semiBold,
          fontSize: 12,
          color: '#666',
          lineHeight: 16,
        }).text(value.title, xDatas, 707 + 16 + this.getFontSize());
        if (datas.length > 1 && inx !== datas.length - 1)
          this.setFillColor('#ccc').roundedRect(
            datas.length >= 3 ? xDatas + 96 + 30 : xDatas * (datas?.length + 1),
            707 + 12,
            1,
            44,
            1,
            1,
            'F'
          );
        this.setFontStyle({
          font: FONTS.OpenSans.semiBold,
          fontSize: 16,
          color: '#333',
          lineHeight: 24,
        }).text(value.value, xDatas, 707 + 36 + this.getFontSize());
        this.setFontStyle({
          font: FONTS.OpenSans.semiBold,
          fontSize: 12,
          color: '#333',
          lineHeight: 18,
        }).text(
          value.subValue,
          xDatas + this.getTextWidth(value.value) + 15,
          707 + 51
        );

        xDatas += 435 / datas?.length;
      });

      // Next page
      this.addPage()
        .addBg(bgCover)
        .addPaging(LIST_TABLE_CONTENTS_LABEL.HealthScreeningFindings);
      this.addSubTitle('How do we maintain a healthy trend?');

      let xContents = 80;
      let lineSpacing = 16;
      let yContents = 104 - 16;

      let CONTENTS = [
        {
          boldText: '1. Diabetes Management workshops',
          text: `1. Diabetes Management workshops will allow staff to learn how to reduce the risk of developing Diabetes and how they can adopt healthier lifestyle habits.`,
        },
        {
          boldText: '2. Diabetes Targeted Intervention Programme',
          text: `2. Diabetes Targeted Intervention Programme is most suitable for those wanting to maintain a acceptable glucose level and need the correct knowledge and skills. Our staff can look forward to learning practical tips to apply in their daily food choices and the benefits of regular physical activity.`,
        },
        {
          boldText: '3. Physical Activities',
          text: `3. Physical Activities (e.g.Boot Camp, Bands of Resistance) is one of the most effective and enjoyable way to use up the excess sugars in the blood. Having fun at the workplace can never be much easier.`,
        },
      ];

      CONTENTS.forEach((value) => {
        const lines = this.setFontStyle({
          font: FONTS.OpenSans.regular,
          fontSize: 12,
          color: '#333',
          lineHeight: 18,
        }).splitTextToSize(value.text, 430);
        const newLines = lines.filter((line) => !isEmpty(line));

        yContents += 16;

        newLines.forEach((lineText, inx) => {
          this.setFontStyle({
            font: FONTS.OpenSans.regular,
            fontSize: 12,
            lineHeight: 18,
            color: '#333',
          }).text(lineText, xContents, yContents + this.getLineHeight(), {
            maxWidth: 430,
            lineHeightFactor: 1.57,
            charSpace: 0.225,
          });

          if (inx === 0) {
            this.setFillColor('#fff').roundedRect(
              xContents,
              yContents + 2,
              this.getTextWidth(value.boldText),
              20,
              0,
              0,
              'F'
            );

            this.setFontStyle({
              font: FONTS.OpenSans.semiBold,
              fontSize: 12,
              color: '#333',
              lineHeight: 18,
            }).text(
              value.boldText,
              xContents,
              yContents + this.getLineHeight()
            );
          }

          yContents += lineSpacing;
        });
      });
    }
    return this;
  }

  addLegendLabelChart(list, x, y) {
    list.forEach((value) => {
      this.setFillColor(value.color)
        .roundedRect(x, y, 40, 20, 4, 4, 'F')
        .setFontStyle({
          font: FONTS.OpenSans.regular,
          fontSize: 12,
          color: '#333333',
          lineHeight: 16,
        })
        .text(value.title, x + 60, y + 3 + this.getFontSize());
      y += 30;
    });
  }
  addTableLable(
    list,
    x,
    y,
    longestAnswer,
    isYesNo,
    mostSelectedAnswer,
    isQuestionHaveTooManyAnswer,
    questionHeight,
    count
  ) {
    if (isYesNo)
      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 12,
        color: '#333333',
      }).text(
        `${mostSelectedAnswer?.percent?.formatPercentValue()}% answered '${
          mostSelectedAnswer?.answer?.length > 78
            ? `${mostSelectedAnswer?.answer.slice(0, 75)}...`
            : mostSelectedAnswer?.answer
        }'.`,
        x,
        y - 14,
        {
          maxWidth: 200,
        }
      );
    else
      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 12,
        color: '#333333',
      }).text(
        `${mostSelectedAnswer?.percent.formatPercentValue()}% answered '${
          mostSelectedAnswer?.answer?.length > 78
            ? `${mostSelectedAnswer?.answer.slice(0, 75)}...`
            : mostSelectedAnswer?.answer
        }'.`,
        80,
        y +
          (isQuestionHaveTooManyAnswer ? 150 : 130) +
          questionHeight +
          5 -
          (count > 1 ? 0 : 24),
        {
          maxWidth: 200,
        }
      );
    const spaceToTop = isYesNo ? 0 : -35 + questionHeight;
    const answerWidth = isYesNo ? 70 : 140;
    // this.getTextWidth(longestAnswer) + 36 > 70
    //   ? this.getTextWidth(longestAnswer) + 36 > 180
    //     ? 180
    //     : this.getTextWidth(longestAnswer) + 36
    //   : 70;

    list.forEach((value) => {
      let answerHeight = 10;
      try {
        const lines = this.setFontStyle({
          font: FONTS.OpenSans.regular,
          fontSize: 10,
        }).splitTextToSize(value.title, answerWidth - 10);

        answerHeight = lines.length * 11 + 8;

        // this.getTextDimensions(this.splitTextToSize(value.title, 100))?.h + 8;
      } catch {}

      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 10,
        color: '#333333',
        background: value.color,
      })
        .setFillColor('#E0E0E0')
        .roundedRect(
          x,
          y - 4 + spaceToTop,
          answerWidth,
          answerHeight,
          4,
          4,
          'F'
        )
        .text(value.title, x + 9, y + 8 + spaceToTop, {
          maxWidth: answerWidth - 10,
        });

      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 9,
        color: '#333333',
        background: value.color,
      })
        .setFillColor('#F5F5F5')
        .roundedRect(
          x + answerWidth + 5,
          y - 4 + spaceToTop,
          40,
          answerHeight,
          4,
          4,
          'F'
        )
        .text(
          '' + value?.value,
          x + answerWidth + 25,
          y + answerHeight / 2 + spaceToTop,
          { align: 'center' }
        );

      this.setFillColor(value.color).roundedRect(
        x + answerWidth + 50,
        y - 4 + spaceToTop,
        20,
        answerHeight,
        4,
        4,
        'F'
      );
      y += answerHeight + 5;
    });
  }

  addRemaingTableLabel(list, x, y, longestAnswer, isYesNo) {
    const spaceToTop = -100;
    const answerWidth = isYesNo ? 70 : 140;
    // this.getTextWidth(longestAnswer) + 36 > 70
    //   ? this.getTextWidth(longestAnswer) + 36 > 180
    //     ? 180
    //     : this.getTextWidth(longestAnswer) + 36
    //   : 70;

    list.forEach((value) => {
      let answerHeight = 10;
      try {
        const lines = this.setFontStyle({
          font: FONTS.OpenSans.regular,
          fontSize: 10,
        }).splitTextToSize(value.title, answerWidth - 10);

        answerHeight = lines.length * 11 + 8;
      } catch {}
      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 10,
        color: '#333333',
        background: value.color,
      })
        .setFillColor('#E0E0E0')
        .roundedRect(
          x,
          y - 4 + spaceToTop,
          answerWidth,
          answerHeight,
          4,
          4,
          'F'
        )
        .text(value.title, x + 9, y + 8 + spaceToTop, {
          maxWidth: answerWidth - 10,
        });

      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 9,
        color: '#333333',
        background: value.color,
      })
        .setFillColor('#F5F5F5')
        .roundedRect(
          x + answerWidth + 5,
          y - 4 + spaceToTop,
          40,
          answerHeight,
          4,
          4,
          'F'
        )
        .text(
          '' + value?.value,
          x + answerWidth + 25,
          y + answerHeight / 2 + spaceToTop,
          { align: 'center' }
        );

      this.setFillColor(value.color).roundedRect(
        x + answerWidth + 50,
        y - 4 + spaceToTop,
        20,
        answerHeight,
        4,
        4,
        'F'
      );
      y += answerHeight + 5;
    });
  }

  addLabelOutsideChart(chart, initX, initY) {
    // const { data, chartArea } = chart;
    // const { width, height } = chartArea;
    // const padding = 19; // Base padding
    // const labelPositions = []; // Store label positions
    // data.datasets[0].data.forEach((dpoint, inx) => {
    //   const number = Number(dpoint);
    //   if (number !== 0.0 && number !== 0) {
    //     const tooltipPos = chart.getDatasetMeta(0).data[inx].tooltipPosition();
    //     let { x: xCoor, y: yCoor } = tooltipPos;
    //     const halfWidth = width / 2;
    //     const halfHeight = height / 2;
    //     let xOffset = xCoor >= halfWidth ? padding : -padding;
    //     let yOffset = yCoor >= halfHeight ? padding : -padding;
    //     // Adjust quadrant-based positioning
    //     if (xCoor < halfWidth && yCoor < halfHeight) {
    //       // Top-left: No change
    //       xOffset -= 20;
    //       yOffset -= 10;
    //     } else if (xCoor < halfWidth && yCoor > halfHeight) {
    //       // Bottom-left: No change
    //       xOffset -= 24;
    //       yOffset += 2;
    //     } else if (xCoor > halfWidth && yCoor < halfHeight) {
    //       // Top-right quadrant: Prevent overlap
    //       labelPositions.forEach((prevPos) => {
    //         while (Math.abs(prevPos.y - (yCoor + yOffset)) < 16) {
    //           yOffset += 4;
    //           xOffset += 2;
    //         }
    //       });
    //     }
    //     // Store adjusted label position
    //     labelPositions.push({ x: xCoor + xOffset, y: yCoor + yOffset });
    //     this.setFontStyle({
    //       font: FONTS.OpenSans.semiBold,
    //       fontSize: 10,
    //       color: '#333333',
    //       lineHeight: 14,
    //     }).text(`${dpoint}%`, initX + xCoor + xOffset, initY + yCoor + yOffset);
    //   }
    // });
  }

  addTable(data, x = 80, y = 555, yLast = 649) {
    let xRowHead = data[0][0].width + x + 2;
    let xRowMid = xRowHead;
    let xRowLast = xRowHead;
    let yRow = y + 16;

    data.forEach((rows, rinx) => {
      rows.forEach((cols, cinx) => {
        // Row 1
        if (rinx === 0 && cinx === 0) {
          this.setFillColor(cols.color)
            .rect(x + cols.width / 2, y, cols.width / 2, cols.height, 'F')
            .setFillColor(cols.color)
            .rect(x, y + cols.height / 2, cols.width, cols.height / 2, 'F')
            .setFillColor(cols.color)
            .roundedRect(x, y, cols.width, cols.height, 10, 10, 'F')
            .text(cols.value, x + 35, y + 17, { align: 'center' });
        } else if (rinx === 0 && cinx === rows.length - 1) {
          const width = rows.map((it) => it.width);
          width.pop();
          const xLast = x + sum(width) + rows.length * 1.7;

          this.setFillColor(cols.color)
            .rect(xLast, y, cols.width / 2, cols.height, 'F')
            .setFillColor(cols.color)
            .rect(xLast, y + 20, cols.width, cols.height / 2, 'F')
            .setFillColor(cols.color)
            .roundedRect(xLast, y, cols.width, cols.height, 10, 10, 'F')
            .text(cols.value, xLast + 25, y + 25, { align: 'center' });
        } else if (rinx === data.length - 1 && cinx === 0) {
          this.setFillColor(cols.color)
            .rect(x + cols.width / 2, yLast, cols.width / 2, cols.height, 'F')
            .setFillColor(cols.color)
            .rect(x, yLast, cols.width, cols.height / 2, 'F')
            .setFillColor(cols.color)
            .roundedRect(x, yLast, cols.width, cols.height, 10, 10, 'F')
            .text(
              cols.value,
              x + cols.width / 2 - this.getTextWidth(cols.value) / 2,
              yLast + 15
            );
        } else if (rinx === data.length - 1 && cinx === rows.length - 1) {
          const width = rows.map((it) => it.width);
          width.pop();
          const xLast = x + sum(width) + rows.length * 1.7;
          this.setFillColor(cols.color)
            .rect(xLast, yLast, cols.width / 2, cols.height, 'F')
            .setFillColor(cols.color)
            .rect(xLast, yLast, cols.width, cols.height / 2, 'F')
            .setFillColor(cols.color)
            .roundedRect(xLast, yLast, cols.width, cols.height, 10, 10, 'F')
            .text(cols.value, xLast + 25, yLast + 15, { align: 'center' });
        } else {
          if (rinx === 0) {
            this.setFillColor(cols.color)
              .rect(xRowHead, y, cols?.width, cols?.height, 'F')
              .text(
                cols.value,
                xRowHead + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                y + 25
              );
          } else if (rinx === data.length - 1) {
            // Last rows
            this.setFillColor(cols.color)
              .rect(xRowLast, yLast, cols.width, cols.height, 'F')
              .text(cols.value, xRowLast + 25, yLast + 15, {
                align: 'center',
              });
            xRowLast += cols.width + 2;
          } else {
            // Middle rows
            this.setFillColor(cols.color)
              .rect(xRowMid, yRow, cols.width, cols.height, 'F')
              .text(
                cols.value,
                xRowMid + cols.width / 2 - this.getTextWidth(cols.value) / 2,
                yRow + 16
              );
            xRowMid += cols.width + 2;
          }

          xRowHead += cols.width + 2;
        }
      });
      yRow += 26;
      xRowMid = data[0][0].width + 7;
    });
  }

  groupByFirstEncounter(data, key) {
    return data.reduce((acc, item) => {
      const category = item[key];
      if (!acc.hasOwnProperty(category)) {
        acc[category] = []; // Maintain order by first encounter
      }
      acc[category].push(item);
      return acc;
    }, {});
  }

  addLifestyleQuestionaire(corporateData, charts, randomColors) {
    if (!corporateData?.questionaires) return this;

    let questionairesData =
      get(corporateData, 'questionaires')?.filter(
        (q) => q.answerType !== 'FreeText'
      ) || [];

    questionairesData = questionairesData.map((question) => {
      const answers = [...question.answers].map((a, i) => ({
        ...a,
        color: randomColors[i],
      }));
      question.answers = answers;
      return question;
    });

    const dataByCategory = this.groupByFirstEncounter(
      questionairesData,
      'category'
    );
    const categories = Object.keys(dataByCategory);

    let sortedQuestionairesData = [];

    categories.forEach((category) => {
      const questions = dataByCategory[category];
      questions.forEach((question) => {
        sortedQuestionairesData.push(question);
      });
    });

    questionairesData = sortedQuestionairesData;

    const totalPatientOptOutSurvey =
      get(corporateData, 'overView.totalPatientOptOutSurvey') || '';

    this.addPage()
      .addBg(bgCover)
      .addPaging(LIST_TABLE_CONTENTS_LABEL.LifestyleQuestionnaireFindings);

    this.setFontStyle({
      font: FONTS.Lato.bold,
      fontSize: 30,
      color: '#333333',
    }).text('Lifestyle Questionnaire Findings', 80, 80);
    if (corporateData?.overView && totalPatientOptOutSurvey > 0) {
      this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 12,
        color: '#333333',
        lineHeight: 18,
      }).text(
        `*Lifestyle Survey results for ${totalPatientOptOutSurvey} participants are invalid due to incomplete.`,
        80,
        124,
        { maxWidth: 435 }
      );
    }
    let count = 0;
    let lastCategory = null; // Store the last category

    if (charts) {
      charts.forEach((chart, index) => {
        let answerHeight = 10;
        let sumAnswerHeight = 0;

        const isQuestionHaveTooManyAnswer =
          questionairesData[index]?.answers?.length > 10;

        questionairesData[index].answers.slice(0, 12).forEach((value) => {
          const lines = this.setFontStyle({
            font: FONTS.OpenSans.regular,
            fontSize: 10,
          }).splitTextToSize(value.answer, 140 - 10);
          answerHeight = lines.length * 11 + 8;
          sumAnswerHeight += answerHeight;
        });
        let maxAnswer = sumAnswerHeight > 600 ? 12 : 15;

        const is100PercentAnswer = !isEmpty(
          questionairesData[index]?.answers.filter((it) => it.percent === 100)
        );
        //
        if (isQuestionHaveTooManyAnswer || (count % 2 === 0 && count > 1)) {
          this.addPage()
            .addBg(bgCover)
            .addPaging(
              LIST_TABLE_CONTENTS_LABEL.LifestyleQuestionnaireFindings
            );
        }

        const isQuestionMoreThan15Answer =
          questionairesData[index]?.answers?.length > maxAnswer;

        const answerToRenderLabel = isQuestionMoreThan15Answer
          ? questionairesData[index]?.answers?.slice(0, maxAnswer)
          : questionairesData[index]?.answers;
        const currentCategory = questionairesData[index]?.category;

        if (
          currentCategory !== lastCategory ||
          count % 2 === 0 ||
          index === charts.length - 1 ||
          isQuestionHaveTooManyAnswer
        ) {
          this.addHeaderSection(
            80,
            count > 1
              ? 64 + (isQuestionHaveTooManyAnswer ? 0 : count % 2) * 350
              : 164 + (isQuestionHaveTooManyAnswer ? 0 : count % 2) * 300,

            currentCategory,
            podiumImg
          );

          lastCategory = currentCategory; // Update lastCategory
        }

        const question =
          new DOMParser().parseFromString(
            questionairesData[index]?.question,
            'text/html'
          ).body.textContent || '';

        const longestAnswer = questionairesData[index]?.answers?.reduce(
          (max, item) =>
            item?.answer?.length > max?.answer?.length ? item : max,
          { answer: '' }
        )?.answer;

        if (chart) {
          const chartImg = chart?.ctx?.canvas?.toDataURL();
          const questionHeight = question.length / 89;
          this.addImage(
            chartImg,
            'PNG',
            isQuestionHaveTooManyAnswer || is100PercentAnswer ? 10 : 40,
            count > 1
              ? 165 +
                  (isQuestionHaveTooManyAnswer ? 0 : count % 2) * 350 +
                  questionHeight * 20 +
                  5
              : 270 +
                  (isQuestionHaveTooManyAnswer ? 0 : count % 2) * 300 +
                  questionHeight * 20,
            isQuestionHaveTooManyAnswer || is100PercentAnswer
              ? 340 - 20
              : 300 - 20,
            isQuestionHaveTooManyAnswer || is100PercentAnswer
              ? 170 - 10
              : 150 - 10
          ) // Adjust Y position dynamically
            .addLabelOutsideChart(
              chart,
              80,
              count > 1
                ? 165 +
                    (isQuestionHaveTooManyAnswer ? 0 : count % 2) * 300 +
                    questionHeight * 20 +
                    5
                : 270 +
                    (isQuestionHaveTooManyAnswer ? 0 : count % 2) * 300 +
                    questionHeight * 20
            );

          this.setFontStyle({
            font: FONTS.OpenSans.semiBold,
            fontSize: 12,
            color: '#333333',
            lineHeight: 18,
          }).text(
            question,
            80,
            count > 1
              ? 150 + (isQuestionHaveTooManyAnswer ? 0 : count % 2) * 320
              : 228 + (isQuestionHaveTooManyAnswer ? 0 : count % 2) * 300,
            {
              maxWidth: 435,
            }
          );

          const LABELS = answerToRenderLabel.map((item, index) => ({
            color: item.color,
            title: item?.answer,
            value: item?.total,
          }));
          const mostSelectedAnswer = questionairesData[index]?.answers?.reduce(
            (max, item) =>
              item.total > (max?.total ?? -Infinity) ? item : max,
            0
          );

          this.addTableLable(
            LABELS,
            303,
            count > 1
              ? 205 + (isQuestionHaveTooManyAnswer ? 0 : count % 2) * 350
              : 325 + (isQuestionHaveTooManyAnswer ? 0 : count % 2) * 300,
            longestAnswer,
            questionairesData[index]?.answers.every((item) =>
              ['no', 'yes'].includes(lowerCase(item.answer))
            ),
            mostSelectedAnswer,
            isQuestionHaveTooManyAnswer || is100PercentAnswer,
            questionHeight * 20,
            count
          );

          if (isQuestionMoreThan15Answer) {
            this.addPage()
              .addBg(bgCover)
              .addPaging(
                LIST_TABLE_CONTENTS_LABEL.LifestyleQuestionnaireFindings
              );
            this.addHeaderSection(
              80,
              count > 1
                ? 64 + (isQuestionHaveTooManyAnswer ? 0 : count % 2) * 350
                : 164 + (isQuestionHaveTooManyAnswer ? 0 : count % 2) * 300,

              currentCategory,
              podiumImg
            );

            let newAnswerHeight = 10;
            let newSumAnswerHeight = 0;
            const answers =
              questionairesData[index]?.answers?.slice(maxAnswer) || [];
            answers.forEach((value) => {
              const lines = this.setFontStyle({
                font: FONTS.OpenSans.regular,
                fontSize: 10,
              }).splitTextToSize(value.answer, 140 - 10);
              newAnswerHeight = lines.length * 11 + 8;
              newSumAnswerHeight += newAnswerHeight;
            });
            const chunkSize = newSumAnswerHeight > 600 ? 15 : 20;
            for (let i = 0; i < answers.length; i += chunkSize) {
              const remainingAnswers = answers.slice(i, i + chunkSize);
              const REMAINING_LABEL = remainingAnswers.map((item, idx) => ({
                color: item.color, // Prevent color overflow
                title: item?.answer,
                value: item?.total,
              }));

              this.addRemaingTableLabel(
                REMAINING_LABEL,
                303,
                count > 1
                  ? 215 + (isQuestionHaveTooManyAnswer ? 0 : count % 2) * 300
                  : 325 + (isQuestionHaveTooManyAnswer ? 0 : count % 2) * 300,
                longestAnswer,
                answers.every((item) =>
                  ['no', 'yes'].includes(lowerCase(item.answer))
                ),
                mostSelectedAnswer,
                isQuestionHaveTooManyAnswer
              );

              // Add new page if there are more items to process
              if (i + chunkSize < answers.length) {
                this.addPage()
                  .addBg(bgCover)
                  .addPaging(
                    LIST_TABLE_CONTENTS_LABEL.LifestyleQuestionnaireFindings
                  );
                this.addHeaderSection(
                  80,
                  count > 1
                    ? 64 + (isQuestionHaveTooManyAnswer ? 0 : count % 2) * 350
                    : 164 + (isQuestionHaveTooManyAnswer ? 0 : count % 2) * 300,

                  currentCategory,
                  podiumImg
                );
              }
            }
          }
        }
        if (isQuestionHaveTooManyAnswer && count % 2 === 0) count += 1;

        count += 1;
      });
    }

    // Next page
    this.addPage().addBg(bgCover).addPaging(LIST_TABLE_CONTENTS_LABEL.ThankYou);
    this.addSubTitle(LIST_TABLE_CONTENTS_LABEL_DISPLAY.ThankYou);

    let xContents = 80;
    let lineSpacing = 16;
    let yContents = 104 - 16;

    let CONTENTS = [
      {
        text: `Thank you for the wonderful memories of your health screening with us. We hope you enjoyed this great start as much as we did.`,
      },
      {
        text: `Complete health and wellness? It is not just a screening for us. It is a lifestyle.`,
      },
      {
        text: `So this is definitely not goodbye, as we have more than this to offer. Talk to our team of in-house team dieticians, nutritionists, doctors, mental health coaches, fitness trainers, chefs and program consultants. Leave it to us to design tailored solutions for you from health to smell to cells (yes, that's how we do it). You name it, we game it. Now, that is what's so Super about us.`,
      },

      {
        text: `We are ready whenever you are - ask@minmed.sg.`,
      },

      {
        text: `PS. If you like our services or have other feedback, do ask@minmed.sg. Or as they say, the best compliment is none other than a recommendation.`,
      },
    ];

    CONTENTS.forEach((value) => {
      const lines = this.setFontStyle({
        font: FONTS.OpenSans.regular,
        fontSize: 12,
        color: '#333',
        lineHeight: 18,
      }).splitTextToSize(value.text, 430);
      const newLines = lines.filter((line) => !isEmpty(line));

      yContents += 16;

      newLines.forEach((lineText, inx) => {
        this.setFontStyle({
          font: FONTS.OpenSans.regular,
          fontSize: 12,
          lineHeight: 18,
          color: '#333',
        }).text(lineText, xContents, yContents + this.getLineHeight(), {
          maxWidth: 430,
          lineHeightFactor: 1.57,
          charSpace: 0.225,
        });

        yContents += lineSpacing;
      });
    });
    return this;
  }
}

export default JsPdfExtended;
