import UploadCompAnt from '../../../../components/UploadCompAnt';
import { blobUrl } from '../../../../helpers';
import { FILTER_CENTERS, FILTER_TAGS } from '../../constants';
import {
  ButtonSecondary,
  CustomSearchInput,
  GeneralInformationContainer,
  GeneralInformationTitle,
  ImageContainer,
  InputLabel,
  PreviewContent,
  StatusContainer,
  StyledDialogContent,
  UploadAction,
  UploadContainer,
  UploadText,
} from '../../createAndEditLocation.styled';
import { IOSSwitch } from '../mainScreen.styled';
import { CloseIcon, ImageIcon, PreviewIcon, TrashIcon } from '@/assets/svg';
import Image from '@/components/Image';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import {
  Dialog,
  makeStyles,
  withStyles,
  Button,
  IconButton,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles({
  root: {
    padding: '0!important',
    overflow: 'unset',
  },
});

const CustomIconButton = withStyles({
  root: {
    minWidth: 'unset',
    width: 36,
    height: 36,
    '&.MuiButton-outlined': {
      padding: 0,
    },
  },
})(Button);
const GeneralInformation = ({
  values,
  errors,
  handleChangeForm,
  handleOnChangeNumber,
  setFormGeneral,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const handlePreviewOpen = () => {
    setPreviewOpen(true);
    handleMouseLeave();
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const {
    imageUrl,
    name,
    address,
    unit,
    postalCode,
    locationType,
    locationStatus,
    platoName,
    center,
  } = values;

  const classes = useStyles();

  return (
    <GeneralInformationContainer>
      <GeneralInformationTitle>General information</GeneralInformationTitle>
      <InputLabel>Location name</InputLabel>
      <CustomSearchInput
        error={!!errors.name}
        helperText={errors.name}
        placeholder="Enter location name"
        value={name || ''}
        fullWidth
        onChange={handleChangeForm('name')}
        variant="outlined"
        allowClear
      />
      <InputLabel>Plato name</InputLabel>
      <CustomSearchInput
        error={!!errors.platoName}
        helperText={errors.platoName}
        placeholder="Enter Plato name"
        value={platoName || ''}
        fullWidth
        onChange={handleChangeForm('platoName')}
        variant="outlined"
        allowClear
      />
      <InputLabel>Address</InputLabel>
      <CustomSearchInput
        error={!!errors.address}
        helperText={errors.address}
        placeholder="Enter full address"
        value={address || ''}
        fullWidth
        onChange={handleChangeForm('address')}
        variant="outlined"
        allowClear
      />
      <InputLabel>Center</InputLabel>
      <CustomSelectContainer
        isFullWidth
        filterValue={center || []}
        data={FILTER_CENTERS}
        renderValue={<>{center || 'Select center'}</>}
        height="48px"
        isMutiple={false}
        onChange={handleChangeForm('center')}
        clearable={!isEmpty(center)}
        onClear={() => {
          setFormGeneral({ ...values, center: '' });
        }}
      />
      <InputLabel mt={errors.address ? '35px' : '0px'}>Unit</InputLabel>
      <CustomSearchInput
        error={!!errors.unit}
        helperText={errors.unit}
        placeholder="Enter unit number"
        value={unit}
        fullWidth
        onChange={handleChangeForm('unit')}
        variant="outlined"
        allowClear
      />
      <InputLabel>Postal code</InputLabel>
      <CustomSearchInput
        error={!!errors.postalCode}
        helperText={errors.postalCode}
        placeholder="Enter postal code"
        value={postalCode}
        fullWidth
        onChange={(e) => handleOnChangeNumber('postalCode', e.target.value)}
        variant="outlined"
        allowClear
        onInput={(e) => {
          e.target.value = e.target.value.toString().slice(0, 6);
        }}
      />
      <InputLabel>Tag</InputLabel>
      <CustomSelectContainer
        isFullWidth
        filterValue={locationType || []}
        data={FILTER_TAGS}
        renderValue={<>{locationType || 'Select a tag'}</>}
        height="48px"
        isMutiple={false}
        onChange={handleChangeForm('locationType')}
      />
      {values?.locationType === 'Clinic' && (
        <>
          <InputLabel mt="20px">Picture (optional)</InputLabel>

          <UploadContainer>
            <ImageContainer
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {imageUrl ? (
                <>
                  <Image
                    src={
                      imageUrl
                        ? `${
                            typeof imageUrl === 'object'
                              ? blobUrl(imageUrl)
                              : imageUrl
                          }`
                        : null
                    }
                    alt="Preview"
                    className="image"
                    style={{ width: '100%', height: 'auto' }}
                  />
                  {isHovered && (
                    <PreviewContent onClick={handlePreviewOpen}>
                      <PreviewIcon />
                    </PreviewContent>
                  )}

                  <Dialog open={previewOpen} onClose={handlePreviewClose}>
                    <StyledDialogContent className={classes.root}>
                      <IconButton className="icon" onClick={handlePreviewClose}>
                        <CloseIcon />
                      </IconButton>
                      <Image
                        src={
                          imageUrl
                            ? `${
                                typeof imageUrl === 'object'
                                  ? blobUrl(imageUrl)
                                  : imageUrl
                              }`
                            : null
                        }
                        alt="Preview"
                      />
                    </StyledDialogContent>
                  </Dialog>
                </>
              ) : (
                <ImageIcon />
              )}
            </ImageContainer>
            <UploadText>
              Upload a picture to illustrate your location. Recommended: PNG or
              JPG (maximum 700 x 430 px)
            </UploadText>
            {imageUrl && (
              <CustomIconButton
                color="primary"
                variant="outlined"
                onClick={() => setFormGeneral({ ...values, imageUrl: '' })}
              >
                <TrashIcon />
              </CustomIconButton>
            )}
            <UploadCompAnt
              error={errors.imageUrl}
              isAddNew={true}
              label="Upload banner"
              fileData={imageUrl}
              onChange={handleChangeForm('imageUrl')}
              editable={true}
              ActionComponent={
                <UploadAction>
                  <ButtonSecondary>
                    {imageUrl ? 'Replace' : 'Upload'}
                  </ButtonSecondary>
                </UploadAction>
              }
            />
          </UploadContainer>
        </>
      )}

      <InputLabel mt="20px">Status</InputLabel>
      <StatusContainer>
        <div>The location is in use for health screenings</div>
        <IOSSwitch
          checked={locationStatus === 'Active'}
          onChange={handleChangeForm('locationStatus')}
        />
      </StatusContainer>
    </GeneralInformationContainer>
  );
};

GeneralInformation.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChangeForm: PropTypes.func.isRequired,
  handleOnChangeNumber: PropTypes.func.isRequired,
};

export default GeneralInformation;
