export const ATTENDANCE_TABLE = [
  [
    {
      color: '#E0E0E0',
      value: 'GENDER/\nAGE GROUP',
      width: '12.27vw', // 73 / (595/100)
      height: 'calc(100vw*1.4*0.04)', //height by width(100vw* (595/842)*40/100)
    },
    {
      color: '#E0E0E0',
      value: '<20',
      width: '8.4vw',
      height: 'calc(100vw*1.4*0.04)',
    },
    {
      color: '#E0E0E0',
      value: '20 - 29',
      width: '8.4vw',
      height: 'calc(100vw*1.4*0.04)',
    },
    {
      color: '#E0E0E0',
      value: '30 - 39',
      width: '8.4vw',
      height: 'calc(100vw*1.4*0.04)',
    },

    {
      color: '#E0E0E0',
      value: '40 - 49',
      width: '8.4vw',
      height: 'calc(100vw*1.4*0.04)',
    },
    {
      color: '#E0E0E0',
      value: '50 - 59',
      width: '8.4vw',
      height: 'calc(100vw*1.4*0.04)',
    },
    {
      color: '#E0E0E0',
      value: '>59',
      width: '8.4vw',
      height: 'calc(100vw*1.4*0.04)',
    },
    {
      color: '#E0E0E0',
      value: 'TOTAL',
      width: '9.24vw',
      height: 'calc(100vw*1.4*0.04)',
    },
  ],
  [
    {
      color: '#E0E0E0',
      value: 'Female',
      width: '12.27vw',
      height: 'calc(100vw*1.4*0.024)',
    },
  ],
  [
    {
      color: '#E0E0E0',
      value: 'Male',
      width: '12.27vw',
      height: 'calc(100vw*1.4*0.024)',
    },
  ],
  [
    {
      color: '#E0E0E0',
      value: 'TOTAL',
      width: '12.27vw',
      height: 'calc(100vw*1.4*0.024)',
    },
  ],
];

export const COLORS = [
  '#BEEEC7',
  '#FFBCB7',
  '#F9E1A1',
  '#FFE9FB',
  '#FCF2EA',

  '#F5C5D5',
  '#E3E9F1',
  '#F9E1A1',
  '#C6F7F1',
  '#F1D3D3',

  '#FFBCB7',
  '#C8E2D9',
  '#E8DEFF',
  '#FFE0B0',
  '#F9E1A1',

  '#F5C5D5',
  '#E3E9F1',
  '#F9E1A1',
  '#C6F7F1',
  '#F1D3D3',
];

export const LIST_TABLE_CONTENTS_LABEL = {
  QuickLook: 'QuickLook',
  Attendance: 'Attendance',
  HealthScreeningFindings: 'Health Screening Findings',
  LifestyleQuestionnaireFindings: 'Lifestyle Questionnaire Findings',
  ThankYou: 'ThankYou',
};

export const LIST_TABLE_CONTENTS_LABEL_DISPLAY = {
  QuickLook: 'Hi, here is a quick look ...',
  Attendance: 'Attendance',
  HealthScreeningFindings: 'Health Screening Findings',
  LifestyleQuestionnaireFindings: 'Lifestyle Questionnaire Findings',
  ThankYou: `It's not goodbye. It's see you later …`,
};

export const BLOOD_PRESSURE_TABLE = [
  [
    {
      color: '#E0E0E0',
      value: 'CATEGORY',
      width: '10.08vw',
      height: '6.72vw',
    },
    {
      color: '#E0E0E0',
      value: 'Normal',
      width: '10.08vw',
      height: '6.72vw',
    },
    {
      color: '#E0E0E0',
      value: 'High Normal',
      width: '12.43vw',
      height: '6.72vw',
    },
    {
      color: '#E0E0E0',
      value: 'Grade 1\nHypertension',
      width: '12.43vw',
      height: '6.72vw',
    },

    {
      color: '#E0E0E0',
      value: 'Grade 2\nHypertension',
      width: '12.43vw',
      height: '6.72vw',
    },
    // {
    //   color: '#E0E0E0',
    //   value: 'Isolated Systolic\nHypertension',
    //   width: '14.79vw',
    //   height: '6.72vw',
    // },
  ],
  [{ color: '#E0E0E0', value: 'Cutoffs', width: '10.08vw', height: '4.03vw' }],
  [{ color: '#E0E0E0', value: 'Pax', width: '10.08vw', height: '4.03vw' }],
  [{ color: '#E0E0E0', value: '%', width: '10.08vw', height: '4.03vw' }],
];

export const renderTableBMI = (nphsYear) => {
  return [
    [
      {
        color: '#E0E0E0',
        value: 'CATEGORY',
        width: '13.78.vw',
        height: '4.03.vw',
      },
      {
        color: '#E0E0E0',
        value: 'Underweight',
        width: '13.94vw',
        height: '4.03.vw',
      },
      {
        color: '#E0E0E0',
        value: 'Low Risk',
        width: '14.62vw',
        height: '4.03.vw',
      },
      {
        color: '#E0E0E0',
        value: 'Moderate Risk',
        width: '14.62vw',
        height: '4.03.vw',
      },

      {
        color: '#E0E0E0',
        value: 'High Risk',
        width: '14.79vw',
        height: '4.03.vw',
      },
    ],
    [
      {
        color: '#E0E0E0',
        value: 'Cutoffs',
        width: '13.78.vw',
        height: '4.03.vw',
      },
    ],
    [{ color: '#E0E0E0', value: 'Pax', width: '13.78.vw', height: '4.03.vw' }],
    [{ color: '#E0E0E0', value: '%', width: '13.78.vw', height: '4.03.vw' }],
    [
      {
        color: '#E0E0E0',
        value: `NPHS ${nphsYear}`,
        width: '13.78.vw',
        height: '4.03.vw',
      },
    ],
  ];
};

export const TC_TABLE = [
  [
    {
      color: '#E0E0E0',
      value: 'CATEGORY',
      width: '13.78.vw',
      height: '4.03.vw',
    },
    {
      color: '#E0E0E0',
      value: 'Normal',
      width: '19.49vw',
      height: '4.03.vw',
      lineHeight: 8,
    },
    {
      color: '#E0E0E0',
      value: 'Borderline High',
      width: '19.49vw',
      height: '4.03.vw',
      lineHeight: 8,
    },
    {
      color: '#E0E0E0',
      value: 'High',
      width: '19.83vw',
      height: '4.03.vw',
      lineHeight: 8,
    },
  ],
  [
    {
      color: '#E0E0E0',
      value: 'Cutoffs',
      width: '13.78.vw',
      height: '4.03.vw',
    },
  ],
  [{ color: '#E0E0E0', value: 'Pax', width: '13.78.vw', height: '4.03.vw' }],
  [{ color: '#E0E0E0', value: '%', width: '13.78.vw', height: '4.03.vw' }],
];

export const LDL_TABLE = [
  [
    {
      color: '#E0E0E0',
      value: 'CATEGORY',
      width: '10.08vw',
      height: '6.72vw',
    },
    {
      color: '#E0E0E0',
      value: 'Normal',
      width: '10.08vw',
      height: '6.72vw',
    },
    {
      color: '#E0E0E0',
      value: 'Desirable',
      width: '12.47vw',
      height: '6.72vw',
    },
    {
      color: '#E0E0E0',
      value: 'Borderline\nHigh',
      width: '12.47vw',
      height: '6.72vw',
    },

    {
      color: '#E0E0E0',
      value: 'High',
      width: '12.47vw',
      height: '6.72vw',
    },
    {
      color: '#E0E0E0',
      value: 'Very High',
      width: '14.79vw',
      height: '6.72vw',
    },
  ],
  [{ color: '#E0E0E0', value: 'Cutoffs', width: '10.08vw', height: '4.03.vw' }],
  [{ color: '#E0E0E0', value: 'Pax', width: '10.08vw', height: '4.03.vw' }],
  [{ color: '#E0E0E0', value: '%', width: '10.08vw', height: '4.03.vw' }],
];

export const HDL_TABLE = [
  [
    {
      color: '#E0E0E0',
      value: 'CATEGORY',
      width: '13.78vw',
      height: '4.03vw',
    },
    {
      color: '#E0E0E0',
      value: 'Low',
      width: '19.5vw',
      height: '4.03vw',
    },
    {
      color: '#E0E0E0',
      value: 'Desirable',
      width: '19.5vw',
      height: '4.03vw',
    },
    {
      color: '#E0E0E0',
      value: 'Optimal',
      width: '19.83vw',
      height: '4.03vw',
    },
  ],
  [{ color: '#E0E0E0', value: 'Cutoffs', width: '13.78vw', height: '4.03vw' }],
  [{ color: '#E0E0E0', value: 'Pax', width: '13.78vw', height: '4.03vw' }],
  [{ color: '#E0E0E0', value: '%', width: '13.78vw', height: '4.03vw' }],
];

export const TRIG_TABLE = [
  [
    {
      color: '#E0E0E0',
      value: 'CATEGORY',
      width: '13.78vw',
      height: '4.03vw',
    },
    {
      color: '#E0E0E0',
      value: 'Optimal',
      width: '14.62vw',
      height: '4.03vw',
    },
    {
      color: '#E0E0E0',
      value: 'Desirable',
      width: '14.62vw',
      height: '4.03vw',
    },
    {
      color: '#E0E0E0',
      value: 'High',
      width: '14.62vw',
      height: '4.03vw',
    },
    {
      color: '#E0E0E0',
      value: 'Very High',
      width: '14.79vw',
      height: '4.03vw',
    },
  ],
  [{ color: '#E0E0E0', value: 'Cutoffs', width: '13.78vw', height: '4.03vw' }],
  [{ color: '#E0E0E0', value: 'Pax', width: '13.78vw', height: '4.03vw' }],
  [{ color: '#E0E0E0', value: '%', width: '13.78vw', height: '4.03vw' }],
];

export const LABEL_COLORS = [
  '#beeec7',
  '#ffbcb7',
  '#f9e1a1',
  '#fee9fb',
  '#fcf1ea',
  '#f4c5d4',
  '#e3e9f1',
  '#c6f7f1',
  '#f1d3d3',
  '#e8ddfe',
  '#c8e8b9',
  '#ffd4a8',
  '#e0c5f4',
  '#b9e5f9',
  '#f9d4c5',
  '#d4e8d0',
  '#ffc5dd',
  '#c5e6e8',
  '#f4e4bc',
  '#d8f4d4',
  '#ffcfa4',
  '#e4c5eb',
  '#b9e8e4',
  '#f4d4b9',
  '#d4e8e4',
  '#ffd4c5',
  '#c5e8f4',
  '#e8d4e4',
  '#f4e8c5',
  '#d4f4e8',
  '#c5e8d4',
  '#ffd4b9',
  '#e4c5f4',
  '#b9e8f4',
  '#f9d4e4',
  '#d4e8f4',
  '#ffc5e4',
  '#c5e6f4',
  '#f4e4d4',
  '#d8f4e4',
  '#ffcfb9',
  '#e4c5d4',
  '#b9e8d4',
  '#f4d4e4',
  '#d4e8b9',
  '#ffd4e4',
  '#c5e8e4',
  '#e8d4f4',
  '#f4e8d4',
  '#d4f4c5',
];

export const RANDOM_GLUCOSE_TABLE = [
  [
    {
      color: '#E0E0E0',
      value: 'CATEGORY',
      width: '13.78vw',
      height: '4.03vw',
    },
    {
      color: '#E0E0E0',
      value: 'Low',
      width: '19.5vw',
      height: '4.03vw',
    },
    {
      color: '#E0E0E0',
      value: 'Normal',
      width: '19.5vw',
      height: '4.03vw',
    },
    {
      color: '#E0E0E0',
      value: 'High',
      width: '19.83vw',
      height: '4.03vw',
    },
  ],
  [{ color: '#E0E0E0', value: 'Cutoffs', width: '13.78vw', height: '4.03vw' }],
  [{ color: '#E0E0E0', value: 'Pax', width: '13.78vw', height: '4.03vw' }],
  [{ color: '#E0E0E0', value: '%', width: '13.78vw', height: '4.03vw' }],
];
