import { createDownloadableLink } from '@/helpers';
import customToast from '@/new-components/CustomNotification';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  setState: (state, value) => ({ state, value }),
  getReceipt:
    (id) =>
    async ({ Api }) => {
      const { status, result } = await Api.get(
        `MinmedSupports/download-pax-receipt?id=${id}`
      );
      if (status === 200) createDownloadableLink(result, 'receipt', 'pdf');
    },
  removeGGAuthen:
    (id) =>
    async ({ Api }) => {
      const { status } = await Api.get(
        `MinmedSupports/remove-gg-auth?id=${id}`
      );
      status === 200 &&
        customToast('success', 'Remove Google Authen successful');
    },
  changeHSStatus:
    (id) =>
    async ({ Api }) => {
      const { status } = await Api.get(
        `MinmedSupports/change-pax-status?id=${id}`
      );
      status === 200 &&
        customToast('success', `Change Pax's status successful`);
    },
  checkPastReport:
    (userId, callback) =>
    async ({ Api }) => {
      const { status, result } = await Api.get(
        `MinmedSupports/check-past-data?userId=${userId}`
      );
      if (status === 200) {
        callback && callback(result);
      }
    },
  resetPastReport:
    (userId) =>
    async ({ Api }) => {
      const { status } = await Api.get(
        `MinmedSupports/reset-syncing-past-data?userId=${userId}`
      );
      status === 200 && customToast('success', `Reset Pax report successful`);
    },
  reActiveAccount:
    (userId) =>
    async ({ Api }) => {
      const { status } = await Api.get(
        `MinmedSupports/unlock-staff-account?userId=${userId}`
      );
      status === 200 && customToast('success', `Re-active account successful`);
    },
  getPaxData:
    (email, callback) =>
    async ({ Api }) => {
      const { status, result } = await Api.get(
        `MinmedSupports/pax-data?email=${email}`
      );
      if (status === 200) {
        callback && callback(result);
      }
    },
  changeSignInMethod:
    (userId, loginType) =>
    async ({ Api }) => {
      const { status } = await Api.get(
        `MinmedSupports/change-sign-in?Id=${userId}&LoginType=${loginType}`
      );
      status === 200 &&
        customToast('success', `Change sign-in method successful`);
    },
  bypassPayment:
    (paxScreeningId) =>
    async ({ Api }) => {
      const { status } = await Api.post(`MinmedSupports/bypass-payment`, {
        paxScreeningId,
      });
      status === 200 && customToast('success', `Bypass payment successful`);
    },
  promoAccount:
    (userId) =>
    async ({ Api }) => {
      const { status } = await Api.put(`MinmedSupports/promo-patient`, {
        userId,
      });
      status === 200 && customToast('success', 'Promo account successful');
    },
};

const minmedSupportsDispatcher = synthesize('minmedSupport', mapDispatchToAC);
export default minmedSupportsDispatcher;
