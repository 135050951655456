import { Box } from '@material-ui/core';
import React from 'react';

const PageFooter = ({ title, page, totalPages }) => {
  return (
    <Box
      textAlign="center"
      mt="auto"
      fontSize="1.25vw"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderTop="1px solid #ccc"
      paddingTop="2.5vw"
      paddingBottom="3.5vw"
      fontFamily="OpenSans"
      fontWeight="400"
    >
      <div>{title}</div>
      <div>
        {' '}
        Page {page} of {totalPages}
      </div>
    </Box>
  );
};
export default PageFooter;
