import { LIST_TABLE_CONTENTS_LABEL } from '../../constants';
import PageFooter from '../common/PageFooter';
import bgCover from '@/assets/image/pdfexport/bg-cover.png';
import { Box, Typography, withStyles } from '@material-ui/core';
import React from 'react';

const CustomContainer = withStyles({
  root: {
    padding: '0 13.44vw',
    height: 'calc(100vw*1.4)',
    width: '100vw',
    backgroundImage: `url(${bgCover})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',

    paddingTop: '10.75vw',
  },
})(Box);
const Title = withStyles({
  root: {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '3.36vw',
    lineHeight: '4.7vw',
    marginBottom: '2.69vw',
  },
})(Typography);

const Paragraph = withStyles({
  root: {
    fontFamily: 'OpenSans',
    fontWeight: 400,
    fontSize: '1.36vw',
    lineHeight: '2vw',
    marginTop: '2vw',
    marginBottom: '2vw',
  },
})(Typography);

const ThankYou = ({ totalLifeStylePage }) => {
  return (
    <CustomContainer>
      <Box display="flex" flexDirection="column" minHeight="100%">
        <Title>It's not goodbye. It's see you later …</Title>

        <Paragraph>
          Thank you for the wonderful memories of your health screening with us.
          We hope you enjoyed this great start as much as we did.
        </Paragraph>
        <Paragraph>
          Complete health and wellness? It is not just a screening for us. It is
          a lifestyle. hope you enjoyed this great start as much as we did.
        </Paragraph>
        <Paragraph>
          So this is definitely not goodbye, as we have more than this to offer.
          Talk to our team of in-house team dieticians, nutritionists, doctors,
          mental health coaches, fitness trainers, chefs and program
          consultants. Leave it to us to design tailored solutions for you from
          health to smell to cells (yes, that's how we do it). You name it, we
          game it. Now, that is what's so Super about us.
        </Paragraph>
        <Paragraph>We are ready whenever you are - ask@minmed.sg.</Paragraph>
        <Paragraph>
          PS. If you like our services or have other feedback, do ask@minmed.sg.
          Or as they say, the best compliment is none other than a
          recommendation.
        </Paragraph>
        <PageFooter
          page={totalLifeStylePage + 13}
          totalPages={totalLifeStylePage + 13}
          title={LIST_TABLE_CONTENTS_LABEL.LifestyleQuestionnaireFindings}
        />
      </Box>
    </CustomContainer>
  );
};

export default ThankYou;
