import customToast from '../CustomNotification';
import Attendance from './components/pages/Attendance';
import FontCover from './components/pages/FontCover';
import HSBMI from './components/pages/HSBMI';
import HSBloodPressure from './components/pages/HSBloodPressure';
import HSDirectLDL from './components/pages/HSDirectLDL';
import HSLowHDL from './components/pages/HSLowHDL';
import HSRandomGlucose from './components/pages/HSRandomGlucose';
import HSTotalCholesterol from './components/pages/HSTotalCholesterol';
import HSTriglyceride from './components/pages/HSTriglyceride';
import LifestyleQuestionaireFinding from './components/pages/LifestyleQuestionaireFinding';
import QuickLook from './components/pages/QuickLook';
import TableContent from './components/pages/TableContent';
import ThankYou from './components/pages/ThankYou';
import { LIST_TABLE_CONTENTS_LABEL_DISPLAY } from './constants';
import { ArrowDownCircle } from '@/assets/svg';
import { urlLabel } from '@/enum/PermissionEnum';
import { API_ROOT } from '@/module/common';
import CRReportViewerDownload from '@/module/coporate-report-viewer-download';
import { LoadingOutlined } from '@ant-design/icons';
import { Box, CircularProgress, IconButton } from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const CRReportHTML = ({ accessToken, projectId }) => {
  const newAccessToken = accessToken || localStorage.getItem('accessToken');
  const query = new URLSearchParams(useLocation().search);
  const [loading, setLoading] = useState(false);
  const [corporateData, setCorporateData] = useState(null);
  const history = useHistory();

  let newPage = 0;
  const [totalPage, setTotalPage] = useState([]);
  const [totalLifeStylePage, setTotalLifeStylePage] = useState(0);

  const projectID = projectId || query.get('projectId');

  const [downloading, setDownloading] = useState({});
  const [activeRow, setActiveRow] = useState(null);
  const handleDownload = async (projectId) => {
    if (downloading[projectId]) return; // Prevent multiple downloads
    setDownloading((prev) => ({ ...prev, [projectId]: true }));
    setActiveRow(projectId); // Render CRReportPDF for this row
  };

  const addPaging = (label) => {
    newPage += 1;
    const key = label.replace(/\s+/g, '');

    setTotalPage((prevHistory) => [
      ...prevHistory,
      {
        key,
        label: LIST_TABLE_CONTENTS_LABEL_DISPLAY[key],
        page: newPage,
      },
    ]);
  };
  useEffect(() => {
    if (!newAccessToken || !projectID) {
      window.location.reload();

      history.push(
        `/${urlLabel.previewDownloadCRReport}?requestKey=${localStorage.getItem(
          'requestKey'
        )}&hrEmail=${localStorage.getItem('hrEmail')}`
      );
    }
    setLoading(true);
    fetch(`${API_ROOT}ProjectCorporateReports/${projectID}/data`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${newAccessToken}`,
      },
    })
      .then((res) => {
        if (get(res, 'status') === 401) {
          customToast('error', 'Token was expired!');
          localStorage.removeItem('accessToken');

          window.location.href = `https://${
            process.env.REACT_APP_MINC_DOMAIN
          }/#/${
            urlLabel.previewDownloadCRReport
          }?requestKey=${localStorage.getItem(
            'requestKey'
          )}&hrEmail=${localStorage.getItem('hrEmail')}`;
          window.location.reload();
        } else if (get(res, 'status') === 200) {
          return res.json();
        }
      })
      .then((res) => {
        if (get(res, 'statusCode') === 200) {
          setLoading(false);
          setCorporateData(res?.data);
        } else {
          customToast('error', res.message);
        }
      })

      .catch((error) => {
        console.log('error', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAccessToken, projectID]);

  return (
    <Box position="relative" overflow="hidden">
      {!corporateData && loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box overflow="auto">
            <Box
              position="fixed"
              top="4.03vw"
              right="4.03vw"
              zIndex={99}
              bgcolor="#fff"
              borderRadius="50vw"
              width="4.1vw"
              height="4.1vw"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton onClick={() => handleDownload(projectID)}>
                {downloading[projectID] ? (
                  <LoadingOutlined
                    style={{ fontSize: 16, color: '#6c6c6c', marginRight: 7.5 }}
                  />
                ) : (
                  <ArrowDownCircle width="4vw" height="4vw" />
                )}
              </IconButton>
            </Box>

            <FontCover corporateData={corporateData} />

            <TableContent
              corporateData={corporateData}
              totalPage={totalPage}
              totalLifeStylePage={totalLifeStylePage}
            />

            {!corporateData?.overView ||
            !corporateData?.healthScreenings ? null : (
              <QuickLook
                corporateData={corporateData}
                addPaging={addPaging}
                totalLifeStylePage={totalLifeStylePage}
              />
            )}

            {!corporateData?.overView ? null : (
              <Attendance
                corporateData={corporateData}
                addPaging={addPaging}
                totalLifeStylePage={totalLifeStylePage}
              />
            )}

            {!corporateData?.healthScreenings?.bloodPressure ? null : (
              <HSBloodPressure
                corporateData={corporateData}
                addPaging={addPaging}
                totalLifeStylePage={totalLifeStylePage}
              />
            )}
            {!corporateData?.healthScreenings?.bmi ? null : (
              <HSBMI
                corporateData={corporateData}
                addPaging={addPaging}
                totalLifeStylePage={totalLifeStylePage}
              />
            )}

            {!corporateData?.healthScreenings?.tc ? null : (
              <HSTotalCholesterol
                corporateData={corporateData}
                addPaging={addPaging}
                totalLifeStylePage={totalLifeStylePage}
              />
            )}

            {!corporateData?.healthScreenings?.ldl ? null : (
              <HSDirectLDL
                corporateData={corporateData}
                addPaging={addPaging}
                totalLifeStylePage={totalLifeStylePage}
              />
            )}

            {!corporateData?.healthScreenings?.hdl ? null : (
              <HSLowHDL
                corporateData={corporateData}
                addPaging={addPaging}
                totalLifeStylePage={totalLifeStylePage}
              />
            )}
            {!corporateData?.healthScreenings?.triglyceride ? null : (
              <HSTriglyceride
                corporateData={corporateData}
                addPaging={addPaging}
                totalLifeStylePage={totalLifeStylePage}
              />
            )}
            {!corporateData?.healthScreenings?.randomGlucose ? null : (
              <HSRandomGlucose
                corporateData={corporateData}
                addPaging={addPaging}
                totalLifeStylePage={totalLifeStylePage}
              />
            )}
            {!corporateData?.questionaires ? null : (
              <LifestyleQuestionaireFinding
                corporateData={corporateData}
                setTotalLifeStylePage={setTotalLifeStylePage}
              />
            )}
            <ThankYou totalLifeStylePage={totalLifeStylePage} />
          </Box>
          {newAccessToken && activeRow === projectID && (
            <CRReportViewerDownload
              accessToken={newAccessToken}
              downloading={downloading}
              resetData={() => {
                setDownloading({});
                setActiveRow(null);
              }}
              projectId={projectID}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default CRReportHTML;
