import PropTypes from 'prop-types';
import React from 'react';

function LinkIconBold({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.25 0C35.0913 0 39.8444 4.65893 39.9963 10.4636L40 10.75V11.25C40 17.0913 35.3411 21.8444 29.5364 21.9963L29.25 22H24.75C23.7835 22 23 21.2165 23 20.25C23 19.3318 23.7071 18.5788 24.6065 18.5058L24.75 18.5H29.25C33.174 18.5 36.3699 15.3826 36.4961 11.4893L36.5 11.25V10.75C36.5 6.82602 33.3826 3.63014 29.4893 3.50388L29.25 3.5H24.75C23.7835 3.5 23 2.7165 23 1.75C23 0.831827 23.7071 0.0788077 24.6065 0.0058012L24.75 0H29.25ZM15.25 0C16.2165 0 17 0.783502 17 1.75C17 2.66817 16.2929 3.42119 15.3935 3.4942L15.25 3.5H10.75C6.82602 3.5 3.63014 6.6174 3.50388 10.5107L3.5 10.75V11.25C3.5 15.174 6.6174 18.3699 10.5107 18.4961L10.75 18.5H15.25C16.2165 18.5 17 19.2835 17 20.25C17 21.1682 16.2929 21.9212 15.3935 21.9942L15.25 22H10.75C4.9087 22 0.155549 17.3411 0.00374079 11.5364L0 11.25V10.75C0 4.9087 4.65893 0.155548 10.4636 0.00374031L10.75 0H15.25ZM10 9.25H30C30.9665 9.25 31.75 10.0335 31.75 11C31.75 11.9182 31.0429 12.6712 30.1435 12.7442L30 12.75H10C9.0335 12.75 8.25 11.9665 8.25 11C8.25 10.0818 8.95711 9.32881 9.85647 9.2558L10 9.25H30H10Z"
        fill={color}
      />
    </svg>
  );
}

LinkIconBold.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

LinkIconBold.defaultProps = {
  width: '20px',
  height: '10px',
  color: '#ccc',
};

export default LinkIconBold;
