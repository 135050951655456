export const CORPORATE_REPORT_STATUS = [
  {
    value: 'Pending',
    key: 'Pending',
  },

  {
    value: 'Done',
    key: 'Done',
  },
];

export const COLUMN_CORPORATE_REPORT = [
  {
    label: 'Project Name',
    stateValue: 'projectName',
  },
  {
    label: 'Project code',
    stateValue: 'projectCode',
  },
  {
    label: 'Screening Date',
    stateValue: 'date',
    sortBy: 'Date',
  },
  {
    label: 'Status',
    stateValue: 'status',
  },
  {
    label: 'Action',
    stateValue: 'action',
    width: 211,
  },
];

export const renderCRCol = () => {
  return COLUMN_CORPORATE_REPORT;
};
