export const USER_GENDER = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];

export const EditableHsStatus = [null, 'Completed'];

export const ID_TYPE = [
  { label: 'NRIC/FIN', value: 'NRIC' },
  { label: 'Passport', value: 'Passport' },
];

export const FORMAT_DD_MM_YYYY = 'DD/MM/yyyy';

export const INIT_PHYSICAL_DATA = {
  jsonData: {
    sections: [
      {
        title: 'general',
        fields: [
          {
            label: 'Skin',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
          {
            label: 'Pallor',
            options: ['Normal', 'Present'],
            selectedIdx: null,
          },
          {
            label: 'Jaundice',
            options: ['Normal', 'Present'],
            selectedIdx: null,
          },
          {
            label: 'Cervical lymph nodes',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
          {
            label: 'Thyroid gland',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
        ],
      },
      {
        title: 'EYES',
        fields: [
          {
            label: 'Eyes',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
          {
            label: 'Pupillary Reflex',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
        ],
      },
      {
        title: 'EARS',
        fields: [
          {
            label: 'Auditory canal',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
          {
            label: 'Tympanic membrane',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
        ],
      },
      {
        title: 'ORAL CAVITY',
        fields: [
          {
            label: 'Mouth',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
        ],
      },
      {
        title: 'RESPIRATORY SYSTEM',
        fields: [
          {
            label: 'Rib cage anatomy',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
          {
            label: 'Respiratory rate',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
          {
            label: 'Breath sounds',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
        ],
      },
      {
        title: 'BREASTS (FEMALE)',
        fields: [
          {
            label: 'Breast',
            options: ['Normal', 'Abnormal'],
            selectedIdx: null,
          },
        ],
      },
      {
        title: 'PER RECTUM',
        fields: [
          {
            label: 'Per rectum',
            options: [
              'Normal',
              'Decline',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
        ],
      },
      {
        title: 'CARDIOVASCULAR SYS',
        fields: [
          {
            label: 'Pulse rate',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
          {
            label: 'Heart sounds',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
          {
            label: 'Murmurs',
            options: ['Absent', 'Present'],
            selectedIdx: null,
          },
          {
            label: 'Peripheral pulses',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
          {
            label: 'Varicose veins',
            options: ['Absent', 'Present'],
            selectedIdx: null,
          },
        ],
      },
      {
        title: 'ABDOMEN',
        fields: [
          {
            label: 'Palpation',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
          {
            label: 'Masses',
            options: ['Absent', 'Present'],
            selectedIdx: null,
          },
          {
            label: 'Hernia',
            options: ['Absent', 'Present'],
            selectedIdx: null,
          },
          {
            label: 'Liver',
            options: ['Not palpable', 'Palpable'],
            selectedIdx: null,
          },
          {
            label: 'Kidneys',
            options: ['Not ballotable', 'Ballotable'],
            selectedIdx: null,
          },
          {
            label: 'Spleen',
            options: ['Not palpable', 'Palpable'],
            selectedIdx: null,
          },
        ],
      },
      {
        title: 'MUSCULOSKELETAL SYS',
        fields: [
          {
            label: 'Gait',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
          {
            label: 'Limbs',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
          {
            label: 'Spine',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
        ],
      },
      {
        title: 'NERUOLOGICAL SYS',
        fields: [
          {
            label: 'Sensation',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
          {
            label: 'Reflexes',
            options: [
              'Normal',
              {
                value: 'Abnormal',
                detail: '',
              },
            ],
            selectedIdx: null,
          },
        ],
      },
    ],
  },
};

export const PT_SURVEY_COL = [
  {
    label: 'Survey',
    stateValue: 'surveyName',
  },
  {
    label: 'Company',
    stateValue: 'company',
  },
  {
    label: 'Date',
    stateValue: 'date',
  },
  {
    label: '',
    stateValue: 'action',
  },
];

export const sampleData = [
  {
    name: 'string',
    description: 'string',
    company: 'string',
    submittedDate: '2021-01-08T07:41:58.058Z',
    questions: [
      {
        category: 'Category 1',
        question:
          '<p><span style="background-color:rgb(255,255,255);color:rgba(0,0,0,0.87);"><strong>Question 1</strong></span></p>',
        answerType: 'FreeText',
        answers: [
          {
            id: 'string',
            answer: 'string',
            isAdditional: true,
            additionalNote: 'string',
            patientNote: 'string',
          },
        ],
        freeTextAnswer: 'string',
      },
    ],
  },
  {
    name: 'string',
    description: 'string',
    company: 'string',
    submittedDate: '2021-01-08T07:41:58.058Z',
    questions: [
      {
        category: 'Category 1',
        question:
          '<p><span style="background-color:rgb(255,255,255);color:rgba(0,0,0,0.87);"><strong>Question 2</strong></span></p>',
        answerType: 'FreeText',
        answers: [
          {
            id: 'string',
            answer: 'string',
            isAdditional: true,
            additionalNote: 'string',
            patientNote: 'string',
          },
        ],
        freeTextAnswer: 'string',
      },
    ],
  },
  {
    name: 'string',
    description: 'string',
    company: 'string',
    submittedDate: '2021-01-08T07:41:58.058Z',
    questions: [
      {
        category: 'Category 1',
        question:
          '<p><span style="background-color:rgb(255,255,255);color:rgba(0,0,0,0.87);"><strong>Question 1</strong></span></p>',
        answerType: 'FreeText',
        answers: [
          {
            id: 'string',
            answer: 'string',
            isAdditional: true,
            additionalNote: 'string',
            patientNote: 'string',
          },
        ],
        freeTextAnswer: 'string',
      },
      {
        category: 'Category 2',
        question: '<p><strong>das</strong></p>',
        answerType: 'FreeText',
        answers: [
          {
            id: 'string',
            answer: 'string',
            isAdditional: true,
            additionalNote: 'string',
            patientNote: 'string',
          },
        ],
        freeTextAnswer: 'string',
      },
      {
        category: 'Category 2',
        question:
          '<p><span style="background-color:rgb(255,255,255);color:rgba(0,0,0,0.87);"><strong>Question 3</strong></span></p>',
        answerType: 'FreeText',
        answers: [
          {
            id: 'string',
            answer: 'string',
            isAdditional: true,
            additionalNote: 'string',
            patientNote: 'string',
          },
        ],
        freeTextAnswer: 'string',
      },
    ],
  },
];

export const INIT_DATA_PE_DOCTOR = {
  sections: [
    {
      title: 'GENERAL',
      options: [
        {
          label: 'Skin',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
        {
          label: 'Pallor',
          valueSelected: null,
          list: [
            {
              value: 'Absent',
            },
            {
              value: 'Present',
            },
          ],
        },
        {
          label: 'Jaundice',
          valueSelected: null,
          list: [
            {
              value: 'Absent',
            },
            {
              value: 'Present',
            },
          ],
        },
        {
          label: 'Cervical lymph nodes',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
        {
          label: 'Thyroid gland',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
      ],
    },
    {
      title: 'EYES',
      options: [
        {
          label: 'Eyes',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
        {
          label: 'Pupillary Reflex',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
      ],
    },
    {
      title: 'EARS',
      options: [
        {
          label: 'Auditory canal',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
        {
          label: 'Tympanic membrane',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
      ],
    },
    {
      title: 'ORAL CAVITY',
      options: [
        {
          label: 'Mouth',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
      ],
    },
    {
      title: 'RESPIRATORY SYSTEM',
      options: [
        {
          label: 'Rib cage anatomy',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
        {
          label: 'Respiratory rate',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
        {
          label: 'Breath sounds',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
      ],
    },
    {
      title: 'BREASTS (FEMALE)',
      options: [
        {
          label: 'Breast',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
            },
          ],
        },
      ],
    },
    {
      title: 'PER RECTUM',
      options: [
        {
          label: 'Per Rectum',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Decline',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
      ],
    },
    {
      title: 'CARDIOVASCULAR SYS',
      options: [
        {
          label: 'Pulse rate',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
        {
          label: 'Heart sounds',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
        {
          label: 'Murmurs',
          valueSelected: null,
          list: [
            {
              value: 'Absent',
            },
            {
              value: 'Present',
            },
          ],
        },
        {
          label: 'Peripheral pulses',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
        {
          label: 'Varicose veins',
          valueSelected: null,
          list: [
            {
              value: 'Absent',
            },
            {
              value: 'Present',
            },
          ],
        },
      ],
    },
    {
      title: 'ABDOMEN',
      options: [
        {
          label: 'Palpation',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
        {
          label: 'Masses',
          valueSelected: null,
          list: [
            {
              value: 'Absent',
            },
            {
              value: 'Present',
            },
          ],
        },
        {
          label: 'Hernia',
          valueSelected: null,
          list: [
            {
              value: 'Absent',
            },
            {
              value: 'Present',
            },
          ],
        },
        {
          label: 'Liver',
          valueSelected: null,
          list: [
            {
              value: 'Not palpable',
            },
            {
              value: 'Palpable',
            },
          ],
        },
        {
          label: 'Kidneys',
          valueSelected: null,
          list: [
            {
              value: 'Not palpable',
            },
            {
              value: 'Ballotable',
            },
          ],
        },
        {
          label: 'Spleen',
          valueSelected: null,
          list: [
            {
              value: 'Not palpable',
            },
            {
              value: 'Palpable',
            },
          ],
        },
      ],
    },
    {
      title: 'MUSCULOSKELETAL SYS',
      options: [
        {
          label: 'Gait',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
        {
          label: 'Limbs',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
        {
          label: 'Spine',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
      ],
    },
    {
      title: 'NERUOLOGICAL SYS',
      options: [
        {
          label: 'Sensation',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
        {
          label: 'Reflexes',
          valueSelected: null,
          list: [
            {
              value: 'Normal',
            },
            {
              value: 'Abnormal',
              detail: null,
            },
          ],
        },
      ],
    },
  ],
};

export const PHYSICAL_EXAMINATION_INIT_SAMPLE = {
  sections: [
    {
      title: 'HEALTH SCREENING CASESHEET',
      options: [
        {
          label: 'Cigarettes',
          value: 'Non Smoker',
        },
        {
          label: 'Alcohol',
          value: 'Social',
        },
        {
          label: 'Regular Exercise',
          value: 'Exercises regularly',
        },
      ],
    },
    {
      title: 'FAMILY MEDICAL HISTORY',
      options: [
        {
          label: 'Diabetes Mellitus',
          value: 'Yes',
          detail:
            'This is the details description. This is the details description. This is the details description. This is the details description. This is the details description. This is the details description. This is the details description.',
        },
        {
          label: 'Tuberculosis',
          value: 'Yes',
          detail: 'This is the details description',
        },
        {
          label: 'Hypertension',
          value: 'Yes',
          detail: 'This is the details description',
        },
        {
          label: 'Kidney Disease',
          value: 'Yes',
          detail: 'This is the details description',
        },
        {
          label: 'Heart Disease',
          value: 'Yes',
          detail: 'This is the details description',
        },
        {
          label: 'Mental Illness',
          value: '',
          detail: '',
        },
        {
          label: 'Stroke',
          value: 'Yes',
          detail: 'This is the details description',
        },
        {
          label: 'Hep B Carrier',
          value: '',
          detail: '',
        },
        {
          label: 'Cancer',
          value: 'Yes',
          detail: 'This is the details description',
        },
        {
          label: 'Thalassemia',
          value: '',
          detail: '',
        },
      ],
    },
    {
      title: 'PRESENT COMPLAINTS',
      options: [
        {
          label: 'Present Concerns',
          value: 'Yes',
          detail:
            'This is the details description. This is the details description. This is the details description. This is the details description. This is the details description. This is the details description. This is the details description.',
        },
      ],
    },
    {
      title: 'PAST MEDICAL HISTORY',
      options: [
        {
          label: 'Drug Allergy',
          value: 'Yes',
        },
        {
          label: 'Hep B Carrier',
          value: 'No',
        },
        {
          label: 'Thalassemia',
          value: 'Yes',
        },
        {
          label: 'Other',
          value: 'Yes',
        },
        {
          label: 'Past Sugery',
          value: 'Yes',
          Details: 'This is the details description',
        },
      ],
    },
  ],
};
export const physicalExaminationCategoryName = [
  'Social History',
  'Family History',
  'General Medical History',
  'Past Medical History',
  'Systems Review',
];
export const SURVEY_ANSWER_TYPE = {
  SingleChoice: 'SingleChoice',
  MultipleChoice: 'MultipleChoice',
};

export const INIT_DATA_PE_PATIENT = [
  {
    category: physicalExaminationCategoryName[0],
    questions: [
      {
        id: physicalExaminationCategoryName[0] + '1',
        question: 'Are you a smoker?',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.SingleChoice,
        answers: [
          {
            id: 'cigarettes_1',
            answer: 'No',
            answerTitle: 'Are you a smoker?',
            isSelected: false,
            isAdditional: false,
            patientNote: '',
          },
          {
            id: 'cigarettes_2',
            answer: 'Yes',
            answerTitle: 'Are you a smoker?',
            isSelected: false,
            isAdditional: false,
            patientNote: '',
          },
          {
            id: 'cigarettes_3',
            answer: 'Ex-Smoker ',
            answerTitle: 'Are you a smoker?',
            isSelected: false,
            isAdditional: false,
            patientNote: '',
          },
        ],
      },
      {
        id: physicalExaminationCategoryName[0] + '2',
        question: 'Do you consume alcohol?',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.SingleChoice,
        answers: [
          {
            id: 'alcohol_1',
            answer: 'No',
            answerTitle: 'Do you consume alcohol?',
            isSelected: false,
            isAdditional: false,
            patientNote: '',
          },
          {
            id: 'alcohol_2',
            answer: 'Socially',
            answerTitle: 'Do you consume alcohol?',
            isSelected: false,
            isAdditional: false,
            patientNote: '',
          },
          {
            id: 'alcohol_3',
            answer: 'Frequently',
            answerTitle: 'Do you consume alcohol?',
            isSelected: false,
            isAdditional: false,
            patientNote: '',
          },
        ],
      },
      {
        id: physicalExaminationCategoryName[0] + '3',
        question: 'Do you exercise?',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.SingleChoice,
        answers: [
          {
            id: 'regular_exercise_1',
            answer: 'No',
            answerTitle: 'Do you exercise?',
            isSelected: false,
            isAdditional: false,

            patientNote: '',
          },
          {
            id: 'regular_exercise_2',
            answer: 'Irregularly',
            answerTitle: 'Do you exercise?',
            isSelected: false,
            isAdditional: false,

            patientNote: '',
          },
          {
            id: 'regular_exercise_3',
            answer: 'Regularly',
            answerTitle: 'Do you exercise?',
            isSelected: false,
            isAdditional: false,
            patientNote: '',
          },
        ],
      },
    ],
  },
  {
    category: physicalExaminationCategoryName[1],
    questions: [
      {
        id: physicalExaminationCategoryName[1] + '1',
        question: 'Tick the boxes if your immediate family members have:',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        answers: [
          {
            id: 'diabetes_mellitus1',
            answer: 'Diabetes Mellitus?',
            answerTitle: 'Diabetes Mellitus?',
            isSelected: false,
            isAdditional: true,
            additionalNote: 'Details',
            patientNote: '',
          },
          {
            id: 'heart_disease1',
            answer: 'Heart Disease?',
            answerTitle: 'Heart Disease?',
            isSelected: false,
            isAdditional: true,
            additionalNote: 'Details',
            patientNote: '',
          },
          {
            id: 'stroke1',
            answer: 'Stroke?',
            answerTitle: 'Stroke?',
            isSelected: false,
            isAdditional: true,
            additionalNote: 'Details',
            patientNote: '',
          },
          {
            id: 'cancer1',
            answer: 'Cancer?',
            answerTitle: 'Cancer?',
            isSelected: false,
            isAdditional: true,
            additionalNote: 'Details',
            patientNote: '',
          },
          {
            id: 'tuberculosis1',
            answer: 'Tuberculosis?',
            answerTitle: 'Tuberculosis?',
            isSelected: false,
            isAdditional: true,
            additionalNote: 'Details',
            patientNote: '',
          },
          {
            id: 'kidney_disease1',
            answer: 'Kidney Disease?',
            answerTitle: 'Kidney Disease?',
            isSelected: false,
            isAdditional: true,
            additionalNote: 'Details',
            patientNote: '',
          },
          {
            id: 'mental_illness1',
            answer: 'Mental Illness?',
            answerTitle: 'Mental Illness?',
            isSelected: false,
            isAdditional: true,
            additionalNote: 'Details',
            patientNote: '',
          },
          {
            id: 'hep_n_carrier1',
            answer: 'Hep B Carrier?',
            answerTitle: 'Hep B Carrier?',
            isSelected: false,
            isAdditional: true,
            additionalNote: 'Details',
            patientNote: '',
          },
          {
            id: 'thalassemi1',
            answer: 'Thalassemia?',
            answerTitle: 'Thalassemia?',
            isSelected: false,
            isAdditional: true,
            additionalNote: 'Details',
            patientNote: '',
          },
        ],
      },
    ],
  },
  {
    category: physicalExaminationCategoryName[2],
    questions: [
      {
        id: physicalExaminationCategoryName[2] + '1',
        question: '',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        answers: [
          {
            id: 'present_concerns_1',
            answer: 'Do you have any present concerns?',
            answerTitle: 'Do you have any present concerns?',
            isSelected: false,
            isAdditional: true,
            additionalNote: 'Details',
            patientNote: '',
          },
        ],
      },
    ],
  },
  {
    category: physicalExaminationCategoryName[3],
    questions: [
      {
        id: physicalExaminationCategoryName[3] + '1',
        question: '',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        answers: [
          {
            id: 'drug_allergys_1',
            answer: 'Do you have any drug allergies?',
            answerTitle: 'Do you have any drug allergies?',
            isSelected: false,
            isAdditional: true,
            additionalNote: 'Details',
            patientNote: '',
          },
        ],
      },
      {
        id: physicalExaminationCategoryName[3] + '2',
        mapParentQuestionId: physicalExaminationCategoryName[3] + '1',
        question: 'Are you a hepatitis B carrier?',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.SingleChoice,
        answers: [
          {
            id: 'hep_b_carrier_1',
            answer: 'Yes',
            answerTitle: 'Are you a hepatitis B carrier?',
            isSelected: false,
          },
          {
            id: 'hep_b_carrier_2',
            answer: 'No',
            answerTitle: 'Are you a hepatitis B carrier?',
            isSelected: false,
          },
        ],
      },
      {
        id: physicalExaminationCategoryName[3] + '3',
        mapParentQuestionId: physicalExaminationCategoryName[3] + '1',
        question: 'Do you have Thalassemia?',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.SingleChoice,
        answers: [
          {
            id: 'thalassemia_1',
            answer: 'Yes',
            answerTitle: 'Do you have Thalassemia?',
            isSelected: false,
          },
          {
            id: 'thalassemia_2',
            answer: 'No',
            answerTitle: 'Do you have Thalassemia?',
            isSelected: false,
          },
        ],
      },
      {
        mapParentQuestionId: physicalExaminationCategoryName[3] + '1',
        id: physicalExaminationCategoryName[3] + '4',
        question: '',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        answers: [
          {
            parentId: physicalExaminationCategoryName[3] + '4',
            id: 'other_drug_allergy_1',
            answer:
              'Do you have other past medical history?\ne.g past surgeries, cancer',
            answerTitle:
              'Do you have other past medical history?\ne.g past surgeries, cancer',
            isSelected: false,
            isAdditional: true,
            patientNote: '',
          },
        ],
      },
    ],
  },

  {
    category: physicalExaminationCategoryName[4],
    questions: [
      {
        id: physicalExaminationCategoryName[4] + '1',
        question: '',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        answers: [
          {
            id: 'weight_change_1',
            answer: 'Any drastic weight changes in the past 1 year?',
            answerTitle: 'Any drastic weight changes in the past 1 year?',
            isSelected: false,
          },
        ],
      },
      {
        mapParentQuestionId: physicalExaminationCategoryName[4] + '1',
        id: physicalExaminationCategoryName[4] + 'weight_change',
        question: '',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        forceOneAnswerSelectMultipleChoice: true,
        answers: [
          {
            id: 'weight_change_2',
            answer: 'Weight gain',
            answerTitle: 'Weight gain',
            isSelected: false,
          },
          {
            id: 'weight_change_3',
            answer: 'Weight loss',
            answerTitle: 'Weight loss',
            isSelected: false,
          },
        ],
      },
      {
        id: physicalExaminationCategoryName[4] + '2',
        question: '',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        answers: [
          {
            descriptionChildAnswers: 'You may select more than one',
            id: 'cardiovascular_sys_1',
            answer: 'Cardiovascular System in the past 1 year?',
            answerTitle: 'Cardiovascular System in the past 1 year?',
            isSelected: false,
          },
        ],
      },
      {
        mapParentQuestionId: physicalExaminationCategoryName[4] + '2',
        id: physicalExaminationCategoryName[4] + 'cardiovascular_sys',
        question: '',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        answers: [
          {
            id: 'cardiovascular_sys_2',
            answer: 'High blood pressure',
            answerTitle: 'High blood pressure',
            isSelected: false,
            isAdditional: false,
            patientNote: '',
          },
          {
            id: 'cardiovascular_sys_3',
            answer: 'High cholesterol',
            answerTitle: 'High cholesterol',
            isSelected: false,
            isAdditional: false,
            patientNote: '',
          },
          {
            id: 'cardiovascular_sys_4',
            answer: 'Heart disease',
            answerTitle: 'Heart disease',
            isSelected: false,
            isAdditional: false,
            patientNote: '',
          },
        ],
      },
      {
        id: physicalExaminationCategoryName[4] + '3',
        question: '',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        answers: [
          {
            id: 'respiratory_system_1',
            answer: 'Respiratory System in the past 1 year?',
            answerTitle: 'Respiratory System in the past 1 year?',
            isSelected: false,
          },
        ],
      },
      {
        mapParentQuestionId: physicalExaminationCategoryName[4] + '3',
        id: physicalExaminationCategoryName[4] + 'respiratory_system',
        question: '',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        answers: [
          {
            id: 'respiratory_system_2',
            answer: 'Asthma',
            answerTitle: 'Asthma',
            isSelected: false,
          },
        ],
      },
      {
        id: physicalExaminationCategoryName[4] + '4',
        question: '',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        answers: [
          {
            descriptionChildAnswers: 'You may select more than one',
            id: 'gastro-intestinal_sys_1',
            answer: 'Gastro-intestinal System in the past 1 year?',
            answerTitle: 'Gastro-intestinal System in the past 1 year?',
            isSelected: false,
          },
        ],
      },
      {
        mapParentQuestionId: physicalExaminationCategoryName[4] + '4',
        id: physicalExaminationCategoryName[4] + 'gastro-intestinal_sys',
        question: '',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        answers: [
          {
            id: 'intestinal_sys_2',
            answer: 'Gastritis',
            answerTitle: 'Gastritis',
            isSelected: false,
          },
          {
            id: 'intestinal_sys_3',
            answer: 'Liver Disease / Gallstones',
            answerTitle: 'Liver Disease / Gallstones',
            isSelected: false,
          },
        ],
      },
      {
        id: physicalExaminationCategoryName[4] + '5',
        question: '',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        answers: [
          {
            id: 'gastro-urinary_sys_1',
            answer: 'Genito-urinary System in the past 1 year?',
            answerTitle: 'Genito-urinary System in the past 1 year?',
            isSelected: false,
          },
        ],
      },
      {
        mapParentQuestionId: physicalExaminationCategoryName[4] + '5',
        id: physicalExaminationCategoryName[4] + 'gastro-urinary_sys',
        question: '',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        answers: [
          {
            id: 'urinary_sys_2',
            answer: 'Kidney Disease',
            answerTitle: 'Kidney Disease',
            isSelected: false,
          },
        ],
      },
      {
        id: physicalExaminationCategoryName[4] + '6',
        question: '',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        answers: [
          {
            id: 'central_nervous_sys_1',
            answer: 'Central Nervous System in the past 1 year?',
            answerTitle: 'Central Nervous System in the past 1 year?',
            isSelected: false,
          },
        ],
      },
      {
        mapParentQuestionId: physicalExaminationCategoryName[4] + '6',
        id: physicalExaminationCategoryName[4] + 'central_nervous_sys',
        question: '',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        answers: [
          {
            id: 'central_nervous_sys_2',
            answer: 'Stroke',
            answerTitle: 'Stroke',
            isSelected: false,
          },
        ],
      },
      {
        id: physicalExaminationCategoryName[4] + '7',
        question: '',
        questionDesc: '',
        answerType: SURVEY_ANSWER_TYPE.MultipleChoice,
        answers: [
          {
            id: 'current_medication_1',
            answer: 'Any current medication?',
            answerTitle: 'Any current medication?',
            isSelected: false,
            isAdditional: true,
            additionalNote: 'Details',
            patientNote: '',
          },
        ],
      },
    ],
  },
];

export const ROLES_PHYSICALEXAMINATION = [
  'HsAdmin',
  'SuperAdmin',
  'SystemAdmin',
  'ScreeningDoctor',
];

export const ETHNIC_GROUP = [
  { label: 'Chinese', value: 'Chinese' },
  { label: 'Malay', value: 'Malay' },
  { label: 'Indian', value: 'Indian' },
  { label: 'Others', value: 'Others' },
];
