import {
  LIST_TABLE_CONTENTS_LABEL_DISPLAY,
  renderTableBMI,
} from '../../constants';
import LegendsOfChartCom from '../common/LegendsOfChartCom';
import PageFooter from '../common/PageFooter';
import CRPreviewSection from '../common/Section';
import TableCom from '../common/TableCom';
import bgCover from '@/assets/image/pdfexport/bg-cover.png';
import DoughnutChart from '@/new-components/CRReportPDF/components/DoughnutChart';
import ChartDataLabels from '@energiency/chartjs-plugin-piechart-outlabels';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';

const CustomContainer = withStyles({
  root: {
    padding: '0 13.44vw',
    height: 'calc(100vw*1.4)',
    width: '100vw',
    backgroundImage: `url(${bgCover})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingTop: '10.75vw',
  },
})(Box);
const Title = withStyles({
  root: {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '3.36vw',
  },
})(Typography);

const Item = withStyles({
  root: {
    paddingLeft: '2.69vw',
    borderRight: '1px solid #CCCCCC',
    fontFamily: 'OpenSans',

    '& .title': {
      color: '#666',
      fontWeight: 600,
      fontSize: '1.68vw',
    },

    '& .value': {
      fontFamily: 'Lato',
      marginTop: '0.67vw',
      color: '#333',
      fontWeight: 600,
      fontSize: '2.69vw',
      lineHeight: '4.03vw',
    },
    '& .subValue': {
      fontFamily: 'OpenSans',
      marginLeft: '1.34vw',
      color: '#333',
      fontWeight: 600,
      fontSize: '2.01vw',
      lineHeight: '3.02vw',
    },

    '&:last-child': {
      borderRight: 'none',
    },
  },
})(Grid);

const HSBMI = ({ corporateData, addPaging, totalLifeStylePage }) => {
  const bmi = get(corporateData, 'healthScreenings.bmi.categories');
  const highRiskPercent = bmi.find((it) => it.title === 'High Risk').percent;

  const nphSPercentHighRisk = get(
    corporateData,
    'healthScreenings.bmi.nphS_PercentHighRisk'
  );

  const nphSPercentLowRisk = get(
    corporateData,
    'healthScreenings.bmi.nphS_PercentLowRisk'
  );

  const nphSPercentRisk = get(
    corporateData,
    'healthScreenings.bmi.nphS_PercentRisk'
  );

  const nphSPercentUnderweight = get(
    corporateData,
    'healthScreenings.bmi.nphS_PercentUnderweight'
  );
  const nphsYear = get(corporateData, 'healthScreenings.nphsYear');

  const trendings = corporateData?.healthScreenings?.bmi?.trendings;

  const textTrending = () => {
    if (trendings?.length >= 2) {
      let latestYears = trendings?.sort((a, b) => b.year - a.year).slice(0, 2);
      const percentLastYear = latestYears[1].percent;
      const percentThisYear = latestYears[0].percent;
      const lastYear = latestYears[1].year;
      if (percentLastYear === percentThisYear) {
        return 'There are increased cases by 0%.';
      } else if (percentThisYear > percentLastYear) {
        return `There are increased cases by ${Math.abs(
          percentThisYear?.formatPercentValue() -
            percentLastYear?.formatPercentValue()
        )?.formatPercentValue()}%.`;
      } else {
        return `GREAT, we have improved by ${Math.abs(
          percentThisYear?.formatPercentValue() -
            percentLastYear?.formatPercentValue()
        )?.formatPercentValue()}% since ${lastYear}!`;
      }
    } else {
      return 'There are increased cases by 0%.';
    }
  };

  const chartData = useMemo(() => {
    return bmi?.map((it) => it.percent.formatPercentValue());
  }, [bmi]);

  const backgroundColor = [
    'rgba(249, 225, 161, 1)',
    'rgba(190, 238, 199, 1)',
    'rgba(255, 208, 183, 1)',
    'rgba(255, 188, 183, 1)',
  ];

  const legends = [
    {
      color: '#F9E1A1',
      title: 'Underweight',
    },
    {
      color: '#BEEEC7',
      title: 'Low Risk',
    },
    {
      color: '#FFD0B7',
      title: 'Moderate Risk',
    },
    {
      color: '#FFBCB7',
      title: 'High Risk',
    },
  ];

  const chartPlugins = [ChartDataLabels];

  const dataTable = useMemo(() => {
    if (isEmpty(bmi)) return [];
    const table = [...renderTableBMI(nphsYear)];

    const dataCutOffs = bmi?.map((it, inx) => {
      return {
        color: '#F5F5F5',
        value: `${it.cutOffs}`,
        width: table[0][inx + 1].width,
        height: 24,
      };
    });

    const dataTotal = bmi?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.total}`,
      width: table[0][inx + 1]?.width,
      height: 24,
    }));
    const dataPercent = bmi?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.percent.formatPercentValue()}%`,
      width: table[0][inx + 1].width,
      height: 24,
    }));

    const dataNPH = [
      nphSPercentUnderweight,
      nphSPercentLowRisk,
      nphSPercentRisk,
      nphSPercentHighRisk,
    ]?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.formatPercentValue()}%`,
      width: table[0][inx + 1]?.width,
      height: 24,
    }));

    let header = [];
    header.push(table[0][0]);
    header = header.concat(
      bmi?.map((it, inx) => ({
        color: '#E0E0E0',
        value: it.title,
        width: table[0][inx + 1]?.width,
        height: table[0][inx + 1]?.height,
      }))
    );

    table[0] = table[0].map((it, inx) => ({
      ...header[inx],
    }));

    table[1] = table[1].concat(dataCutOffs);
    table[2] = table[2].concat(dataTotal);
    table[3] = table[3].concat(dataPercent);
    table[4] = table[4].concat(dataNPH);

    return table;
  }, [bmi]);

  const renderDatas = useMemo(() => {
    let datas = trendings
      ?.sort((a, b) => a.year - b.year)
      .map((it) => ({
        value: `${it.percent?.formatPercentValue()}%`,
        subValue: ` (${it.total} pax)`,
        title: `${it.year}`,
      }));
    return datas;
  }, [corporateData]);

  let CONTENTS = [
    {
      boldText: '1. Weight Management workshops',
      text: `touches on the differences between being overweight and obese, highlighting the causes and serious impacts to health, as well as the solutions to better manage our weight. This talk is beneficial for those currently overweight or simply interested to learn more about weight management.`,
    },
    {
      boldText: '2. Healthy food',
      text: `making demonstration place emphasis on the importance of eating healthily and include valuable tips on modifying diets without compromising the enjoyment of food.`,
    },
    {
      boldText: '3. Weight Targeted Intervention Programme',
      text: `is most suitable for those wanting to get back in shape but need a little nudge and motivation. In this programme, they can take a big step forward by learning how to set and achieve weight loss goals and equip themselves the knowledge and skills to make healthier food choices and include adequate physical activity.`,
    },
  ];

  useEffect(() => {
    addPaging(LIST_TABLE_CONTENTS_LABEL_DISPLAY.HealthScreeningFindings);
  }, []);

  return (
    <>
      <CustomContainer>
        <Box display="flex" flexDirection="column" minHeight="100%">
          <Box>
            <CRPreviewSection title="Body Mass Index (BMI)" />
          </Box>

          <Box
            fontFamily="OpenSans"
            mt="4.03vw"
            fontSize="2.01vw"
            lineHeight="3.02vw"
          >
            <p>
              Overweight and obesity indicates major health risks and increases
              the prevalence of premature death, diabetes mellitus,
              hypertension, hyperlipidaemia, atherosclerosis, coronary heart
              disease, gout, respiratory disease, arthritis and certain types of
              cancer. It also affect one’s psychological and social well-being.
            </p>
            <p>In Singapore, this is a rising health problem.</p>
          </Box>

          <Box display="flex" alignItems="center" gridGap={'12vw'} mt="6.72vw">
            <Box width="30vw" height="25vw">
              <DoughnutChart
                chartData={chartData}
                chartPlugins={chartPlugins}
                backgroundColor={backgroundColor}
                animationDuration={400}
                isLabelOutside
              />
            </Box>
            <Box>
              <LegendsOfChartCom data={legends} />
            </Box>
          </Box>

          <Box
            fontFamily="OpenSans"
            mt="6.72vw"
            fontSize="2.01vw"
            lineHeight="3.02vw"
          >
            <p>
              {highRiskPercent.formatPercentValue()}% has BMI in High Risk
              category. This is{' '}
              {highRiskPercent.formatPercentValue() >
              nphSPercentHighRisk.formatPercentValue()
                ? 'higher'
                : 'lower'}{' '}
              than the national prevalence of{' '}
              {nphSPercentHighRisk.formatPercentValue()}%.
            </p>
            <p>
              To combat the increasing prevalence of obesity, we should educate
              and motivate our staff to adopt a healthy lifestyle and eating
              habits.
            </p>
          </Box>

          <Box mt="6.72vw">
            <TableCom data={dataTable} />
          </Box>
          <PageFooter
            page={'05'}
            totalPages={totalLifeStylePage + 13}
            title={LIST_TABLE_CONTENTS_LABEL_DISPLAY.HealthScreeningFindings}
          />
        </Box>
      </CustomContainer>
      {/*Trending*/}
      {corporateData?.healthScreenings?.bmi?.trendings && (
        <CustomContainer>
          <Box display="flex" flexDirection="column" minHeight="100%">
            <Title>Trending: High BMI Readings</Title>
            <Box
              fontFamily="OpenSans"
              mt="2.68vw"
              fontSize="2.01vw"
              lineHeight="3.02vw"
            >
              {textTrending()}
            </Box>

            <Box
              mt="2.68vw"
              display="flex"
              bgcolor="#E2F2FF"
              width="73.1vw"
              height="11.42vw"
              borderRadius="1.68vw"
              py="2.01vw"
            >
              <Grid container>
                {renderDatas?.map((it, inx) => (
                  <Item
                    item
                    xs={renderDatas?.length >= 3 ? 4 : 6}
                    key={`${it.title}-${inx}`}
                  >
                    <div className="title">{it.title}</div>
                    <div className="value">
                      {it.value}

                      <span className="subValue">{it.subValue}</span>
                    </div>
                  </Item>
                ))}
              </Grid>
            </Box>

            <Box
              fontFamily="OpenSans"
              mt="2.68vw"
              fontSize="2.67vw"
              lineHeight="4.03vw"
              fontWeight={600}
            >
              How do we maintain a healthy trend?
            </Box>

            {CONTENTS.map((it, inx) => (
              <Box
                fontFamily="OpenSans"
                mt="4.03vw"
                fontSize="2.01vw"
                lineHeight="3.02vw"
                key={`content-${inx}`}
              >
                <Box component="span" fontWeight={600}>
                  {it.boldText}
                </Box>{' '}
                {it.text}
              </Box>
            ))}
            <PageFooter
              page={'06'}
              totalPages={totalLifeStylePage + 13}
              title={LIST_TABLE_CONTENTS_LABEL_DISPLAY.HealthScreeningFindings}
            />
          </Box>
        </CustomContainer>
      )}
    </>
  );
};

export default HSBMI;
