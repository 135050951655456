import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import React, { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({
  chartData,
  onDone,
  chartPlugins,
  backgroundColor,
  animationDuration = 0, // general animation time
  isLabelOutside = false,
  isPDF,
}) => {
  const chartOptions = () => {
    return {
      layout: isLabelOutside
        ? isPDF
          ? {
              padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              },
            }
          : {
              padding: {
                left: 100,
                right: 100,
                top: 40,
                bottom: 40,
              },
            }
        : {},
      responsive: isPDF ? false : true,
      maintainAspectRatio: false,
      devicePixelRatio: 2,
      borderWidth: 0,
      cutout: '70%',
      hover: {
        animationDuration, // duration of animations when hovering an item
      },
      responsiveAnimationDuration: 0,
      plugins: {
        legend: {
          display: false,
        },

        outlabels: isPDF
          ? {
              backgroundColor: null, // Background color of Label
              color: '#333333', // Font color
              padding: 0,
              textAlign: 'center',
              stretch: 5,
              lineWidth: 0, // **Set to 0 to remove the line**

              font: {
                family: 'OpenSans',
                resizable: true,
                minSize: 8,
                maxSize: 10,
                weight: 600,
              },
              text: (ctx, inx) => {
                const number = Number(ctx.dataset.data[ctx.dataIndex]);
                if (number === 0 || number === 0.0) return '';
                return `${ctx.dataset.data[
                  ctx.dataIndex
                ]?.formatPercentValue()}%`;
              },
            }
          : {
              backgroundColor: null, // Background color of Label
              color: '#333333', // Font color
              padding: 0,
              textAlign: 'center',
              stretch: 10,
              lineWidth: 0, // **Set to 0 to remove the line**

              font: {
                family: 'OpenSans',
                resizable: true,
                minSize: 20,
                maxSize: 24,
                weight: 600,
              },

              text: (ctx, inx) => {
                const number = Number(ctx.dataset.data[ctx.dataIndex]);
                if (number === 0 || number === 0.0) return '';
                return `${ctx.dataset.data[
                  ctx.dataIndex
                ]?.formatPercentValue()}%`;
              },
            },
      },
      animation: {
        duration: animationDuration, // general animation time
        onComplete: onDone,
      },
    };
  };

  const formatChartData = useMemo(() => {
    let datasets = [];

    datasets.push({
      data: chartData,
      backgroundColor,
    });

    return { datasets };
  }, [chartData, backgroundColor]);

  return (
    <Doughnut
      data={formatChartData}
      options={chartOptions()}
      plugins={chartPlugins}
    />
  );
};

export default DoughnutChart;
