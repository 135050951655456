import corporateReportDispatcher from '../action';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0,
  },
};

const corporateReportReducer = corporateReportDispatcher(initialState, {
  [corporateReportDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined,
    },
  }),
});

export default corporateReportReducer;
