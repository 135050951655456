import { DATE_FORMAT, TIME_FORMAT } from '../common';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import marketingBroadcastDispatcher from './action';
import GroupToolbar from './component/GroupToolbar';
import ModalSendMessage from './component/ModalSendMessage';
import {
  COLUMN_MARKETING_MESSAGES,
  MARKETING_MESSAGES_STATUS,
  MARKETING_MESSAGES_STATUS_LIST,
} from './constants';
import {
  DeleteModalIcon,
  RemoveIcon,
  SendIcon,
  NoDataBroadcast,
} from '@/assets/svg';
import { handleSelectAll, momentFormatted } from '@/helpers';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';
import { Chip, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { debounce, isEmpty } from 'lodash';
import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  sentStatus: {
    color: '#0B8E5E',
    backgroundColor: '#0B8E5E29',
  },
  scheduledStatus: { color: '#23A6CC', backgroundColor: '#23A6CC29' },
});

const MarketingMessages = () => {
  const { list: data, paging } = useSelector(
    (state) => state.marketingBroadcast
  );
  const [filterOption, setFilterOption] = useState({});
  const [sortOption, setSortOption] = useState({ sortAscending: false });
  const [publishDate, setPublishDate] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSendMessageModal, setShowSendMessageModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedItem, setSelectedItem] = useState({});

  const classes = useStyles();

  const fetchData = async (
    pagingData = paging,
    sortOptionData = sortOption,
    filterOptionData = filterOption
  ) => {
    marketingBroadcastDispatcher.getBroadcastData(
      pagingData,
      ...[sortOptionData, filterOptionData].filter((i) => i)
    );
  };

  const debounceLoadData = useCallback(
    debounce((paging, sortOption, filterOption) => {
      fetchData(
        {
          pageIndex: 0,
          pageSize: paging.pageSize,
        },
        sortOption,
        filterOption
      );
    }, 500),
    []
  );

  const onDeleteBroadcast = () => {
    if (isEmpty(selectedItem)) return;
    marketingBroadcastDispatcher.deleteMarketingBroadcasts(
      selectedItem.id,
      () => {
        fetchData(paging, filterOption);
        setShowDeleteModal(false);
      }
    );
  };

  useEffect(() => {
    debounceLoadData(paging, sortOption, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOption, filterOption]);

  const onFilterOptionChange = (key) => (e) => {
    let value = e?.target?.value;
    if (key === 'statuses') {
      value = handleSelectAll(
        value,
        MARKETING_MESSAGES_STATUS_LIST.map((item) => item.key),
        filterOption.statuses
      );
    }

    setFilterOption({
      ...filterOption,
      [key]: value,
    });
  };

  const onResetFilterOps = () => {
    setFilterOption({});
    setPublishDate([]);
  };

  const onSendMessage = (data, isSchedule = false) => {
    setLoading(true);
    marketingBroadcastDispatcher.pushMarketingBroadcasts(data, () => {
      fetchData(paging, filterOption);
      setShowSendMessageModal(false);
      renderToast(data.title, isSchedule);
      setLoading(false);
    });
  };

  const renderToast = (data, isSchedule) => {
    return customToast(
      'success',
      <span>
        Message <strong style={{ fontWeight: 600 }}>{data}</strong> has been
        successully {isSchedule ? 'scheduled for sending.' : 'sent to the app.'}
      </span>,
      `Message ${isSchedule ? 'scheduled to send' : 'sent'}`
    );
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {COLUMN_MARKETING_MESSAGES.map((item) =>
          item.sortBy ? (
            <TableCellFilted
              isHidden={false}
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              sortBy="Date"
              sortOption={sortOption}
              onSortChange={() => {
                let newSortOption = {
                  sortAscending: !sortOption.sortAscending,
                };
                setSortOption(newSortOption);
              }}
              style={{ minWidth: item.minWidth || 'unset' }}
            />
          ) : (
            <TableCell
              key={item.stateValue || item.label}
              style={{ minWidth: item.minWidth, width: item.width }}
            >
              {item.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = (row) => {
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.title} />
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.body} />
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.redirectUrl} />
        </TableCell>
        <TableCell>
          <div> {momentFormatted(row.publishDate, DATE_FORMAT)}</div>
          <div>{momentFormatted(row.publishDate, TIME_FORMAT)}</div>
        </TableCell>
        <TableCell>
          <Chip
            size="small"
            className={
              row?.status === MARKETING_MESSAGES_STATUS.Sent
                ? classes.sentStatus
                : classes.scheduledStatus
            }
            label={row?.status}
          />
        </TableCell>
        <TableCell>{row?.totalClicks || 0}</TableCell>

        <TableCell>
          <IconButton
            onClick={() => {
              setShowDeleteModal(true);
              setSelectedItem(row);
            }}
            disabled={row.status === MARKETING_MESSAGES_STATUS.Sent}
          >
            <RemoveIcon />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <CustomPaperContainer
        header={
          <CustomHeader
            isSearch={false}
            title="App Notifications"
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            fetchData={fetchData}
            renderToolbar={() => (
              <GroupToolbar
                filterOption={filterOption}
                setFilterOption={setFilterOption}
                onFilterOptionChange={onFilterOptionChange}
                onResetFilterOps={onResetFilterOps}
                publishDate={publishDate}
                setPublishDate={setPublishDate}
              />
            )}
            renderButton={() => (
              <Button
                color="primary"
                variant="contained"
                startIcon={<SendIcon />}
                onClick={() => setShowSendMessageModal(true)}
              >
                Send message
              </Button>
            )}
          />
        }
      >
        <CustomTable
          data={data}
          paging={paging}
          header={TableHeader}
          renderRow={(row, i) => renderTableBody(row)}
          noDataHelperText="No marketing message"
          noDataHelperSubText="Please create a new message"
          totalCols={COLUMN_MARKETING_MESSAGES.length}
          fetchData={fetchData}
          Icon={NoDataBroadcast}
        />
      </CustomPaperContainer>
      {showDeleteModal && (
        <ModalConfirmation
          open={showDeleteModal}
          onClick={onDeleteBroadcast}
          onClose={() => setShowDeleteModal(false)}
          mainContent={'Delete message'}
          subContent={'Are you sure you want to delete this marketing message?'}
          cancelLabel="Cancel"
          confirmLabel="Delete"
          confirmColor="red"
          Icon={<DeleteModalIcon />}
        />
      )}

      {showSendMessageModal && (
        <ModalSendMessage
          open={showSendMessageModal}
          onClose={() => setShowSendMessageModal(false)}
          onSubmit={onSendMessage}
          loading={loading}
        />
      )}
    </>
  );
};

export default MarketingMessages;
