import FiltedBodyCell from '../common/component/FiltedBodyCell';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import stuckAccountsDispatcher from './action';
import ModalShowConsultList from './component/ModalShowConsultList';
import { renderColumn, renderListAction } from './constants';
import TableContainer from '@/components/Table/TableContainer';
import { getNationalNumber } from '@/helpers';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const TableCellSticky = withStyles({
  root: {
    position: 'sticky',
    background: 'white',
    right: 0,
    width: 100,
  },
})(TableCell);

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 230,
    padding: '8px 16px',
    fontSize: 14,
    boxShadow: '1px 1px 4px -1px #000000',
  },
})(Tooltip);

const StuckAccounts = () => {
  const { list: data, paging } = useSelector((state) => state.stuckAccounts);

  const [hiddenRows, setHiddenRows] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [listConsult, setListConsult] = useState([]);

  const onHandleModal = () => {
    setShowModal(true);
  };

  const tableColumn = renderColumn();

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows,
      }}
      {...props}
    />
  );

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {tableColumn.map(({ label, stateValue }) =>
          stateValue ? (
            <TableCellFiltedWrapper
              key={stateValue}
              label={label}
              stateValue={stateValue}
            >
              {label}
            </TableCellFiltedWrapper>
          ) : (
            <TableCellSticky />
          )
        )}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = (row) => (
    <>
      <FiltedBodyCell hidden={hiddenRows.fullname}>
        {isEmpty(row.firstName)
          ? row.fullName
          : row.firstName + ' ' + row.lastName}
      </FiltedBodyCell>
      <FiltedBodyCell hidden={hiddenRows.emailAddress}>
        {row.emailAddress}
      </FiltedBodyCell>
      <FiltedBodyCell hidden={hiddenRows.phoneNumber}>
        {getNationalNumber(row.phoneNumber, row.countryCode, true)}
      </FiltedBodyCell>
      <TableCellSticky align="right" style={{ padding: 2 }}>
        {renderListAction(onHandleModal).map((action) => {
          const Icon = action.icon;
          return (
            <CustomTooltip title={action.label}>
              <IconButton
                key={action.key}
                disabled={action.disabled}
                onClick={() => {
                  action.onClick();
                  stuckAccountsDispatcher.getStuckAccountsDetails(
                    row.id,
                    (data) => setListConsult(data)
                  );
                }}
              >
                <Icon style={{ color: '#8f9ba9' }} />
              </IconButton>
            </CustomTooltip>
          );
        })}
      </TableCellSticky>
    </>
  );

  return (
    <>
      <TableContainer
        fullWidthSearch
        title="Restricted Accounts"
        header={TableHeader}
        data={data}
        paging={paging}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        query={(...params) => {
          stuckAccountsDispatcher.getData(...params);
        }}
        renderRow={(row, i) => renderTableBody(row)}
      />

      <Dialog
        fullWidth
        maxWidth="lg"
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <ModalShowConsultList
          onClose={() => setShowModal(false)}
          listConsult={listConsult}
        />
      </Dialog>
    </>
  );
};

export default StuckAccounts;
