import { LIST_TABLE_CONTENTS_LABEL_DISPLAY, LDL_TABLE } from '../../constants';
import LegendsOfChartCom from '../common/LegendsOfChartCom';
import PageFooter from '../common/PageFooter';
import CRPreviewSection from '../common/Section';
import TableCom from '../common/TableCom';
import bgCover from '@/assets/image/pdfexport/bg-cover.png';
import DoughnutChart from '@/new-components/CRReportPDF/components/DoughnutChart';
import ChartDataLabels from '@energiency/chartjs-plugin-piechart-outlabels';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';

const CustomContainer = withStyles({
  root: {
    padding: '0 13.44vw',
    height: 'calc(100vw*1.4)',
    width: '100vw',
    backgroundImage: `url(${bgCover})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingTop: '10.75vw',
  },
})(Box);
const Title = withStyles({
  root: {
    marginTop: '2.68vw',
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '3.36vw',
    lineHeight: '4.7vw',
  },
})(Typography);

const Item = withStyles({
  root: {
    paddingLeft: '2.69vw',
    borderRight: '1px solid #CCCCCC',
    fontFamily: 'OpenSans',

    '& .title': {
      color: '#666',
      fontWeight: 600,
      fontSize: '1.68vw',
    },

    '& .value': {
      fontFamily: 'Lato',
      marginTop: '0.67vw',
      color: '#333',
      fontWeight: 600,
      fontSize: '2.69vw',
      lineHeight: '4.03vw',
    },
    '& .subValue': {
      fontFamily: 'OpenSans',
      marginLeft: '1.34vw',
      color: '#333',
      fontWeight: 600,
      fontSize: '2.01vw',
      lineHeight: '3.02vw',
    },

    '&:last-child': {
      borderRight: 'none',
    },
  },
})(Grid);

const HSDirectLDL = ({ corporateData, addPaging, totalLifeStylePage }) => {
  const ldl = get(corporateData, 'healthScreenings.ldl.categories');

  const veryHighPercent = ldl.find(
    (it) => it.title.replace('\n', ' ') === 'Very High'
  ).percent;

  const highPercent = ldl.find(
    (it) => it.title.replace('\n', ' ') === 'High'
  ).percent;

  const nphSPercentHigh = get(
    corporateData,
    'healthScreenings.ldl.nphS_PercentHigh'
  );

  const nphSPercentNormal = get(
    corporateData,
    'healthScreenings.ldl.nphS_PercentNormal'
  );

  const nphsYear = get(corporateData, 'healthScreenings.nphsYear');

  const trendings = corporateData?.healthScreenings?.ldl?.trendings;

  const textTrending = () => {
    if (trendings?.length >= 2) {
      let latestYears = trendings?.sort((a, b) => b.year - a.year).slice(0, 2);
      const percentLastYear = latestYears[1].percent;
      const percentThisYear = latestYears[0].percent;
      const lastYear = latestYears[1].year;
      if (percentLastYear === percentThisYear) {
        return 'There are increased cases by 0%.';
      } else if (percentThisYear > percentLastYear) {
        return `There are increased cases by ${Math.abs(
          percentThisYear?.formatPercentValue() -
            percentLastYear?.formatPercentValue()
        )?.formatPercentValue()}%.`;
      } else {
        return `GREAT, we have improved by ${Math.abs(
          percentThisYear?.formatPercentValue() -
            percentLastYear?.formatPercentValue()
        )?.formatPercentValue()}% since ${lastYear}!`;
      }
    } else {
      return 'There are increased cases by 0%.';
    }
  };

  const chartData = useMemo(() => {
    return corporateData?.healthScreenings?.ldl?.categories?.map((it) =>
      it.percent.formatPercentValue()
    );
  }, [ldl]);

  const backgroundColor = [
    'rgba(22, 164, 64, 1)',
    'rgba(190, 238, 199, 1)',
    'rgba(255, 224, 176, 1)',
    'rgba(255, 188, 183, 1)',
    'rgba(219, 61, 73, 1)',
  ];

  const legends = [
    {
      color: '#16a440',
      title: 'Optimal',
    },
    {
      color: '#BEEEC7',
      title: 'Desirable',
    },
    {
      color: '#FFE0B0',
      title: 'Borderline High',
    },
    {
      color: '#FFBCB7',
      title: 'High',
    },
    {
      color: '#DB3D49',
      title: 'Very High',
    },
  ];

  const chartPlugins = [ChartDataLabels];

  const dataTable = useMemo(() => {
    if (isEmpty(ldl)) return [];
    const table = [...LDL_TABLE];

    const dataCutOffs = ldl?.map((it, inx) => {
      return {
        color: '#F5F5F5',
        value: `${it.cutOffs}`,
        width: table[0][inx + 1].width,
        height: 24,
      };
    });

    const dataTotal = ldl?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.total}`,
      width: table[0][inx + 1].width,
      height: 24,
    }));
    const dataPercent = ldl?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.percent.formatPercentValue()}%`,
      width: table[0][inx + 1].width,
      height: 24,
    }));
    let header = [];
    header.push(table[0][0]);
    header = header.concat(
      ldl?.map((it, inx) => ({
        color: '#E0E0E0',
        value: it.title,
        width: table[0][inx + 1]?.width,
        height: table[0][inx + 1]?.height,
      }))
    );

    table[0] = table[0].map((it, inx) => ({
      ...header[inx],
    }));

    table[1] = table[1].concat(dataCutOffs);
    table[2] = table[2].concat(dataTotal);
    table[3] = table[3].concat(dataPercent);

    return table;
  }, [ldl]);

  const renderDatas = useMemo(() => {
    let datas = trendings
      ?.sort((a, b) => a.year - b.year)
      .map((it) => ({
        value: `${it.percent?.formatPercentValue()}%`,
        subValue: ` (${it.total} pax)`,
        title: `${it.year}`,
      }));
    return datas;
  }, [corporateData]);

  useEffect(() => {
    addPaging(LIST_TABLE_CONTENTS_LABEL_DISPLAY.HealthScreeningFindings);
  }, []);

  return (
    <>
      <CustomContainer>
        <Box display="flex" flexDirection="column" minHeight="100%">
          <Box>
            <CRPreviewSection title="Direct Low Density Lipoprotein (Direct LDL)" />
          </Box>

          <Box
            fontFamily="OpenSans"
            mt="3.03vw"
            fontSize="2.01vw"
            lineHeight="3.02vw"
          >
            <p>
              Lipoproteins are carriers of cholesterol in the blood. LDL will
              build up cholesterol in the arteries when in excess and is a major
              cause of atherosclerosis.
            </p>
          </Box>

          <Box display="flex" alignItems="center" gridGap={'12vw'} mt="3.72vw">
            <Box width="30vw" height="25vw">
              <DoughnutChart
                chartData={chartData}
                chartPlugins={chartPlugins}
                backgroundColor={backgroundColor}
                animationDuration={400}
                isLabelOutside
              />
            </Box>
            <Box>
              <LegendsOfChartCom data={legends} />
            </Box>
          </Box>

          <Box
            fontFamily="OpenSans"
            mt="6.72vw"
            fontSize="2.01vw"
            lineHeight="3.02vw"
          >
            {(veryHighPercent + highPercent).formatPercentValue()}% has
            unhealthy level of Direct LDL (i.e. High and Very High). This is{' '}
            {(veryHighPercent + highPercent).formatPercentValue() >
            nphSPercentNormal.toFixed(1)
              ? 'higher'
              : 'lower'}{' '}
            than national prevalence of {nphSPercentNormal.toFixed(1)}%
          </Box>

          <Box mt="4.72vw">
            <TableCom
              data={dataTable}
              nphsYear={nphsYear}
              lastRowData={[
                {
                  colSpan: 3,
                  value: nphSPercentHigh.formatPercentValue(),
                  color: '#F5F5F5',
                },
                {
                  colSpan: 2,
                  value: nphSPercentNormal.formatPercentValue(),
                  color: '#F5F5F5',
                },
              ]}
            />
          </Box>
          {corporateData?.healthScreenings?.ldl?.trendings && (
            <>
              <Title>Trending: High Direct LDL Readings</Title>
              <Box
                fontFamily="OpenSans"
                mt="2vw"
                fontSize="2.01vw"
                lineHeight="3.02vw"
              >
                {textTrending()}
              </Box>
              <Box
                mt="2vw"
                display="flex"
                bgcolor="#E2F2FF"
                width="73.1vw"
                height="11.42vw"
                borderRadius="1.68vw"
                py="2.01vw"
              >
                <Grid container>
                  {renderDatas?.map((it, inx) => (
                    <Item
                      item
                      xs={renderDatas?.length >= 3 ? 4 : 6}
                      key={`${it.title}-${inx}`}
                    >
                      <div className="title">{it.title}</div>
                      <div className="value">
                        {it.value}

                        <span className="subValue">{it.subValue}</span>
                      </div>
                    </Item>
                  ))}
                </Grid>
              </Box>
            </>
          )}
          <PageFooter
            page={'08'}
            totalPages={totalLifeStylePage + 13}
            title={LIST_TABLE_CONTENTS_LABEL_DISPLAY.HealthScreeningFindings}
          />
        </Box>
      </CustomContainer>
    </>
  );
};

export default HSDirectLDL;
