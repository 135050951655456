export const INIT_PERMISSION = [
  {
    privilege: 'HomescreenAllUsers',
    title: 'All Users',
    items: [],
  },
  //TeleDoc
  {
    privilege: 'DoctorManagement',
    title: 'Doctor Management',
    items: [],
  },
  {
    privilege: 'PatientManagement',
    title: 'Patient Management',
    items: [],
  },
  { privilege: 'CAManagement', title: 'CA Management', items: [] },
  { privilege: 'SwabberManagement', title: 'Swabber Management', items: [] },

  { privilege: 'WaitingRoom', title: 'Waiting Room', items: [] },
  { privilege: 'ConsultList', title: 'Consult List', items: [] },
  { privilege: 'MeetingHistory', title: 'Meeting History', items: [] },
  {
    privilege: 'ClinicManagement',
    title: 'Clinic Management',
    child: [
      {
        privilege: 'ClinicOperatingHour',
        title: 'Clinic Management',
        items: [],
      },
      {
        privilege: 'BookingTime',
        title: 'Manage Booking Time',
        items: [],
      },
      {
        privilege: 'BookingTimeSA',
        title: 'Manage Booking Time (SA)',
        items: [],
      },
    ],
  },
  { privilege: 'QueueRequest', title: 'Queue Requests', items: [] },
  {
    privilege: 'BookedAppointment',
    title: 'Appointment Management',
    items: [],
  },
  { privilege: 'MHSManagement', title: 'MHS Management', items: [] },
  { privilege: 'Promotion', title: 'Promotion', items: [] },
  //TeleFit
  {
    privilege: 'Workout',
    title: 'Workout',
    child: [
      { privilege: 'Category', title: 'Categories', items: [] },
      { privilege: 'Livestream', title: 'Livestream', items: [] },
      { privilege: 'OnDemand', title: 'OnDemand', items: [] },
      { privilege: 'StudioClass', title: 'Studio Classes', items: [] },
    ],
  },
  { privilege: 'UpcomingWorkout', title: 'Upcoming Workout', items: [] },
  {
    privilege: 'StudioManagement',
    title: 'Studio Management',
    items: [],
  },
  {
    privilege: 'TrainerManagement',
    title: 'Trainer Management',
    items: [],
  },
  {
    privilege: 'UserManagement',
    title: 'Internal Trainer Management',
    items: [],
  },
  //TeleScreen
  {
    privilege: 'TelescreenAppointments',
    title: 'Appointments',
    child: [
      {
        privilege: 'TelescreenAppointments',
        title: 'Appointments',
        items: [],
      },
      {
        privilege: 'OrderListExport',
        title: 'Order List Export',
        items: [],
      },
      {
        privilege: 'BackroomModule',
        title: 'Backroom Module',
        items: [],
      },
    ],
  },
  { privilege: 'SiteClosingReport', title: 'Site Closing Report', items: [] },
  {
    privilege: 'Queue',
    title: 'Queue',
    items: [],
  },
  {
    privilege: 'ResultInbox',
    title: 'Result Inbox',
    items: [],
  },
  {
    privilege: 'Projects',
    title: 'Projects',
    child: [
      {
        privilege: 'CompanyManagement',
        title: 'Company Management',
        items: [],
      },
      {
        privilege: 'LocationManagement',
        title: 'Location Management',
        items: [],
      },
      { privilege: 'StationManagement', title: 'Stations', items: [] },
      {
        privilege: 'LocationTimeTemplateManagement',
        title: 'Time Slot Template',
        items: [],
      },
    ],
  },
  {
    privilege: 'Services',
    title: 'Services',
    child: [
      {
        privilege: 'Test',
        title: 'Tests',
        items: [],
      },
      {
        privilege: 'Profile',
        title: 'Profiles',
        items: [],
      },
      { privilege: 'PackageManagement', title: 'Packages', items: [] },
      {
        privilege: 'Measurement',
        title: 'Measurements',
        items: [],
      },
      {
        privilege: 'Procedure',
        title: 'Procedures',
        items: [],
      },
      {
        privilege: 'Radiology',
        title: 'Radiologies',
        items: [],
      },
      {
        privilege: 'Vaccination',
        title: 'Vaccinations',
        items: [],
      },
      {
        privilege: 'Review',
        title: 'Reviews',
        items: [],
      },
      {
        privilege: 'MotherhoodStatement',
        title: 'Motherhood Statement',
        items: [],
      },
      {
        privilege: 'Questionnaires',
        title: 'Questionnaires',
        items: [],
      },
    ],
  },
  {
    privilege: 'ConsentManagement',
    title: 'Consent Management',
    items: [],
  },
  {
    privilege: 'SurveyManagement',
    title: 'Survey Management',
    items: [],
  },
  //Other
  { privilege: 'AdminManagement', title: 'Admin Management', items: [] },
  {
    privilege: 'DeletionRequest',
    title: 'Deletion Request',
    items: [],
  },
  {
    privilege: 'BannerManagement',
    title: 'Banner Management',
    items: [],
  },
  { privilege: 'LabResult', title: 'Lab Result', items: [] },
];

export const ROLE_TYPE = [
  { roleType: 'SuperAdmin', label: 'Admin' },
  { roleType: 'HsAdmin', label: 'HS Admin' },
  { roleType: 'Doctor', label: 'Doctor' },
  { roleType: 'CA', label: 'CA' },
  { roleType: 'TeleFITSuperAdmin', label: 'Telefit Super Admin' },
  { roleType: 'TeleFITAdmin', label: 'Telefit Admin' },
  { roleType: 'Manager', label: 'Telefit Host' },
  { roleType: 'Trainer', label: 'Telefit Trainer' },
  { roleType: 'InternalTrainer', label: 'Telefit Internal Trainer' },
  { roleType: 'TeleFITStudio', label: 'Telefit Studio' },
  { roleType: 'CSO', label: 'CSO' },
  { roleType: 'MHS', label: 'MHS' },
  { roleType: 'Staff', label: 'Staff' },
  { roleType: 'LabAdmin', label: 'Lab Admin' },
  { roleType: 'Swabber', label: 'Swabber' },
];

export const ROLE_MERGE_TRAINER = [
  { roleType: 'SuperAdmin', label: 'Admin' },
  { roleType: 'HsAdmin', label: 'HS Admin' },
  { roleType: 'Doctor', label: 'Doctor' },
  { roleType: 'CA', label: 'CA' },
  { roleType: 'TeleFITSuperAdmin', label: 'Telefit Super Admin' },
  { roleType: 'TeleFITAdmin', label: 'Telefit Admin' },
  { roleType: 'Manager', label: 'Telefit Host' },
  { roleType: 'Trainer', label: 'Telefit Trainer' },
  { roleType: 'TeleFITStudio', label: 'Telefit Studio' },
  { roleType: 'CSO', label: 'CSO' },
  { roleType: 'MHS', label: 'MHS' },
  { roleType: 'Staff', label: 'Staff' },
  { roleType: 'LabAdmin', label: 'Lab Admin' },
  { roleType: 'Swabber', label: 'Swabber' },
];
