import { isEmpty } from 'lodash';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  onGetWaitingList: (data) => ({ data }),
  onPTJoinRoom: (data, isJoin = true, isCurrentPT) => ({
    data,
    isJoin,
    isCurrentPT,
  }),
  onRemovePT: (data, shouldRemove = true, isCurrentPT) => ({
    data,
    shouldRemove,
    isCurrentPT,
  }),
  setState: (state, value) => ({ state, value }),
  updateLocalStream: (streams) => ({ streams }),
  updateRemoteStream: (streams) => ({ streams }),
  cancelConsult:
    () =>
    async ({ Api, customToast, getState }) => {
      let { callId } = getState().waitingRoom;
      let { status } = await Api.put(`Calls/${callId}/cancel`);
      if (status === 200) {
        customToast('success', 'Cancel Success');
      }
    },
  completeConsult:
    () =>
    async ({ Api, customToast, getState }) => {
      let { callId } = getState().waitingRoom;
      let { status } = await Api.put(`Calls/${callId}/complete`);
      if (status === 200) {
        customToast('success', 'Complete Success');
      }
    },
  checkVerify:
    () =>
    async ({ getState }) => {
      let { verifyingUserData } = getState().waitingRoom;
      if (!isEmpty(verifyingUserData))
        waitingRoomDispatcher.setState('verifyModalVisibility', true);
    },
  clearWaitingList: (payload) => ({ payload }),
  getMemberAppointment:
    (userId, cb) =>
    async ({ Api }) => {
      const { status, result } = await Api.get(
        `DoctorAppointments/member-appointments?memberId=${userId}`
      );
      cb && cb();
      if (status === 200) {
        waitingRoomDispatcher.getMemberAppointmentSuccess(result || []);
      } else waitingRoomDispatcher.getMemberAppointmentSuccess([]);
    },
  getMemberAppointmentSuccess: (data) => ({ data }),
  resetState: () => ({}),
  checkConsultPending:
    (cb) =>
    async ({ Api }) => {
      const { result, status } = await Api.get(`Consultations/pending`);
      status === 200 && cb(result);
    },
};

const waitingRoomDispatcher = synthesize('waitingRoom', mapDispatchToAC);
export default waitingRoomDispatcher;
