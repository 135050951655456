import CustomTooltip from '@/components/CustomTooltip';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  withStyles,
  Card,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, withRouter } from 'react-router-dom';

const CustomListItem = withStyles((theme) => ({
  root: {
    height: 42,
    padding: '0 8px',

    color: theme.palette.general.black,
    fontWeight: 600,
    borderRadius: 8,

    '&:hover': {
      backgroundColor: theme.palette.general.white,
    },

    '&.Mui-selected, &.Mui-selected > .list-item-icon, &.parent-selected , &.parent-selected > .list-item-icon':
      {
        color: theme.palette.primary.main,
      },

    '&.Mui-selected': {
      background: 'transparent',
    },

    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(ListItem);

const CustomListItemSubMenu = withStyles((theme) => ({
  root: {
    height: 42,
    color: theme.palette.general.black,

    '& .MuiTypography-root': {
      fontWeight: 500,
    },

    '&:hover, &.Mui-selected, &.Mui-selected:hover': {
      color: theme.palette.general.black,
      backgroundColor: 'unset',
    },

    '& .list-item-text': {
      padding: 10,

      minHeight: 42,
      borderRadius: 8,

      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },

    '&:hover .list-item-line': {
      borderLeft: `2px solid ${theme.palette.common.white}`,
    },

    '&.Mui-selected': {
      color: theme.palette.common.white,

      '&:hover > .list-item-text, .list-item-text': {
        backgroundColor: theme.palette.primary.main,
      },

      '& .list-item-line': {
        borderLeft: `2px solid ${theme.palette.primary.main}`,
      },
    },
  },
}))(ListItem);

const CustomListItemClosed = withStyles((theme) => ({
  root: {
    width: 64,
    height: 'unset',
    overflow: 'hidden',
    flexDirection: 'column',
    position: 'relative',

    padding: 6,
    margin: 'auto',
    marginBottom: 6,
    borderRadius: 8,

    color: theme.palette.general.black,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',

    '& .MuiTypography-root': {
      fontWeight: 500,
      fontSize: 10,
    },

    '&:hover .submenu': {
      visibility: 'visible',
      transition: 'visibility 0.04s' /* fade in when hovered */,
      transitionDelay: '0s',
    },

    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },

    '&.Mui-selected, &.Mui-selected:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,

      '& .list-item-icon': {
        color: theme.palette.common.white,
      },
    },
  },
}))(ListItem);

const CustomListItemSubMenuClosed = withStyles((theme) => ({
  root: {
    height: 42,
    color: theme.palette.general.black,

    '& .MuiTypography-root': {
      fontWeight: 500,
    },

    '&:hover, &.Mui-selected, &.Mui-selected:hover': {
      color: theme.palette.general.black,
      backgroundColor: 'unset',
    },

    '&.submenu-sidebar-closed': {
      '& > .list-item-text': {
        width: '100%',
        padding: '8px 10px',

        minHeight: 42,
        borderRadius: 8,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '&:hover': {
          backgroundColor: theme.palette.common.white,
        },
      },
    },

    '&.Mui-selected, &.Mui-selected > .list-item-icon, &.parent-selected , &.parent-selected > .list-item-icon':
      {
        color: theme.palette.primary.main,
      },
  },
}))(ListItem);

const CustomCard = withStyles((theme) => ({
  root: {
    position: 'fixed',
    left: 88,
    zIndex: 99,
    minWidth: 160,

    padding: theme.spacing(1),
    boxShadow: ' 0 4px 16px 0 rgba(51,51,51,0.1)',
    border: 'none',

    visibility: 'hidden',
    transition:
      'visibility 0.04s' /* change length to longer for a nicer fade */,
    transitionDelay: '0.04s',

    '& .MuiTypography-root': {
      fontSize: 14,
    },

    '& .list-item-line': {
      borderLeft: `2px solid ${theme.palette.common.white}`,
    },

    '& .MuiListItem-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },

    '& .MuiListItem-root:hover > .list-item-text': {
      backgroundColor: theme.palette.grey.disableBackground,
    },

    '& .submenu-sidebar-closed > .MuiListItem-root:hover > .list-item-text': {
      backgroundColor: 'unset',
    },

    '& .MuiListItem-root:hover > .list-item-line': {
      borderLeft: `2px solid ${theme.palette.grey.disableBackground}`,
    },
  },
}))(Card);

const CustomListItemTextWithIcon = withStyles({
  primary: {
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    fontWeight: 600,
  },
})(ListItemText);

const CustomListItemTextWithChilren = withStyles((theme) => ({
  root: {
    '& .MuiCollapse-wrapper': {
      paddingLeft: 48,
    },
  },
  primary: {
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    fontWeight: 500,
    fontSize: 14,
    paddingLeft: 10,
  },
}))(ListItemText);

const CustomListItemText = withStyles({
  root: {
    '& > span > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
    },
  },
  primary: {
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    fontWeight: 600,
  },
})(ListItemText);

const CustomListItemIcon = withStyles({
  root: {
    minWidth: 24,
  },
})(ListItemIcon);

const CustomListItemLine = withStyles((theme) => ({
  root: {
    minWidth: 8,
    height: 42,
    borderLeft: `2px solid ${theme.palette.grey.line}`,
  },
}))(ListItemIcon);

const ListItemBody = ({ config, Icon }) => {
  return (
    !isEmpty(config) && (
      <>
        {Icon ? (
          <>
            <CustomListItemIcon className="list-item-icon">
              <Icon />
            </CustomListItemIcon>
            <CustomListItemTextWithIcon
              primary={<CustomTooltip content={config.title} maxLength={21} />}
            />
          </>
        ) : config.children && get(config, 'children[0].privilege') ? (
          <>
            <CustomListItemLine
              className="list-item-line"
              style={{ marginLeft: 8 }}
            ></CustomListItemLine>
            <CustomListItemTextWithChilren
              primary={<CustomTooltip content={config.title} maxLength={21} />}
            />
          </>
        ) : (
          <>
            <CustomListItemLine className="list-item-line"></CustomListItemLine>
            <CustomListItemText
              className="list-item-text"
              primary={<CustomTooltip content={config.title} maxLength={21} />}
            />
          </>
        )}
      </>
    )
  );
};

const ListItemBodySidebarClosed = ({ config, path, Icon, handleCollapse }) => {
  const [topValue, setTopValue] = useState(0);

  const onMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setTopValue(rect.top - 30);
  };

  return (
    !isEmpty(config) && (
      <>
        {Icon && (
          <>
            <CustomListItemIcon className="list-item-icon">
              <Icon />
            </CustomListItemIcon>
            <CustomListItemTextWithIcon
              primary={config.title}
              onMouseOver={onMouseMove}
            />

            {config.children && get(config, 'children[0].privilege') && (
              <CustomCard
                className="submenu"
                variant="outlined"
                style={topValue ? { top: topValue } : { display: 'none' }}
              >
                <List>
                  {config.children.map((item) =>
                    item.children && get(item, 'children[0].privilege') ? (
                      <ExpandableMenuItemSidebarClosed
                        key={item.title}
                        config={item}
                        handleCollapse={handleCollapse}
                        path={path}
                      />
                    ) : (
                      <Link to={`/${item.value}`}>
                        <CustomListItemSubMenu selected={path === item.value}>
                          <CustomListItemLine className="list-item-line" />
                          <CustomListItemText
                            className="list-item-text"
                            primary={item.title}
                          />
                        </CustomListItemSubMenu>
                      </Link>
                    )
                  )}
                </List>
              </CustomCard>
            )}
          </>
        )}
      </>
    )
  );
};

const ExpandableMenuItemSidebarClosed = ({ path, config, handleCollapse }) => {
  return (
    <>
      <CustomListItemSubMenuClosed
        button
        onClick={() => handleCollapse(config)}
        isOpen={config.isOpen}
        className={
          config.isOpen
            ? 'submenu-sidebar-closed parent-selected'
            : 'submenu-sidebar-closed'
        }
      >
        <CustomListItemLine className="list-item-line" />
        <div className="list-item-text" primary={config.title}>
          {config.title}
          {config.isOpen ? <ExpandLess /> : <ExpandMore />}
        </div>
      </CustomListItemSubMenuClosed>

      <Collapse in={config.isOpen} timeout="auto" unmountOnExit>
        {config.children && get(config, 'children[0].privilege') && (
          <List style={{ marginLeft: 17 }}>
            {config.children.map((item) => (
              <Link id={`${item.value}`} to={`/${item.value}`}>
                <CustomListItemSubMenu selected={path === item.value}>
                  <CustomListItemLine className="list-item-line" />
                  <CustomListItemText
                    className="list-item-text"
                    primary={item.title}
                  />
                </CustomListItemSubMenu>
              </Link>
            ))}
          </List>
        )}
      </Collapse>
    </>
  );
};

const ExpandableMenuItem = ({ config, handleCollapse, renderMenuItem }) => {
  const Icon = config.isOpen ? config?.iconActive : config?.iconInActive;

  return (
    <>
      <CustomListItem
        button
        onClick={() => handleCollapse(config)}
        className={config.isOpen ? 'parent-selected' : ''}
      >
        <ListItemBody config={config} Icon={Icon} />
        {config.isOpen ? <ExpandLess /> : <ExpandMore />}
      </CustomListItem>

      <Collapse in={config.isOpen} timeout="auto" unmountOnExit>
        {config.hasSubmenu ? (
          <List style={{ marginLeft: 16, borderLeft: '2px solid #E1E1E1' }}>
            {renderMenuItem(config.children, true)}
          </List>
        ) : (
          <List>{renderMenuItem(config.children, true)}</List>
        )}
      </Collapse>
    </>
  );
};

const CustomSidebarMenu = ({ isSidebarOpen }) => {
  const location = useLocation();

  const { groupedPrivilege } = useSelector((state) => state.roleManagement);
  const [menuItems, setMenuItems] = useState([]);

  const path = location.pathname.substring(1, location.pathname.length);

  useEffect(() => {
    if (groupedPrivilege) {
      groupedPrivilege.forEach((menu) => {
        const initTab = menu.children.find((item) => item.value === path);
        if (initTab) reOpenGroupItem(initTab, groupedPrivilege);
        menu.children.forEach((childMenu) => {
          const initSubTab = childMenu.children?.find(
            (item) => item.value === path
          );

          childMenu.children &&
            childMenu.children.forEach((subMenu) => {
              const initSubTab = subMenu.children?.find(
                (item) => item.value === path
              );
              if (initSubTab) reOpenGroupItem(subMenu, groupedPrivilege);
            });

          if (initSubTab) reOpenGroupItem(childMenu, groupedPrivilege);
        });
      });
    }
    setMenuItems(groupedPrivilege);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedPrivilege]);

  const reOpenGroupItem = (initTab, menuItems) => {
    let groupItemIdx, itemIdx, subItemIdx;
    menuItems.forEach((menuItem, mnIdx) => {
      if (!menuItem.isGroupItem) return;
      menuItem.children.forEach((mnItem, index) => {
        if (mnItem.value === initTab.value) {
          itemIdx = index;
          groupItemIdx = mnIdx;
        } else if (mnItem?.children) {
          let childIdx = mnItem.children.findIndex(
            (i) => i.value === initTab.value
          );
          if (childIdx >= 0) {
            groupItemIdx = mnIdx;
            itemIdx = index;
            subItemIdx = childIdx;
          }
        }
      });
    });
    if (itemIdx >= 0 && groupItemIdx >= 0) {
      const listMenu = [...menuItems];
      listMenu[groupItemIdx].isOpen = true;
      listMenu[groupItemIdx].children[itemIdx].isOpen = true;
      if (subItemIdx >= 0)
        listMenu[groupItemIdx].children[itemIdx].children[
          subItemIdx
        ].isOpen = true;
      setMenuItems(listMenu);
    }
  };

  const handleCollapse = (menuItem) => {
    const { parentInx, childInx } = generateIndex(menuItem);
    const listMenu = [...menuItems];

    if (!parentInx && !childInx && childInx !== 0) {
      const pos = menuItems.findIndex((item) => item.value === menuItem.value);
      if (pos !== -1) listMenu[pos].isOpen = !listMenu[pos].isOpen;

      setMenuItems(listMenu);
    } else {
      const pos = menuItems[parentInx].children.findIndex(
        (item) => item.value === menuItem.value
      );
      if (pos !== -1)
        listMenu[parentInx].children[pos].isOpen =
          !listMenu[parentInx].children[pos].isOpen;
      else listMenu[parentInx].isOpen = !listMenu[parentInx].isOpen;

      setMenuItems(listMenu);
    }
  };

  const MenuItem = ({
    config,
    path,
    hasSubMenu,

    handleCollapse,
  }) => {
    const isExist = config.children?.map((it) => it.value);
    const isSubmenuExist = config.children
      ?.find((it) => it.isOpen)
      ?.children?.map((it) => it.value);

    const isSlected =
      path === config.value ||
      isExist?.includes(config.value) ||
      isExist?.includes(path) ||
      isSubmenuExist?.includes(path);

    const Icon = isSlected ? config?.iconActive : config?.iconInActive;

    return isSidebarOpen ? (
      <Link to={`/${config.value}`}>
        {hasSubMenu ? (
          <CustomListItemSubMenu selected={isSlected} button>
            <ListItemBody config={config} Icon={Icon} />
          </CustomListItemSubMenu>
        ) : (
          <CustomListItem selected={isSlected} button>
            <ListItemBody config={config} Icon={Icon} />
          </CustomListItem>
        )}
      </Link>
    ) : (
      <Link id={`${config.value}`} to={config.path ? `/${config.path}` : '#'}>
        <CustomListItemClosed selected={isSlected} button>
          <ListItemBodySidebarClosed
            config={config}
            path={path}
            Icon={Icon}
            handleCollapse={handleCollapse}
          />
        </CustomListItemClosed>
      </Link>
    );
  };

  const generateIndex = (subItem) => {
    let parentInx;
    let childInx;
    menuItems.forEach((menuItem, index) => {
      menuItem.children.forEach((subMenu, subInx) => {
        if (subMenu === subItem) {
          parentInx = index;
          childInx = subInx;
        }
      });
    });
    return { parentInx, childInx };
  };

  const renderMenuItem = (children, hasSubMenu = false) => {
    let menu = [];

    children.forEach((menuItem, index) =>
      Array.isArray(menuItem.children) &&
      !isEmpty(menuItem.children) &&
      get(menuItem, 'children[0].privilege') &&
      isSidebarOpen
        ? menu.push(
            <ExpandableMenuItem
              key={menuItem.title}
              config={menuItem}
              renderMenuItem={renderMenuItem}
              handleCollapse={handleCollapse}
            />
          )
        : menu.push(
            <MenuItem
              config={menuItem}
              key={menuItem.title}
              path={path}
              hasSubMenu={hasSubMenu}
              handleCollapse={handleCollapse}
            />
          )
    );

    return menu.concat();
  };

  return (
    <List component="nav" style={{ margin: '0 8px' }}>
      {renderMenuItem(menuItems)}
    </List>
  );
};
export default withRouter(CustomSidebarMenu);
