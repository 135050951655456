import { CORPORATE_REPORT_STATUS } from '../constants';
import CustomDateRange from '@/new-components/CustomDateRange';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import { Box, Toolbar, Button } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { isBoolean, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';

const CustomButton = withStyles({
  label: {
    fontWeight: 700,
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  isBtnSelected: {
    border: 'none',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
  checkboxIcon: {
    padding: 0,
    marginRight: 4,
  },
  tagStyle: {
    maxWidth: 'calc(100% - 60px)', // Adjust based on your needs
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selectWrapper: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: '#ffffff',
      },
    },
  },
}));

const GroupToolbar = ({
  filterOption,
  setFilterOption,
  onFilterOptionClear,
  onFilterOptionChange,
  onResetFilterOps,

  counselDate,
  setCounselDate,
  statuses,
  setAppointmentStatuses,
}) => {
  const classes = useStyles();
  const renderValue = (array, label, defaultLabel) => {
    let value = '';
    if (array.length === 0) value = defaultLabel;
    else if (array.length === 1) value = array[0];
    else value = `${label} (${array.length})`;

    return value;
  };

  const renderDateValue = (array, defaultLabel) => {
    /* 
    1. !startDate && !endDate => defaultLabel
    2. startDate && !endDate => startDate format: DD/MM/YYYY
    3. startDate && endDate
      - if startDate and endDate in the same month, year => format(DD-DD/MM/YYYY)
      - else format(DD/MM/YYYY-DD/MM/YYYY)
    */

    if (isEmpty(array)) return defaultLabel;
    const startDate = moment(array[0]).format('DD/MM/YYYY');
    const endDate = moment(array[1]).format('DD/MM/YYYY');
    return `${startDate}-${endDate} `;
  };

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = Object.keys(filterOption).filter(
      (item) => !['appointmentTypes'].includes(item)
    );
    return filterKeys.every((key) =>
      isBoolean(filterOption[key])
        ? !filterOption[key]
        : isEmpty(filterOption[key])
    );
  }, [filterOption]);

  return (
    <Toolbar>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gridGap={12}>
          <CustomDateRange
            selectedDate={counselDate || []}
            renderValue={() =>
              renderDateValue(counselDate || [], 'Select project start date')
            }
            onChange={(startDate, endDate) => {
              if (startDate && endDate) {
                setCounselDate([startDate, endDate]);
                setFilterOption({
                  ...filterOption,
                  startDate: startDate.toISOString(),
                  endDate: endDate.toISOString(),
                });
              } else {
                setCounselDate([]);
                setFilterOption({
                  ...filterOption,
                  startDate: null,
                  endDate: null,
                });
              }
            }}
          />
          <CustomSelectContainer
            isFullWidth
            filterValue={statuses || []}
            data={CORPORATE_REPORT_STATUS}
            renderValue={renderValue(
              statuses
                .filter((it) => it !== 'Assigned')
                .map(
                  (it) =>
                    CORPORATE_REPORT_STATUS.find((item) => item.key === it)
                      ?.value
                ),
              'Statuses',
              'Select status'
            )}
            onClear={onFilterOptionClear(
              'statuses',
              CORPORATE_REPORT_STATUS,
              setAppointmentStatuses
            )}
            onChange={onFilterOptionChange('statuses', setAppointmentStatuses)}
            labelSelectAll={'All statuses'}
            useSelectAll
            checkedAll={CORPORATE_REPORT_STATUS.length === statuses?.length}
          />
        </Box>

        <CustomButton
          color="primary"
          disabled={isEmptyFilterParams}
          onClick={onResetFilterOps}
        >
          Reset
        </CustomButton>
      </Box>
    </Toolbar>
  );
};

export default GroupToolbar;
