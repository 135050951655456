import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import React from 'react';

export const DEFAULT = [
  {
    color: '#BEEEC7',
    title: 'Normal',
  },
  {
    color: '#F9E1A1',
    title: 'High Normal',
  },
  {
    color: '#FFBCB7',
    title: 'Grade 1 Hypertension',
  },
  {
    color: '#FF695E',
    title: 'Grade 2 Hypertension',
  },
  {
    color: '#E8DEFF',
    title: 'Isolated Systolic Hypertension',
  },
];

const CustomList = withStyles({
  root: {
    '& .MuiTypography-body1': {
      color: '#333',
      fontSize: '2.01vw',
    },

    '& .MuiListItem-root': {
      paddingTop: '0.84vw',
      paddingBottom: '0.84vw',
      gap: '6vw',
    },
    '& .MuiListItemText-root': {
      margin: 0,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 'unset',
    },
  },
})(List);

const LegendsOfChartCom = ({ data = DEFAULT }) => {
  return (
    <CustomList>
      {data.map((it, inx) => (
        <ListItem key={`${it.title}-${inx}`} disableGutters>
          <ListItemIcon>
            <Box
              borderRadius={'0.67vw'}
              width={'6.72vw'}
              height={'3.36vw'}
              bgcolor={it.color}
            />
          </ListItemIcon>
          <ListItemText primary={it.title} />
        </ListItem>
      ))}
    </CustomList>
  );
};

export default LegendsOfChartCom;
