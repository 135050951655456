import { barChartLabelPlugins } from '../ultils';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useCallback } from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StackedBarChart = ({
  chartData,
  onDone,
  animationDuration = 0,
  borderRadius = 4,
  barThickness = 32,
  fontSize = 8,
  plugins = barChartLabelPlugins,
}) => {
  const chartOptions = () => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      devicePixelRatio: 2,
      hover: {
        animationDuration, // duration of animations when hovering an item
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            font: {
              family: 'OpenSans',
              size: fontSize,
            },
          },
        },
        y: {
          stacked: true,

          ticks: {
            font: {
              family: 'OpenSans',
              size: fontSize,
            },
            callback: function (value) {
              let newLabel = this.getLabelForValue(value);
              return newLabel + '%';
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          color: '#333333',
          font: {
            size: fontSize,
          },
          formatter: function (value, context) {
            return value !== '0.0' ? value + '%' : '';
          },
        },
      },
      animation: {
        duration: animationDuration, // general animation time
        onComplete: onDone,
      },
    };
  };

  const formatChartData = useCallback(() => {
    let datasets = [];

    const data = chartData?.overView?.ageGroups.filter(
      (it) => it.title !== 'Total'
    );

    const labels = data?.map((it) => it.title);
    const malePercent = data?.map((it) => it.malePercent?.toFixed(1));
    const femalePercent = data?.map((it) => it.femalePercent?.toFixed(1));

    datasets.push(
      {
        data: femalePercent,
        backgroundColor: 'rgba(245, 197, 213, 1)',
        borderRadius,
        barThickness,
      },
      {
        data: malePercent,
        backgroundColor: 'rgba(226, 242, 255, 1)',
        borderRadius,
        barThickness,
      }
    );

    return { labels, datasets };
  }, [chartData]);

  return (
    <Bar
      options={chartOptions()}
      data={formatChartData()}
      plugins={[...plugins, ChartDataLabels]}
    />
  );
};

export default StackedBarChart;
