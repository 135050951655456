import { FILTER_RESTRICT_STATUSES } from '../../constants';
import {
  CustomSearchInputModal,
  FilterContainer,
  PackageTableBody,
  PackageTableHeader,
  PackageTableRow,
  RestrictPackageContainer,
  RestrictPackageDescription,
  RestrictPackageTitle,
  RestrictPackageWrapper,
} from '../../createAndEditLocation.styled';
import { IOSSwitch } from '../mainScreen.styled';
import { CloseCircle, SearchIcon } from '@/assets/svg';
import { CLINIC_SERVICE_TYPE } from '@/module/clinic-services/constants';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import CustomTooltip from '@/new-components/CustomTooltip';
import {
  Grid,
  InputAdornment,
  IconButton,
  Box,
  Tooltip,
} from '@material-ui/core';
import { Assignment } from '@material-ui/icons';
import { isEmpty, lowerCase } from 'lodash';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { FixedSizeList, areEqual } from 'react-window';

const RestrictClinicServicesForm = ({
  selectedOption,
  setSelectedOption,
  clinicServices,
  clinicServicesSelected,
  name,
  setName,
  status,
  setStatus,

  prefix,
  prefixPlural,

  restrictedSubPurposeIds,
  setRestrictedSubPurposeIds,
  restrictedPurposeIds,
  setRestrictedPurposeIds,
}) => {
  const listDataFilter = useMemo(() => {
    let listData = clinicServices;
    if (selectedOption && clinicServicesSelected) {
      listData = clinicServicesSelected?.subPurposes;
    }

    if (status === 'Restricted') {
      const restricted =
        selectedOption && clinicServicesSelected
          ? restrictedSubPurposeIds
          : restrictedPurposeIds;
      return listData
        .filter((item) => restricted?.includes(item.id))
        .filter((item) => lowerCase(item.name).includes(lowerCase(name)));
    }

    return listData?.filter((item) =>
      lowerCase(item.name).includes(lowerCase(name))
    );
  }, [
    selectedOption,
    clinicServices,
    clinicServicesSelected,
    name,
    status,
    restrictedSubPurposeIds,
    restrictedPurposeIds,
  ]);

  const Row = memo(function Row({ data, index, style }) {
    const { items, restrictedPurposeIds, setRestrictedPurposeIds } = data;
    const item = items[index];

    return (
      <PackageTableRow container key={item.id} style={style}>
        <Grid item xs={9}>
          <CustomTooltip content={item.name} />
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
          {!item.subPurposes &&
            [
              CLINIC_SERVICE_TYPE.MULTIPLE_CHOICE,
              CLINIC_SERVICE_TYPE.SINGLE_CHOICE,
            ].includes(item.purposeType) && (
              <IconButton
                onClick={() => {
                  setStatus('');
                  setName('');
                  setSelectedOption(item.id);
                }}
              >
                <Tooltip title="View sub-services">
                  <Assignment color="primary" />
                </Tooltip>
              </IconButton>
            )}
        </Grid>

        <Grid item xs={1}>
          <IOSSwitch
            color="primary"
            size="medium"
            disabled={
              selectedOption &&
              restrictedPurposeIds?.includes(clinicServicesSelected?.id)
            }
            checked={
              restrictedPurposeIds?.includes(clinicServicesSelected?.id)
                ? restrictedPurposeIds?.includes(clinicServicesSelected?.id)
                : selectedOption
                ? restrictedSubPurposeIds?.includes(item.id)
                : restrictedPurposeIds?.includes(item.id)
            }
            onChange={() => {
              if (selectedOption) {
                if (restrictedSubPurposeIds?.includes(item.id))
                  setRestrictedSubPurposeIds(
                    restrictedSubPurposeIds?.filter(
                      (selected) => selected !== item.id
                    )
                  );
                else
                  setRestrictedSubPurposeIds([
                    ...restrictedSubPurposeIds,
                    item.id,
                  ]);
              } else {
                if (restrictedPurposeIds?.includes(item.id))
                  setRestrictedPurposeIds(
                    restrictedPurposeIds?.filter(
                      (selected) => selected !== item.id
                    )
                  );
                else
                  setRestrictedPurposeIds([...restrictedPurposeIds, item.id]);
              }
            }}
          />
        </Grid>
      </PackageTableRow>
    );
  }, areEqual);

  const itemData = useMemo(
    () => ({
      items: listDataFilter,
      restrictedPurposeIds,
      setRestrictedPurposeIds,
      restrictedSubPurposeIds,
      setRestrictedSubPurposeIds,
    }),
    [listDataFilter, restrictedPurposeIds, restrictedSubPurposeIds]
  );

  useEffect(() => {
    if (clinicServicesSelected && selectedOption) {
      const restrictedAll = clinicServicesSelected?.subPurposes?.filter((it) =>
        restrictedSubPurposeIds?.includes(it.id)
      );
      const newRestrictedPurposeIds = restrictedPurposeIds.filter(
        (selected) => !restrictedAll.some((item) => item.id === selected)
      );

      if (restrictedAll.length === clinicServicesSelected?.subPurposes.length) {
        setRestrictedPurposeIds(newRestrictedPurposeIds);
        setRestrictedPurposeIds([
          ...restrictedPurposeIds,
          clinicServicesSelected.id,
        ]);
      }
    }
  }, [selectedOption, clinicServicesSelected, restrictedSubPurposeIds]);

  return (
    <RestrictPackageWrapper>
      <Box display={'flex'} justifyContent={'space-between'}>
        <RestrictPackageTitle>
          Restrict {prefix}{' '}
          {clinicServicesSelected && selectedOption
            ? `: ${clinicServicesSelected?.name}`
            : ''}
        </RestrictPackageTitle>

        {clinicServicesSelected && selectedOption && (
          <Box display="flex" justifyContent="flex-end">
            <IOSSwitch
              checked={restrictedPurposeIds?.includes(
                clinicServicesSelected?.id
              )}
              onChange={() => {
                if (restrictedPurposeIds?.includes(clinicServicesSelected.id)) {
                  setRestrictedPurposeIds(
                    restrictedPurposeIds?.filter(
                      (selected) => selected !== clinicServicesSelected.id
                    )
                  );

                  const restrictedAll =
                    clinicServicesSelected?.subPurposes?.filter((it) =>
                      restrictedSubPurposeIds?.includes(it.id)
                    );
                  const newRestrictedPurposeIds = restrictedPurposeIds.filter(
                    (selected) =>
                      !restrictedAll.some((item) => item.id === selected)
                  );
                  console.log(
                    'newRestrictedPurposeIds',
                    restrictedAll,
                    newRestrictedPurposeIds
                  );
                  // setRestrictedPurposeIds(newRestrictedPurposeIds);
                } else {
                  setRestrictedPurposeIds([
                    ...restrictedPurposeIds,
                    clinicServicesSelected.id,
                  ]);
                }
              }}
            />
          </Box>
        )}
      </Box>
      <RestrictPackageDescription>
        Restricted {prefixPlural} will not be available at this location.
      </RestrictPackageDescription>
      <FilterContainer>
        <Box minWidth={144}>
          <CustomSelectContainer
            isFullWidth
            data={FILTER_RESTRICT_STATUSES}
            isMutiple={false}
            renderValue={<>{status || 'Status'}</>}
            filterValue={status}
            onChange={(e) => setStatus(e.target.value)}
            height={'40px'}
          />
        </Box>
        <CustomSearchInputModal
          placeholder={
            clinicServicesSelected
              ? 'Search by sub-service name...'
              : 'Search by service name...'
          }
          value={name || ''}
          fullWidth
          onChange={(e) => setName(e.target.value)}
          variant="outlined"
          allowClear
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <IconButton
                    style={{
                      visibility: !isEmpty(name) ? 'visible' : 'hidden',
                    }}
                    onClick={() => setName('')}
                  >
                    <CloseCircle
                      height={20}
                      width={20}
                      style={{
                        color: '#666666',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </>
            ),
          }}
        />
      </FilterContainer>
      <RestrictPackageContainer>
        <PackageTableHeader container>
          <Grid item xs={9}>
            Clinic {selectedOption ? 'sub-services' : 'services'}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1} style={{ textAlign: 'right' }}></Grid>
          <Grid item xs={1} style={{ textAlign: 'right' }}>
            Status
          </Grid>
        </PackageTableHeader>
        <PackageTableBody>
          {!isEmpty(listDataFilter) ? (
            <FixedSizeList
              itemCount={listDataFilter.length}
              itemData={itemData}
              itemSize={47}
              height={
                listDataFilter.length > 10 ? 500 : 47 * listDataFilter.length
              }
              width={630}
            >
              {Row}
            </FixedSizeList>
          ) : (
            <PackageTableRow container>
              <Grid item xs={9}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}></Grid>
            </PackageTableRow>
          )}
        </PackageTableBody>
      </RestrictPackageContainer>
    </RestrictPackageWrapper>
  );
};

export default RestrictClinicServicesForm;
