import CustomSelect from '../../../components/CustomSelect';
import MutipleSelection from '../../../components/CustomSelect/MutipleSelection';
import TableContainer from '../../../components/Table/TableContainer';
import { QueueStatus, QueueColor } from '../../../enum/QueueEnum';
import { momentFormatted } from '../../../helpers';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import { ChipStatus } from '../../common/componentUI/commonStyleComponents';
import doctorDispatcher from '../../doctor/action/doctor';
import caQueueDispatcher from '../action/caQueue';
import CustomDate from '@/components/CustomDate';
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@material-ui/core';
import {
  MoreVertRounded,
  ArtTrackOutlined,
  CancelRounded,
} from '@material-ui/icons';
import { startCase, isEmpty } from 'lodash';
import { sortBy } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const CAQueue = () => {
  const data = useSelector((state) => state.caQueue.list);
  const [selectedItem, setSelectedItem] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusOpen, setStatusOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState({
    fromDate: moment().startOf('date'),
    toDate: moment().endOf('date'),
  });
  const [hiddenRows, setHiddenRows] = useState({});
  const [clinicOpen, setClinicOpen] = useState(false);
  const [clinicFilter, setClinicFilter] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('date'));

  const listClinic = useSelector((state) =>
    sortBy(
      (state.doctor.clinics.list || []).map((i) => ({
        key: i.id,
        value: i.name,
      })),
      (item) => item.value.toLowerCase()
    )
  );

  const onChangeStartDate = (value) => {
    setStartDate(value || '');
    setFilterOption({
      ...filterOption,
      fromDate: moment(value).startOf('date'),
      toDate: moment(value).endOf('date'),
    });
  };
  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows,
      }}
      {...props}
    />
  );

  const Header = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper
          style={{ paddingLeft: 20 }}
          label="Date Time"
          stateValue="creationTime"
        />
        <TableCellFiltedWrapper label="Clinic Name" stateValue="clinicName" />
        <TableCellFiltedWrapper label="Address" stateValue="clinicAddress" />
        <TableCellFiltedWrapper label="Patient" stateValue="patientFullName" />
        <TableCellFiltedWrapper label="Queue Number" stateValue="queueNo" />
        {/* <TableCellFiltedWrapper
          label="Estimated Wait Time"
          stateValue="estimatedTime"
        /> */}
        <TableCellFiltedWrapper label="Status" stateValue="status" />
        <TableCellFiltedWrapper label="Status Name" stateValue="statusName" />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  useEffect(() => {
    doctorDispatcher.getClinics();
    return () => {};
  }, []);

  const handleClick = (item) => (event) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const renderMenuItem = () => {
    if (!selectedItem) return [];
    let options = [];
    if (selectedItem.queueRequestStatus === QueueStatus.Accepted) {
      options = [
        {
          key: 'cancel',
          icon: CancelRounded,
          label: 'Cancel Queue Request',
          onClick: () => {
            caQueueDispatcher.rejectQueue(
              selectedItem.id,
              searchKey,
              filterOption
            );
            handleClose();
          },
        },
      ];
    }
    return options.map((d) => {
      let Icon = d.icon;
      return (
        <MenuItem
          key={d.key}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Source Sans Pro',
            color: '#192637',
          }}
          onClick={d.onClick}
        >
          <ListItemIcon style={{ minWidth: 34 }}>
            <Icon style={{ color: '#CACFD3' }} />
          </ListItemIcon>
          {d.label}
        </MenuItem>
      );
    });
  };
  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {renderMenuItem()}
      </Menu>
      <TableContainer
        title="Queue Requests"
        query={caQueueDispatcher.getData}
        header={Header}
        data={data}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        filterOption={filterOption}
        ToolbarComponent={
          <>
            <div style={{ width: 300, margin: '22px 20px 0' }}>
              <CustomDate
                {...{
                  selectedDate: startDate,
                  onChange: onChangeStartDate,
                  placeholder: startDate ? `${startDate}` : 'Date',
                  onClear: () => {
                    setStartDate('');
                    setFilterOption({
                      ...filterOption,
                      fromDate: '',
                      toDate: '',
                    });
                  },
                }}
              />
            </div>
            <CustomSelect
              onClick={() => {
                setClinicOpen(!clinicOpen);
              }}
              clearable={!isEmpty(clinicFilter)}
              onClear={() => {
                setClinicFilter([]);
                setFilterOption({
                  ...filterOption,
                  clinicIds: '',
                });
              }}
              prefixIcon={
                <ArtTrackOutlined
                  style={{
                    fontSize: 20,
                    color: '#E9E9E9',
                    marginRight: 8,
                  }}
                />
              }
              renderValue={
                <>
                  {listClinic
                    .filter((c) => clinicFilter.includes(c.key))
                    .map((c) => c.value)
                    .join(', ') || 'Select Clinic'}
                </>
              }
            >
              <MutipleSelection
                isMutiple={false}
                value={clinicFilter}
                data={listClinic}
                open={clinicOpen}
                onChange={(e) => {
                  setFilterOption({
                    ...filterOption,
                    clinicIds: e.target.value,
                  });
                  setClinicFilter(e.target.value);
                }}
              />
            </CustomSelect>
            <CustomSelect
              onClick={() => {
                setStatusOpen(!statusOpen);
              }}
              clearable={!isEmpty(statusFilter)}
              onClear={() => {
                setStatusFilter([]);
                setFilterOption({
                  ...filterOption,
                  queueRequestStatuses: [],
                });
              }}
              prefixIcon={
                <ArtTrackOutlined
                  style={{
                    fontSize: 20,
                    color: '#E9E9E9',
                    marginRight: 8,
                  }}
                />
              }
              renderValue={<>{statusFilter.join(', ') || 'Select Status'}</>}
            >
              <MutipleSelection
                value={statusFilter}
                data={QueueStatus}
                open={statusOpen}
                onChange={(e) => {
                  setFilterOption({
                    ...filterOption,
                    queueRequestStatuses: e.target.value,
                  });
                  setStatusFilter(e.target.value);
                }}
              />
            </CustomSelect>
          </>
        }
        renderRow={(d) => (
          <>
            <TableCell
              style={{
                paddingLeft: 20,
              }}
            >
              {!hiddenRows.creationTime &&
                momentFormatted(d.creationTime, ' hh:mm DD MMM, YYYY')}
            </TableCell>
            <TableCell>{!hiddenRows.clinicName && d.clinicName}</TableCell>
            <TableCell>
              {!hiddenRows.clinicAddress && d.clinicAddress}
            </TableCell>
            <TableCell>
              {!hiddenRows.patientFullName && d.patientFullName}
            </TableCell>
            <TableCell>{!hiddenRows.queueNo && d.queueNo}</TableCell>
            {/* <TableCell>
              {!hiddenRows.estimatedTime && d.estimatedTime}
            </TableCell> */}
            <TableCell>
              {!hiddenRows.status && (
                <ChipStatus color={QueueColor[d.queueRequestStatus]}>
                  {startCase(d.queueRequestStatus)}
                </ChipStatus>
              )}
            </TableCell>
            <TableCell>{!hiddenRows.statusName && d.statusName}</TableCell>
            <TableCell align="right">
              {d.queueRequestStatus === QueueStatus.Accepted && (
                <IconButton onClick={handleClick(d)}>
                  <MoreVertRounded />
                </IconButton>
              )}
            </TableCell>
          </>
        )}
      />
    </>
  );
};

export default CAQueue;
