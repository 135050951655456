import clinicApptDispatcher from '../action';
import { TYPE_MODAL } from '../constants';
import { ArrowDown, CheckboxActive, CheckboxDefault } from '@/assets/svg';
import AsyncAutoComplete from '@/components/AsyncAutoComplete';
import validateData from '@/helpers/validationHelpers/validationSchema';
import clinicServiceDispatcher from '@/module/clinic-services/action';
import { CLINIC_SERVICE_TYPE } from '@/module/clinic-services/constants';
import { CustomAutocomplete } from '@/module/company/component/CreateAndEditCompany/styled';
import locationDispatcher from '@/module/setup/action';
import { formatTimeShowUILocal } from '@/module/setup/utils';
import CustomButton from '@/new-components/CustomButton';
import CustomDate from '@/new-components/CustomDate';
import CustomModalTitle from '@/new-components/CustomModal/CustomModalTitle';
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  InputLabel,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { cloneDeep, get, isBoolean, isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const CustomPaper = withStyles((theme) => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}))(Paper);

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    width: 480,
  },
})(Dialog);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    overflowY: 'auto',
    maxHeight: '70vh',
  },
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end',
  },
})(DialogActions);

const CustomInputLabel = withStyles((theme) => ({
  root: {
    color: '#666666',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}))(InputLabel);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: (props) => (props.hasClear ? 0 : 30),
      padding: '8px 5px',
      fontSize: 16,
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
    '& .MuiInputBase-multiline': {
      height: 'auto',
      paddingLeft: 0,

      '& .MuiOutlinedInput-input': {
        marginRight: 8,
      },
    },
  },
})(TextField);

const CustomMultipleTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      minHeight: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: 0,
        paddingLeft: 4,
        marginLeft: 4,
        height: 30,
      },

    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },
})(TextField);

const CustomMultiplePaper = withStyles((theme) => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
}))(Paper);

const ModalAddOrEditClinicAppt = ({
  typeModal,
  open,
  onClose,
  onSubmit,
  selectedItem,
}) => {
  const { list: clinicServiceList } = useSelector(
    (state) => state.clinicService
  );

  const [errors, setErrors] = useState({});
  const [purposeDetail, setPurposeDetail] = useState({});
  const [listTimeSlots, setListTimeSlots] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [locationDetail, setLocationDetail] = useState({});
  const [listPurpose, setListPurpose] = useState(clinicServiceList || []);

  const [formValue, setFormValue] = useState({
    patient: {},
    location: {},
    purpose: {},
    date: moment().startOf('date').format(),
    timeSlotId: '',
    purposeId: '',
    subPurposeIds: [],
    purposeAnswers: [],
  });

  const getClinicDetail = async () => {
    setIsLoadingData(true);
    clinicApptDispatcher.getDetailClinic(selectedItem?.id, (result) => {
      setIsLoadingData(false);

      locationDispatcher.getDetailLocation(
        selectedItem.locationId,
        (locationDetail) => {
          const { restrictedSubPurposeIds, restrictedPurposeIds } =
            locationDetail;

          const newFormValue =
            {
              ...formValue,
              id: result.id,
              date: result.date,
              timeSlotId: result.timeSlotId,
              purposeId: result.purposeId,
              purposeAnswers: isEmpty(result.purposeQuestions)
                ? result.purposeQuestions
                : result.purposeQuestions?.sort((a, b) => a.order - b.order),
            } || {};

          newFormValue.patient = {
            fullName: result.patientName,
            identityNumber: result.patientNric,
          };
          newFormValue.location = {
            id: result.locationId,
            name: result.locationName,
          };

          if (restrictedPurposeIds?.includes(result.purposeId)) {
            newFormValue.purpose = {};
            newFormValue.purposeId = '';
          } else {
            newFormValue.purpose = {
              id: result.purposeId,
              name: result.purposeName,
              purposeType: result.purposeType,
            };
            newFormValue.purposeId = result.purposeId;
          }

          const newSubPurposeIds = result.subPurposes.filter(
            (it) => !restrictedSubPurposeIds?.includes(it.id)
          );

          newFormValue.subPurposeIds = isEmpty(newSubPurposeIds)
            ? []
            : result.subPurposes
                ?.sort((a, b) => a.order - b.order)
                ?.map((it) => it.id);

          if (newFormValue.date) {
            const currentMinutes = moment(result.date).get('minute');
            const currentHours = moment(result.date).get('hour');
            newFormValue.appointmentTime = moment(result.date)
              .set({
                hour: currentHours,
                minute: currentMinutes,
              })
              .format('h:mm A');
          }

          setFormValue(newFormValue);
        }
      );
    });
  };

  const handleChangeForm = (key, data, rowInx) => (e, newValue) => {
    let value = '';

    switch (key) {
      case 'date':
        setFormValue({
          ...formValue,
          appointmentTime: '',
          timeSlotId: '',
          [key]: moment(e).startOf('date').format(),
        });
        return;
      case 'purposeData':
        let subPurposeIds = [];
        let purposeAnswers = [];
        if (
          data.purposeType === CLINIC_SERVICE_TYPE.SINGLE_CHOICE &&
          newValue
        ) {
          subPurposeIds.push(newValue.id);

          setFormValue({
            ...formValue,
            subPurposeIds,
            purposeAnswers,
          });
        } else if (
          data.purposeType === CLINIC_SERVICE_TYPE.MULTIPLE_CHOICE &&
          newValue
        ) {
          subPurposeIds = data.subPurposes
            .filter((it) => newValue.includes(it.name))
            .map((it) => it.id);
          setFormValue({
            ...formValue,
            subPurposeIds,
            purposeAnswers,
          });
        } else {
          purposeAnswers = cloneDeep(formValue.purposeAnswers).map((it, inx) =>
            inx === rowInx
              ? {
                  ...it,
                  answer: get(e, 'target.value'),
                }
              : it
          );

          setFormValue({
            ...formValue,
            subPurposeIds,
            purposeAnswers,
          });
        }

        return;

      case 'purposeId':
        setFormValue({
          ...formValue,
          purposeId: newValue.id,
          subPurposeIds: [],
          purposeAnswers: [],
        });
        return;

      case 'appointmentTime':
        const timeSlotId = listTimeSlots?.find(
          (it) => it.value === newValue.value
        ).timeSlotId;
        setFormValue({
          ...formValue,
          timeSlotId,
          appointmentTime: newValue.value,
        });

        return;
      default:
        value = newValue.value;
        break;
    }

    setFormValue({ ...formValue, [key]: value });
  };

  const handleSubmit = async () => {
    const hours = moment(formValue.appointmentTime, 'hh:mm A').get('hour');
    const minutes = moment(formValue.appointmentTime, 'hh:mm A').get('minute');
    const appointmentTime = moment(formValue.date).set({
      hours,
      minutes,
    });

    try {
      await validateData(
        'appointmentClinicSchema',
        {
          ...formValue,
          appointmentTime,
          typeModal,
        },
        () => {
          let newParams = {
            patientId: formValue.patient.id,
            locationId: formValue.location.id,
            timeSlotId: formValue.timeSlotId,
            date: moment(formValue.date).format('YYYY-MM-DD'),
            purposeId: formValue.purposeId,
            subPurposeIds: formValue.subPurposeIds,
            purposeAnswers: formValue.purposeAnswers,
          };

          if ([TYPE_MODAL.Edit, TYPE_MODAL.Reschedule].includes(typeModal)) {
            delete newParams.patientId;
          }

          onSubmit(typeModal, newParams, formValue.patient.fullName);
          setErrors({});
        }
      );
    } catch (errs) {
      setErrors(errs);
    }
  };

  const checkIsValidDate = (valueStartDate) => {
    const startDateUnix = moment(valueStartDate).startOf('date').unix();

    const dateCompare = moment().startOf('date').unix();
    return startDateUnix < dateCompare;
  };

  const checkIsValidTime = (endTime) => {
    const endTimeUnix = moment(endTime).unix();
    const timeCompare = moment().unix();

    return endTimeUnix < timeCompare;
  };

  const checkIsValidTime5Mins = (endTime) => {
    const endTime5Mins = moment(endTime).subtract(5, 'minutes');
    const timeCompare = moment();
    const diffTime = endTime5Mins.diff(timeCompare, 'minutes');

    return diffTime < 0;
  };

  const checkInvalidTimeslot = (row) => {
    const { startDate, endDate, isActive, quotaUsed, quotaBase } = row;
    // Disable Time Slot:
    // 1 Start time valid is after current time
    // 2 Start date valid is after current date 2 days
    // 3 Timeslot not active
    // 4 End time valid is before current 5 minutes
    // 5 Timslot is out of quota

    if (checkIsValidDate(startDate)) return true;
    if (checkIsValidTime(endDate)) return true;
    if (checkIsValidTime5Mins(endDate)) return true;

    if (!isActive) return true;

    return quotaUsed === quotaBase;
  };

  const isEmptyParams = useMemo(() => {
    let filterKeys = ['patient', 'location', 'date', 'purposeId', 'timeSlotId'];
    const checkValid = filterKeys.some((key) =>
      isBoolean(formValue[key]) ? !formValue[key] : isEmpty(formValue[key])
    );

    if (
      [
        CLINIC_SERVICE_TYPE.SINGLE_CHOICE,
        CLINIC_SERVICE_TYPE.MULTIPLE_CHOICE,
      ].includes(purposeDetail.purposeType)
    ) {
      return isEmpty(formValue.subPurposeIds) || checkValid;
    } else if (purposeDetail.purposeType === CLINIC_SERVICE_TYPE.INPUT_TEXT) {
      const checkEmptyAnsswers = formValue.purposeAnswers.some((it) =>
        isEmpty(it.answer)
      );
      return checkEmptyAnsswers || checkValid;
    }
    return checkValid;
  }, [formValue, purposeDetail]);

  useEffect(() => {
    if (formValue.purposeId && formValue.location.id) {
      clinicServiceDispatcher.getDetailPurpose(
        formValue.purposeId,
        (result) => {
          locationDispatcher.getDetailLocation(
            formValue.location?.id,
            (data) => {
              const { restrictedSubPurposeIds, restrictedPurposeIds } = data;
              const newSubPurposes = result.subPurposes.filter(
                (it) => !restrictedSubPurposeIds?.includes(it.id)
              );

              if (restrictedPurposeIds?.includes(formValue.purposeId)) {
                setPurposeDetail({});
              } else {
                setPurposeDetail({
                  ...result,
                  subPurposes: isEmpty(newSubPurposes)
                    ? newSubPurposes
                    : newSubPurposes?.sort((a, b) => a.order - b.order),
                  purposeQuestions: isEmpty(result?.purposeQuestions)
                    ? result?.purposeQuestions
                    : result?.purposeQuestions?.sort(
                        (a, b) => a.order - b.order
                      ),
                });
              }
            }
          );
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue.purposeId, formValue.location.id]);

  useEffect(() => {
    if (formValue.location?.id) {
      locationDispatcher.getDetailLocation(formValue.location?.id, (result) => {
        const { restrictedPurposeIds } = result;
        const newClinicPurpose = clinicServiceList.filter(
          (it) => !restrictedPurposeIds?.includes(it.id)
        );
        setListPurpose(newClinicPurpose);
        // setLocationDetail(result);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue.location?.id]);

  useEffect(() => {
    if (formValue.location.id && formValue.date) {
      locationDispatcher.getDetailLocationTimeSlot(
        {
          id: formValue.location.id,
          viewAll: true,
          date: moment(formValue.date).format('YYYY-MM-DD'),
        },
        (result) => {
          const dayOfWeek = moment(formValue.date).day();
          const timeSlots = result
            .filter(
              (it) => it.dayOfWeek === dayOfWeek && !checkInvalidTimeslot(it)
            )
            .map((it) => ({
              timeSlotId: it?.id,
              key: formatTimeShowUILocal(it?.startDate),
              value: formatTimeShowUILocal(it?.startDate),
            }));

          setListTimeSlots(timeSlots);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue.location.id, formValue.date]);

  useEffect(() => {
    if (!selectedItem) return;
    getClinicDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  useEffect(() => {
    clinicServiceDispatcher.getPurposesData();
  }, []);
  return (
    <CustomDialog
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <CustomModalTitle onClose={onClose}>
        {typeModal === TYPE_MODAL.Create
          ? 'Create appointment'
          : typeModal === TYPE_MODAL.Edit
          ? 'Edit appointment'
          : 'Reschedule appointment'}
      </CustomModalTitle>
      <CustomDialogContent dividers>
        {isLoadingData ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <CustomInputLabel>Patient name</CustomInputLabel>
            <AsyncAutoComplete
              label="Patient name"
              endpoint="Patients/filter-for-clinic-appointment"
              method="post"
              defaultFilter={{
                appointmentType: 'TeleConsult',
              }}
              value={formValue.patient}
              setValue={(newValue) =>
                setFormValue({
                  ...formValue,
                  patient: newValue,
                })
              }
              error={errors.patient}
              getOptionLabel={(option) =>
                option.fullName &&
                option.identityNumber &&
                `${option.fullName} - ${option.identityNumber}`
              }
              renderInput={(params) => (
                <CustomTextField
                  hasClear
                  variant="outlined"
                  {...params}
                  placeholder="Search by patient name or ID Number"
                />
              )}
              renderOption={(option) =>
                option.fullName &&
                option.identityNumber &&
                `${option?.fullName} - ${option?.identityNumber}`
              }
              disabled={typeModal !== TYPE_MODAL.Create}
              PaperComponent={({ children, ...other }) => (
                <CustomPaper {...other}>{children}</CustomPaper>
              )}
            />

            <CustomInputLabel style={{ marginTop: '20px' }}>
              Location
            </CustomInputLabel>
            <AsyncAutoComplete
              label="Locations"
              endpoint="Locations/filter"
              method="post"
              isLocation
              defaultFilter={{
                sortByName: true,
                hasPlatoName: true,
                statuses: ['Active'],
              }}
              value={formValue.location}
              setValue={(newValue) => {
                setPurposeDetail({});
                setFormValue({
                  ...formValue,
                  appointmentTime: '',
                  location: newValue,
                  purpose: {},
                  purposeId: '',
                  subPurposeIds: [],
                  purposeAnswers: [],
                });
              }}
              getOptionLabel={(option) => option.name || ''}
              nameKey="name"
              renderInput={(params) => (
                <CustomTextField
                  variant="outlined"
                  {...params}
                  placeholder="Select or search clinic"
                />
              )}
              disableClearable
              PaperComponent={({ children, ...other }) => (
                <CustomPaper {...other}>{children}</CustomPaper>
              )}
            />

            <CustomInputLabel style={{ marginTop: '20px' }}>
              Purpose of visit
            </CustomInputLabel>
            {/* <AsyncAutoComplete
              label="Purposes"
              endpoint="Purposes/filter"
              method="post"
              value={formValue.purpose}
              setValue={(newValue) => {
                clinicServiceDispatcher.getDetailPurpose(
                  newValue.id,
                  (result) => {
                    setFormValue({
                      ...formValue,
                      purposeId: newValue.id,
                      subPurposeIds: [],
                      purposeAnswers: !isEmpty(result?.purposeQuestions)
                        ? result?.purposeQuestions
                            .sort((a, b) => a.order - b.order)
                            .map((it) => ({
                              ...it,
                              question: it.title,
                              answer: it.answer || '',
                            }))
                        : [],
                      purpose: newValue,
                    });

                    setPurposeDetail({
                      ...result,
                      subPurposes: isEmpty(result?.subPurposes)
                        ? result?.subPurposes
                        : result?.subPurposes?.sort(
                            (a, b) => a.order - b.order
                          ),
                      purposeQuestions: isEmpty(result?.purposeQuestions)
                        ? result?.purposeQuestions
                        : result?.purposeQuestions?.sort(
                            (a, b) => a.order - b.order
                          ),
                    });
                  }
                );
              }}
              error={errors.purpose}
              getOptionLabel={(option) => option.name || ''}
              nameKey="name"
              isFormatResult
              renderInput={(params) => (
                <CustomTextField
                  variant="outlined"
                  {...params}
                  placeholder="Select purpose"
                />
              )}
              disableClearable
              PaperComponent={({ children, ...other }) => (
                <CustomPaper {...other}>{children}</CustomPaper>
              )}
            /> */}

            <CustomAutocomplete
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) => option.name || ''}
              options={listPurpose || []}
              value={
                listPurpose?.find((it) => it?.id === formValue?.purposeId) || ''
              }
              disabled={isEmpty(formValue.location)}
              onChange={handleChangeForm('purposeId')}
              popupIcon={<ArrowDown />}
              renderInput={(params) => (
                <CustomTextField
                  variant="outlined"
                  placeholder="Select purpose"
                  {...params}
                />
              )}
              disableClearable
              PaperComponent={({ children, ...other }) => (
                <CustomPaper {...other}>{children}</CustomPaper>
              )}
            />

            {!isEmpty(purposeDetail) &&
              purposeDetail.purposeType ===
                CLINIC_SERVICE_TYPE.SINGLE_CHOICE && (
                <>
                  <CustomInputLabel style={{ marginTop: '20px' }}>
                    {purposeDetail?.name === 'Medical Screening'
                      ? 'Screening'
                      : purposeDetail?.name}{' '}
                    type
                  </CustomInputLabel>

                  <CustomAutocomplete
                    getOptionSelected={(option, value) => option === value}
                    getOptionLabel={(option) => option.name || ''}
                    options={purposeDetail.subPurposes || []}
                    value={
                      purposeDetail.subPurposes.find((d) =>
                        formValue.subPurposeIds.includes(d.id)
                      ) || ''
                    }
                    onChange={handleChangeForm('purposeData', purposeDetail)}
                    popupIcon={<ArrowDown />}
                    renderInput={(params) => (
                      <CustomTextField
                        placeholder="Select"
                        {...params}
                        variant="outlined"
                        error={errors.typeId}
                        helperText={errors.typeId}
                      />
                    )}
                    PaperComponent={({ children, ...other }) => (
                      <CustomPaper {...other}>{children}</CustomPaper>
                    )}
                  />
                </>
              )}

            {!isEmpty(purposeDetail) &&
              purposeDetail.purposeType ===
                CLINIC_SERVICE_TYPE.MULTIPLE_CHOICE && (
                <>
                  <CustomInputLabel style={{ marginTop: '20px' }}>
                    {purposeDetail?.name === 'Medical Screening'
                      ? 'Screening'
                      : purposeDetail?.name}{' '}
                    type
                  </CustomInputLabel>

                  <CustomAutocomplete
                    multiple
                    disableCloseOnSelect
                    value={
                      purposeDetail.subPurposes
                        .filter((d) => formValue.subPurposeIds.includes(d.id))
                        .map((it) => it.name) || ''
                    }
                    onChange={handleChangeForm('purposeData', purposeDetail)}
                    options={purposeDetail.subPurposes.map((it) => it.name)}
                    getOptionLabel={(option) => option}
                    popupIcon={<ArrowDown />}
                    renderInput={(params) => (
                      <CustomMultipleTextField
                        {...params}
                        variant="outlined"
                        placeholder={
                          isEmpty(formValue?.subPurposeIds) ? 'Select' : ''
                        }
                      />
                    )}
                    renderOption={(option, { selected }) => {
                      return (
                        <Box display="flex" gridGap={4}>
                          <Checkbox
                            icon={<CheckboxDefault width={20} height={20} />}
                            checkedIcon={
                              <CheckboxActive width={20} height={20} />
                            }
                            checked={selected}
                            color="primry"
                            style={{ padding: 0, marginRight: 4 }}
                          />
                          <span>{option}</span>
                        </Box>
                      );
                    }}
                    renderTags={(value, getTagProps) => {
                      return (
                        <Box fontSize={16} ml={1}>
                          {value.join(', ')}
                        </Box>
                      );
                    }}
                    PaperComponent={({ children, ...other }) => (
                      <CustomMultiplePaper {...other}>
                        {children}
                      </CustomMultiplePaper>
                    )}
                  />
                </>
              )}

            {!isEmpty(purposeDetail) &&
              purposeDetail.purposeType === CLINIC_SERVICE_TYPE.INPUT_TEXT &&
              formValue.purposeAnswers.map((it, inx) => (
                <>
                  <CustomInputLabel style={{ marginTop: '20px' }}>
                    {it.title}
                  </CustomInputLabel>

                  <CustomTextField
                    rows={3}
                    multiline
                    fullWidth
                    variant="outlined"
                    placeholder={it.hintText}
                    value={it.answer || ''}
                    onChange={handleChangeForm(
                      'purposeData',
                      purposeDetail,
                      inx
                    )}
                  />
                </>
              ))}

            <CustomInputLabel style={{ marginTop: '20px' }}>
              Date
            </CustomInputLabel>
            <CustomDate
              selectedDate={formValue.date ? moment(formValue.date) : undefined}
              disablePast
              isFullWidth
              placeholder="Select date"
              clearable={false}
              onChange={handleChangeForm('date')}
              style={{ height: 48, fontSize: 16 }}
            />

            <CustomInputLabel style={{ marginTop: '20px' }}>
              Time slot
            </CustomInputLabel>

            <CustomAutocomplete
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) => option.value || ''}
              options={listTimeSlots || []}
              value={
                typeModal === TYPE_MODAL.Create
                  ? listTimeSlots?.find(
                      (it) => it?.value === formValue?.appointmentTime
                    ) || ''
                  : listTimeSlots?.find(
                      (it) => it?.value === formValue?.appointmentTime
                    ) || {
                      key: formValue?.appointmentTime,
                      value: formValue?.appointmentTime,
                    }
              }
              onChange={handleChangeForm('appointmentTime')}
              popupIcon={<ArrowDown />}
              renderInput={(params) => (
                <CustomTextField
                  variant="outlined"
                  placeholder="Select time slot"
                  {...params}
                />
              )}
              disableClearable
              PaperComponent={({ children, ...other }) => (
                <CustomPaper {...other}>{children}</CustomPaper>
              )}
            />
            {errors.appointmentTime && (
              <Typography color="error">{errors.appointmentTime}</Typography>
            )}
          </>
        )}
      </CustomDialogContent>

      <CustomDialogActions>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isEmptyParams}
        >
          {typeModal === TYPE_MODAL.Create ? 'Create' : 'Save'}
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

ModalAddOrEditClinicAppt.propTypes = {
  typeModal: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  selectedItem: PropTypes.object,
};

ModalAddOrEditClinicAppt.defaultProps = {
  typeModal: TYPE_MODAL.Create,
  open: false,
  onClose: () => console.warn('Not implemented yet'),
  onSubmit: () => console.warn('Not implemented yet'),
  selectedItem: null,
};

export default ModalAddOrEditClinicAppt;
