import { urlLabel } from '@/enum/PermissionEnum';
import AllScreenings from '@/module/all-screenings';
import CreateAppointment from '@/module/all-screenings/component/CreateAppointment';
import EditAppointment from '@/module/all-screenings/component/EditAppointment';
import EditPackage from '@/module/all-screenings/component/EditPackage';
import RescheduleAppointment from '@/module/all-screenings/component/RescheduleAppointment';
import ViewPatientRegistration from '@/module/all-screenings/component/ViewPatientRegistration';
import BackroomModule from '@/module/backroom-module';
import EditCompany from '@/module/company/component/EditCompany';
import Configuration from '@/module/configuration';
import OtherServices from '@/module/configuration/component/OtherServices';
import Packages from '@/module/configuration/component/Packages';
import Profiles from '@/module/configuration/component/Profiles';
import Consents from '@/module/consents';
import MotherhoodStatements from '@/module/mother-statement-management';
import OrderListExport from '@/module/order-list-export';
import TestManagement from '@/module/services/component/sub-module/test/TestManagement';
import CreateAndEditLocation from '@/module/setup/CreateAndEditLocation';
import Company from '@/module/setup/components/CompanyTable';
import LocationListPage from '@/module/setup/setup';
import SiteClosingReport from '@/module/site-closing-report';
import Stations from '@/module/station-management';
import LifestyleSurvey from '@/module/surveys';
import TimeSlotTemplate from '@/module/time-slot-template';
import UpcomingHealthScreening from '@/module/upcoming-health-screening';

export const healthScreeningRoutes = [
  {
    path: `/${urlLabel.upcomingHealthScreening}`,
    component: UpcomingHealthScreening,
    exact: true,
  },
  {
    path: `/${urlLabel.upcomingEditAppointment}`,
    component: EditAppointment,
    exact: true,
  },
  {
    path: `/${urlLabel.allScreenings}`,
    component: AllScreenings,
    exact: true,
  },
  {
    path: `/${urlLabel.aSEditAppointment}`,
    component: EditAppointment,
    exact: true,
  },
  {
    path: `/${urlLabel.aSRescheduleAppointment}`,
    component: RescheduleAppointment,
    exact: true,
  },
  {
    path: `/${urlLabel.aSviewPatientRegistration}`,
    component: ViewPatientRegistration,
    exact: true,
  },
  {
    path: `/${urlLabel.aSRegisterPatient}`,
    component: ViewPatientRegistration,
    exact: true,
  },
  {
    path: `/${urlLabel.aSScreeningProgress}`,
    component: ViewPatientRegistration,
    exact: true,
  },
  {
    path: `/${urlLabel.aSPatientRegistration}`,
    component: ViewPatientRegistration,
    exact: true,
  },
  {
    path: `/${urlLabel.aSCreateFollowUpAppointment}`,
    component: CreateAppointment,
    exact: true,
  },
  {
    path: `/${urlLabel.aSCreateAppointment}`,
    component: CreateAppointment,
    exact: true,
  },
  {
    path: `/${urlLabel.aSEditPackage}`,
    component: EditPackage,
    exact: true,
  },
  {
    path: `/${urlLabel.orderListExport}`,
    component: OrderListExport,
    exact: true,
  },
  {
    path: `/${urlLabel.siteClosingReport}`,
    component: SiteClosingReport,
    exact: true,
  },
  {
    path: `/${urlLabel.backroomModule}`,
    component: BackroomModule,
    exact: true,
  },
  {
    path: `/${urlLabel.locationManagement}`,
    component: LocationListPage,
    exact: true,
  },
  {
    path: `/${urlLabel.locationManagementCreate}`,
    component: CreateAndEditLocation,
    exact: true,
  },
  {
    path: `/${urlLabel.locationManagementEdit}`,
    component: CreateAndEditLocation,
    exact: true,
  },
  {
    path: `/${urlLabel.companyManagement}`,
    component: Company,
    exact: true,
  },
  {
    path: `/${urlLabel.editCompany}`,
    component: EditCompany,
    exact: true,
  },
  {
    path: `/${urlLabel.configuration}`,
    component: Configuration,
    exact: true,
  },
  {
    path: `/${urlLabel.configPackages}`,
    component: Packages,
    exact: true,
  },
  {
    path: `/${urlLabel.configProfiles}`,
    component: Profiles,
    exact: true,
  },
  {
    path: `/${urlLabel.configOtherServices}`,
    component: OtherServices,
    exact: true,
  },
  {
    path: `/${urlLabel.configTimeslotTemplate}`,
    component: TimeSlotTemplate,
    exact: true,
  },
  {
    path: `/${urlLabel.configStations}`,
    component: Stations,
    exact: true,
  },
  {
    path: `/${urlLabel.configLifestyleSurvey}`,
    component: LifestyleSurvey,
    exact: true,
  },
  {
    path: `/${urlLabel.configConsentForm}`,
    component: Consents,
    exact: true,
  },
  {
    path: `/${urlLabel.configMotherhood}`,
    component: MotherhoodStatements,
    exact: true,
  },
  {
    path: `/${urlLabel.testManagement}`,
    component: TestManagement,
    exact: true,
  },
];
