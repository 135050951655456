export const REQUIRED_FIELD = {
  mobile: [
    'SuperAdmin',
    'HsAdmin',
    'Doctor',
    'CA',
    'TeleFITSuperAdmin',
    'TeleFITAdmin',
    'Manager',
    'TeleFITStudio',
    'CSO',
    'MHS',
    'Staff',
    'LabAdmin',
  ],
  imageUrl: ['Doctor'],
  mcr: ['Doctor'],
  clinicId: ['Doctor'],
  praticisingCertificate: ['Doctor'],
  lstClinicId: ['CA'],
  lstCertificates: ['Trainer'],
};
