import { LABEL_COLORS } from '../CRReportHTML/constants';
import JsPdfExtended from './JsPdfExtended';
import DoughnutChart from './components/DoughnutChart';
import StackedBarChart from './components/StackedBarChart';
import { showLabelPlugins, textCenterPlugins } from './ultils';
import globalDispatcher from '@/redux/global/actions';
import '@/utils/formatExtensions';
import ChartDataLabels from '@energiency/chartjs-plugin-piechart-outlabels';
import { Box } from '@material-ui/core';
import React, { memo, useEffect, useMemo, useState } from 'react';

// Import it once to apply globally

const CRReportPDF = memo(({ corporateData }) => {
  const [imageAgeChart, setImageAgeChart] = useState(null);
  const [imageAttendanceChart, setImageAttendanceChart] = useState(null);
  const [imageBloodPressureChart, setImageBloodPressureChart] = useState(null);
  const [questionnairesCharts, setQuestionnairesCharts] = useState([]); // Store multiple charts

  const [imageBMIChart, setImageBMIChart] = useState(null);
  const [imageTCChart, setImageTCChart] = useState(null);
  const [imageDirectLDLChart, setImageDirectLDLChart] = useState(null);
  const [imageHDLChart, setImageHDLChart] = useState(null);
  const [imageTriglycerideChart, setImageTriglycerideChart] = useState(null);
  const [imageRandomGlucoseChart, setImageRandomGlucoseChart] = useState(null);
  const [listRandomColors, setListRandomColors] = useState([]);
  const groupByFirstEncounter = (data, key) => {
    return data.reduce((acc, item) => {
      const category = item[key];
      if (!acc.hasOwnProperty(category)) {
        acc[category] = []; // Maintain order by first encounter
      }
      acc[category].push(item);
      return acc;
    }, {});
  };

  let filteQuestionnaires =
    corporateData?.questionaires?.filter((q) => q.answerType !== 'FreeText') ||
    [];

  const dataByCategory = groupByFirstEncounter(filteQuestionnaires, 'category');
  const categories = Object.keys(dataByCategory);
  let sortedQuestionairesData = [];

  categories.forEach((category) => {
    const questions = dataByCategory[category];
    questions.forEach((question) => {
      sortedQuestionairesData.push(question);
    });
  });

  filteQuestionnaires = sortedQuestionairesData;

  const generateRandomColors = (length) => {
    const colors = [];
    for (let i = 0; i < length; i++) {
      // Tạo màu ngẫu nhiên theo định dạng hex (#RRGGBB)
      const color = `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, '0')}`;
      colors.push(color);
    }
    return colors;
  };
  const listCharts = useMemo(() => {
    let tempQuestionaires = new Array(filteQuestionnaires.length).fill(null);

    const questionnaireChartsList = filteQuestionnaires.map((q, index) => {
      return {
        id: `questionnaire-chart-${index}`,
        setImage: (image) => {
          tempQuestionaires[index] = image; // Store image in temporary array
          if (tempQuestionaires.every((img) => img !== null)) {
            setQuestionnairesCharts([...tempQuestionaires]);
          }
        },
        chartData: () => q.answers.map((it) => it.percent.formatPercentValue()),
        backgroundColor: LABEL_COLORS,
        chartPlugins: [ChartDataLabels],
      };
    });

    return [
      {
        id: 'age-chart',
        setImage: setImageAgeChart,
        chartData: () => {
          return corporateData?.overView?.ageGroups
            .filter((it) => it.title !== 'Total')
            .map((it) => it.total);
        },
        backgroundColor: [
          'rgba(255, 233, 251, 1)',
          'rgba(255, 188, 183, 1)',
          'rgba(190, 238, 199, 1)',
          'rgba(245, 197, 213, 1)',
          'rgba(249, 225, 161, 1)',
          'rgba(232, 222, 255, 1)',
        ],
        chartPlugins: [textCenterPlugins, showLabelPlugins],
      },
      {
        id: 'blood-pressure-chart',
        setImage: setImageBloodPressureChart,
        chartData: () => {
          return corporateData?.healthScreenings?.bloodPressure?.categories?.map(
            (it) => it.percent.formatPercentValue()
          );
        },
        backgroundColor: [
          'rgba(190, 238, 199, 1)',
          'rgba(249, 225, 161, 1)',
          'rgba(255, 188, 183, 1)',
          'rgba(255, 105, 94, 1)',
          'rgba(232, 222, 255, 1)',
        ],
        chartPlugins: [ChartDataLabels],
      },
      {
        id: 'bmi-chart',
        setImage: setImageBMIChart,
        chartData: () => {
          return corporateData?.healthScreenings?.bmi?.categories?.map((it) =>
            it.percent.formatPercentValue()
          );
        },
        backgroundColor: [
          'rgba(249, 225, 161, 1)',
          'rgba(190, 238, 199, 1)',
          'rgba(255, 208, 183, 1)',
          'rgba(255, 188, 183, 1)',
        ],
        chartPlugins: [ChartDataLabels],
      },
      {
        id: 'tc-chart',
        setImage: setImageTCChart,
        chartData: () => {
          return corporateData?.healthScreenings?.tc?.categories?.map((it) =>
            it.percent.formatPercentValue()
          );
        },
        backgroundColor: [
          'rgba(190, 238, 199, 1)',
          'rgba(249, 225, 161, 1)',
          'rgba(255, 188, 183, 1)',
        ],
        chartPlugins: [ChartDataLabels],
      },
      {
        id: 'direct-ldl-chart',
        setImage: setImageDirectLDLChart,
        chartData: () => {
          return corporateData?.healthScreenings?.ldl?.categories?.map((it) =>
            it.percent.formatPercentValue()
          );
        },
        backgroundColor: [
          'rgba(22, 164, 64, 1)',
          'rgba(190, 238, 199, 1)',
          'rgba(255, 224, 176, 1)',
          'rgba(255, 188, 183, 1)',
          'rgba(219, 61, 73, 1)',
        ],
        chartPlugins: [ChartDataLabels],
      },
      {
        id: 'hdl-chart',
        setImage: setImageHDLChart,
        chartData: () => {
          return corporateData?.healthScreenings?.hdl?.categories?.map((it) =>
            it.percent.formatPercentValue()
          );
        },
        backgroundColor: [
          'rgba(190, 238, 199, 1)',
          'rgba(249, 225, 161, 1)',
          'rgba(255, 188, 183, 1)',
        ],
        chartPlugins: [ChartDataLabels],
      },
      {
        id: 'triglyceride-chart',
        setImage: setImageTriglycerideChart,
        chartData: () => {
          return corporateData?.healthScreenings?.triglyceride?.categories?.map(
            (it) => it.percent.formatPercentValue()
          );
        },
        backgroundColor: [
          'rgba(190, 238, 199, 1)',
          'rgba(249, 225, 161, 1)',
          'rgba(255, 188, 183, 1)',
          'rgba(219, 61, 73, 1)',
        ],
        chartPlugins: [ChartDataLabels],
      },
      {
        id: 'random-glucose-chart',
        setImage: setImageRandomGlucoseChart,
        chartData: () => {
          return corporateData?.healthScreenings?.randomGlucose?.categories?.map(
            (it) => it.percent.formatPercentValue()
          );
        },
        backgroundColor: [
          'rgba(249, 225, 161, 1)',
          'rgba(190, 238, 199, 1)',
          'rgba(255, 188, 183, 1)',
        ],
        chartPlugins: [ChartDataLabels],
      },
      // ...physicalActivityChartsList,
      // ...dietaryHabitsChartsList,
      ...questionnaireChartsList,
    ];
  }, [corporateData, listRandomColors]);

  useEffect(() => {
    if (
      imageAgeChart &&
      imageAttendanceChart &&
      corporateData &&
      questionnairesCharts.length === filteQuestionnaires.length
    ) {
      var _doc = new JsPdfExtended();
      _doc
        .addFontCover(corporateData)
        .addQuickLook(corporateData, imageAgeChart)
        .addAttendance(corporateData, imageAttendanceChart)
        .addBloodPressure(corporateData, imageBloodPressureChart)
        .addBloodPressureTrending(corporateData)
        .addBMI(corporateData, imageBMIChart)
        .addBMITrending(corporateData)
        .addTotalCholesterol(corporateData, imageTCChart)
        .addDirectLDL(corporateData, imageDirectLDLChart)
        .addHDL(corporateData, imageHDLChart)
        .addTriglyceride(corporateData, imageTriglycerideChart)
        .addTriglycerideTrending(corporateData)
        .addRandomGlucose(corporateData, imageRandomGlucoseChart)

        .addLifestyleQuestionaire(
          corporateData,
          questionnairesCharts,
          LABEL_COLORS
        );

      _doc.addPagingAll().addTableContents();
      globalDispatcher.saveDataSuccess('pdfDoc', _doc);
    }
  }, [
    corporateData,
    imageAgeChart,
    imageAttendanceChart,
    imageBloodPressureChart,
    imageBMIChart,
    imageTCChart,
    imageDirectLDLChart,
    imageHDLChart,
    imageTriglycerideChart,
    imageRandomGlucoseChart,
    questionnairesCharts,
    listRandomColors,
  ]);

  useEffect(() => {
    setListRandomColors(generateRandomColors(100));
  }, []);

  const onDone = (setChart) => (event) => setChart(event.chart);

  return (
    <>
      {listCharts?.map((it) => (
        <Box
          visibility={'hidden'}
          id={it.id}
          width={it.id !== 'age-chart' ? 400 : 148}
          height={it.id !== 'age-chart' ? 200 : 148}
        >
          <DoughnutChart
            chartData={it.chartData()}
            chartPlugins={it.chartPlugins}
            onDone={onDone(it.setImage)}
            backgroundColor={it.backgroundColor}
            isPDF={it.id !== 'age-chart'}
          />
        </Box>
      ))}

      <Box visibility={'hidden'} width={435} height={200}>
        <StackedBarChart
          chartData={corporateData}
          onDone={onDone(setImageAttendanceChart)}
        />
      </Box>
    </>
  );
});

export default CRReportPDF;
