import {
  LIST_TABLE_CONTENTS_LABEL_DISPLAY,
  RANDOM_GLUCOSE_TABLE,
} from '../../constants';
import LegendsOfChartCom from '../common/LegendsOfChartCom';
import PageFooter from '../common/PageFooter';
import CRPreviewSection from '../common/Section';
import TableCom from '../common/TableCom';
import bgCover from '@/assets/image/pdfexport/bg-cover.png';
import DoughnutChart from '@/new-components/CRReportPDF/components/DoughnutChart';
import ChartDataLabels from '@energiency/chartjs-plugin-piechart-outlabels';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';

const CustomContainer = withStyles({
  root: {
    padding: '0 13.44vw',
    height: 'calc(100vw*1.4)',
    width: '100vw',
    backgroundImage: `url(${bgCover})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingTop: '10.75vw',
  },
})(Box);
const Title = withStyles({
  root: {
    marginTop: '2.68vw',
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '3.36vw',
    lineHeight: '4.7vw',
  },
})(Typography);

const Item = withStyles({
  root: {
    paddingLeft: '2.69vw',
    borderRight: '1px solid #CCCCCC',
    fontFamily: 'OpenSans',

    '& .title': {
      color: '#666',
      fontWeight: 600,
      fontSize: '1.68vw',
    },

    '& .value': {
      fontFamily: 'Lato',
      marginTop: '0.67vw',
      color: '#333',
      fontWeight: 600,
      fontSize: '2.69vw',
      lineHeight: '4.03vw',
    },
    '& .subValue': {
      fontFamily: 'OpenSans',
      marginLeft: '1.34vw',
      color: '#333',
      fontWeight: 600,
      fontSize: '2.01vw',
      lineHeight: '3.02vw',
    },

    '&:last-child': {
      borderRight: 'none',
    },
  },
})(Grid);

const HSRandomGlucose = ({ corporateData, addPaging, totalLifeStylePage }) => {
  const randomGlucose = get(
    corporateData,
    'healthScreenings.randomGlucose.categories'
  );

  const trendings = corporateData?.healthScreenings?.randomGlucose?.trendings;

  const textTrending = () => {
    if (trendings?.length >= 2) {
      let latestYears = trendings?.sort((a, b) => b.year - a.year).slice(0, 2);
      const percentLastYear = latestYears[1].percent;
      const percentThisYear = latestYears[0].percent;
      const lastYear = latestYears[1].year;
      if (percentLastYear === percentThisYear) {
        return 'There are increased cases by 0%.';
      } else if (percentThisYear > percentLastYear) {
        return `There are increased cases by ${Math.abs(
          percentThisYear?.formatPercentValue() -
            percentLastYear?.formatPercentValue()
        )?.formatPercentValue()}%.`;
      } else {
        return `GREAT, we have improved by ${Math.abs(
          percentThisYear?.formatPercentValue() -
            percentLastYear?.formatPercentValue()
        )?.formatPercentValue()}% since ${lastYear}!`;
      }
    } else {
      return 'There are increased cases by 0%.';
    }
  };

  const chartData = useMemo(() => {
    return corporateData?.healthScreenings?.randomGlucose?.categories?.map(
      (it) => it.percent.formatPercentValue()
    );
  }, [randomGlucose]);

  const backgroundColor = ['#f9e1a1', '#beeec7', '#ffbcb7'];

  const legends = [
    {
      color: '#f9e1a1',
      title: 'Low',
    },
    {
      color: '#beeec7',
      title: 'Normal',
    },
    {
      color: '#ffbcb7',
      title: 'High',
    },
  ];

  const chartPlugins = [ChartDataLabels];

  const dataTable = useMemo(() => {
    if (isEmpty(randomGlucose)) return [];
    const table = [...RANDOM_GLUCOSE_TABLE];
    const dataCutOffs = randomGlucose?.map((it, inx) => {
      return {
        color: '#F5F5F5',
        value: `${it.cutOffs}`,
        width: table[0][inx + 1]?.width,
        height: 24,
      };
    });

    const dataTotal = randomGlucose?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.total}`,
      width: table[0][inx + 1]?.width,
      height: 24,
    }));
    const dataPercent = randomGlucose?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.percent.formatPercentValue()}%`,
      width: table[0][inx + 1]?.width,
      height: 24,
    }));
    let header = [];
    header.push(table[0][0]);
    header = header.concat(
      randomGlucose?.map((it, inx) => ({
        color: '#E0E0E0',
        value: it.title,
        width: table[0][inx + 1]?.width,
        height: table[0][inx + 1]?.height,
      }))
    );

    table[0] = table[0].map((it, inx) => ({
      ...header[inx],
    }));

    table[1] = table[1].concat(dataCutOffs);
    table[2] = table[2].concat(dataTotal);
    table[3] = table[3].concat(dataPercent);
    return table;
  }, [randomGlucose]);

  const renderDatas = useMemo(() => {
    let datas = trendings
      ?.sort((a, b) => a.year - b.year)
      .map((it) => ({
        value: `${it.percent?.formatPercentValue()}%`,
        subValue: ` (${it.total} pax)`,
        title: `${it.year}`,
      }));
    return datas;
  }, [corporateData]);

  let CONTENTS = [
    {
      boldText: '1. Cholesterol Management workshops',
      text: `will allow staff to learn that the key to achieving a healthy lipid profile is to choose healthy fats and exercise their way to a healthier heart.`,
    },
    {
      boldText: '2. Cholesterol Targeted Intervention Programme',
      text: `is most suitable for those wanting to achieve a healthy lipid profile but need the right knowledge and skills. In this programme, they can look forward to learning practical tips to apply in their daily food choices and the benefits of regular physical activity.`,
    },
    {
      boldText: '3. Physical Activities',
      text: `(e.g. Kickboxing, Aerobics) boosts health and in particular to HDLs which have protective effects to heart health. Workplace exercise sessions can support our staff in achieving adequate physical activity and move their way to a healthier heart.`,
    },
  ];

  useEffect(() => {
    addPaging(LIST_TABLE_CONTENTS_LABEL_DISPLAY.HealthScreeningFindings);
  }, []);

  return (
    <>
      <CustomContainer>
        <Box display="flex" flexDirection="column" minHeight="100%">
          <Box>
            <CRPreviewSection title="Random Glucose" />
          </Box>

          <Box
            fontFamily="OpenSans"
            mt="4.03vw"
            fontSize="2.01vw"
            lineHeight="3.02vw"
          >
            <p>
              A high sugar reading could be an indication that the body does not
              make or utilize insulin well resulting in excess sugar in the
              blood. This condition is termed as Diabetes Mellitus where over
              time could give rise to problems associated with infections,
              blindness, cardiovascular diseases and disorders of the kidney,
              leg and foot.
            </p>

            <p>
              To date, Type 1 Diabetes Mellitus is unpreventable. Type 2
              Diabetes, associated with being overweight, can be prevented.
            </p>
          </Box>

          <Box display="flex" alignItems="center" gridGap={'12vw'} mt="6.72vw">
            <Box width="30vw" height="25vw">
              <DoughnutChart
                chartData={chartData}
                chartPlugins={chartPlugins}
                backgroundColor={backgroundColor}
                animationDuration={400}
                isLabelOutside
              />
            </Box>
            <Box>
              <LegendsOfChartCom data={legends} />
            </Box>
          </Box>

          <Box mt="6.72vw">
            <TableCom data={dataTable} />
          </Box>

          {corporateData?.healthScreenings?.randomGlucose?.trendings && (
            <>
              <Title>Trending: High Random Blood Glucose Readings</Title>
              <Box
                fontFamily="OpenSans"
                mt="2.68vw"
                fontSize="2.01vw"
                lineHeight="3.02vw"
              >
                {textTrending()}
              </Box>

              <Box
                mt="2.68vw"
                display="flex"
                bgcolor="#E2F2FF"
                width="73.1vw"
                height="11.42vw"
                borderRadius="1.68vw"
                py="2.01vw"
              >
                <Grid container>
                  {renderDatas?.map((it, inx) => (
                    <Item
                      item
                      xs={renderDatas?.length >= 3 ? 4 : 6}
                      key={`${it.title}-${inx}`}
                    >
                      <div className="title">{it.title}</div>
                      <div className="value">
                        {it.value}

                        <span className="subValue">{it.subValue}</span>
                      </div>
                    </Item>
                  ))}
                </Grid>
              </Box>
            </>
          )}
          <PageFooter
            page={'10'}
            totalPages={totalLifeStylePage + 13}
            title={LIST_TABLE_CONTENTS_LABEL_DISPLAY.HealthScreeningFindings}
          />
        </Box>
      </CustomContainer>
      {/*Trending*/}
      {corporateData?.healthScreenings?.triglyceride?.trending && (
        <CustomContainer>
          <Box display="flex" flexDirection="column" minHeight="100%">
            <Box
              fontFamily="OpenSans"
              mt="2.68vw"
              fontSize="2.67vw"
              lineHeight="4.03vw"
              fontWeight={600}
            >
              How do we maintain a healthy trend?
            </Box>

            {CONTENTS.map((it, inx) => (
              <Box
                fontFamily="OpenSans"
                mt="4.03vw"
                fontSize="2.01vw"
                lineHeight="3.02vw"
                key={`content-${inx}`}
              >
                <Box component="span" fontWeight={600}>
                  {it.boldText}
                </Box>{' '}
                {it.text}
              </Box>
            ))}
            <PageFooter
              page={'11'}
              totalPages={totalLifeStylePage + 13}
              title={LIST_TABLE_CONTENTS_LABEL_DISPLAY.HealthScreeningFindings}
            />
          </Box>
        </CustomContainer>
      )}
    </>
  );
};

export default HSRandomGlucose;
