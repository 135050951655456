export const textCenterPlugins = {
  id: 'textCenter',
  beforeDraw: (chart, args, opts) => {
    const { ctx, chartArea } = chart;
    const { width, height } = chartArea;

    ctx.restore();
    ctx.fillStyle = '#AD5E99';
    ctx.font = 'bold 24px Lato';
    ctx.textBaseline = 'top';
    ctx.textAlign = 'center';

    ctx.fillText(chart.getDatasetMeta(0).total, width / 2, height / 3.2);
    ctx.font = '10px OpenSans';
    ctx.fillText('attended', width / 2, height / 1.8);
    ctx.save();
  },
};

export const showLabelPlugins = {
  id: 'showLabel',
  afterDatasetsDraw: (chart, args, opts) => {
    const { ctx, data } = chart;

    ctx.restore();
    data.datasets[0].data.forEach((dpoint, inx) => {
      if (dpoint !== 0) {
        const xCoor = chart.getDatasetMeta(0).data[inx].tooltipPosition().x;
        const yCoor = chart.getDatasetMeta(0).data[inx].tooltipPosition().y;
        ctx.font = '8px Lato';
        ctx.fillStyle = '#000';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
        ctx.fillText(dpoint, xCoor, yCoor);
      }
    });
    ctx.save();
  },
};

export const showLabelOutsidePlugins = {
  id: 'showLabelOutside',
  afterDraw: (chart, args, opts) => {
    const { ctx, data, chartArea } = chart;

    const { width, height } = chartArea;

    ctx.restore();

    data.datasets[0].data.forEach((dpoint, inx) => {
      const number = Number(dpoint);
      if (number !== 0 || number !== 0.0) {
        const xCoor = chart.getDatasetMeta(0).data[inx].tooltipPosition().x;
        const yCoor = chart.getDatasetMeta(0).data[inx].tooltipPosition().y;
        const halfWidth = width / 2;
        const halfHeight = height / 2;
        const x = xCoor >= halfWidth ? xCoor + 60 : xCoor - 70;
        const y = yCoor >= halfHeight ? yCoor + 42 : yCoor - 42;

        ctx.font = '500 1.5vw Lato ';
        ctx.fillStyle = '#000';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
        ctx.fillText(dpoint + '%', x, y);
      }
    });
    ctx.save();
  },
};

export const showLabelResponsivePlugins = {
  id: 'showLabelResponsive',
  afterDatasetsDraw: (chart, args, opts) => {
    const { ctx, data } = chart;

    ctx.restore();
    data.datasets[0].data.forEach((dpoint, inx) => {
      if (dpoint !== 0) {
        const xCoor = chart.getDatasetMeta(0).data[inx].tooltipPosition().x;
        const yCoor = chart.getDatasetMeta(0).data[inx].tooltipPosition().y;
        ctx.font = '1.43vw Lato';
        ctx.fillStyle = '#000';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
        ctx.fillText(dpoint, xCoor, yCoor);
      }
    });
    ctx.save();
  },
};

export const textCenterResponsivePlugins = {
  id: 'textCenter',
  beforeDraw: (chart, args, opts) => {
    const { ctx, chartArea } = chart;
    const { width, height } = chartArea;

    ctx.restore();
    ctx.fillStyle = '#AD5E99';
    ctx.font = 'bold 4.03vw Lato';
    ctx.textBaseline = 'top';
    ctx.textAlign = 'center';

    ctx.fillText(chart.getDatasetMeta(0).total, width / 2, height / 3.2);
    ctx.font = '1.68vw OpenSans';
    ctx.fillText('attended', width / 2, height / 1.8);
    ctx.save();
  },
};

export const barChartLabelResponsivePlugins = [
  {
    id: 'chartLabelInsideResponsive',
    afterDatasetsDraw: (chart, args, opts) => {
      const { ctx, data } = chart;

      ctx.restore();
      data.datasets[0].data.forEach((dpoint, inx) => {
        if (dpoint === '0.0') {
          const xCoor = chart.getDatasetMeta(0).data[inx].tooltipPosition().x;
          const yCoor =
            chart.getDatasetMeta(0).data[inx].tooltipPosition().y - 7;
          ctx.font = '16px Lato';
          ctx.fillStyle = '#000';
          ctx.textBaseline = 'middle';
          ctx.textAlign = 'center';
          ctx.fillText(dpoint + '%', xCoor, yCoor);
        }
      });

      ctx.save();
    },
  },
];

export const barChartLabelPlugins = [
  {
    id: 'chartLabelInside',
    afterDatasetsDraw: (chart, args, opts) => {
      const { ctx, data } = chart;

      ctx.restore();
      data.datasets[0].data.forEach((dpoint, inx) => {
        if (dpoint === '0.0') {
          const xCoor = chart.getDatasetMeta(0).data[inx].tooltipPosition().x;
          const yCoor =
            chart.getDatasetMeta(0).data[inx].tooltipPosition().y - 5;
          ctx.font = '8px Lato';
          ctx.fillStyle = '#000';
          ctx.textBaseline = 'middle';
          ctx.textAlign = 'center';
          ctx.fillText(dpoint + '%', xCoor, yCoor);
        }
      });

      ctx.save();
    },
  },
];
