import { LIST_TABLE_CONTENTS_LABEL_DISPLAY, HDL_TABLE } from '../../constants';
import LegendsOfChartCom from '../common/LegendsOfChartCom';
import PageFooter from '../common/PageFooter';
import CRPreviewSection from '../common/Section';
import TableCom from '../common/TableCom';
import bgCover from '@/assets/image/pdfexport/bg-cover.png';
import DoughnutChart from '@/new-components/CRReportPDF/components/DoughnutChart';
import ChartDataLabels from '@energiency/chartjs-plugin-piechart-outlabels';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';

const CustomContainer = withStyles({
  root: {
    padding: '0 13.44vw',
    height: 'calc(100vw*1.4)',
    width: '100vw',
    backgroundImage: `url(${bgCover})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingTop: '10.75vw',
  },
})(Box);
const Title = withStyles({
  root: {
    marginTop: '2.68vw',
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '3.36vw',
    lineHeight: '4.7vw',
  },
})(Typography);

const Item = withStyles({
  root: {
    paddingLeft: '2.69vw',
    borderRight: '1px solid #CCCCCC',
    fontFamily: 'OpenSans',

    '& .title': {
      color: '#666',
      fontWeight: 600,
      fontSize: '1.68vw',
    },

    '& .value': {
      fontFamily: 'Lato',
      marginTop: '0.67vw',
      color: '#333',
      fontWeight: 600,
      fontSize: '2.69vw',
      lineHeight: '4.03vw',
    },
    '& .subValue': {
      fontFamily: 'OpenSans',
      marginLeft: '1.34vw',
      color: '#333',
      fontWeight: 600,
      fontSize: '2.01vw',
      lineHeight: '3.02vw',
    },

    '&:last-child': {
      borderRight: 'none',
    },
  },
})(Grid);

const HSLowHDL = ({ corporateData, addPaging, totalLifeStylePage }) => {
  const hdl = get(corporateData, 'healthScreenings.hdl.categories');
  const lowPercent = hdl.find((it) => it.title === 'Low').percent;

  const trendings = corporateData?.healthScreenings?.hdl?.trendings;

  const textTrending = () => {
    if (trendings?.length >= 2) {
      let latestYears = trendings?.sort((a, b) => b.year - a.year).slice(0, 2);
      const percentLastYear = latestYears[1].percent;
      const percentThisYear = latestYears[0].percent;
      const lastYear = latestYears[1].year;
      if (percentLastYear === percentThisYear) {
        return 'There are increased cases by 0%.';
      } else if (percentThisYear > percentLastYear) {
        return `There are increased cases by ${Math.abs(
          percentThisYear?.formatPercentValue() -
            percentLastYear?.formatPercentValue()
        )?.formatPercentValue()}%.`;
      } else {
        return `GREAT, we have improved by ${Math.abs(
          percentThisYear?.formatPercentValue() -
            percentLastYear?.formatPercentValue()
        )?.formatPercentValue()}% since ${lastYear}!`;
      }
    } else {
      return 'There are increased cases by 0%.';
    }
  };

  const chartData = useMemo(() => {
    return corporateData?.healthScreenings?.hdl?.categories?.map((it) =>
      it.percent.formatPercentValue()
    );
  }, [hdl]);

  const backgroundColor = [
    'rgba(190, 238, 199, 1)',
    'rgba(249, 225, 161, 1)',
    'rgba(255, 188, 183, 1)',
  ];

  const legends = [
    {
      color: '#BEEEC7',
      title: 'Optimal',
    },
    {
      color: '#F9E1A1',
      title: 'Desirable',
    },
    {
      color: '#FFBCB7',
      title: 'Low',
    },
  ];

  const chartPlugins = [ChartDataLabels];

  const dataTable = useMemo(() => {
    if (isEmpty(hdl)) return [];
    const table = [...HDL_TABLE];

    const dataCutOffs = hdl?.map((it, inx) => {
      return {
        color: '#F5F5F5',
        value: `${it.cutOffs}`,
        width: table[0][inx + 1]?.width,
        height: 24,
      };
    });

    const dataTotal = hdl?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.total}`,
      width: table[0][inx + 1]?.width,
      height: 24,
    }));
    const dataPercent = hdl?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.percent.formatPercentValue()}%`,
      width: table[0][inx + 1]?.width,
      height: 24,
    }));

    let header = [];
    header.push(table[0][0]);
    header = header.concat(
      hdl?.map((it, inx) => ({
        color: '#E0E0E0',
        value: it.title,
        width: table[0][inx + 1]?.width,
        height: table[0][inx + 1]?.height,
      }))
    );

    table[0] = table[0].map((it, inx) => ({
      ...header[inx],
    }));

    table[1] = table[1].concat(dataCutOffs);
    table[2] = table[2].concat(dataTotal);
    table[3] = table[3].concat(dataPercent);

    return table;
  }, [hdl]);

  const renderDatas = useMemo(() => {
    let datas = trendings
      ?.sort((a, b) => a.year - b.year)
      .map((it) => ({
        value: `${it.percent?.formatPercentValue()}%`,
        subValue: ` (${it.total} pax)`,
        title: `${it.year}`,
      }));
    return datas;
  }, [corporateData]);

  useEffect(() => {
    addPaging(LIST_TABLE_CONTENTS_LABEL_DISPLAY.HealthScreeningFindings);
  }, []);

  return (
    <>
      <CustomContainer>
        <Box display="flex" flexDirection="column" minHeight="100%">
          <Box>
            <CRPreviewSection title="High Density Lipoprotein (HDL)" />
          </Box>

          <Box
            fontFamily="OpenSans"
            mt="3.03vw"
            fontSize="2.01vw"
            lineHeight="3.02vw"
          >
            <p>
              Lipoproteins are carriers of cholesterol in the blood. HDL is
              protective against cardiovascular diseases as they can remove
              excess cholesterol from the body and reduce the risk of build-up
              of cholesterol in the arteries.
            </p>
          </Box>

          <Box display="flex" alignItems="center" gridGap={'12vw'} mt="3.72vw">
            <Box width="30vw" height="25vw">
              <DoughnutChart
                chartData={chartData}
                chartPlugins={chartPlugins}
                backgroundColor={backgroundColor}
                animationDuration={400}
                isLabelOutside
              />
            </Box>
            <Box>
              <LegendsOfChartCom data={legends} />
            </Box>
          </Box>

          <Box
            fontFamily="OpenSans"
            mt="6.72vw"
            fontSize="2.01vw"
            lineHeight="3.02vw"
          >
            {lowPercent.formatPercentValue()}% has low level of HDL.
          </Box>

          <Box mt="4.72vw">
            <TableCom data={dataTable} />
          </Box>
          {corporateData?.healthScreenings?.hdl?.trendings && (
            <>
              <Title>Trending: Low HDL Readings</Title>
              <Box
                fontFamily="OpenSans"
                mt="2vw"
                fontSize="2.01vw"
                lineHeight="3.02vw"
              >
                {textTrending()}
              </Box>
              <Box
                mt="2vw"
                display="flex"
                bgcolor="#E2F2FF"
                width="73.1vw"
                height="11.42vw"
                borderRadius="1.68vw"
                py="2.01vw"
              >
                <Grid container>
                  {renderDatas?.map((it, inx) => (
                    <Item
                      item
                      xs={renderDatas?.length >= 3 ? 4 : 6}
                      key={`${it.title}-${inx}`}
                    >
                      <div className="title">{it.title}</div>
                      <div className="value">
                        {it.value}

                        <span className="subValue">{it.subValue}</span>
                      </div>
                    </Item>
                  ))}
                </Grid>
              </Box>
            </>
          )}
          <PageFooter
            page={'09'}
            totalPages={totalLifeStylePage + 13}
            title={LIST_TABLE_CONTENTS_LABEL_DISPLAY.HealthScreeningFindings}
          />
        </Box>
      </CustomContainer>
    </>
  );
};

export default HSLowHDL;
