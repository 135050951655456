import { urlLabel } from '@/enum/PermissionEnum';
import { AppointmentManagement } from '@/module/appointment-management';
import BookingManagement from '@/module/booking-management';
import CAConsult from '@/module/ca-consult/component/caConsult';
import CAManagement from '@/module/ca/component/ca';
import { DoctorManagement } from '@/module/doctor';
import MeetingHistory from '@/module/meeting-history/component/MeetingHistory';
import MHSManagement from '@/module/mhs-management';
import { PatientListPage } from '@/module/patient';
import Promotion from '@/module/promotion';
import TeleconsultAnalytics from '@/module/teleconsult-analytics';
import { WaitingRoom } from '@/module/waiting-room';

export const teleconsultRoutes = [
  {
    path: `/${urlLabel.doctorManagement}`,
    component: DoctorManagement,
    exact: true,
  },
  {
    path: `/${urlLabel.patientManagement}`,
    component: PatientListPage,
    exact: true,
  },
  {
    path: `/${urlLabel.caManagement}`,
    component: CAManagement,
    exact: true,
  },
  {
    path: `/${urlLabel.waitingRoom}`,
    component: WaitingRoom,
    exact: true,
  },
  {
    path: `/${urlLabel.consultList}`,
    component: CAConsult,
    exact: true,
  },
  {
    path: `/${urlLabel.meetingHistory}`,
    component: MeetingHistory,
    exact: true,
  },
  {
    path: `/${urlLabel.bookingManagement}`,
    component: BookingManagement,
    exact: true,
  },
  {
    path: `/${urlLabel.appointmentManagement}`,
    component: AppointmentManagement,
    exact: true,
  },
  {
    path: `/${urlLabel.mhsManagement}`,
    component: MHSManagement,
    exact: true,
  },
  {
    path: `/${urlLabel.teleconsultAnalytics}`,
    component: TeleconsultAnalytics,
    exact: true,
  },
  {
    path: `/${urlLabel.promotion}`,
    component: Promotion,
    exact: true,
  },
];
