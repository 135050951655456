import {
  ATTENDANCE_TABLE,
  LIST_TABLE_CONTENTS_LABEL_DISPLAY,
} from '../../constants';
import LegendsOfChartCom from '../common/LegendsOfChartCom';
import PageFooter from '../common/PageFooter';
import TableCom from '../common/TableCom';
import bgCover from '@/assets/image/pdfexport/bg-cover.png';
import StackedBarChart from '@/new-components/CRReportPDF/components/StackedBarChart';
import { barChartLabelResponsivePlugins } from '@/new-components/CRReportPDF/ultils';
import { Box, Typography, withStyles } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';

const CustomContainer = withStyles({
  root: {
    padding: '0 13.44vw',
    height: 'calc(100vw*1.4)',
    width: '100vw',
    backgroundImage: `url(${bgCover})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',

    paddingTop: '10.75vw',
  },
})(Box);
const Title = withStyles({
  root: {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '6.05vw',
    lineHeight: '4.7vw',
  },
})(Typography);

const Attendance = ({ corporateData, addPaging, totalLifeStylePage }) => {
  const totalAttended = get(corporateData, 'overView.totalAttended');

  const totalFemalePercent = get(corporateData, 'overView.totalFemalePercent');

  const totalMalePercent = get(corporateData, 'overView.totalMalePercent');

  const dataAttendance = useMemo(() => {
    if (isEmpty(corporateData?.overView.ageGroups)) return [];
    const ageGroups = get(corporateData, 'overView.ageGroups');

    const table = [...ATTENDANCE_TABLE];
    const dataFemaleTotal = ageGroups.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.femaleTotal}`,
      width: table[0][inx + 1]?.width,
      height: table[0][inx + 1]?.height,
    }));

    const dataMaleTotal = ageGroups?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.maleTotal}`,
      width: table[0][inx + 1]?.width,
      height: table[0][inx + 1]?.height,
    }));

    const dataTotal = ageGroups?.map((it, inx) => ({
      color: '#F5F5F5',
      value: `${it.total}`,
      width: table[0][inx + 1]?.width,
      height: table[0][inx + 1]?.height,
    }));

    table[1] = table[1].concat(dataFemaleTotal);
    table[2] = table[2].concat(dataMaleTotal);
    table[3] = table[3].concat(dataTotal);

    return table;
  }, [corporateData]);

  const legends = [
    {
      color: '#F5C5D5',
      title: 'Female',
    },
    {
      color: '#E2F2FF',
      title: 'Male',
    },
  ];
  useEffect(() => {
    addPaging(LIST_TABLE_CONTENTS_LABEL_DISPLAY.Attendance);
  }, []);

  return (
    <CustomContainer>
      <Box display="flex" flexDirection="column" minHeight="100%">
        <Title>Attendance</Title>

        <Box
          mt="6.72vw"
          bgcolor="#F5F5F5"
          borderRadius="1.68vw"
          width="100%"
          height="10.42vw"
          color="#666"
          fontSize="1.68vw"
          fontWeight="600"
          fontFamily="OpenSans"
          px="2.68vw"
          py="2.18vw"
        >
          <Box display="flex" gridGap="4.37vw">
            Total Attendance
            <Box color="#333">{totalAttended}</Box>
          </Box>
          <Box mt="1vw">{`${totalFemalePercent.formatPercentValue()}% attended are female; ${totalMalePercent.formatPercentValue()}% attended are male.`}</Box>
        </Box>

        <Box mt="6.72vw" width={'73.1vw'} height={'33.61vw'}>
          <StackedBarChart
            chartData={corporateData}
            animationDuration={0}
            borderRadius={10}
            barThickness={70}
            fontSize={16}
            plugins={barChartLabelResponsivePlugins}
          />
        </Box>
        <Box ml="7.84vw" mt="5.37vw">
          <LegendsOfChartCom data={legends} />
        </Box>

        <Box mt="6.72vw">
          <TableCom data={dataAttendance} />
        </Box>
        <PageFooter
          page={'02'}
          totalPages={totalLifeStylePage + 13}
          title={LIST_TABLE_CONTENTS_LABEL_DISPLAY.Attendance}
        />
      </Box>
    </CustomContainer>
  );
};

export default Attendance;
