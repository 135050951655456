import { LIST_TABLE_CONTENTS_LABEL_DISPLAY } from '../../constants';
import LegendsOfChartCom from '../common/LegendsOfChartCom';
import PageFooter from '../common/PageFooter';
import CRPreviewSection from '../common/Section';
import bgCover from '@/assets/image/pdfexport/bg-cover.png';
import headImg from '@/assets/image/pdfexport/head-fill.png';
import manImg from '@/assets/image/pdfexport/man.png';
import womanImg from '@/assets/image/pdfexport/woman.png';
import DoughnutChart from '@/new-components/CRReportPDF/components/DoughnutChart';
import {
  showLabelResponsivePlugins,
  textCenterResponsivePlugins,
} from '@/new-components/CRReportPDF/ultils';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useMemo } from 'react';

const CustomContainer = withStyles({
  root: {
    padding: '0 13.44vw',
    height: 'calc(100vw*1.4)',
    width: '100vw',
    backgroundImage: `url(${bgCover})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingTop: '10.75vw',
  },
})(Box);
const Title = withStyles({
  root: {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '6.05vw',
    lineHeight: '4.7vw',
  },
})(Typography);

const Value = withStyles({
  root: {
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: '3.36vw',
    lineHeight: '4.7vw',
    mt: '2.69vw',
  },
})(Typography);

const Item = withStyles({
  root: {
    paddingLeft: '2.69vw',
    borderRight: '1px solid #CCCCCC',
    fontFamily: 'OpenSans',

    '& .title': {
      color: '#666',
      fontWeight: 600,
      fontSize: '1.68vw',
    },

    '& .value': {
      marginTop: '0.67vw',
      color: '#333',
      fontWeight: 600,
      fontSize: '2.69vw',
      lineHeight: '4.03vw',
    },

    '&:last-child': {
      borderRight: 'none',
    },
  },
})(Grid);

const QuickLook = ({ corporateData, addPaging, totalLifeStylePage }) => {
  const totalFemalePercent = get(corporateData, 'overView.totalFemalePercent');

  const totalMalePercent = get(corporateData, 'overView.totalMalePercent');
  const hypertensive = get(corporateData, 'healthScreenings.hypertensive');
  const obese = get(corporateData, 'healthScreenings.obese');
  const highCholesterol = get(
    corporateData,
    'healthScreenings.highCholesterol'
  );
  const diabetic = get(corporateData, 'healthScreenings.diabetic');

  const chartData = useMemo(() => {
    return corporateData?.overView?.ageGroups
      .filter((it) => it.title !== 'Total')
      .map((it) => it.total);
  }, [corporateData]);

  const backgroundColor = [
    'rgba(255, 233, 251, 1)',
    'rgba(255, 188, 183, 1)',
    'rgba(190, 238, 199, 1)',
    'rgba(245, 197, 213, 1)',
    'rgba(249, 225, 161, 1)',
    'rgba(232, 222, 255, 1)',
  ];
  const chartPlugins = [
    textCenterResponsivePlugins,
    showLabelResponsivePlugins,
  ];

  const legends = [
    {
      color: '#FFE9FB',
      title: '<20',
    },
    {
      color: '#FFBCB7',
      title: '20 - 29',
    },
    {
      color: '#BEEEC7',
      title: '30 - 39',
    },
    {
      color: '#F5C5D5',
      title: '40 - 49',
    },
    {
      color: '#F9E1A1',
      title: '50 - 59',
    },
    {
      color: '#E8DEFF',
      title: '>59',
    },
  ];

  const DATAS = [
    {
      value: `${hypertensive.formatPercentValue()}%`,
      title: 'Hypertensive',
    },
    {
      value: `${obese.formatPercentValue()}%`,
      title: 'Obese',
    },
    {
      value: `${highCholesterol.formatPercentValue()}%`,
      title: 'High Cholesterol',
    },
    {
      value: `${diabetic.formatPercentValue()}%`,
      title: 'Diabetic',
    },
  ];

  useEffect(() => {
    addPaging(LIST_TABLE_CONTENTS_LABEL_DISPLAY.QuickLook);
  }, []);

  return (
    <CustomContainer>
      <Box display="flex" flexDirection="column" minHeight="100%">
        <Title>Hi, here is a quick look ...</Title>

        <Box display="flex" justifyContent="space-between" mt="11.42vw">
          <Box>
            <img alt="#" src={womanImg} style={{ width: '12.6vw' }} />
            <Value align="center" color="primary">
              {totalFemalePercent.formatPercentValue()}%
            </Value>
          </Box>
          <Box>
            <DoughnutChart
              chartData={chartData}
              chartPlugins={chartPlugins}
              backgroundColor={backgroundColor}
              animationDuration={400}
            />
          </Box>

          <Box>
            <img alt="#" src={manImg} style={{ width: '12.6vw' }} />
            <Value align="center" color="primary">
              {totalMalePercent.formatPercentValue()}%
            </Value>
          </Box>
        </Box>
        <Box mt="6.72vw">
          <CRPreviewSection title="AGE GROUP" img={headImg} />
        </Box>

        <Box ml="7.84vw" mt="2.69vw">
          <LegendsOfChartCom data={legends} />
        </Box>

        <Box
          mt="6.72vw"
          display="flex"
          bgcolor="#E2F2FF"
          width="73.1vw"
          height="11.42vw"
          borderRadius="1.68vw"
          py="2.01vw"
        >
          <Grid container>
            {DATAS.map((it, inx) => (
              <Item item xs={3} key={`${it.title}-${inx}`}>
                <div className="title">{it.title}</div>
                <div className="value">{it.value}</div>
              </Item>
            ))}
          </Grid>
        </Box>
        <PageFooter
          page={'01'}
          totalPages={totalLifeStylePage + 13}
          title={LIST_TABLE_CONTENTS_LABEL_DISPLAY.QuickLook}
        />
      </Box>
    </CustomContainer>
  );
};

export default QuickLook;
