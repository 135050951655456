import { API_ROOT } from '../common';
import crBg from '@/assets/image/cr-bg.png';
import { urlLabel } from '@/enum/PermissionEnum';
import { BACK_KEY } from '@/module/common';
import CRReportHTML from '@/new-components/CRReportHTML';
import customToast from '@/new-components/CustomNotification';
import {
  Box,
  Button,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { format } from 'date-fns';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const CRReportViewer = (props) => {
  const query = new URLSearchParams(useLocation().search);

  const accessToken = useSelector((state) => state.auth.accessToken);

  const [projectCode, setProjectCode] = useState('');
  const [step, setStep] = useState(0);
  const [firstChar, setFirstChar] = useState(null);
  const [secondChar, setSecondChar] = useState(null);
  const [thirdChar, setThirdChar] = useState(null);
  const [fourthChar, setFourthChar] = useState(null);
  const [fifthChar, setFifthChar] = useState(null);
  const [sixthChar, setSixthChar] = useState(null);
  const [email, setEmail] = useState('');
  const [timer, setTimer] = useState(0);
  const [isAbleToResend, setIsAbleToResend] = useState(false);
  const [projectId, setProjectId] = useState(query.get('projectId'));
  const hrEmail = query.get('hrEmail') || localStorage.getItem('hrEmail');
  const requestKey =
    query.get('requestKey') || localStorage.getItem('requestKey');

  const [accessTokenCRReport, setAccessTokenCRReport] = useState(
    localStorage.getItem('accessToken')
  );

  useEffect(() => {
    if (hrEmail && requestKey) {
      localStorage.setItem('hrEmail', hrEmail);
      localStorage.setItem('requestKey', requestKey.replace(' ', '+'));
    } else {
      history.push(
        `/${urlLabel.previewDownloadCRReport}?requestKey=${localStorage.getItem(
          'requestKey'
        )}&hrEmail=${localStorage.getItem('hrEmail')}`
      );
    }

    if (query.get('hrEmail') && query.get('requestKey')) {
      setAccessTokenCRReport(null);
      localStorage.removeItem('accessToken');
    }
  }, []);

  useEffect(() => {
    if (step === 1) {
      setTimer(180); // Start the timer when entering OTP step
      setIsAbleToResend(false);
    }
  }, [step]);

  useEffect(() => {
    if (timer > 0) {
      setIsAbleToResend(false);
      const interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setIsAbleToResend(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  useEffect(() => {
    if (step === 0) return;
    if (isInit()) document.getElementById('first-char').focus();
    else if (firstChar) document.getElementById('second-char').focus();
  }, [firstChar]);
  useEffect(() => {
    if (step === 0) return;
    if (isInit()) return;
    else if (secondChar) document.getElementById('third-char').focus();
    else document.getElementById('first-char').focus();
  }, [secondChar]);
  useEffect(() => {
    if (step === 0) return;
    if (isInit()) return;
    else if (thirdChar) document.getElementById('fourth-char').focus();
    else document.getElementById('second-char').focus();
  }, [thirdChar]);
  useEffect(() => {
    if (step === 0) return;
    if (isInit()) return;
    else if (fourthChar) document.getElementById('fifth-char').focus();
    else document.getElementById('third-char').focus();
  }, [fourthChar]);
  useEffect(() => {
    if (step === 0) return;
    if (isInit()) return;
    else if (fifthChar) document.getElementById('sixth-char').focus();
    else document.getElementById('fourth-char').focus();
  }, [fifthChar]);
  useEffect(() => {
    if (step === 0) return;
    if (isInit()) return;
    else if (!sixthChar) document.getElementById('fifth-char').focus();
  }, [sixthChar]);

  const isInit = () =>
    !(
      firstChar ||
      secondChar ||
      thirdChar ||
      fourthChar ||
      fifthChar ||
      sixthChar
    );
  const onChangeValue = (setState, isFirstChar) => (event) => {
    const value = event.target.value;
    if (value.length <= 1) setState(value);
    else {
      if (isFirstChar) {
        if (value.length === 6) {
          setFirstChar(value[0]);
          setSecondChar(value[1]);
          setThirdChar(value[2]);
          setFourthChar(value[3]);
          setFifthChar(value[4]);
          setSixthChar(value[5]);
        }
      } else setState(value.substring(1));
    }
  };

  const onBackKey = (setState) => (e) => {
    if (!e.target.value) setState(false);
  };
  const history = useHistory();

  const reSendOTP = () => {
    if (!isAbleToResend) return;
    onVerifyProjectCode();
    setTimer(180);
  };
  const onVerifyProjectCode = async () => {
    const requestKey =
      history.location.search.match(/requestKey=([^&]*)/)?.[1] ||
      localStorage.getItem('requestKey');
    fetch(`${API_ROOT}ProjectCorporateReports/get-otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'secret-key': process.env.REACT_APP_SECRET_KEY,
      },
      body: JSON.stringify({
        requestKey,
        projectCode,
      }),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
        else {
          return res.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .then((res) => {
        if (get(res, 'statusCode') === 200) {
          customToast('success', 'An OTP has been sent to your email.');
          setStep(1);
          setEmail(res?.data?.hrEmail);
        } else {
          customToast('error', 'The code you entered is invalid');
        }
      })
      .catch((err) => {
        const errorMessage = JSON.parse(err?.message)?.message;
        customToast('error', errorMessage);
      });
  };

  const onValidateOTP = async () => {
    const otp = `${firstChar}${secondChar}${thirdChar}${fourthChar}${fifthChar}${sixthChar}`;
    const requestKey =
      history.location.search.match(/requestKey=([^&]*)/)?.[1] ||
      localStorage.getItem('requestKey');
    fetch(`${API_ROOT}ProjectCorporateReports/validate-otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'secret-key': process.env.REACT_APP_SECRET_KEY,
      },
      body: JSON.stringify({
        requestKey,
        otp,
      }),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
        else {
          return res.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .then((res) => {
        setProjectId(res?.data?.projectId);
        setAccessTokenCRReport(res?.data?.accessToken);
        localStorage.setItem('projectId', res?.data?.projectId);
        localStorage.setItem('accessToken', res?.data?.accessToken);

        history.push({
          pathname: `${urlLabel.previewDownloadCRReport}`,
          search: `projectId=${res?.data?.projectId}`,
        });

        // setLoading(true);
        // fetch(
        //   `${API_ROOT}ProjectCorporateReports/${res?.data?.projectId}/data`,
        //   {
        //     method: 'GET',
        //     headers: {
        //       'Content-Type': 'application/json',
        //       Authorization: `Bearer ${res?.data?.accessToken}`,
        //     },
        //   }
        // )
        //   .then((res) => {
        //     return res.json();
        //   })
        //   .then((res) => {
        //     Progress.hide();
        //     if (get(res, 'statusCode') === 200) {
        //       customToast('success', 'OTP has been successfully verified.');
        //       setLoading(false);
        //       setCorporateData(res?.data);
        //     } else {
        //       customToast('error', 'The code you entered already expired');
        //     }
        //   })
        //   .catch((err) => {
        //     Progress.hide();
        //     customToast('error', 'The code you entered is invalid');
        //   });
      })
      .catch((err) => {
        const errorMessage = JSON.parse(err?.message)?.message;
        customToast('error', errorMessage);
      });
  };

  const resetInput = () => {
    setFirstChar('');
    setSecondChar('');
    setThirdChar('');
    setFourthChar('');
    setFifthChar('');
    setSixthChar('');
  };

  const onBack = () => {
    resetInput();
    setStep(0);
    setProjectCode('');
  };
  // useEffect(() => {
  //   if (corporateData && pdfDoc && !loading && !alreadyLoad) {
  //     setAlreadyLoad(true);
  //     const blob = pdfDoc
  //       .setProperties({
  //         title: 'CR Report',
  //       })
  //       .output('blob', {
  //         filename: `CR_Report_${corporateData.projectInfo.projectCode}.pdf`,
  //       });
  //     const blobUrl = URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = blobUrl;
  //     link.target = '_blank';
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     URL.revokeObjectURL(blobUrl);
  //     setCorporateData(null);
  //     setStep(0);
  //     setProjectCode('');
  //     resetInput();
  //     setAlreadyLoad(false);
  //   }
  // }, [corporateData, pdfDoc, loading, alreadyLoad]);

  return (
    <>
      {projectId && accessTokenCRReport ? (
        <CRReportHTML accessToken={accessTokenCRReport} projectId={projectId} />
      ) : (
        <Container>
          <LinkContainer>
            {/* <CRReportPDF corporateData={corporateData} /> */}
          </LinkContainer>

          <FormContainer>
            {step === 0 ? (
              <Form height="280px">
                <Typography
                  style={{
                    fontSize: 24,
                    letterSpacing: 0,
                    fontWeight: 600,
                    marginTop: 36,
                  }}
                >
                  OTP verification
                </Typography>

                <Box fontFamily="OpenSans" mt={1}>
                  <p>You will receive an email with a one-time password at </p>
                  <strong>{hrEmail}</strong>
                </Box>
                <Footer>
                  <CustomButton
                    color="primary"
                    variant="contained"
                    onClick={onVerifyProjectCode}
                  >
                    Continue
                  </CustomButton>
                </Footer>
              </Form>
            ) : (
              <Form height="480px">
                {/* <BackButton onClick={onBack}> Back </BackButton> */}
                <Typography
                  style={{
                    fontSize: 24,
                    letterSpacing: 0,
                    fontWeight: 600,
                    marginTop: 16,
                  }}
                >
                  OTP Verification
                </Typography>
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    marginTop: 16,
                    marginBottom: 32,
                  }}
                >
                  Enter the OTP code that was sent to <b>{email}</b>
                </Typography>
                <Box
                  mt={8}
                  mb={8}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CustomTextField
                    id="first-char"
                    value={firstChar || ''}
                    onChange={onChangeValue(setFirstChar, true)}
                    variant="outlined"
                  />
                  <CustomTextField
                    id="second-char"
                    value={secondChar || ''}
                    style={{ marginLeft: 10 }}
                    onKeyUp={(e) =>
                      e.keyCode === BACK_KEY && onBackKey(setSecondChar)(e)
                    }
                    onChange={onChangeValue(setSecondChar)}
                    variant="outlined"
                  />
                  <CustomTextField
                    id="third-char"
                    value={thirdChar || ''}
                    style={{ marginLeft: 10 }}
                    onKeyUp={(e) =>
                      e.keyCode === BACK_KEY && onBackKey(setThirdChar)(e)
                    }
                    onChange={onChangeValue(setThirdChar)}
                    variant="outlined"
                  />
                  <CustomTextField
                    id="fourth-char"
                    value={fourthChar || ''}
                    style={{ marginLeft: 10 }}
                    onKeyUp={(e) =>
                      e.keyCode === BACK_KEY && onBackKey(setFourthChar)(e)
                    }
                    onChange={onChangeValue(setFourthChar)}
                    variant="outlined"
                  />
                  <CustomTextField
                    id="fifth-char"
                    value={fifthChar || ''}
                    style={{ marginLeft: 10 }}
                    onKeyUp={(e) =>
                      e.keyCode === BACK_KEY && onBackKey(setFifthChar)(e)
                    }
                    onChange={onChangeValue(setFifthChar)}
                    variant="outlined"
                  />
                  <CustomTextField
                    id="sixth-char"
                    value={sixthChar || ''}
                    onKeyUp={(e) =>
                      e.keyCode === BACK_KEY && onBackKey(setSixthChar)(e)
                    }
                    style={{ marginLeft: 10 }}
                    onChange={onChangeValue(setSixthChar)}
                    variant="outlined"
                    onKeyDown={(ev) => {
                      if (ev.key === 'Enter') {
                        onValidateOTP();
                        ev.preventDefault();
                      }
                    }}
                  />
                </Box>
                <Footer>
                  <CustomButton
                    color="primary"
                    variant="contained"
                    onClick={onValidateOTP}
                  >
                    Verify
                  </CustomButton>
                  <ResendContainer>
                    <>
                      <Typography
                        style={{
                          fontSize: 16,
                          letterSpacing: 0,
                          color: '#8F9BA9',
                          marginRight: 7,
                        }}
                      >
                        Didn't receive OTP
                        {!isAbleToResend &&
                          ` (${format(
                            new Date(
                              0,
                              0,
                              0,
                              Math.floor(timer / 60),
                              timer % 60
                            ),
                            'HH:mm'
                          )}) `}
                        ?
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 16,
                          letterSpacing: 0,
                          color: isAbleToResend ? '#AD5E99' : '#8F9BA9',
                          fontWeight: 600,
                          cursor: isAbleToResend ? 'pointer' : 'not-allowed',
                        }}
                        onClick={() => {
                          if (isAbleToResend) reSendOTP();
                          resetInput();
                        }}
                      >
                        Resend
                      </Typography>
                    </>
                  </ResendContainer>
                </Footer>
              </Form>
            )}
          </FormContainer>
        </Container>
      )}
    </>
  );
};

export default CRReportViewer;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  background-image: url(${crBg});
  background-size: cover;
`;
const LinkContainer = styled.div`
  flex: 1 1 0px;
`;
const FormContainer = styled.div`
  flex: 1 1 0px;
  display: flex;
  align-items: center;
`;

const Form = styled.div`
  height: ${(props) => props.height};
  width: 530px;
  border-radius: 10px;
  background: white;
  padding: 16px 56px;
  text-align: center;
`;

const TextFieldStyle = styled(TextField)`
  height: 36px;
  width: 308px;
  margin-top: 32px !important;
  margin-bottom: 24px !important;
  & input {
    font-size: 20px;
    margin-left: 0;
  }
`;

const CustomButton = styled(Button)`
  width: 100%;
  height: 44px;
  span {
    font-family: 'OpenSans'
    font-size: 20px;
  }
`;
const Footer = styled.div`
  margin-top: 36px;
`;
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
      width: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      marginLeft: 0,
      fontSize: 24,
      textAlign: 'center',
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiTypography-root': {
        fontSize: 16,
        color: '#333333',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },
      '& input': {
        marginLeft: 0,
      },
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'OpenSans',
      fontSize: 12,
      gap: 6,
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 'unset',
      },
    },
  },
})(TextField);

const BackButton = styled.div`
  cursor: pointer;
  text-align: left;
  width: fit-content;
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
`;

const ResendContainer = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: center;
`;
