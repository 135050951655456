import { LIST_TABLE_CONTENTS_LABEL, LABEL_COLORS } from '../../constants';
import ListOfAnswersCom from '../common/ListOfAnswersCom';
import PageFooter from '../common/PageFooter';
import CRPreviewSection from '../common/Section';
import bgCover from '@/assets/image/pdfexport/bg-cover.png';
import headImg from '@/assets/image/pdfexport/head-fill.png';
import DoughnutChart from '@/new-components/CRReportPDF/components/DoughnutChart';
import ChartDataLabels from '@energiency/chartjs-plugin-piechart-outlabels';
import { Box, Typography, withStyles } from '@material-ui/core';
import { get, lowerCase } from 'lodash';
import React, { useEffect, useState } from 'react';

const CustomContainer = withStyles({
  root: {
    padding: '0 13.44vw',
    width: '100vw',
    height: 'calc(100vw*1.4)',
    backgroundImage: `url(${bgCover})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingTop: '10.75vw',
  },
})(Box);

const MostSelected = withStyles((theme) => ({
  root: (props) => ({
    fontFamily: 'OpenSans',
    fontWeight: 400,
    fontSize: '1.3vw',
    lineHeight: '1.8vw',
    marginTop: '0',
    marginBottom: props.noMarginBottom ? '2vw' : '12vw', // Conditionally apply margin
  }),
}))(Typography);

const Title = withStyles({
  root: {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '5vw',
    lineHeight: '4.7vw',
  },
})(Typography);

const Question = withStyles({
  root: {
    fontFamily: 'Lato',
    fontWeight: 600,
    fontSize: '1.3vw',
    lineHeight: '1.8vw',
    marginTop: '4vw',
  },
})(Typography);

const Description = withStyles({
  root: {
    fontFamily: 'OpenSans',
    fontWeight: 400,
    fontSize: '1.3vw',
    lineHeight: '1.5vw',
    marginTop: '3.49vw',
    marginBottom: '4vw',
  },
})(Typography);

const groupByFirstEncounter = (data, key) => {
  return data.reduce((acc, item) => {
    const category = item[key];
    if (!acc.hasOwnProperty(category)) {
      acc[category] = []; // Maintain order by first encounter
    }
    acc[category].push(item);
    return acc;
  }, {});
};
const LifestyleQuestionaireFinding = ({
  corporateData,
  setTotalLifeStylePage,
}) => {
  const [listRandomColors, setListRandomColors] = useState([]);

  const generateRandomColors = (length) => {
    const colors = [];
    for (let i = 0; i < length; i++) {
      // Tạo màu ngẫu nhiên theo định dạng hex (#RRGGBB)
      const color = `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, '0')}`;
      colors.push(color);
    }
    return colors;
  };
  const dataByCategory = groupByFirstEncounter(
    corporateData?.questionaires?.filter((q) => q.answerType !== 'FreeText'),
    'category'
  );

  const categories = Object.keys(dataByCategory);
  const chartPlugins = [ChartDataLabels];
  const backgroundColor = LABEL_COLORS;

  const totalPatientOptOutSurvey =
    get(corporateData, 'overView.totalPatientOptOutSurvey') || '';

  const first2Questions = dataByCategory[categories[0]]
    .slice(0, 2)
    .map((question) => {
      const answers = [...question.answers].map((a, i) => ({
        ...a,
        color: LABEL_COLORS[i],
      }));
      question.answers = answers;

      return question;
    });

  const allQuestions = categories.flatMap((category, index) => {
    const data =
      index === 0
        ? dataByCategory[category]?.slice(2)
        : dataByCategory[category];

    return data.map((question) => {
      const answers = [...question.answers].map((a, i) => ({
        ...a,
        color: LABEL_COLORS[i],
      }));
      question.answers = answers;

      return { question, category };
    });
  });

  const splitLongQuestions = (questions) => {
    const updatedQuestions = [];

    questions.forEach((q) => {
      const { question, category } = q;
      const { answers } = question;

      if (answers.length > 10) {
        let partNumber = 1;
        for (let i = 0; i < answers.length; i += 10) {
          updatedQuestions.push({
            question: {
              ...question,
              question: `${question.question}`,
              answers: answers.slice(i, i + 10), // Take max 8 answers per part
            },
            part: partNumber,
            category,
          });
          partNumber++;
        }
        updatedQuestions.push({
          question,
          category,
          part: 'All',
        });
      } else {
        updatedQuestions.push(q);
      }
    });

    return updatedQuestions;
  };

  const newQuestions = splitLongQuestions(allQuestions);

  const groupedQuestions = newQuestions
    .filter((it) => it.part !== 'All')
    .reduce((acc, { question, category, part }) => {
      const lastGroup = acc[acc.length - 1];
      if (!lastGroup || lastGroup.length === 2 || lastGroup[0]?.single) {
        acc.push([{ question, category, part }]);
      } else {
        lastGroup.push({ question, category, part });
      }
      return acc;
    }, []);

  // Calculate total pages before rendering
  const totalPages = groupedQuestions.length;

  useEffect(() => {
    setTotalLifeStylePage(groupedQuestions.length + 1);
  }, [groupedQuestions.length, setTotalLifeStylePage]);

  useEffect(() => {
    setListRandomColors(generateRandomColors(100));
  }, []);
  return (
    <>
      <CustomContainer>
        <Box display="flex" flexDirection="column" minHeight="100%">
          <Title>Lifestyle Questionnaire Findings</Title>
          <Description>
            *Lifestyle Survey results for {totalPatientOptOutSurvey}{' '}
            participants are invalid due to incomplete.
          </Description>
          <Box>
            <CRPreviewSection title={categories[0]} img={headImg} />
          </Box>
          {first2Questions.map((question) => {
            const mostSelected = question.answers?.reduce(
              (max, item) =>
                item.total > (max?.total ?? -Infinity) ? item : max,
              0
            );
            const chartData = question.answers.map((it) =>
              it.percent?.formatPercentValue()
            );
            const isYesNo = question?.answers.every((item) =>
              ['no', 'yes', 'other'].includes(lowerCase(item.answer))
            );
            return (
              <>
                <Question>
                  {new DOMParser().parseFromString(
                    question?.question,
                    'text/html'
                  ).body.textContent || ''}
                </Question>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  mt="2vw"
                  mb="4vw"
                >
                  <Box width="30vw" height="25vw">
                    <DoughnutChart
                      chartData={chartData}
                      chartPlugins={chartPlugins}
                      backgroundColor={backgroundColor}
                      animationDuration={400}
                      isLabelOutside={true}
                    />
                    {!isYesNo && (
                      <MostSelected>
                        {mostSelected?.percent?.formatPercentValue()}% answered
                        '{mostSelected?.answer}
                        '.
                      </MostSelected>
                    )}
                  </Box>
                  <Box ml="5.84vw" mt="4.69vw">
                    {isYesNo && (
                      <MostSelected noMarginBottom>
                        {mostSelected?.percent?.formatPercentValue()}% answered
                        '{mostSelected?.answer}
                        '.
                      </MostSelected>
                    )}
                    <ListOfAnswersCom
                      data={question?.answers}
                      colors={backgroundColor}
                    />
                  </Box>
                </Box>
              </>
            );
          })}
          <PageFooter
            page={13}
            totalPages={totalPages + 14}
            title={LIST_TABLE_CONTENTS_LABEL.LifestyleQuestionnaireFindings}
          />
        </Box>
      </CustomContainer>

      {groupedQuestions.map((pair, pageIndex) => {
        return (
          <CustomContainer key={`container-${pageIndex}`}>
            <Box display="flex" flexDirection="column" minHeight="100%">
              {pair.map(({ question, category, part }, qIndex) => {
                const showCategoryTitle =
                  qIndex === 0 || pair[qIndex - 1].category !== category;
                const isYesNo = question?.answers.every((item) =>
                  ['no', 'yes', 'other'].includes(lowerCase(item.answer))
                );
                const filtered = newQuestions.find(
                  (q) =>
                    question.question === q.question.question &&
                    q.part === 'All'
                );

                return (
                  <React.Fragment key={`question-${pageIndex}-${qIndex}`}>
                    {(showCategoryTitle ||
                      (filtered &&
                        filtered?.question?.question === question.question &&
                        part === 1)) && (
                      <Box mb="2vw">
                        <CRPreviewSection title={category} img={headImg} />
                      </Box>
                    )}
                    {filtered && part > 1 ? null : (
                      <Question>
                        {new DOMParser().parseFromString(
                          question?.question,
                          'text/html'
                        ).body.textContent || ''}
                      </Question>
                    )}

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mt={
                        filtered?.question?.question === question.question &&
                        part > 1
                          ? '0.67vw'
                          : '2vw'
                      }
                      mb={
                        filtered?.question?.question === question.question
                          ? 0
                          : '4vw'
                      }
                    >
                      <Box width="30vw" height="30vw">
                        {filtered?.question?.question === question.question ? (
                          part > 1 ? null : (
                            <DoughnutChart
                              chartData={filtered.question.answers.map((it) =>
                                it.percent?.formatPercentValue()
                              )}
                              chartPlugins={chartPlugins}
                              backgroundColor={backgroundColor}
                              animationDuration={400}
                              isLabelOutside={true}
                            />
                          )
                        ) : (
                          <DoughnutChart
                            chartData={question.answers.map((it) =>
                              it.percent?.formatPercentValue()
                            )}
                            chartPlugins={chartPlugins}
                            backgroundColor={backgroundColor}
                            animationDuration={400}
                            isLabelOutside={true}
                          />
                        )}

                        {!isYesNo &&
                          (filtered ? (
                            part === 1 && (
                              <MostSelected>
                                {filtered?.question?.answers
                                  .reduce(
                                    (max, item) =>
                                      item.total > (max?.total ?? -Infinity)
                                        ? item
                                        : max,
                                    0
                                  )
                                  ?.percent?.formatPercentValue()}
                                % answered '
                                {
                                  filtered?.question?.answers?.find(
                                    (a) =>
                                      a.total ===
                                      Math.max(
                                        ...filtered?.question?.answers.map(
                                          (a) => a.total
                                        )
                                      )
                                  )?.answer
                                }
                                '.
                              </MostSelected>
                            )
                          ) : (
                            <MostSelected>
                              {question.answers
                                .reduce(
                                  (max, item) =>
                                    item.total > (max?.total ?? -Infinity)
                                      ? item
                                      : max,
                                  0
                                )
                                ?.percent?.formatPercentValue()}
                              % answered '
                              {
                                question.answers?.find(
                                  (a) =>
                                    a.total ===
                                    Math.max(
                                      ...question.answers.map((a) => a.total)
                                    )
                                )?.answer
                              }
                              '.
                            </MostSelected>
                          ))}
                      </Box>
                      <Box
                        ml="5.84vw"
                        mt={
                          filtered &&
                          filtered?.question?.question === question.question &&
                          part > 1
                            ? 0
                            : '1.69vw'
                        }
                      >
                        {isYesNo && (
                          <MostSelected noMarginBottom>
                            {question.answers
                              .reduce(
                                (max, item) =>
                                  item.total > (max?.total ?? -Infinity)
                                    ? item
                                    : max,
                                0
                              )
                              ?.percent?.formatPercentValue()}
                            % answered '
                            {
                              question.answers?.find(
                                (a) =>
                                  a.total ===
                                  Math.max(
                                    ...question.answers.map((a) => a.total)
                                  )
                              )?.answer
                            }
                            '.
                          </MostSelected>
                        )}
                        <ListOfAnswersCom data={question?.answers} />
                      </Box>
                    </Box>
                  </React.Fragment>
                );
              })}
              <PageFooter
                page={pageIndex + 14}
                totalPages={totalPages + 14}
                title={LIST_TABLE_CONTENTS_LABEL.LifestyleQuestionnaireFindings}
              />
            </Box>
          </CustomContainer>
        );
      })}
    </>
  );
};

export default LifestyleQuestionaireFinding;
