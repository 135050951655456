import CustomSelect from '../../../components/CustomSelect';
import MutipleSelection from '../../../components/CustomSelect/MutipleSelection';
import ModalPresenter from '../../../components/ModalPresenter';
import TableContainer from '../../../components/Table/TableContainer';
import { countries } from '../../../countries';
import { userStatus } from '../../../enum/RequestEnum';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import { ChipStatus } from '../../common/componentUI/commonStyleComponents';
import doctorDispatcher from '../../doctor/action/doctor';
import caDispatcher from '../action/ca';
import DetailPresenter from './DetailPresenter';
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Dialog,
  Modal,
  Typography,
} from '@material-ui/core';
import {
  MoreVertRounded,
  DeleteRounded,
  CreateRounded,
  ArtTrackOutlined,
} from '@material-ui/icons';
import { startCase, isEmpty, get } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const CAManagement = () => {
  const data = useSelector((state) => state.ca.list);
  const paging = useSelector((state) => state.ca.paging);
  const listClinic = useSelector((state) =>
    (state.doctor.clinics.list || []).map((i) => ({
      ...i,
      key: i.id,
      value: i.name,
    }))
  );

  const [selectedItem, setSelectedItem] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [detailModal, setDetailModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusOpen, setStatusOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);
  const [clinicOpen, setClinicOpen] = useState(false);
  const [clinicFilter, setClinicFilter] = useState([]);
  const [filterOption, setFilterOption] = useState({});
  const [hiddenRows, setHiddenRows] = useState({});
  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows,
      }}
      {...props}
    />
  );

  useEffect(() => {
    doctorDispatcher.getClinics();
    return () => {};
  }, []);

  const Header = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper
          style={{ paddingLeft: 20 }}
          label="Name"
          stateValue="name"
        />
        <TableCellFiltedWrapper label="Email" stateValue="email" />
        <TableCellFiltedWrapper label="Clinic" stateValue="clinic" />
        <TableCellFiltedWrapper
          label="Active Status"
          stateValue="activeStatus"
        />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  const handleClick = (item) => (event) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuItem = () => {
    let options = [
      {
        key: 'edit',
        icon: CreateRounded,
        label: 'Edit',
        onClick: () => {
          setDetailModal({
            isAddNew: false,
          });
          handleClose();
        },
      },
      {
        key: 'delete',
        icon: DeleteRounded,
        label: 'Delete',
        onClick: () => {
          setDeleteModal(true);
          handleClose();
        },
      },
    ];
    return options.map((d) => {
      let Icon = d.icon;
      return (
        <MenuItem
          key={d.key}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Source Sans Pro',
            color: '#192637',
          }}
          onClick={d.onClick}
        >
          <ListItemIcon style={{ minWidth: 34 }}>
            <Icon style={{ color: '#CACFD3' }} />
          </ListItemIcon>
          {d.label}
        </MenuItem>
      );
    });
  };

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {renderMenuItem()}
      </Menu>
      <Dialog
        open={!!detailModal}
        onClose={() => setDetailModal(false)}
        scroll="body"
        maxWidth="md"
      >
        <DetailPresenter
          selectedItem={selectedItem}
          detailModal={detailModal}
          searchKey={searchKey}
          onClose={() => setDetailModal(false)}
        />
      </Dialog>
      <Modal open={!!deleteModal} onClose={() => setDeleteModal(false)}>
        <div>
          <ModalPresenter
            onClose={() => setDeleteModal(false)}
            Icon={<DeleteRounded style={{ fontSize: 80, color: '#EA6B75' }} />}
            title="Delete Profile?"
            onClick={() => {
              caDispatcher.deleteCA(selectedItem.id, searchKey);
              setDeleteModal(false);
            }}
          >
            <Typography>Click “Continue” to delete</Typography>
            <Typography>
              <Typography style={{ fontWeight: 600 }} display={'inline'}>
                {selectedItem.firstName + ' ' + selectedItem.lastName}
              </Typography>
              ’s profile.
            </Typography>
          </ModalPresenter>
        </div>
      </Modal>
      <TableContainer
        title="CE"
        query={caDispatcher.getData}
        header={Header}
        paging={paging}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        filterOption={filterOption}
        data={data}
        onAddNew={() => {
          setSelectedItem({});
          setDetailModal({
            isAddNew: true,
          });
        }}
        ToolbarComponent={
          <>
            <CustomSelect
              onClick={() => {
                setStatusOpen(!statusOpen);
              }}
              clearable={!isEmpty(statusFilter)}
              onClear={() => {
                setStatusFilter([]);
                setFilterOption({
                  ...filterOption,
                  activeStatus: [],
                });
              }}
              prefixIcon={
                <ArtTrackOutlined
                  style={{
                    fontSize: 20,
                    color: '#E9E9E9',
                    marginRight: 8,
                  }}
                />
              }
              renderValue={<>{statusFilter.join(', ') || 'Select Status'}</>}
            >
              <MutipleSelection
                value={statusFilter}
                data={userStatus}
                open={statusOpen}
                onChange={(e) => {
                  setFilterOption({
                    ...filterOption,
                    activeStatus: e.target.value,
                  });
                  setStatusFilter(e.target.value);
                }}
              />
            </CustomSelect>
            <CustomSelect
              onClick={() => {
                setClinicOpen(!clinicOpen);
              }}
              clearable={!isEmpty(clinicFilter)}
              onClear={() => {
                setClinicFilter([]);
                setFilterOption({
                  ...filterOption,
                  clinicIds: [],
                });
              }}
              prefixIcon={
                <ArtTrackOutlined
                  style={{
                    fontSize: 20,
                    color: '#E9E9E9',
                    marginRight: 8,
                  }}
                />
              }
              renderValue={
                <>
                  {listClinic
                    .filter((c) => clinicFilter.includes(c.key))
                    .map((c) => c.value)
                    .join(', ') || 'Select Clinic'}
                </>
              }
            >
              <MutipleSelection
                value={clinicFilter}
                data={listClinic}
                open={clinicOpen}
                onChange={(e) => {
                  setFilterOption({
                    ...filterOption,
                    clinicIds: e.target.value,
                  });
                  setClinicFilter(e.target.value);
                }}
              />
            </CustomSelect>
          </>
        }
        renderRow={(d) => (
          <>
            <TableCell style={{ paddingLeft: 20 }}>
              {!hiddenRows.name &&
                (!isEmpty(d.firstName)
                  ? d.firstName + ' ' + d.lastName
                  : d.fullName)}
            </TableCell>
            <TableCell
              style={{
                paddingLeft: 20,
                color: '#AD5E99',
                textDecoration: 'underline',
                maxWidth: 200,
              }}
            >
              {!hiddenRows.email && d.email}
            </TableCell>
            <TableCell style={{ maxWidth: 200 }}>
              {!hiddenRows.clinic &&
                (d.lstClinic || [])
                  .map((e) => e.name)
                  .filter((e) => e)
                  .join(', ')}
            </TableCell>
            {/* <TableCell>{!hiddenRows.mobile && genPhone(d)}</TableCell> */}
            <TableCell>
              {!hiddenRows.activeStatus && (
                <ChipStatus
                  color={d.activeStatus === 'Active' ? '#62CAA4' : '#EA6B75'}
                >
                  {startCase(d.activeStatus)}
                </ChipStatus>
              )}
            </TableCell>
            <TableCell align="right">
              <IconButton onClick={handleClick(d)}>
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </>
        )}
      />
    </>
  );
};

const genPhone = ({ countryCode, mobile }) => {
  let prefix = '+';
  if (countryCode) {
    prefix = prefix.concat(get(countries, `${countryCode}.callingCode[0]`));
  }
  return prefix.concat(mobile);
};
export default CAManagement;
