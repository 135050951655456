import motherStatementDispatcher from './action';
import GroupToolbar from './component/GroupToolbar';
import ModalContainerForm from './component/ModalContainerForm';
import { COLUMN_MOTHER_STATEMENT } from './contansts';
import { AddIcon, CreateNewIcon } from '@/assets/svg';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';
import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { debounce, isEmpty } from 'lodash';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

const MotherhoodStatements = () => {
  const {
    list: data,
    paging,
    loading,
  } = useSelector((state) => state.motherhoodStatement);

  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState({});

  const [openDrawer, setOpenDrawer] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});
  const [currentLang, setCurrentLang] = useState('');

  const renderColumn = useMemo(() => {
    if (isEmpty(currentLang)) return COLUMN_MOTHER_STATEMENT;
    let newColumns = [
      {
        label: 'Statement code',
        stateValue: 'code',
      },
    ];
    const bodyCol = COLUMN_MOTHER_STATEMENT.filter((item) =>
      item.stateValue.includes(currentLang)
    );
    newColumns = newColumns.concat(bodyCol);
    newColumns = newColumns.concat({
      label: 'Action',
      stateValue: 'action',
    });

    return newColumns;
  }, [currentLang]);

  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    filterOptionData = filterOption
  ) => {
    motherStatementDispatcher.getData(
      search,
      pagingData,
      ...[filterOptionData].filter((i) => i)
    );
  };

  const onResetFilterOps = () => {
    setSearchKey('');
    setCurrentLang('');
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging, sortOption, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize,
        },
        sortOption,
        filterOption
      );
    }, 500),
    []
  );

  useEffect(() => {
    debounceLoadData(searchKey, paging, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, filterOption]);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {renderColumn.map((item) => (
          <TableCell
            key={item.stateValue || item.label}
            style={{ minWidth: item.minWidth, width: item.width }}
          >
            {item.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = (row) => {
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.status} />
        </TableCell>
        {isEmpty(currentLang) ? (
          <>
            <TableCell>
              <CustomTooltip content={row.s1English || '—'} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.s2English || '—'} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.r1English || '—'} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.r2English || '—'} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.s1Chinese || '—'} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.s2Chinese || '—'} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.r1Chinese || '—'} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.r2Chinese || '—'} />
            </TableCell>
          </>
        ) : currentLang === 'English' ? (
          <>
            <TableCell>
              <CustomTooltip content={row.s1English || '—'} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.s2English || '—'} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.r1English || '—'} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.r2English || '—'} />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell>
              <CustomTooltip content={row.s1Chinese || '—'} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.s2Chinese || '—'} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.r1Chinese || '—'} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.r2Chinese || '—'} />
            </TableCell>
          </>
        )}
        <TableCell>
          <IconButton
            onClick={() => {
              setIsCreate(false);
              setOpenDrawer(true);
              setSelectedItem(row);
            }}
          >
            <AddIcon />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        disableBackdropClick
        PaperProps={{
          style: {
            width: '50%',
            minWidth: 768,
          },
        }}
      >
        <ModalContainerForm
          loading={loading}
          selectedItem={selectedItem}
          isCreate={isCreate}
          onClose={() => {
            setOpenDrawer(false);
            fetchData();
          }}
        />
      </Drawer>
      <CustomPaperContainer
        header={
          <CustomHeader
            searchPlaceholder="Search by statement name"
            title="Motherhood statement"
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            fetchData={fetchData}
            renderToolbar={() => (
              <GroupToolbar
                searchKey={searchKey}
                currentLang={currentLang}
                setCurrentLang={setCurrentLang}
                onResetFilterOps={onResetFilterOps}
              />
            )}
            renderButton={() => (
              <Button
                color="primary"
                variant="contained"
                startIcon={<CreateNewIcon />}
                onClick={() => {
                  setOpenDrawer(true);
                  setIsCreate(true);
                }}
              >
                Create new
              </Button>
            )}
          />
        }
      >
        <CustomTable
          data={data}
          paging={paging}
          header={TableHeader}
          renderRow={(row, i) => renderTableBody(row)}
          noDataHelperText="No statement"
          noDataHelperSubText="Please create a new motherhood statement."
          totalCols={COLUMN_MOTHER_STATEMENT.length}
          fetchData={fetchData}
        />
      </CustomPaperContainer>
    </>
  );
};

export default MotherhoodStatements;
