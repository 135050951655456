import { DATE_FORMAT } from '../common';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import eOrderListDispatcher from './action';
import GroupToolbar from './component/GroupToolbar';
import GroupToolbarSelectedCheckbox from './component/GroupToolbarSelectedCheckbox';
import renderListAction from './component/renderListAction';
import { COLUMN_EORDER_LIST } from './constants';
import {
  CheckboxActive,
  CheckboxDefault,
  CheckboxDeselected,
} from '@/assets/svg';
import companyDispatcher from '@/module/company/action';
// New component
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Checkbox,
} from '@material-ui/core';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const OrderListExport = () => {
  const { list: data, paging } = useSelector((state) => state.eOrderList);
  const [selected, setSelected] = useState([]);

  const [listLocations, setListLocations] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);

  const [listProjects, setListProjects] = useState([]);
  const [projectFilter, setProjectFilter] = useState('');
  const [filterOption, setFilterOption] = useState({
    date: moment(),
  });

  const [orderList, setOrderList] = useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(orderList);
      return;
    }
    setSelected([]);
  };

  const handleCheckbox = (event, item) => {
    const selectedIndex = selected.indexOf(item);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    companyDispatcher.getLocationKeyValue((result) => setListLocations(result));
    return () => {
      eOrderListDispatcher.resetData();
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(locationFilter)) {
      const locationIds = locationFilter.map((it) => it.id);
      companyDispatcher.getProjectByLocation(locationIds, (result) =>
        setListProjects(result)
      );
    }
  }, [locationFilter]);

  useEffect(() => {
    const newData = data.map((it) => ({
      ...it,
      date: moment(filterOption.date).format(DATE_FORMAT),
    }));
    setOrderList(newData);

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (!isEmpty(projectFilter) && !isEmpty(locationFilter))
      setFilterOption({
        date: filterOption.date,
        projectId: projectFilter,
        locationIds: locationFilter.map((it) => it.id),
      });
    else if (!isEmpty(locationFilter)) {
      setFilterOption({
        locationIds: locationFilter.map((it) => it.id),
        date: filterOption.date,
      });
    } else
      setFilterOption({
        date: filterOption.date,
      });
  }, [projectFilter, locationFilter]);

  const fetchData = async (
    pagingData = paging,
    filterOptionData = filterOption
  ) => {
    eOrderListDispatcher.getDataInnoquestOrderList(
      pagingData,
      filterOptionData
    );
  };

  const debounceLoadData = useCallback(
    debounce((paging, filterOption) => {
      fetchData(
        {
          pageIndex: 0,
          pageSize: paging.pageSize,
        },
        filterOption
      );
    }, 500),
    []
  );

  useEffect(() => {
    debounceLoadData(paging, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOption]);

  const onResetFilterOps = () => {
    setLocationFilter('');
    setProjectFilter('');
    setListProjects([]);
    setFilterOption({
      date: moment(),
    });
  };

  const onFilterOptionChange = (key) => (e, newValue) => {
    let value = '';
    if (key === 'date') {
      value = e;
    } else {
      value = e.target.value;
    }

    setFilterOption({
      ...filterOption,
      [key]: value,
    });
  };

  const onHandleSubmit = (type, healthScreeningId = '') => {
    const healthScreeningIds = selected?.map((it) => it.healthScreeningId);

    switch (type) {
      case 'email':
        eOrderListDispatcher.emailOrderListToLab({
          healthScreeningDate: filterOption.date,
          healthScreeningId,
        });

        break;
      case 'export': {
        eOrderListDispatcher.exportInnoquestOrderList({
          healthScreeningDate: filterOption.date,
          healthScreeningId,
        });
        break;
      }
      case 'email-all':
        eOrderListDispatcher.emailMultiOrderListToLab({
          healthScreeningDate: filterOption.date,
          healthScreeningIds,
        });

        break;
      case 'export-all': {
        eOrderListDispatcher.exportMultiInnoquestOrderList({
          healthScreeningDate: filterOption.date,
          healthScreeningIds,
        });
        break;
      }
      default:
        break;
    }
  };

  const listActions = renderListAction(onHandleSubmit);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell>
          <Checkbox
            indeterminate={
              selected.length > 0 && selected.length < orderList.length
            }
            checked={
              orderList.length > 0 && selected.length === orderList.length
            }
            onChange={handleSelectAllClick}
            icon={<CheckboxDefault width={20} height={20} />}
            checkedIcon={<CheckboxActive width={20} height={20} />}
            indeterminateIcon={<CheckboxDeselected width={20} height={20} />}
            inputProps={{ 'aria-label': 'select all desserts' }}
            style={{ padding: 0 }}
          />
        </TableCell>
        {COLUMN_EORDER_LIST.map((item) => (
          <TableCellFilted
            key={item.stateValue}
            label={item.label}
            stateValue={item.stateValue}
            style={{
              minWidth: item.minWidth || 'unset',
              maxWidth: item.maxWidth || 'unset',
              width: item.width || 'unset',
            }}
            isHidden={false}
          />
        ))}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = (row) => {
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.project} />
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.location} />
        </TableCell>
        <TableCell>{row.date}</TableCell>

        <TableCell>{row.orderCount}</TableCell>
        <TableCell>
          {listActions.map((action) => {
            const Icon = action.icon;
            return (
              <Tooltip title={action.label}>
                <IconButton
                  key={action.key}
                  onClick={() => {
                    action.onClick(row.healthScreeningId);
                  }}
                >
                  <Icon width={20} height={20} />
                </IconButton>
              </Tooltip>
            );
          })}
        </TableCell>
      </>
    );
  };

  return (
    <CustomPaperContainer
      header={
        <CustomHeader
          title="Lab E-order"
          isSearch={false}
          renderToolbar={() =>
            !isEmpty(selected) ? (
              <GroupToolbarSelectedCheckbox
                selected={selected}
                setSelected={setSelected}
                onSubmit={onHandleSubmit}
              />
            ) : (
              <GroupToolbar
                onResetFilterOps={onResetFilterOps}
                filterOption={filterOption}
                onFilterOptionChange={onFilterOptionChange}
                listLocations={listLocations}
                locationFilter={locationFilter}
                setLocationFilter={setLocationFilter}
                listProjects={listProjects}
                setListProjects={setListProjects}
                projectFilter={projectFilter}
                setProjectFilter={setProjectFilter}
              />
            )
          }
        />
      }
    >
      <CustomTable
        hasCheckbox
        isItemSelected={(row) => selected.indexOf(row) !== -1}
        handleCheckbox={handleCheckbox}
        data={orderList}
        paging={paging}
        header={TableHeader}
        renderRow={(row, i) => renderTableBody(row)}
        noDataHelperText="No e-order"
        noDataHelperSubText="Please come back at another time."
        totalCols={COLUMN_EORDER_LIST.length}
        fetchData={fetchData}
        nameKey="healthScreeningId"
      />
    </CustomPaperContainer>
  );
};

export default OrderListExport;
