import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: (data) => ({ data }),

  getData:
    (search, paging, sortOption = {}, filterOption = {}) =>
    async ({ Api, getState }) => {
      let pagingData = paging || getState().corporateReport.paging;
      let { result, status } = await Api.post(
        `ProjectCorporateReports/paging`,
        {
          pageIndex: pagingData.pageIndex,
          pageSize: pagingData.pageSize,
          search,
          ...filterOption,
          ...sortOption,
        }
      );
      status === 200 && corporateReportDispatcher.getDataSuccess(result);
    },
  sendMail:
    (id, onSuccess) =>
    async ({ Api }) => {
      let { status } = await Api.post(
        `ProjectCorporateReports/${id}/sent-mail`
      );
      status === 200 && onSuccess();
    },
  getOTP:
    (data, onSuccess) =>
    async ({ Api }) => {
      let { status } = await Api.post(
        `ProjectCorporateReports/${data.id}/otp`,
        { requestKey: data.requestKey }
      );
      status === 200 && onSuccess();
    },
  getAccessLink:
    (id, onSuccess) =>
    async ({ Api }) => {
      let { result, status } = await Api.get(
        `ProjectCorporateReports/${id}/access-link`
      );
      status === 200 && onSuccess(result);
    },
};

const corporateReportDispatcher = synthesize(
  'corporateReport',
  mapDispatchToAC
);
export default corporateReportDispatcher;
