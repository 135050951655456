import { API_ROOT } from '../common';
import { urlLabel } from '@/enum/PermissionEnum';
import CRReportPDF from '@/new-components/CRReportPDF';
import customToast from '@/new-components/CustomNotification';
import { get } from 'lodash';
import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const CRReportViewerDownload = (props) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const { pdfDoc } = useSelector((state) => state.globalState);
  const [corporateData, setCorporateData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (!(props.accessToken || accessToken)) {
      window.location.reload();
      return history.push(
        `/${urlLabel.previewDownloadCRReport}?requestKey=${localStorage.getItem(
          'requestKey'
        )}&hrEmail=${localStorage.getItem('hrEmail')}`
      );
    }
    fetch(`${API_ROOT}ProjectCorporateReports/${props.projectId}/data`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${props.accessToken || accessToken}`,
      },
    })
      .then((res) => {
        if (
          get(res, 'status') === 401 &&
          window.location.hash.includes(`${urlLabel.previewDownloadCRReport}`)
        ) {
          customToast('error', 'Token was expired!');
          window.location.href = `https://${
            process.env.REACT_APP_MINC_DOMAIN
          }/#/${
            urlLabel.previewDownloadCRReport
          }?requestKey=${localStorage.getItem(
            'requestKey'
          )}&hrEmail=${localStorage.getItem('hrEmail')}`;
          window.location.reload();
        } else if (get(res, 'status') === 200) {
          return res.json();
        }
      })
      .then((res) => {
        if (get(res, 'statusCode') === 200) {
          setCorporateData(res?.data);
        } else {
          customToast('error', res.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history]);
  useEffect(() => {
    if (
      pdfDoc &&
      corporateData &&
      props.downloading &&
      Object.keys(props.downloading).length > 0
    ) {
      const blob = pdfDoc
        .setProperties({
          title: 'CR Report',
        })
        .output('blob', {
          filename: `CR_Report_${corporateData.projectInfo.projectCode}.pdf`,
        });
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `CR_Report_${corporateData.projectInfo.projectCode}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
      props.resetData();
    }
  }, [pdfDoc]);
  return (
    <Container>
      <LinkContainer>
        <CRReportPDF corporateData={corporateData} />
      </LinkContainer>
    </Container>
  );
};

export default CRReportViewerDownload;

const Container = styled.div`
  display: flex;
  align-items: center;
  visibility: hidden;
  height: 0;
  width: 0;
`;
const LinkContainer = styled.div`
  flex: 1 1 0px;
`;
