import telecounselAppointmentConfigDispatch from './action';
import GroupToolbar from './components/GroupToolbar';
import TableBodyBooking from './components/TableBody';
import TableHeadBooking from './components/TableHead';
import { TYPE_BOOKING_TIME } from './constants';
import { groupDataByTime } from './utils';
import { CreateNewIcon } from '@/assets/svg';
import ModalAddTimeslotAppt from '@/new-components/CustomModal/ModalAddTimeslotAppt';
import ModalEditTimeslotAppt from '@/new-components/CustomModal/ModalEditTimeslotAppt';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import { Table, makeStyles, Button, Divider } from '@material-ui/core';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  container: {
    minHeight: 842,
    boxSizing: 'border-box',
    padding: '0 8px',
    marginBottom: 16,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
  btnBooking: {
    width: 200,
    height: 40,
    borderRadius: 20,
  },
});

const toDay = moment().startOf('week').add('day', 1);

const TelecounselAppointmentConfig = () => {
  const classes = useStyles();
  const [weekPicker, setWeekPicker] = useState(toDay);
  const [currentBookingItem, setCurrentBookingItem] = useState({});
  const [openAddTimeslotItem, setOpenAddTimeslotItem] = useState(false);
  const [openEditBookingTimeModal, setOpenEditBookingTimeModal] =
    useState(false);

  const { bookings: bookingsDateRaw, headingKeys } = useSelector(
    (state) => state.telecounselApptConfig
  );

  const bookings = groupDataByTime(bookingsDateRaw);

  const onClickToday = () => {
    setWeekPicker(toDay);
  };

  useEffect(() => {
    telecounselAppointmentConfigDispatch.getDataBooking({
      week: weekPicker,
      type: TYPE_BOOKING_TIME.TeleCounsel,
    });
  }, [weekPicker]);

  const onChangeWeekPicker = (value) => {
    setWeekPicker(value);
  };

  const onCountWeekPicker = (isAdd) => {
    if (isAdd) {
      setWeekPicker((weekPicker) => moment(weekPicker).add('week', 1));
    } else {
      setWeekPicker((weekPicker) => moment(weekPicker).subtract('week', 1));
    }
  };

  const updateBookingItem = (item) => {
    telecounselAppointmentConfigDispatch.updateBookingTime(
      { ...item, type: TYPE_BOOKING_TIME.TeleCounsel },
      () => {
        setOpenEditBookingTimeModal(false);
        setCurrentBookingItem({});
        customToast(
          'success',
          <span>
            Timeslots has been successfully updated for{' '}
            <strong style={{ fontWeight: 600 }}>
              {moment(item.date).format('DD/MM/YYYY')}
            </strong>
            .
          </span>,
          'Timeslots updated'
        );
      },
      () => {
        customToast('error', 'Update booking fail');
      }
    );
  };

  const submitFormEditBooking = (data, cb) => {
    telecounselAppointmentConfigDispatch.updateBlockBooking(
      { ...data, type: TYPE_BOOKING_TIME.TeleCounsel },
      () => {
        cb();
        setOpenAddTimeslotItem(false);
        telecounselAppointmentConfigDispatch.getDataBooking({
          week: weekPicker,
          type: TYPE_BOOKING_TIME.TeleCounsel,
        });
        customToast(
          'success',
          <span>
            Timeslots has been successfully added for{' '}
            <strong style={{ fontWeight: 600 }}>
              {data.startDate.format('DD/MM/YYYY')} -{' '}
              {data.endDate.format('DD/MM/YYYY')}
            </strong>
            .
          </span>,
          'Timeslots added'
        );
      },
      () => {
        cb();
      }
    );
  };

  return (
    <>
      <CustomPaperContainer
        header={
          <CustomHeader
            title="Appointments"
            isSearch={false}
            renderToolbar={() => (
              <GroupToolbar
                setWeekPicker={setWeekPicker}
                weekPicker={weekPicker}
                onChangeWeekPicker={onChangeWeekPicker}
                onClickToday={onClickToday}
                onCountWeekPicker={onCountWeekPicker}
              />
            )}
            renderButton={() => (
              <Button
                color="primary"
                variant="contained"
                startIcon={<CreateNewIcon />}
                onClick={() => setOpenAddTimeslotItem(true)}
              >
                Add timeslots
              </Button>
            )}
          />
        }
      >
        <Divider light />
        <Table aria-label="simple table" className={classes.table}>
          <TableHeadBooking keys={headingKeys} value={weekPicker} />
          <TableBodyBooking
            setCurrentBookingItem={setCurrentBookingItem}
            openEditModal={() => setOpenEditBookingTimeModal(true)}
            keys={headingKeys}
            data={bookings}
          />
        </Table>
      </CustomPaperContainer>
      {openAddTimeslotItem && (
        <ModalAddTimeslotAppt
          onSubmit={submitFormEditBooking}
          open={openAddTimeslotItem}
          onClose={() => setOpenAddTimeslotItem(false)}
        />
      )}
      {openEditBookingTimeModal && currentBookingItem && (
        <ModalEditTimeslotAppt
          value={currentBookingItem}
          onSubmit={updateBookingItem}
          open={openEditBookingTimeModal}
          onClose={() => setOpenEditBookingTimeModal(false)}
        />
      )}
    </>
  );
};

export default TelecounselAppointmentConfig;
