import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';

const useStyles = makeStyles({
  table: {
    overflow: 'auto',
    borderRadius: '1.68vw',
    color: '#333333',
    boxShadow: 'none',

    '& tr:last-child td:first-child': {
      border: '2px solid #fff',
      borderBottomLeftRadius: '1.68vw',
    },

    '& tr:last-child td:last-child': {
      border: '2px solid #fff',
      borderBottomRightRadius: '1.68vw',
    },
    '& tr:first-child th:first-child': {
      border: '2px solid #fff',
      borderTopLeftRadius: '1.68vw',
    },

    '& tr:first-child th:last-child': {
      border: '2px solid #fff',
      borderTopRightRadius: '1.68vw',
    },

    '& .MuiTableHead-root > .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        padding: '4px 16px',
        backgroundColor: 'transparent',
        verticalAlign: 'middle',

        fontSize: '1.34vw',
        fontWeight: 400,
        border: '2px solid #fff',
        lineHeight: '2.29vw',
      },
    },
  },
  tableBody: {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        padding: '12px 16px',
        fontSize: '1.34vw', // 10px / (595 /100vw)px
        verticalAlign: 'middle',
        border: '2px solid #fff',
      },
    },
  },
  tableCellFooter: {
    borderBottom: 'none',
  },
});
const TableCom = ({ data, lastRowData, nphsYear }) => {
  const classes = useStyles();

  const columns = useMemo(() => {
    if (isEmpty(data)) return [];
    return data[0]?.map((it) => it);
  }, [data]);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {columns.map((item) => (
          <TableCell
            key={item.value}
            style={{
              padding: 12,
              minWidth: item.minWidth,
              maxWidth: item.maxWidth,
              width: item.width,
              backgroundColor: item.color,
              height: item.height,
            }}
            align="center"
          >
            {item.value}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  return (
    <TableContainer elevation={0} component={Paper}>
      <Table className={classes.table}>
        <TableHeader />

        <TableBody className={classes.tableBody}>
          {!isEmpty(data) &&
            data.map((rows, rowsInx) => {
              return (
                rowsInx !== 0 && (
                  <TableRow key={`${rowsInx}`}>
                    {rows.map((cell, cellsInx) => (
                      <TableCell
                        key={`row${rowsInx}-${cellsInx}`}
                        align="center"
                        style={{
                          backgroundColor: cell.color,
                          height: cell.height,
                        }}
                      >
                        {cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              );
            })}
          {nphsYear ? (
            <TableRow>
              <TableCell
                align="center"
                style={{
                  backgroundColor: '#E0E0E0',
                  height: '4.03vw',
                }}
              >
                NPHS {nphsYear}
              </TableCell>
              {lastRowData.map((cell) => (
                <TableCell
                  align="center"
                  colSpan={cell.colSpan}
                  style={{
                    backgroundColor: cell.color,
                  }}
                >
                  {cell.value}%
                </TableCell>
              ))}
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableCom;
