import { isEmpty } from 'lodash';
import moment from 'moment';

// Set week start on Monday
moment.updateLocale('en', { week: { dow: 1 } });

export const getGradient = (
  ctx,
  chartArea,
  startColor = '#FFE9FB',
  endColor = '#ffffff'
) => {
  let gradient = ctx.createLinearGradient(
    0,
    chartArea.bottom,
    0,
    chartArea.top
  );
  gradient.addColorStop(0.9, startColor);
  gradient.addColorStop(0, endColor);
  return gradient;
};

export const chartPlugins = [
  {
    id: 'hoverLine',
    afterDatasetsDraw: (chart, args, opts) => {
      const { ctx, tooltip, chartArea } = chart;
      const { top, bottom } = chartArea;

      if (!isEmpty(tooltip?._active)) {
        const activePoint = tooltip?._active[0];
        const xCoor = activePoint.element.x;

        ctx.save();

        ctx.beginPath();
        ctx.lineWidth = opts.width;
        ctx.strokeStyle = '#e1e1e1';
        ctx.setLineDash([6, 6]);
        ctx.moveTo(xCoor, top - 5);
        ctx.lineTo(xCoor, bottom);
        ctx.stroke();
        ctx.closePath();

        ctx.restore();
      }
    },
  },
];

export const renderOptionLineChart = (label, type) => {
  return {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: 'nearest',
        backgroundColor: '#333333',
        displayColors: false,
        titleColor: '#999999',
        bodyColor: '#c1c1c1',
        bodySpacing: 4,
        events: ['click'],

        titleFont: {
          weight: 600,
          family: 'OpenSans',
          size: 14,
        },

        bodyFont: {
          weight: 500,
          family: 'OpenSans',
          size: 14,
        },

        callbacks: {
          title: renderToolTipsTitle,
          label: renderToolTips(label, type),
        },
      },
    },
    scales: {
      x: {
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          font: {
            family: 'OpenSans',
            size: 14,
          },
          callback: function (value) {
            let newLabel = this.getLabelForValue(value);
            if (moment(newLabel, 'dddd').isValid()) {
              newLabel = moment(newLabel, 'dddd').format('ddd');
            }

            if (moment(newLabel, 'MMM').isValid()) {
              newLabel = moment(newLabel, 'MMM').format('MMM');
            }
            return newLabel;
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          font: {
            family: 'OpenSans',
            size: 14,
          },
          callback: renderYAxisLabel(type),
        },
      },
    },
  };
};

export const renderOptionBarChart = (
  label,
  type,
  xAxisHoursLabel = false,
  displayYAxis = false
) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: 'nearest',
        backgroundColor: '#333333',
        displayColors: false,
        titleColor: '#999999',
        bodyColor: '#c1c1c1',
        bodySpacing: 4,
        events: ['click'],

        titleFont: {
          weight: 600,
          family: 'OpenSans',
          size: 14,
        },

        bodyFont: {
          weight: 500,
          family: 'OpenSans',
          size: 14,
        },

        callbacks: {
          title: renderToolTipsTitle,
          label: renderToolTips(label, type),
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          font: {
            family: 'OpenSans',
            size: 14,
          },
          callback: function (value, index, ticks) {
            let newLabel = this.getLabelForValue(value);

            if (moment(newLabel, 'dddd').isValid()) {
              newLabel = moment(newLabel, 'dddd').format('ddd');
            }

            if (xAxisHoursLabel) {
              newLabel =
                index % 6 === 0 || index === ticks[ticks.length - 1].value
                  ? newLabel
                  : '';
            }
            return newLabel;
          },
        },
      },
      y: {
        display: displayYAxis,
        stacked: true,
        grid: {
          drawBorder: false,
          display: false,
        },
        beginAtZero: true,
        ticks: {
          font: {
            family: 'OpenSans',
            size: 14,
          },
          callback: renderYAxisBarLabel(type),
        },
      },
    },
  };
};

export const renderLineChartData = (labels = [], data = [], label = '') => {
  return {
    labels,
    datasets: [
      {
        fill: true,
        label,
        data,
        radius: 1,
        borderColor: '#AD5E99',
        pointBackgroundColor: '#AD5E99',
        pointHoverBorderColor: '#ffe9fb',
        pointBorderColor: '#AD5E99',
        borderWidth: 2,
        tension: 0.1,
        spanGaps: false,
        pointBorderWidth: 0,

        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          // This case happens on initial chart load
          if (!chartArea) return;
          return getGradient(ctx, chartArea);
        },
      },
    ],
  };
};

export const renderBarChartData = (
  labels = [],
  data = [],
  label = '',
  barThickness = 16
) => {
  const backgroundColor = data.map((x) =>
    x === Math.max(...data) ? '#AD5E99' : '#c1c1c1'
  );

  return {
    labels,
    datasets: [
      {
        label,
        data,
        backgroundColor,
        borderRadius: 2,
        barThickness,
      },
    ],
  };
};

export const formatCash = (n) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'k';
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
};

export const getMaxValue = (array) => {
  if (isEmpty(array)) return;
  return array.map((x, i) => [x, i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1];
};

export const renderToolTips =
  (label, unitType = '') =>
  (tooltipItem) => {
    let value = '';
    switch (unitType) {
      case 'number':
        value = formatCash(tooltipItem.raw);
        break;

      case 'time':
        value = moment('00:00:00', 'HH:mm:ss')
          .add(tooltipItem.raw * 60, 'seconds')
          .format('m [min] s [sec]');
        break;

      default:
        value = `${tooltipItem.raw}${unitType}`;
        break;
    }

    return label + ' '.repeat(label.length < 10 ? 30 : 10) + value;
  };

export const renderToolTipsTitle = (tooltipItem) => {
  const title = tooltipItem[0].label;
  return title;
};

export const renderYAxisLabel =
  (labelType = '') =>
  (labelItem) => {
    let label = '';
    switch (labelType) {
      case 'number':
        label = formatCash(labelItem);
        break;

      case 'time':
        label =
          labelItem !== 0
            ? moment('00:00:00', 'HH:mm:ss')
                .add(labelItem * 60, 'seconds')
                .format("m[']s")
            : labelItem;
        break;

      default:
        label = `${labelItem}${labelType}`;
        break;
    }
    return label;
  };

export const renderYAxisBarLabel =
  (labelType = '') =>
  (labelItem, labelIndex) => {
    let label = `${labelItem}${labelType}`;
    // return labelIndex % 2 === 0 ? label : '';
    return label;
  };

export const DEFAULT_DATE_FILTER_TAB = [
  {
    key: 'Week',
    filterOptions: {
      filterBy: 'Week',
      fromDate: moment().startOf('week'),
      toDate: moment().endOf('week'),
    },
  },
  {
    key: 'Month',
    filterOptions: {
      filterBy: 'Month',
      fromDate: moment().startOf('month'),
      toDate: moment().endOf('month'),
    },
  },
  {
    key: 'Year',
    filterOptions: {
      filterBy: 'Year',
      fromDate: moment().startOf('year'),
      toDate: moment().endOf('year'),
    },
  },
];
